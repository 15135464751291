import moment from 'moment';
import TrainingRequestSession from '../../shared/interfaces/TrainingRequestSession';
import { TrainingRequest, TrainingRequestChannel, TrainingRequestStatus, RateUnit } from '../../shared/interfaces/TrainingRequest';

export const baseUrl = 'requests';

export const newTrainingRequestSession: TrainingRequestSession = {
  '@id': '0',
  id: 0,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),
  location: undefined,
  isDaytimeSession: false,
  trainingRequest: undefined
};

export const newTrainingRequest: TrainingRequest = {
  '@id': '0',
  id: 0,
  orderDocument: undefined,
  type: undefined,
  firstName: '',
  lastName: '',
  email: '',
  phoneNr: '',
  companyName: '',
  vatNr: '',
  hasKmoWallet: false,
  remarks: '',
  channel: TrainingRequestChannel.Others,
  status: TrainingRequestStatus.NewRequest,
  totalParticipants: 0,
  training: undefined,
  trainingType: undefined,
  requestIsCancelled: false,
  cancellationReason: '',
  customer: undefined,
  trainingRequestSessions: [],
  rate: undefined,
  rateUnit: RateUnit.PerDay,
  course: undefined
};
