export const toTrainingQuery = (cursor: string) => {
  return `{
      trainings(first: 500 ${cursor ? `after: "${cursor}"` : ''}) {
        edges {
          cursor
          node {
            _id,
            course {
              title
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }`;
};
