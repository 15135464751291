import React, { Fragment, SyntheticEvent } from 'react';
import { Pagination } from 'semantic-ui-react';
import DataTable from './DataTable';
import DataTableFilterBox from './DataTableFilterBox';
import ConfirmDialog from '../ConfirmDialog';
import QueryParams from '../../interfaces/QueryParams';
import { RouteComponentProps } from 'react-router';
import { queryParams, updateQueryString } from '../../helpers/queryStringHelpers';
import { WithNamespaces, withNamespaces } from 'react-i18next';

export type DataTableWrapperProps = RouteComponentProps &
  WithNamespaces & {
    children?: React.ReactNode;
    onSubmit: (value: string | number) => void;
    defaultActivePage: number;
    totalPages: number;
    onPageChange: any;
    fieldSet: { header: string; key: string }[];
    withActionButtons: boolean;
    tableData: any;
    activeId: number;
    activeSortHeader: string | undefined;
    dialogOpen: boolean;
    dialogContent: string;
    dialogHeader: string;
    onDialogCancel: (e: SyntheticEvent) => void;
    onDialogClose: (e: SyntheticEvent) => void;
    onDialogConfirm: (e: SyntheticEvent) => void;
    onSelect: (key: number) => void;
    onArchive: (key: number) => void;
    onEdit?: (key: number) => void;
    enabledSortingHeaders?: string[];
  };

function onHeaderClick(props: RouteComponentProps, header: string) {
  const params = queryParams<QueryParams>(props.location.search);
  const currentOrder = params.order;
  const sameSort = params.sort === header;
  updateQueryString<QueryParams>(props, {
    page: 1,
    sort: header,
    order: sameSort ? (currentOrder === 'asc' ? 'desc' : 'asc') : 'desc'
  });
}

function sortOrder(props: RouteComponentProps) {
  const sortOrder = queryParams<QueryParams>(props.location.search).order;
  return ['asc', 'desc'].includes(sortOrder || '') ? (sortOrder as QueryParams['order']) : undefined;
}

const DataTableWrapper = (props: DataTableWrapperProps) => {
  const {
    children,
    onDialogCancel,
    onDialogConfirm,
    onPageChange,
    onSubmit,
    dialogOpen,
    defaultActivePage,
    dialogContent,
    tableData,
    totalPages,
    activeId,
    activeSortHeader,
    fieldSet,
    onSelect,
    onArchive,
    onEdit,
    dialogHeader,
    enabledSortingHeaders,
    t
  } = props;

  return (
    <Fragment>
      <div style={{ marginBottom: '5px' }}>
        <DataTableFilterBox {...props} onSubmit={onSubmit} />
      </div>
      <div style={{ marginBottom: '5px' }}>{children}</div>
      {tableData.length !== 0 ? (
        <>
          <DataTable
            fieldSet={fieldSet}
            withActionButtons={true}
            rows={tableData}
            onSelect={onSelect}
            onArchive={onArchive}
            onEdit={onEdit}
            activeId={activeId}
            OnHeaderClick={header => onHeaderClick(props, header)}
            activeSortHeader={activeSortHeader}
            direction={sortOrder(props)}
            enabledSortingHeaders={enabledSortingHeaders}
          />
          <Pagination menu="middle" defaultActivePage={defaultActivePage} totalPages={totalPages} onPageChange={onPageChange} />
        </>
      ) : (
        t('no-search-results')
      )}

      <ConfirmDialog
        header={dialogHeader}
        open={dialogOpen}
        content={dialogContent}
        onCancel={onDialogCancel}
        onClose={onDialogCancel}
        onConfirm={onDialogConfirm}
      />
    </Fragment>
  );
};

export default withNamespaces(['common'])(DataTableWrapper);
