import { DeepReadonly } from 'utility-types';
import * as actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { Training, TrainingType, WithTrainingSessions, WithCourse, WithCustomer } from '../../shared/interfaces/Training';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import Course from '../../shared/interfaces/Course';
import { EntityReference } from '../../shared/interfaces/Entity';
import CalendarListItem from '../../shared/interfaces/CalendarLocation';
import TrainingSession, { WithTrainer, WithSessionMaterials } from '../../shared/interfaces/TrainingSession';
import TrainingParticipant from '../../shared/interfaces/TrainingParticipant';
import { TrainingDocument, WithTrainingDocument } from '../../shared/interfaces/TrainingDocument';
import { WithMaterial, TrainingSessionMaterial } from '../../shared/interfaces/TrainingSessionMaterial';
import { Participant, WithParticipations, WithTrainingReference } from '../../shared/interfaces/participant';
import Contract from '../../shared/interfaces/Contract';
import { Mail, WithTrainingDocuments } from '../../shared/interfaces/Mail';
import Contact from '../../shared/interfaces/Contact';

export type TrainingsAction = ActionType<typeof actions>;

export interface GQLTrainingSession {
  _id: number;
  id: EntityReference;
  startDate: string;
  endDate: string;
  deletedAt?: string;
  location?: {
    id: EntityReference;
    name: string;
    displayColor: string;
    deletedAt?: string;
  };
  trainer?: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    deletedAt?: string;
  };
  training?: {
    _id: number;
    course: {
      _id: number;
      title: string;
    };
  };
  courseLink?: string
}

export interface GQLTrainingClient {
  companyName: string;
  email: string;
}

export interface GQLTrainingContact {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface GQLTrainingParticipant {
  _id: number;
  id: EntityReference;
  firstName: string;
  lastName: string;
  email: string;
  customer?: GQLTrainingClient;
  contact: GQLTrainingContact;
  phone?: string;
  cellphone?: string;
  deletedAt?: string;
}

export interface GQLTrainingTask {
  id: EntityReference;
  executed: boolean;
  dueDate: string;
  description: string;
  trainingPredefinedTask?: {
    id: EntityReference;
    description: string;
    trainingType: TrainingType;
  };
}

export interface GQLParticipation {
  _id: number;
  id: EntityReference;
  attended: boolean;
  participant: {
    id: EntityReference;
  };
  trainingSession: {
    id: EntityReference;
  };
}

export type OverviewTraining = Training<WithCustomer & WithCourse & WithTrainingSessions<WithTrainer>>;
export type TrainingSessionWithMaterials = TrainingSession<WithSessionMaterials<WithMaterial> & WithTrainingReference>;

export type TrainingsState = DeepReadonly<{
  calendarListTrainings: CalendarListItem[] | null;
  calendarListTrainingsStatus: ProgressStatus;
  errorMessage: string | null;
  loadingTrainingsStatus: ProgressStatus;
  loadingTrainingSessionStatus: ProgressStatus;
  loadingTrainingSessionsStatus: ProgressStatus;
  loadingTrainingParticipantsStatus: ProgressStatus;
  loadingTrainingTasksStatus: ProgressStatus;
  saveTrainingTaskStatus: ProgressStatus;
  savingTrainingStatus: ProgressStatus;
  savingTrainingSessionStatus: ProgressStatus;
  deleteTrainingSessionStatus: ProgressStatus;
  saveTrainingParticipantStatus: ProgressStatus;
  loadingParticipantStatus: ProgressStatus;
  loadingParticipationsStatus: ProgressStatus;
  saveParticipationStatus: ProgressStatus;
  addFileStatus: ProgressStatus;
  loadFilesStatus: ProgressStatus;
  loadingSessionMaterialStatus: ProgressStatus;
  saveSessionMaterialStatus: ProgressStatus;
  savingTrainerContract: ProgressStatus;
  trainings: OverviewTraining[];
  totalTrainings: number;
  selectedTraining: Training | undefined;
  selectedTrainingCourse: Course | undefined;
  selectedTrainingClient: GQLTrainingClient | undefined;
  selectedTrainingSessions: GQLTrainingSession[] | undefined;
  selectedTrainingParticipants: GQLTrainingParticipant[] | undefined;
  selectedTrainingTasks: GQLTrainingTask[] | undefined;
  selectedTrainingFiles: TrainingDocument<WithTrainingDocument>[] | undefined;
  selectedSession: TrainingSessionWithMaterials | undefined;
  selectedParticipant: Contact | undefined;
  participations: GQLParticipation[] | undefined;
  selectedSessionMaterial: TrainingSessionMaterial | undefined;
  fetchTrainingParticipantsListStatus: ProgressStatus;
  participantsListFileUrl: string | undefined;
  informationFormLink: string | undefined;
  activeTabIndex: { index: number; training: Training } | undefined;
  allParticipants: Participant<WithParticipations>[] | [];
  allTrainerContracts: Contract[] | [];
  contract: string | undefined;
  signedContract: string | undefined;
  mails: Mail<WithTrainingDocument & WithTrainingDocuments>[] | [];
}>;

const initialState: TrainingsState = {
  errorMessage: null,
  trainings: [],
  calendarListTrainings: null,
  calendarListTrainingsStatus: ProgressStatus.Uninitialized,
  totalTrainings: 0,
  loadingTrainingsStatus: ProgressStatus.Uninitialized,
  loadingTrainingSessionStatus: ProgressStatus.Uninitialized,
  loadingTrainingSessionsStatus: ProgressStatus.Uninitialized,
  loadingTrainingParticipantsStatus: ProgressStatus.Uninitialized,
  loadingTrainingTasksStatus: ProgressStatus.Uninitialized,
  saveTrainingTaskStatus: ProgressStatus.Uninitialized,
  savingTrainingStatus: ProgressStatus.Uninitialized,
  savingTrainingSessionStatus: ProgressStatus.Uninitialized,
  deleteTrainingSessionStatus: ProgressStatus.Uninitialized,
  saveTrainingParticipantStatus: ProgressStatus.Uninitialized,
  loadingParticipantStatus: ProgressStatus.Uninitialized,
  loadingParticipationsStatus: ProgressStatus.Uninitialized,
  saveParticipationStatus: ProgressStatus.Uninitialized,
  addFileStatus: ProgressStatus.Uninitialized,
  loadFilesStatus: ProgressStatus.Uninitialized,
  loadingSessionMaterialStatus: ProgressStatus.Uninitialized,
  saveSessionMaterialStatus: ProgressStatus.Uninitialized,
  savingTrainerContract: ProgressStatus.Uninitialized,
  selectedTraining: undefined,
  selectedTrainingCourse: undefined,
  selectedTrainingClient: undefined,
  selectedTrainingSessions: undefined,
  selectedTrainingParticipants: undefined,
  selectedTrainingTasks: undefined,
  selectedSession: undefined,
  selectedParticipant: undefined,
  participations: undefined,
  selectedTrainingFiles: undefined,
  selectedSessionMaterial: undefined,
  fetchTrainingParticipantsListStatus: ProgressStatus.Uninitialized,
  participantsListFileUrl: undefined,
  informationFormLink: undefined,
  activeTabIndex: undefined,
  allParticipants: [],
  allTrainerContracts: [],
  contract: undefined,
  signedContract: undefined,
  mails: []
};

export function trainings(state: TrainingsState = initialState, action: TrainingsAction): TrainingsState {
  switch (action.type) {
    case getType(actions.FetchCalendarListTrainings.request):
      return {
        ...state,
        calendarListTrainingsStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchCalendarListTrainings.success):
      return {
        ...state,
        calendarListTrainingsStatus: ProgressStatus.Done,
        calendarListTrainings: action.payload.calendarListTrainings
      };
    case getType(actions.FetchCalendarListTrainings.failure):
      return {
        ...state,
        calendarListTrainingsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainings.request):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.InProgress,
        trainings: [],
        totalTrainings: 0
      };
    case getType(actions.FetchTrainings.success):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.Done,
        trainings: action.payload.trainings,
        totalTrainings: action.payload.totalTrainings
      };
    case getType(actions.FetchTrainings.failure):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchOneTraining.request):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.InProgress,
        selectedTraining: undefined
      };
    case getType(actions.FetchOneTraining.success):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.Done,
        selectedTraining: action.payload.training,
        selectedTrainingCourse: action.payload.course,
        selectedTrainingClient: action.payload.client
      };
    case getType(actions.FetchOneTraining.failure):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainingSessions.request):
      return {
        ...state,
        loadingTrainingSessionsStatus: ProgressStatus.InProgress,
        selectedTrainingSessions: undefined
      };
    case getType(actions.FetchTrainingSessions.success):
      return {
        ...state,
        loadingTrainingSessionsStatus: ProgressStatus.Done,
        selectedTrainingSessions: action.payload
      };
    case getType(actions.FetchTrainingSessions.failure):
      return {
        ...state,
        loadingTrainingSessionsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainingParticipants.request):
      return {
        ...state,
        loadingTrainingParticipantsStatus: ProgressStatus.InProgress,
        selectedTrainingParticipants: undefined
      };
    case getType(actions.FetchTrainingParticipants.success):
      return {
        ...state,
        loadingTrainingParticipantsStatus: ProgressStatus.Done,
        selectedTrainingParticipants: action.payload
      };
    case getType(actions.FetchTrainingParticipants.failure):
      return {
        ...state,
        loadingTrainingParticipantsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainingTasks.request):
      return {
        ...state,
        loadingTrainingTasksStatus: ProgressStatus.InProgress,
        selectedTrainingTasks: undefined
      };
    case getType(actions.FetchTrainingTasks.success):
      return {
        ...state,
        loadingTrainingTasksStatus: ProgressStatus.Done,
        selectedTrainingTasks: action.payload
      };
    case getType(actions.FetchTrainingTasks.failure):
      return {
        ...state,
        loadingTrainingTasksStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveTrainingTask.request):
      return {
        ...state,
        saveTrainingTaskStatus: ProgressStatus.InProgress
      };
    case getType(actions.SaveTrainingTask.success):
      // eslint-disable-next-line
      const { executed, dueDate } = action.payload;
      return {
        ...state,
        saveTrainingTaskStatus: ProgressStatus.Done,
        selectedTrainingTasks: (state.selectedTrainingTasks || []).map((task: GQLTrainingTask) =>
          task.id === action.payload.taskId
            ? {
                ...task,
                executed: executed === undefined ? task.executed : executed,
                dueDate: dueDate === undefined ? task.dueDate : dueDate
              }
            : task
        )
      };
    case getType(actions.SaveTrainingTask.failure):
      return {
        ...state,
        saveTrainingTaskStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveTraining.request):
      return {
        ...state,
        savingTrainingStatus: ProgressStatus.InProgress
      };
    case getType(actions.SaveTraining.success):
      return {
        ...state,
        savingTrainingStatus: ProgressStatus.Done,
        selectedTraining: action.payload
      };
    case getType(actions.SaveTraining.failure):
      return {
        ...state,
        savingTrainingStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveTrainingSession.request):
      return {
        ...state,
        savingTrainingSessionStatus: ProgressStatus.InProgress
      };
    case getType(actions.SaveTrainingSession.success):
      return {
        ...state,
        savingTrainingSessionStatus: ProgressStatus.Done,
        selectedTrainingSessions: [],
        loadingTrainingSessionsStatus: ProgressStatus.Uninitialized
      };
    case getType(actions.SaveTrainingSession.failure):
      return {
        ...state,
        savingTrainingSessionStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchOneTrainingSession.request):
      return {
        ...state,
        loadingTrainingSessionStatus: ProgressStatus.InProgress,
        selectedSession: undefined
      };
    case getType(actions.FetchOneTrainingSession.success):
      return {
        ...state,
        loadingTrainingSessionStatus: ProgressStatus.Done,
        selectedSession: action.payload
      };
    case getType(actions.FetchOneTrainingSession.failure):
      return {
        ...state,
        loadingTrainingSessionStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.DeleteTrainingSession.request):
      return {
        ...state,
        deleteTrainingSessionStatus: ProgressStatus.InProgress
      };
    case getType(actions.DeleteTrainingSession.success):
      return {
        ...state,
        deleteTrainingSessionStatus: ProgressStatus.Done
      };
    case getType(actions.DeleteTrainingSession.failure):
      return {
        ...state,
        deleteTrainingSessionStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveTrainingParticipant.request):
      return {
        ...state,
        saveTrainingParticipantStatus: ProgressStatus.InProgress
      };
    case getType(actions.SaveTrainingParticipant.success):
      return {
        ...state,
        saveTrainingParticipantStatus: ProgressStatus.Done
      };
    case getType(actions.SaveTrainingParticipant.failure):
      return {
        ...state,
        saveTrainingParticipantStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchOneParticipant.request):
      return {
        ...state,
        selectedParticipant: undefined,
        loadingParticipantStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchOneParticipant.success):
      return {
        ...state,
        loadingParticipantStatus: ProgressStatus.Done,
        selectedParticipant: action.payload
      };
    case getType(actions.FetchOneParticipant.failure):
      return {
        ...state,
        loadingParticipantStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchParticipations.request):
      return {
        ...state,
        loadingParticipationsStatus: ProgressStatus.InProgress,
        participations: []
      };
    case getType(actions.FetchParticipations.success):
      return {
        ...state,
        loadingParticipationsStatus: ProgressStatus.Done,
        participations: action.payload
      };
    case getType(actions.FetchParticipations.failure):
      return {
        ...state,
        loadingParticipationsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveParticipation.request):
      return {
        ...state,
        saveParticipationStatus: ProgressStatus.InProgress
      };
    case getType(actions.SaveParticipation.success):
      return {
        ...state,
        saveParticipationStatus: ProgressStatus.Done
      };
    case getType(actions.SaveParticipation.failure):
      return {
        ...state,
        saveParticipationStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };

    case getType(actions.DeleteTrainingFile.failure):
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };

    case getType(actions.AddFileToTraining.request):
      return {
        ...state,
        addFileStatus: ProgressStatus.InProgress
      };
    case getType(actions.AddFileToTraining.success):
      return {
        ...state,
        addFileStatus: ProgressStatus.Done
      };
    case getType(actions.AddFileToTraining.failure):
      return {
        ...state,
        addFileStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };

    case getType(actions.FetchTrainingFiles.request):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchTrainingFiles.success):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.Done,
        selectedTrainingFiles: action.payload
      };
    case getType(actions.FetchTrainingFiles.failure):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchOneTrainingSessionMaterial.request):
      return {
        ...state,
        loadingSessionMaterialStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchOneTrainingSessionMaterial.success):
      return {
        ...state,
        loadingSessionMaterialStatus: ProgressStatus.Done,
        selectedSessionMaterial: action.payload
      };
    case getType(actions.FetchOneTrainingSessionMaterial.failure):
      return {
        ...state,
        loadingSessionMaterialStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveTrainingSessionMaterial.request):
      return {
        ...state,
        saveSessionMaterialStatus: ProgressStatus.InProgress
      };
    case getType(actions.SaveTrainingSessionMaterial.success):
      return {
        ...state,
        saveSessionMaterialStatus: ProgressStatus.Done,
        selectedSessionMaterial: action.payload
      };
    case getType(actions.SaveTrainingSessionMaterial.failure):
      return {
        ...state,
        saveSessionMaterialStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainingParticipantsList.request):
      return {
        ...state,
        fetchTrainingParticipantsListStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchTrainingParticipantsList.success):
      return {
        ...state,
        fetchTrainingParticipantsListStatus: ProgressStatus.Done,
        participantsListFileUrl: action.payload.fileUrl
      };
    case getType(actions.FetchTrainingParticipantsList.failure):
      return {
        ...state,
        fetchTrainingParticipantsListStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.ClearTrainingSessions):
      return {
        ...state,
        selectedTrainingSessions: []
      };
    case getType(actions.SendInformationForm.success):
      return {
        ...state,
        informationFormLink: action.payload
      };
    case getType(actions.SetTrainingTabIndex):
      return {
        ...state,
        activeTabIndex: { index: action.payload.index, training: action.payload.training }
      };
    case getType(actions.FetchAllParticipants.request):
      return {
        ...state
      };
    case getType(actions.FetchAllParticipants.success):
      return {
        ...state,
        allParticipants: action.payload.allParticipants
      };
    case getType(actions.FetchAllParticipants.failure):
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainerContractsForTraining.request):
      return {
        ...state
      };
    case getType(actions.FetchTrainerContractsForTraining.success):
      return {
        ...state,
        allTrainerContracts: action.payload.allTrainerContracts
      };
    case getType(actions.FetchTrainerContractsForTraining.failure):
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainerContract.request):
      return {
        ...state
      };
    case getType(actions.FetchTrainerContract.success):
      return {
        ...state,
        [action.payload.type]: action.payload.fileUrl
      };
    case getType(actions.FetchTrainerContract.failure):
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveTrainingContract.request):
      return {
        ...state,
        savingTrainerContract: ProgressStatus.InProgress
      };
    case getType(actions.SaveTrainingContract.success):
      return {
        ...state,
        savingTrainerContract: action.payload ? ProgressStatus.Done : ProgressStatus.Error
      };
    case getType(actions.SaveTrainingContract.failure):
      return {
        ...state,
        savingTrainerContract: ProgressStatus.Error
      };

    case getType(actions.FetchTrainingMails.request):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchTrainingMails.success):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.Done,
        mails: action.payload
      };
    case getType(actions.FetchTrainingMails.failure):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };

    default:
      return state;
  }
}
