import React, { Component, Fragment, SyntheticEvent } from 'react';
import { GlobalState } from '../../core/state';
import { getTaskLists, getSelectedTaskList, getSelectedTaskListId, getAmountPages } from '../state/selectors';
import { getPathName } from '../../core/state/selectors';
import QueryParams from '../../shared/interfaces/QueryParams';
import * as actions from '../state/actions';
import { Dispatch } from 'redux';
import { RouteComponentProps, match } from 'react-router';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { queryParams, updateQueryString } from '../../shared/helpers/queryStringHelpers';
import InitialLoader from '../../shared/components/InitialLoader';
import DataTableWrapper from '../../shared/components/DataTable/DataTableWrapper';
import NoDataFound from '../../shared/components/NoDataFound';
import { dataTableModel } from '../helpers/DataTableFields';
import { ConfirmAction } from '../../core/state/actions';
import isEqual from 'lodash/isEqual';
import { Button, PaginationProps } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import TaskList from '../../shared/interfaces/TaskList';
import PageHeader from '../../shared/components/PageHeader';

type TaskListsProps = {
  taskLists: TaskList[];
  selectedTaskList: TaskList | null;
  selectedTaskListId: number;
  clearTaskLists: () => void;
  loadTaskLists: (queryParams: QueryParams) => void;
  taskListsLoaded: boolean;
  match: match;
  history: History;
  onHeaderClick: (key: string) => void;
  pages: number;
  pathName: string;
  taskListStatus: ProgressStatus;
  deleteTaskList: (taskListId: number, queryParams: QueryParams) => void;
} & RouteComponentProps &
  WithNamespaces;

interface TaskListsState {
  open: boolean;
  isCalendarView: boolean;
  taskListIdToDelete: number;
}

class TaskLists extends Component<TaskListsProps, TaskListsState> {
  state: TaskListsState = {
    isCalendarView: false,
    open: false,
    taskListIdToDelete: -1
  };

  componentDidMount = () => {
    this.props.loadTaskLists(this.queryParams());
  };

  componentWillUpdate(nextProps: TaskListsProps) {
    const nextQueryparams = this.queryParams(nextProps);
    const queryChanged = !isEqual(this.queryParams(), nextQueryparams);
    if (queryChanged) {
      this.props.loadTaskLists(nextQueryparams);
    }
  }

  queryParams(props: TaskListsProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  handleCancel = () => this.setState({ open: false });

  filterResults = (value: string | number) => {
    updateQueryString(this.props, {
      searchValue: value,
      page: 1,
      sort: undefined,
      order: undefined
    });
  };

  handlePaginationChange = (e: SyntheticEvent, { activePage }: PaginationProps) => {
    updateQueryString(this.props, { page: activePage });
  };

  renderTable = () => {
    const { taskListStatus, taskLists, selectedTaskListId, history, match, pages, t, deleteTaskList } = this.props;
    const { page, sort } = this.queryParams();

    const taskListsWithLink = taskLists.map((taskList: TaskList) => ({
      ...taskList,
      predefinedTasks: <span>{taskList.trainingPredefinedTasks.length}</span>,
      name: <Link to={`/task-lists/${taskList.id}`}>{taskList.name}</Link>
    }));

    switch (taskListStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        return (
          <DataTableWrapper
            {...this.props}
            onDialogCancel={this.handleCancel}
            onDialogClose={this.handleCancel}
            onDialogConfirm={() => {}}
            onPageChange={this.handlePaginationChange}
            onSubmit={this.filterResults}
            dialogOpen={this.state.open}
            defaultActivePage={page || 1}
            dialogContent={t('confirmArchiveBody')}
            dialogHeader={t('confirmArchiveHeader')}
            tableData={taskListsWithLink}
            totalPages={pages}
            withActionButtons={true}
            activeId={selectedTaskListId}
            activeSortHeader={sort}
            fieldSet={dataTableModel.map(header => ({
              header: t(header),
              key: header
            }))}
            onSelect={(id: number) => history.push(`${match.path}/${id}`)}
            onArchive={(id: number) => deleteTaskList(id, this.queryParams())}
          />
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <PageHeader {...this.props} breadCrumbParts={[t('task-lists')]} title={t('task-lists')} />
        <Button
          positive
          className="primary mb-3"
          content="Takenlijst toevoegen"
          onClick={() => this.props.history.push(this.props.match.path + '/create')}
        />
        {this.renderTable()}
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    taskLists: getTaskLists(state.taskLists) as TaskList[],
    selectedTaskList: getSelectedTaskList(state.taskLists) as TaskList | null,
    selectedTaskListId: getSelectedTaskListId(state.taskLists) as number,
    pages: getAmountPages(state.taskLists) as number,
    taskListStatus: state.taskLists.loadingTaskListsStatus,
    pathName: getPathName(state.router)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadTaskLists: (queryParams: QueryParams) => dispatch(actions.FetchTaskLists.request(queryParams)),
  deleteTaskList: (taskListId: number, queryParams: QueryParams) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteTaskList',
        content: 'confirmDeleteTaskList',
        action: () => dispatch(actions.DeleteTaskList.request({ taskListId, queryParams }))
      })
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('tasks')(TaskLists));
