import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from '../state';
import { Dispatch } from 'redux';
import { AppAction, ConfirmationModalOptions } from '../state/app.state';
import * as actions from '../state/actions';
import { Modal } from 'semantic-ui-react';
import { translate, WithNamespaces } from 'react-i18next';

type Props = WithNamespaces & {
  confirmationDialog: ConfirmationModalOptions | undefined;
  approve: (action: Function) => void;
  cancel: () => void;
};

class ConfirmationModal extends React.Component<Props> {
  render() {
    const { t, confirmationDialog, approve, cancel } = this.props;

    if (!confirmationDialog) {
      return null;
    }

    return (
      <Modal
        closeIcon
        open={true}
        header={t(confirmationDialog.title)}
        onClose={cancel}
        content={confirmationDialog.content ? t(confirmationDialog.content) : null}
        actions={[
          { key: 'no', content: t('no'), onClick: cancel },
          { key: 'yes', content: t('yes'), positive: true, onClick: () => approve(confirmationDialog.action) }
        ]}
      />
    );
  }
}

export default connect(
  (state: GlobalState) => ({
    confirmationDialog: state.app.confirmationDialog as ConfirmationModalOptions
  }),
  (dispatch: Dispatch<AppAction>) => ({
    approve: (action: Function) => dispatch(actions.ConfirmAction.success(action)),
    cancel: () => dispatch(actions.ConfirmAction.failure())
  })
)(translate(['common', 'clients', 'courses', 'locations', 'requests', 'trainers', 'trainings'], { nsMode: 'fallback' })(ConfirmationModal));
