import React, { Dispatch } from 'react';
import { List } from 'semantic-ui-react';
import { GQLTrainingTask, trainings } from '../../../state/state';
import ProgressStatus from '../../../../shared/interfaces/ProgressStatus';
import { RouteComponentProps } from 'react-router';
import { Training } from '../../../../shared/interfaces/Training';
import moment, { Moment } from 'moment';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { EntityReference } from '../../../../shared/interfaces/Entity';
import { FormikValues } from 'formik';
import { connect } from 'react-redux';
import * as actions from '../../../state/actions';
import { TrainingTask } from '../../../../shared/interfaces/TrainingTask';
import { WithNamespaces } from 'react-i18next';
import { ConfirmAction } from '../../../../core/state/actions';
import { AnyAction } from 'redux';
import { Task } from '../../../../shared/components/Task';
import { Input, Skeleton, Button, Empty } from 'antd';

type Props = {
  tasks: GQLTrainingTask[] | undefined;
  tasksStatus: ProgressStatus;
  saveTaskStatus: ProgressStatus;
  training: Training;
  saveTask: (data: { taskId: EntityReference; executed?: boolean; dueDate?: string }) => void;
  saveNewTask: (task: TrainingTask) => void;
  generatePredefinedTasks: (trainingId: number) => void;
} & RouteComponentProps &
  WithNamespaces;

function getDueDateColor(dueDate: Moment): SemanticCOLORS | undefined {
  const now = moment();

  if (now.isAfter(dueDate)) {
    return 'red';
  }

  if (dueDate.subtract(7, 'days').isBefore(now)) {
    return 'orange';
  }

  return undefined;
}

class TasksView extends React.Component<Props> {
  state = {
    inputVisible: false,
    inputValue: ''
  };

  saveNewTask(values: FormikValues) {
    const { saveNewTask } = this.props;
    const task = {
      training: this.props.training['@id'],
      executed: false,
      ...values
    } as TrainingTask;
    saveNewTask(task);
  }

  toggleTask(taskId: EntityReference, executed: boolean) {
    if (this.props.saveTaskStatus !== ProgressStatus.InProgress) {
      this.props.saveTask({ taskId, executed });
    }
  }

  updateDueDate(task: GQLTrainingTask, date: Moment) {
    if (!date) {
      return;
    }

    this.props.saveTask({
      taskId: task.id,
      dueDate: date.toISOString(true)
    });
  }

  renderTaskRow(task: GQLTrainingTask, index: number) {
    const dueDate = task.dueDate ? moment(task.dueDate) : null;

    return (
      <List.Item key={task.id}>
        <Task
          executed={task.executed}
          description={task.description}
          dueDate={dueDate}
          onToggle={() => this.toggleTask(task.id, !task.executed)}
          onDateChange={date => this.updateDueDate(task, date)}
        />
      </List.Item>
    );
  }

  renderSessions() {
    const { tasks, tasksStatus, t } = this.props;

    if (tasksStatus === ProgressStatus.InProgress) {
      return <Skeleton />;
    }

    return (
      <List relaxed>
        {tasks && tasks.length > 0 ? (
          tasks.map(this.renderTaskRow.bind(this))
        ) : (
          <List.Item>
            <List.Content>
              <Empty description={<span>{t('noTasks')}</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </List.Content>
          </List.Item>
        )}
      </List>
    );
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state;

    if (inputValue.length > 1 && inputValue.length < 50) {
      this.saveNewTask({
        description: inputValue,
        dueDate: this.props.training.firstSessionDate ? moment(this.props.training.firstSessionDate, 'DD/MM/YYYY').toDate() : moment()
      });
    }

    this.setState({
      inputVisible: false,
      inputValue: ''
    });
  };

  renderForm() {
    return (
      <Input
        type="text"
        value={this.state.inputValue}
        onChange={e => this.setState({ inputValue: e.target.value })}
        onPressEnter={this.handleInputConfirm}
        placeholder={this.props.t('addTask')}
        className="task-input"
        maxLength={50}
        minLength={1}
      />
    );
  }

  render() {
    const { training, generatePredefinedTasks, t } = this.props;
    return (
      <div className="detail-group">
        <h4 className="text-uppercase">{t('tasks')}</h4>
        {this.renderForm()}
        {this.renderSessions()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  saveNewTask: (task: TrainingTask) => dispatch(actions.SaveNewTrainingTask.request(task)),
  generatePredefinedTasks: (trainingId: number) =>
    dispatch(
      ConfirmAction.request({
        title: 'renderPredefinedTasks',
        content: 'confirmGeneratePredefinedTasks',
        action: () => dispatch(actions.FetchPredefinedTasks.request(trainingId))
      })
    )
});

export default connect(null, mapDispatchToProps)(TasksView);
