import React from 'react';
import { Material } from '../../../shared/interfaces/Material';
import PageHeader from '../../../shared/components/PageHeader';
import { RouteComponentProps } from 'react-router';
import { WithNamespaces, translate } from 'react-i18next';
import { Button } from 'semantic-ui-react';

type Props = RouteComponentProps &
  WithNamespaces & {
    material: Material;
  };

const DetailView = (props: Props) => (
  <>
    <PageHeader
      {...props}
      title={props.t('materials')}
      breadCrumbParts={[props.t('materials'), props.material.name]}
      rightBlock={
        <Button className="primary" onClick={() => props.history.push(props.match.url + '/edit')}>
          {props.t('edit')}
        </Button>
      }
    />

    <div className="row contact">
      <div className="col-12 col-md-6">
        <h4>{props.t('name')}</h4>
        <p>{props.material.name}</p>
      </div>

      <div className="col-12 col-md-6">
        <h4>{props.t('price')}</h4>
        <p>€ {props.material.rate}</p>
      </div>

      <div className="col-12 col-md-6">
        <h4>{props.t('color')}</h4>
        <p>
          <div
            style={{
              width: 100,
              height: 100,
              backgroundColor: props.material.displayColor
            }}
          />
        </p>
      </div>
    </div>
  </>
);

export default translate(['materials', 'menu', 'common'], { nsMode: 'fallback' })(DetailView);
