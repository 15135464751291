import React from 'react';
import { Checkbox } from 'semantic-ui-react';
interface ToggleBoxProps {
  onChange: (e: any) => void;
  value: any;
  label: string;
  keyField: string;
}

const ToggleBox = ({ onChange, value, label, keyField }: ToggleBoxProps) => {
  const handleChange = (e: any, { checked, name }: any) => {
    onChange({
      target: {
        name: name,
        value: checked
      }
    });
  };
  return <Checkbox toggle onChange={handleChange} checked={value} name={keyField} label={label} />;
};

export default ToggleBox;
