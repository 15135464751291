import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { configConstants } from '../../config/constants';
import { Trainer, WithCompetencies, WithLanguages } from '../../shared/interfaces/Trainer';
import Course from '../../shared/interfaces/Course';
import { DeepReadonly } from 'utility-types/dist/mapped-types';
import { TrainerTraining } from '../../shared/interfaces/TrainerTraining';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';

export type TrainersAction = ActionType<typeof actions>;
const { pageSize } = configConstants;

// Interface
export type TrainersState = DeepReadonly<{
  loadingTrainersStatus: ProgressStatus;
  loadingCoursesStatus: ProgressStatus;
  loadingTrainingsStatus: ProgressStatus;
  savingTrainerStatus: ProgressStatus;
  deletingTrainerStatus: ProgressStatus;
  trainers: Trainer<WithCompetencies>[];
  errorMessage: string | null;
  selectedTrainerId: number;
  selectedTrainer: Trainer | Trainer<WithLanguages> | null;
  pageSize: number;
  totalTrainers: number;
  types: any;
  courses: Course[];
  trainings: TrainerTraining[];
  activeTabIndex: { index: number; trainer: Trainer } | undefined;
}>;

// Initial State
const initialState: TrainersState = {
  loadingTrainersStatus: ProgressStatus.Uninitialized,
  loadingCoursesStatus: ProgressStatus.Uninitialized,
  loadingTrainingsStatus: ProgressStatus.Uninitialized,
  savingTrainerStatus: ProgressStatus.Uninitialized,
  deletingTrainerStatus: ProgressStatus.Uninitialized,
  errorMessage: null,
  trainers: [],
  selectedTrainerId: -1,
  selectedTrainer: null,
  pageSize: pageSize,
  totalTrainers: 0,
  types: [],
  courses: [],
  trainings: [],
  activeTabIndex: undefined
};

// Reducer
export function trainers(state: TrainersState = initialState, action: TrainersAction): TrainersState {
  switch (action.type) {
    case getType(actions.FetchTrainersSuccess):
      return {
        ...state,
        trainers: action.payload.trainers,
        loadingTrainersStatus: ProgressStatus.Done,
        totalTrainers: action.payload.totalTrainers,
        courses: action.payload.courses
      };
    case getType(actions.SetTrainer):
      return { ...state, selectedTrainerId: action.payload.trainerId };
    case getType(actions.ClearTrainers):
      return { ...state, ...initialState };
    case getType(actions.FetchOneTrainer):
    case getType(actions.FetchTrainers):
      return {
        ...state,
        trainers: [],
        loadingTrainersStatus: ProgressStatus.InProgress
      };
    case getType(actions.DeleteTrainer.request):
      return { ...state, deletingTrainerStatus: ProgressStatus.InProgress };
    case getType(actions.DeleteTrainerSuccess):
      return {
        ...state,
        deletingTrainerStatus: ProgressStatus.Done,
        trainers: state.trainers ? state.trainers.filter(trainer => trainer.id !== action.payload.trainerId) : []
      };
    case getType(actions.SaveTrainer):
      return { ...state, savingTrainerStatus: ProgressStatus.InProgress };
    case getType(actions.SaveTrainerSuccess):
      return {
        ...state,
        savingTrainerStatus: ProgressStatus.Done
      };
    case getType(actions.SaveTrainerFail):
      return {
        ...state,
        savingTrainerStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchOneTrainerSuccess):
      return {
        ...state,
        selectedTrainer: action.payload.trainer as Trainer<WithLanguages>,
        loadingTrainersStatus: ProgressStatus.Done
      };
    case getType(actions.FetchTrainerCourses):
      return {
        ...state,
        loadingCoursesStatus: ProgressStatus.InProgress,
        courses: []
      };
    case getType(actions.FetchTrainerCoursesSuccess):
      return {
        ...state,
        loadingCoursesStatus: ProgressStatus.Done,
        courses: action.payload.courses
      };
    case getType(actions.FetchTrainerCoursesFail):
      return {
        ...state,
        loadingCoursesStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainerTrainings):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.InProgress,
        trainings: []
      };
    case getType(actions.FetchTrainerTrainingsSuccess):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.Done,
        trainings: action.payload.trainings
      };
    case getType(actions.FetchTrainerTrainingsFail):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainersFail):
    case getType(actions.FetchOneTrainerFail):
      return {
        ...state,
        loadingTrainersStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SetTrainerTabIndex):
      return {
        ...state,
        activeTabIndex: { index: action.payload.index, trainer: action.payload.trainer }
      };
    default:
      return { ...state };
  }
}
