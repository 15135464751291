import React from 'react';
import './TeaserView.scss';
import { Icon } from 'semantic-ui-react';
import applyArchiveTagCheck from '../../../shared/helpers/archivedFormatter';

interface TrainerTeaserViewProps {
  name: string;
  deletedAt?: string;
}

class TrainerTeaserView extends React.Component<TrainerTeaserViewProps> {
  render() {
    const { name, deletedAt } = this.props;

    return (
      <div className="trainer-teaser">
        <div className="avatar">
          <Icon name="user" />
        </div>
        <span
          dangerouslySetInnerHTML={{
            __html: applyArchiveTagCheck(name, deletedAt, true, true)
          }}></span>
      </div>
    );
  }
}

export default TrainerTeaserView;
