import React from 'react';
import { GlobalState } from '../../../core/state';
import { Dispatch } from 'redux';
import { TrainingsAction } from '../../state/state';
import { connect } from 'react-redux';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { Training } from '../../../shared/interfaces/Training';
import * as actions from '../../state/actions';
import InitialLoader from '../../../shared/components/InitialLoader';
import NoDataFound from '../../../shared/components/NoDataFound';
import DetailEdit from './DetailEdit';
import DetailView from './DetailView';
import SessionShell from './components/Sessions/Shell';
import ParticipantShell from './components/Participants/Shell';

export interface TrainingRouteInfo {
  trainingId: string;
}

type Props = {
  loadingTrainingStatus: ProgressStatus;
  training: Training | undefined;
  loadTraining: (id: number) => void;
} & RouteComponentProps<TrainingRouteInfo>;

class DetailShell extends React.Component<Props> {
  componentDidMount() {
    const { match, loadTraining } = this.props;
    loadTraining(parseInt(match.params.trainingId));
  }

  render() {
    const { loadingTrainingStatus, training, match } = this.props;

    if (loadingTrainingStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    if (!training) {
      return <NoDataFound />;
    }

    return (
      <Switch>
        <Route exact path={`${match.path}`} component={DetailView} />

        <Route path={`${match.path}/edit`} component={DetailEdit} />

        <Route exact path={`${match.path}/sessions`} render={() => <Redirect to={match.url} />} />
        <Route exact path={`${match.path}/sessions/create`} component={SessionShell} />
        <Route path={`${match.path}/sessions/:sessionId(\\d+)`} component={SessionShell} />

        <Route path={`${match.path}/participants/create`} component={ParticipantShell} />
        <Route path={`${match.path}/participants/:participantId(\\d+)`} component={ParticipantShell} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  loadingTrainingStatus: state.trainings.loadingTrainingsStatus,
  training: state.trainings.selectedTraining as Training
});

const mapDispatchToProps = (dispatch: Dispatch<TrainingsAction>) => ({
  loadTraining: (id: number) => dispatch(actions.FetchOneTraining.request(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailShell);
