import React, { Component, Fragment, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from '../../../core/state';
import { Dispatch } from 'redux';
import { queryParams, updateQueryString } from '../../../shared/helpers/queryStringHelpers';
import QueryParams from '../../../shared/interfaces/QueryParams';
import * as actions from '../../state/actions';
import { RouteComponentProps } from 'react-router';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../../shared/components/InitialLoader';
import NoDataFound from '../../../shared/components/NoDataFound';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import DataTableWrapper from '../../../shared/components/DataTable/DataTableWrapper';
import { PaginationProps, Button } from 'semantic-ui-react';
import PageHeader from '../../../shared/components/PageHeader';
import { getPathName } from '../../../core/state/selectors';
import {
  TrainingRequest,
  WithTrainingRequestSessions,
  WithCourse,
  WithTraining,
  WithTrainingSessions
} from '../../../shared/interfaces/TrainingRequest';
import { configConstants } from '../../../config/constants';
import isEqual from 'lodash/isEqual';
import DataTableFilterBox from '../../../shared/components/DataTable/DataTableAdvancedFilter';
import { FormikValues } from 'formik';
import { searchColumnFields } from '../../helpers/formDefinitions';
import RequestStatusLabel from '../RequestStatusLabel';
import { TrainingRequestsAction } from '../../state/state';
import { AppAction } from '../../../core/state/app.state';
import { ConfirmAction } from '../../../core/state/actions';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { $enum } from 'ts-enum-util';
import { TrainingType } from '../../../shared/interfaces/Training';
import { FormFieldDefinition, DropdownlistFieldDefinition } from '../../../shared/interfaces/FormFieldDefinition';
import Course from '../../../shared/interfaces/Course';
import applyArchiveTagCheck from '../../../shared/helpers/archivedFormatter';

type ListShellProps = {
  loadTrainingRequests: (queryParams: QueryParams) => void;
  deleteRequest: (requestId: number, queryParams: QueryParams) => void;
  trainingRequests: TrainingRequest<WithTrainingRequestSessions & WithCourse & WithTraining>[] | null;
  trainingRequestStatus: ProgressStatus;
  pathName: string;
  totalTrainingRequests: number;
  courses: Course[];
} & RouteComponentProps &
  WithNamespaces;

class ListShell extends Component<ListShellProps> {
  queryParams(props: ListShellProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  componentDidMount = () => {
    const { loadTrainingRequests } = this.props;
    loadTrainingRequests(this.queryParams());
  };

  get page() {
    return this.queryParams().page || 1;
  }

  componentWillUpdate(nextProps: ListShellProps) {
    const nextQueryparams = this.queryParams(nextProps);
    const queryChanged = !isEqual(this.queryParams(), nextQueryparams);
    if (queryChanged) {
      this.props.loadTrainingRequests(nextQueryparams);
    }
  }

  filterResults = (value: string | number) => {
    updateQueryString(this.props, {
      searchValue: value,
      page: 1,
      sort: undefined,
      order: undefined
    });
  };

  filterAdvancedSearch = (values: FormikValues) => {
    updateQueryString(this.props, Object.assign({ page: 1 }, values));
  };

  handlePaginationChange = (e: SyntheticEvent, { activePage }: PaginationProps) => {
    updateQueryString(this.props, { page: activePage });
  };

  toTrainingRequestTableData = (
    trainingRequest: TrainingRequest<WithTrainingRequestSessions & WithCourse & WithTraining & WithTrainingSessions>
  ) => {
    return {
      ...trainingRequest,
      status: <RequestStatusLabel status={trainingRequest.status} as="a" ribbon={false} />,
      createdAt: moment(trainingRequest.createdAt).format('DD/MM/YY'),
      followUpDate: trainingRequest.followUpDate ? moment(trainingRequest.followUpDate).format('DD/MM/YY') : '-',
      startDate:
        trainingRequest.training && trainingRequest.training.trainingSessions[0]
          ? moment(trainingRequest.training.trainingSessions[0].startDate).format('DD/MM/YY')
          : trainingRequest.trainingRequestSessions[0]
          ? moment(trainingRequest.trainingRequestSessions[0].startDate).format('DD/MM/YY')
          : '-',

      course: trainingRequest.course ? (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: applyArchiveTagCheck(trainingRequest.course.title, trainingRequest.course.deletedAt, true, false)
            }}></span>
        </>
      ) : trainingRequest.training ? (
        trainingRequest.training.course ? (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: applyArchiveTagCheck(trainingRequest.training.course.title, trainingRequest.training.deletedAt, true, false)
              }}></span>
          </>
        ) : (
          '-'
        )
      ) : (
        '-'
      ),
      trainingType: trainingRequest.trainingType ? this.props.t($enum(TrainingType).getKeyOrDefault(trainingRequest.trainingType)!) : '-'
    };
  };

  translateFormDefinitions = (formDefinition: FormFieldDefinition[]): FormFieldDefinition[] => {
    const result = formDefinition.map((definition: FormFieldDefinition) => ({
      ...definition,
      label: this.props.t(definition.propertyName || definition.label)
    }));

    return result;
  };

  enrichDropDownList = (courses: Course[] | undefined, formDefinitions: Array<FormFieldDefinition>) => {
    if (courses) {
      const coursesOptions = courses.map((course: Course) => ({ key: course.id, text: course.title, value: course.id }));

      return formDefinitions.map(element => {
        if (element.propertyName === 'requestCourse') {
          return { ...(element as DropdownlistFieldDefinition), dropDownOptions: coursesOptions };
        }
        return element;
      });
    }
    return [];
  };

  renderTrainingRequestList = () => {
    const { trainingRequests, trainingRequestStatus, t, match, history, totalTrainingRequests, courses } = this.props;
    switch (trainingRequestStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        return (
          <DataTableWrapper
            {...this.props}
            onDialogCancel={() => {}}
            onDialogClose={() => {}}
            onDialogConfirm={() => {}}
            onPageChange={this.handlePaginationChange}
            onSubmit={this.filterResults}
            dialogOpen={false}
            defaultActivePage={this.queryParams().page || 1}
            dialogContent={t('confirmArchiveBody')}
            dialogHeader={t('confirmArchiveHeader')}
            tableData={trainingRequests!.map(this.toTrainingRequestTableData)}
            totalPages={Math.ceil(totalTrainingRequests / configConstants.pageSize)}
            withActionButtons={true}
            activeId={0}
            activeSortHeader={this.queryParams().sort}
            fieldSet={['createdAt', 'startDate', 'followUpDate', 'course', 'trainingType', 'status'].map(header => ({
              header: t(header === 'createdAt' ? 'createdAt' : header),
              key: header
            }))}
            onSelect={(id: number) => history.push(`${match.path}/${id}`)}
            onArchive={(id: number) => this.props.deleteRequest(id, this.queryParams())}>
            <DataTableFilterBox
              {...this.props}
              fieldSet={['id', 'course', 'training', 'createdAt', 'trainingFirstSessionDate', 'followUpDate', 'trainingType', 'status']}
              searchFields={this.enrichDropDownList(courses, searchColumnFields)}
            />
          </DataTableWrapper>
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <PageHeader {...this.props} breadCrumbParts={[t('requests')]} title={t('moduleTitle')} />
        <Link to={this.props.match.path + '/create'}>
          <Button positive className="primary" content={t('createRequest')} />
        </Link>
        <br /> <br />
        {this.renderTrainingRequestList()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    pathName: getPathName(state.router),
    trainingRequests: state.requests.trainingRequests as TrainingRequest<WithTrainingRequestSessions & WithCourse>[],
    trainingRequestStatus: state.requests.loadTrainingRequestStatus,
    totalTrainingRequests: state.requests.totalTrainingRequests,
    courses: state.requests.courses as Course[]
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TrainingRequestsAction | AppAction>) => {
  return {
    loadTrainingRequests: (queryParams: QueryParams) => dispatch(actions.FetchTrainingRequests.request(queryParams)),
    deleteRequest: (requestId: number, queryParams: QueryParams) =>
      dispatch(
        ConfirmAction.request({
          title: 'deleteRequest',
          content: 'confirmDeleteRequest',
          action: () => dispatch(actions.DeleteTrainingRequest.request({ requestId, queryParams }))
        })
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['requests', 'menu', 'resources', 'common'], { nsMode: 'fallback' })(ListShell));
