import { createAction, createAsyncAction } from 'typesafe-actions';
import QueryParams from '../../shared/interfaces/QueryParams';
import { TreeNode } from '../../shared/components/Tree/CourseTree';
import Course from '../../shared/interfaces/Course';

export enum CourseActionTypes {
  FetchCourses = '[Courses] Fetch Courses...',
  FetchCoursesSuccess = '[Courses] Fetch Courses Success',
  FetchCoursesFail = '[Courses] Fetch Courses Fail',
  FetchOneCourse = '[Courses] Fetch One Course...',
  FetchOneCourseSuccess = '[Courses] Fetch One Course Success',
  FetchOneCourseFail = '[Courses] Fetch One Course Fail',
  SaveCourse = '[Courses] Saving Course...',
  SaveCourseSuccess = '[Courses] Saving Course Success',
  SaveCourseFail = '[Courses] Saving Course Fail',
  FetchCourseCategories = '[Courses] Fetch Course Categories...',
  FetchCourseCategoriesSuccess = '[Courses] Fetch Course Categories Success',
  FetchCourseCategoriesFail = '[Courses] Fetch Course Categories Fail'
}

export const FetchCourses = createAction(CourseActionTypes.FetchCourses, resolve => {
  return (value: QueryParams) => resolve(value);
});

export const FetchCoursesSuccess = createAction(CourseActionTypes.FetchCoursesSuccess, resolve => {
  return (value: { courses: Course[]; totalCourses: number }) => resolve(value);
});

export const FetchCoursesFail = createAction(CourseActionTypes.FetchCoursesFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const SaveCourse = createAction(CourseActionTypes.SaveCourse, resolve => {
  return (value: { course: Course; noredirect?: boolean }) => resolve(value);
});

export const SaveCourseSuccess = createAction(CourseActionTypes.SaveCourseSuccess, resolve => {
  return (value: { course: Course }) => resolve(value);
});

export const SaveCourseFail = createAction(CourseActionTypes.SaveCourseFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const FetchCourseCategories = createAction(CourseActionTypes.FetchCourseCategories, resolve => {
  return (value: QueryParams) => resolve(value);
});

export const FetchCourseCategoriesSuccess = createAction(CourseActionTypes.FetchCourseCategoriesSuccess, resolve => {
  return (value: { courseCategories: TreeNode[] }) => resolve(value);
});

export const FetchCourseCategoriesFail = createAction(CourseActionTypes.FetchCourseCategoriesFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const FetchOneCourse = createAction(CourseActionTypes.FetchOneCourse, resolve => {
  return (courseId: number) => resolve({ courseId });
});

export const FetchOneCourseSuccess = createAction(CourseActionTypes.FetchOneCourseSuccess, resolve => {
  return (value: { course: Course }) => resolve(value);
});

export const FetchOneCourseFail = createAction(CourseActionTypes.FetchOneCourseFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const DeleteCourse = createAsyncAction(
  '[Courses] Delete course...',
  '[Courses] Delete course success',
  '[Courses] Delete course failure'
)<{ courseId: number; queryParams: QueryParams }, void, { errorMessage: string }>();

export const FetchAllCourses = createAsyncAction(
  '[Courses] Fetching all courses...',
  '[Courses] Fetching all courses success',
  '[Courses] Fetching all courses failure'
)<void, Course[], { errorMessage: string }>();
