import React, { useEffect, useState } from 'react';
import { TextArea, Icon, Button } from 'semantic-ui-react';
import { Form, Formik } from 'formik';
import { TaskListInitialValue, TaskListValidationSchema, PredefinedTaskInitialValue } from '../helpers/definitions';
import PageHeader from '../../shared/components/PageHeader';
import { RouteComponentProps } from 'react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { GlobalState } from '../../core/state';
import { Dispatch } from 'redux';
import PredefinedTask from '../../shared/interfaces/PredefinedTask';
import { Badge, Input, InputNumber } from 'antd';

import Label from '../../shared/components/DetailForm/Label';
import { SaveTaskList, FetchTaskList, SavePredefinedTask, DeletePredefinedTask } from '../state/actions';
import { push } from 'connected-react-router';
import TaskList from '../../shared/interfaces/TaskList';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../shared/components/InitialLoader';

type EditListProps = {
  saveTaskList: (taskList: TaskList) => void;
  saveTask: (taskList: TaskList, predefinedTask: PredefinedTask) => void;
  deleteTask: (taskList: TaskList, predefinedTask: PredefinedTask) => void;
  fetchTaskList: (taskListId: number) => void;
  navigatePush: (values: string) => void;
  taskList: TaskList;
  taskListStatus: ProgressStatus;
} & RouteComponentProps<RouteInfo> &
  WithNamespaces;

interface RouteInfo {
  id: string;
}

const EditList = (props: EditListProps) => {
  const { t } = props;
  const [titleIsSet, setTitleIsSet] = useState(true);
  const [newTask, setNewTask] = useState<PredefinedTask>(PredefinedTaskInitialValue);

  useEffect(() => {
    props.fetchTaskList(parseInt(props.match.params.id));
  }, []);

  function setDescription(event: React.ChangeEvent<HTMLInputElement>): void {
    setNewTask({ ...newTask, description: event.target.value });
  }

  function setDaysBeforeFirstSession(days: number | undefined): void {
    if (!days) {
      return;
    }

    setNewTask({ ...newTask, daysBeforeFirstSession: days });
  }

  function handleNewTask(): void {
    props.saveTask(props.taskList, newTask);
    setNewTask(PredefinedTaskInitialValue);
  }

  const renderTaskEditor = () => {
    if (props.taskListStatus === ProgressStatus.Done) {
      return (
        <Formik
          initialValues={{ ...TaskListInitialValue, ...props.taskList }}
          validationSchema={TaskListValidationSchema}
          onSubmit={values => {
            props.saveTaskList(values);
            setTitleIsSet(true);
          }}>
          {formProps => (
            <Form className="ui form">
              <div className="d-flex justify-content-center aligned-items-center w-100">
                {!titleIsSet ? (
                  <div className="task-list-creation">
                    <div className="form-group">
                      <label>{t('title-of-list')}</label>
                      <input
                        type={'text'}
                        name={'name'}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        value={formProps.values['name' || '']}
                      />
                      {(formProps.errors as any)['name' || ''] && (formProps.touched as any)['name' || ''] ? (
                        <div className="error field">
                          <Label value={(formProps.errors as any)['name' || '']} />
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label>
                        <i>{t('description')}</i>
                      </label>
                      <TextArea
                        name={'description'}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        value={formProps.values['description' || '']}
                        rows={3}
                        style={{ resize: 'none' }}
                      />
                    </div>
                    <div className="d-flex justify-content-between">
                      <Button icon type="button" onClick={() => props.navigatePush(`/task-lists`)}>
                        {t('back-to-task-lists')}
                      </Button>
                      <Button icon type="submit" className="primary" disabled={!formProps.values['name']}>
                        <Icon name="long arrow alternate right" />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-block">
                    <div className="task-list-create-widget">
                      <h3 onClick={() => setTitleIsSet(false)}>
                        {formProps.values['name']}
                        <Icon name="pencil" />
                      </h3>
                      <small>{formProps.values['description']}</small>
                      <hr />
                      <div className="mb-3">
                        {props.taskList.trainingPredefinedTasks.map((value: PredefinedTask, index) => {
                          return (
                            <div className="predefined-task" key={index}>
                              <label>{value.description}</label>
                              <Badge count={`- ${value.daysBeforeFirstSession} ${value.daysBeforeFirstSession === 1 ? 'dag' : 'dagen'}`} />
                              <Icon onClick={() => props.deleteTask(props.taskList, value)} name="minus" />
                            </div>
                          );
                        })}
                      </div>
                      <div className="task-input-widget">
                        <Input value={newTask.description} onChange={event => setDescription(event)} type="text" />
                        <Icon className="calendar-split" name="calendar alternate outline" />
                        <InputNumber value={newTask.daysBeforeFirstSession} onChange={days => setDaysBeforeFirstSession(days)} />
                        <Button className="primary" type="button" icon onClick={handleNewTask}>
                          <Icon name="check" />
                        </Button>
                      </div>
                    </div>
                    <div className="d-flex justify-content-start mt-3">
                      <Button icon type="button" onClick={() => props.navigatePush(`/task-lists`)}>
                        {t('back-to-task-lists')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      );
    } else {
      return <InitialLoader />;
    }
  };

  return (
    <>
      <PageHeader {...props} breadCrumbParts={[t('task-lists')]} title={t('task-lists')} />
      {renderTaskEditor()}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    taskList: state.taskLists.selectedTaskList as TaskList,
    taskListStatus: state.taskLists.loadingTaskListStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveTaskList: (taskList: TaskList) => dispatch(SaveTaskList.request(taskList)),
  saveTask: (taskList: TaskList, task: PredefinedTask) => dispatch(SavePredefinedTask.request({ taskList, task })),
  deleteTask: (taskList: TaskList, task: PredefinedTask) => dispatch(DeletePredefinedTask.request({ taskList, task })),
  fetchTaskList: (taskListId: number) => dispatch(FetchTaskList.request(taskListId)),
  navigatePush: (path: string) => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['tasks', 'resources', 'menu'], { nsMode: 'fallback' })(EditList));
