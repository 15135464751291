import { all, put } from 'redux-saga/effects';
import { trainersSaga } from '../../trainers/state/sagas';
import { appSaga } from './sagas';
import { locationsSaga } from '../../locations/state/sagas';
import { clientsSaga } from '../../clients/state/sagas';
import { coursesSaga } from '../../courses/state/sagas';
import { materialsSaga } from '../../materials/state/sagas';
import { trainingsSaga } from '../../trainings/state/sagas';
import { categoriesSaga } from '../../categories/state/sagas';
import { TrainingRequestSaga } from '../../requests/state/sagas';
import { dashboardSaga } from '../../dashboard/state/sagas';
import { tasksSaga } from '../../tasks/state/sagas';
import { taskListsSaga } from '../../tasklists/state/sagas';

export function* rootSaga() {
  yield all([
    ...appSaga,
    ...trainersSaga,
    ...locationsSaga,
    ...clientsSaga,
    ...coursesSaga,
    ...categoriesSaga,
    ...materialsSaga,
    ...trainingsSaga,
    ...TrainingRequestSaga,
    ...dashboardSaga,
    ...tasksSaga,
    ...taskListsSaga
  ]);
}
