import { combineReducers } from 'redux';
import { app, AppState } from './app.state';
import { TrainersState, trainers } from '../../trainers/state/state';
import { connectRouter, RouterState } from 'connected-react-router';
import { LocationsState, locations } from '../../locations/state/state';
import { ClientsState, clients } from '../../clients/state/state';
import { CourseState, courses } from '../../courses/state/state';
import { History } from 'history';
import { MaterialsState, materials } from '../../materials/state/state';
import { TrainingsState, trainings } from '../../trainings/state/state';
import { CategoryState, categories } from '../../categories/state/state';
import { TrainingRequestsState, requests } from '../../requests/state/state';
import { dashboard, DashboardState } from '../../dashboard/state/state';
import { TasksState, tasks } from '../../tasks/state/state';
import { TaskListsState, taskLists } from '../../tasklists/state/state';

export interface GlobalState {
  app: AppState;
  trainers: TrainersState;
  router: RouterState;
  locations: LocationsState;
  clients: ClientsState;
  courses: CourseState;
  categories: CategoryState;
  materials: MaterialsState;
  trainings: TrainingsState;
  requests: TrainingRequestsState;
  dashboard: DashboardState;
  tasks: TasksState;
  taskLists: TaskListsState;
}

export const reducers = (history: History) =>
  combineReducers({
    app,
    trainers,
    router: connectRouter(history),
    locations,
    clients,
    courses,
    categories,
    materials,
    trainings,
    requests,
    dashboard,
    tasks,
    taskLists
  });
