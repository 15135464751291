export const filenameFormatter = (name: string, length: number, attachment?: string) => {
  const exploded = name.split('.');
  const extension = exploded.pop();
  const filenameExploded = exploded.slice(0, exploded.length);
  const filename = filenameExploded.join('.');

  if (filename.length > length) {
    return filename.substring(0, length) + '(...).' + extension + (attachment ? ' - ' + attachment : '');
  }

  return name + (attachment ? ' - ' + attachment : '');
};
