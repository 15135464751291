import { createSelector } from 'reselect';
import { TrainersState } from './state';
import { GlobalState } from '../../core/state';
import Language from '../../shared/interfaces/Language';
import CountryCode from '../../shared/interfaces/CountryCode';
import { TrainerType, Trainer, WithLanguages, WithCompetencies } from '../../shared/interfaces/Trainer';
import { TrainerResources } from '../components/Detail/DetailShell';
import Competency from '../../shared/interfaces/Competency';

export const getTrainers = createSelector(
  (state: TrainersState) => state,
  state => state.trainers
);

export const getSelectedTrainerId = createSelector(
  (state: TrainersState) => state,
  state => state.selectedTrainerId
);

export const getSelectedTrainer = createSelector(
  (state: TrainersState) => state,
  state => state.selectedTrainer as Trainer<WithLanguages & WithCompetencies> | null
);

export const getAmountPages = createSelector(
  (state: TrainersState) => state,
  state => Math.ceil(state.totalTrainers / state.pageSize)
);

export const getResources = createSelector(
  (state: GlobalState) => state,
  state =>
    ({
      languages: state.app.languages as Language[],
      competencies: state.app.competencies as Competency[],
      countryCodes: state.app.countryCodes as CountryCode[],
      types: Object.values(TrainerType) as TrainerType[]
    } as TrainerResources)
);
