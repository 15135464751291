import { checkVAT, belgium, netherlands, france, germany, unitedKingdom, VatCheckResult } from 'jsvat';

export function vatValidation(vat: string) {
  if (vat) {
    const response: VatCheckResult = checkVAT(vat, [belgium, netherlands, france, germany, unitedKingdom]);
    return response.isValid;
  }

  return true;
}
