import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from '../../../core/state';
import { getSelectedTrainer, getResources } from '../../state/selectors';
import { FetchOneTrainer, ClearTrainers, SaveTrainer } from '../../state/actions';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import InitialLoader from '../../../shared/components/InitialLoader';
import DetailView from './DetailView';
import { Trainer, WithCompetencies, WithLanguages } from '../../../shared/interfaces/Trainer';
import { push } from 'connected-react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Dispatch } from 'redux';
import { formDefinition } from '../../helpers/DetailFormFields';
import Course from '../../../shared/interfaces/Course';
import { TrainerTraining } from '../../../shared/interfaces/TrainerTraining';
import Language from '../../../shared/interfaces/Language';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { FormikValues } from 'formik';
import PageHeader from '../../../shared/components/PageHeader';
import NoDataFound from '../../../shared/components/NoDataFound';
import { Button } from 'semantic-ui-react';
import { TrainerCourses } from '../../helpers/TrainerCourses';
import Selector from '../../../shared/components/Selector';
import { configConstants } from '../../../config/constants';
import moment, { lang } from 'moment';
import Competency from '../../../shared/interfaces/Competency';

export interface TrainerResources {
  languages: Language[];
  competencies: Competency[];
}

type DetailShellProps = {
  selectedTrainer: Trainer<WithLanguages & WithCompetencies> | null;
  courses: Course[];
  trainings: TrainerTraining[];
  loadingTrainersStatus: ProgressStatus;
  loadingTrainingsStatus: ProgressStatus;
  loadingCoursesStatus: ProgressStatus;
  savingTrainerStatus: ProgressStatus;
  resources: TrainerResources;
  loadTrainer: (id: number) => void;
  saveTrainer: (trainer: Trainer) => void;
  navigatePush: (path: string) => void;
} & WithNamespaces &
  RouteComponentProps<RouteInfo>;

interface RouteInfo {
  id: string;
}

class DetailShell extends Component<DetailShellProps> {
  componentDidMount = () => {
    const { match, loadTrainer } = this.props;
    loadTrainer(parseInt(match.params.id));
  };

  handleSubmit = (formValues: FormikValues) => {
    const updatedTrainer = { ...this.props.selectedTrainer, ...(formValues as Trainer) };

    this.props.saveTrainer(updatedTrainer);
  };

  handleCoursesUpdateSubmit = (keys: string[]) => {
    const updatedTrainer = {
      ...this.props.selectedTrainer,
      courses: keys,
      languages: this.props.selectedTrainer ? this.props.selectedTrainer.languages.map(l => l['@id']) : [],
      competencies: this.props.selectedTrainer ? this.props.selectedTrainer.competencies.map(l => l['@id']) : [],
    } as Trainer;
    this.props.saveTrainer(updatedTrainer);
    const { match, loadTrainer } = this.props;
    loadTrainer(parseInt(match.params.id));
  };

  render() {
    const { selectedTrainer, match, resources, savingTrainerStatus, navigatePush, t } = this.props;
    const trainersLoaded = this.props.loadingTrainersStatus === ProgressStatus.Done;
    const trainingsLoaded = this.props.loadingTrainingsStatus === ProgressStatus.Done;
    const coursesLoaded = this.props.loadingCoursesStatus === ProgressStatus.Done;
    const everythingLoaded = trainersLoaded && trainingsLoaded && coursesLoaded;

    if (!everythingLoaded) {
      return <InitialLoader />;
    }

    if (!selectedTrainer) {
      return <NoDataFound />;
    }

    const trainerName = `${selectedTrainer.firstName} ${selectedTrainer.lastName}`;

    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/edit`}
          render={() => (
            <>
              <PageHeader title={trainerName} breadCrumbParts={[t('trainers'), trainerName, t('edit')]} {...this.props} />
              <DetailForm
                onFormSubmit={this.handleSubmit}
                savingForm={savingTrainerStatus === ProgressStatus.InProgress}
                selectedEntity={{
                  ...selectedTrainer,
                  languages: selectedTrainer.languages.map(language => language['@id']),
                  competencies: selectedTrainer.competencies.map(competency => competency['@id'])
                }}
                onCancelBtnClick={() => navigatePush(`${match.url}`)}
                formDefinition={formDefinition(resources.languages, resources.competencies)}
              />
            </>
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/courses/edit`}
          render={() => (
            <>
              <PageHeader
                title={t('editCourse', { trainerName: trainerName })}
                breadCrumbParts={[t('trainers'), trainerName]}
                {...this.props}
              />

              <Selector originalSelected={selectedTrainer.courses || []} selected={this.handleCoursesUpdateSubmit} />
            </>
          )}
        />
        <Route
          exact
          path={`${match.path}`}
          render={props => (
            <>
              <PageHeader
                {...this.props}
                title={
                  <span>
                    {trainerName}
                    {selectedTrainer.active ? null : (
                      <>
                        &nbsp;-&nbsp;<span className="deactivated">{t('deactived')}</span>
                      </>
                    )}
                    {selectedTrainer.deletedAt ? (
                      <span className="archived"> (Gearchiveerd op {moment(selectedTrainer.deletedAt).format('DD/MM/YY')})</span>
                    ) : null}
                  </span>
                }
                breadCrumbParts={[t('trainers'), `${selectedTrainer.firstName} ${selectedTrainer.lastName}`]}
                rightBlock={
                  <Button className="primary" onClick={() => navigatePush(`/trainers/${match.params.id}/edit`)}>
                    {t('edit')}
                  </Button>
                }
              />
              <DetailView
                key={selectedTrainer.id}
                selectedTrainer={selectedTrainer}
                courses={this.props.courses}
                trainings={this.props.trainings}
                onEditBtnClick={() => navigatePush(`/trainers/${match.params.id}/edit`)}
                {...props}
              />
            </>
          )}
          key={`${match.path}/:id`}
        />
        <Route
          render={() => (
            <Redirect
              to={{
                pathname: `/${match.url.split('/')[1]}`
              }}
            />
          )}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    selectedTrainer: getSelectedTrainer(state.trainers),
    courses: state.trainers.courses as Course[],
    trainings: state.trainers.trainings as TrainerTraining[],
    loadingTrainersStatus: state.trainers.loadingTrainersStatus,
    loadingTrainingsStatus: state.trainers.loadingTrainingsStatus,
    loadingCoursesStatus: state.trainers.loadingCoursesStatus,
    savingTrainerStatus: state.trainers.savingTrainerStatus,
    resources: getResources(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearTrainers: () => dispatch(ClearTrainers()),
  loadTrainer: (id: number) => dispatch(FetchOneTrainer({ trainerId: id as number })),
  saveTrainer: (trainer: Trainer) => dispatch(SaveTrainer({ trainer })),
  navigatePush: (path: string) => dispatch(push(path))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces(['trainers', 'resources'], { nsMode: 'fallback' })(DetailShell));
