import React, { Component, Fragment, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { getAmountPages } from '../../state/selectors';
import { GlobalState } from '../../../core/state';
import * as actions from '../../state/actions';
import InitialLoader from '../../../shared/components/InitialLoader';
import { dataTableModel } from '../../helpers/DataTableFields';
import { searchColumnFields } from '../../helpers/DataSearchFields';
import { PaginationProps, Button } from 'semantic-ui-react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import PageHeader from '../../../shared/components/PageHeader';
import { getPathName } from '../../../core/state/selectors';
import DataTableWrapper from '../../../shared/components/DataTable/DataTableWrapper';
import { Trainer, WithCompetencies } from '../../../shared/interfaces/Trainer';
import DataTableAdvancedFilter from '../../../shared/components/DataTable/DataTableAdvancedFilter';
import { queryParams, updateQueryString } from '../../../shared/helpers/queryStringHelpers';
import { RouteComponentProps } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import QueryParams from '../../../shared/interfaces/QueryParams';
import CountryCode from '../../../shared/interfaces/CountryCode';
import Language from '../../../shared/interfaces/Language';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { ConfirmAction } from '../../../core/state/actions';
import { Dispatch } from 'redux';
import { TrainersAction } from '../../state/sagas';
import { AppAction } from '../../../core/state/app.state';
import Course from '../../../shared/interfaces/Course';
import { FormFieldDefinition, DropdownlistFieldDefinition } from '../../../shared/interfaces/FormFieldDefinition';
import SiteSettings from '../../../shared/interfaces/SiteSettings';

type ListShellProps = {
  selectedTrainer: Trainer | null;
  selectedTrainerId: number;
  trainers: Trainer<WithCompetencies>[];
  loadingTrainersStatus: ProgressStatus;
  totalPages: number;
  pathName: string;
  resources: {
    countryCodes: CountryCode[];
    languages: Language[];
  };
  courses: Course[];
  loadTrainers: (queryParams: QueryParams) => void;
  onHeaderClick: (key: string) => void;
  deleteTrainer: (trainerId: number, queryParams: QueryParams) => void;
  siteSettings: SiteSettings;
} & RouteComponentProps;

interface ListShellState {
  activePage: number;
  activeSortHeader: string | undefined;
  direction?: QueryParams['order'];
  searchValue: string | undefined;
}

class ListShell extends Component<ListShellProps & WithNamespaces, ListShellState> {
  queryParams(props: ListShellProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  get page() {
    return this.queryParams().page || 1;
  }

  componentDidMount = ({ loadTrainers } = this.props) => {
    loadTrainers(this.queryParams());
  };

  componentWillUpdate(nextProps: ListShellProps) {
    const nextQueryparams = this.queryParams(nextProps);
    const queryChanged = !isEqual(this.queryParams(), nextQueryparams);
    if (queryChanged) {
      this.props.loadTrainers(nextQueryparams);
    }
  }

  handlePaginationChange = (_: SyntheticEvent, { activePage }: PaginationProps) => {
    updateQueryString(this.props, { page: activePage });
  };

  filterResults = (value: string | number) => {
    updateQueryString(this.props, {
      searchValue: value,
      page: 1,
      sort: undefined,
      order: undefined
    });
  };

  enrichDropDownList = (courses: Course[] | undefined, formDefinitions: Array<FormFieldDefinition>) => {
    if (courses) {
      const coursesOptions = courses.map((course: Course) => ({ key: course.id, text: course.title, value: course.id }));
      const defaultOption = [{key: 0, text: 'Alles', value: 0}];

      const courseDropdownOptions = defaultOption.concat(coursesOptions);
      
      return formDefinitions.map(element => {
        if (element.propertyName === 'course') {
          return { ...(element as DropdownlistFieldDefinition), dropDownOptions: courseDropdownOptions };
        }
        return element;
      });
    }
    return [];
  };

  renderTrainers() {
    const { trainers, selectedTrainerId, history, match, totalPages, t, resources, deleteTrainer } = this.props;

    if (this.props.loadingTrainersStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    const formattedTrainers = trainers.map(trainer => ({
      ...trainer,
      name: `${trainer.firstName} ${trainer.lastName}`,
      competencies: (
        <ul>
          {trainer.competencies.map(competency => (
            <li key={competency.id}>{competency.value}</li>
          ))}
        </ul>
      )
    }));

    return (
      <>
        {this.props.siteSettings.CAN_ACCESS_TRAINERS_CRUD && (
          <Button
            positive
            content="Trainer toevoegen"
            className="primary m-b-lg"
            onClick={() => this.props.history.push(this.props.match.path + '/create')}
          />
        )}

        <DataTableWrapper
          {...this.props}
          onDialogCancel={() => {}}
          onDialogClose={() => {}}
          onDialogConfirm={() => {}}
          onPageChange={this.handlePaginationChange}
          onSubmit={this.filterResults}
          dialogOpen={false}
          defaultActivePage={this.page}
          dialogContent={t('confirmArchiveBody')}
          dialogHeader={t('confirmArchiveHeader')}
          tableData={formattedTrainers}
          totalPages={totalPages}
          withActionButtons={true}
          activeId={selectedTrainerId}
          activeSortHeader={this.queryParams().sort}
          fieldSet={dataTableModel.map(header => ({
            header: t(header),
            key: header
          }))}
          onSelect={(id: number | string) => history.push(`${match.path}/${id}`)}
          onArchive={(id: number) => deleteTrainer(id, this.queryParams())}>
          <DataTableAdvancedFilter
            {...this.props}
            fieldSet={['id', 'firstName', 'lastName', 'city', 'phoneNumber', 'email', 'routine', 'gradation']}
            searchFields={this.enrichDropDownList(this.props.courses, searchColumnFields(resources.languages))}
          />
        </DataTableWrapper>
      </>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <PageHeader {...this.props} breadCrumbParts={[t('moduleTitle')]} title={t('moduleTitle')} />
        {this.renderTrainers()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    siteSettings: state.app.siteSettings,
    courses: state.trainers.courses as Course[],
    selectedTrainer: state.trainers.selectedTrainer as Trainer | null,
    selectedTrainerId: state.trainers.selectedTrainerId,
    trainers: state.trainers.trainers as Trainer<WithCompetencies>[],
    loadingTrainersStatus: state.trainers.loadingTrainersStatus,
    totalPages: getAmountPages(state.trainers),
    pathName: getPathName(state.router),
    resources: {
      countryCodes: state.app.countryCodes as CountryCode[],
      languages: state.app.languages as Language[]
    }
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TrainersAction | AppAction>) => ({
  clearTrainers: () => dispatch(actions.ClearTrainers()),
  loadTrainers: (queryParams: QueryParams) => dispatch(actions.FetchTrainers(queryParams)),
  deleteTrainer: (trainerId: number, queryParams: QueryParams) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteTrainer',
        content: 'confirmDeleteTrainer',
        action: () => dispatch(actions.DeleteTrainer.request({ trainerId, queryParams }))
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces(['trainers', 'resources'], { nsMode: 'fallback' })(ListShell));
