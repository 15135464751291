import React, { Fragment } from 'react';
import { CalendarEvent } from '../../interfaces/CalendarEvent';
import moment from 'moment';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import applyArchiveTagCheck from '../../helpers/archivedFormatter';

type CalendarDetailEventProps = {
  event: CalendarEvent;
  entityPath?: string;
} & WithNamespaces;

const eventToPath = (entityPath: string, entityId: number) => {
  return entityPath.replace('{id}', entityId.toString());
};

const CalendarDetailEvent = ({ event, entityPath, t }: CalendarDetailEventProps) => {
  return (
    <div className="calender-select-item">
      {event.color ? <span className="line" style={{ backgroundColor: `${event.color}` }} /> : null}

      {entityPath && event.entityId ? (
        <a href={eventToPath(entityPath, event.entityId)}>
          <h4>{event.title}</h4>
        </a>
      ) : (
        <h4>{event.title}</h4>
      )}

      {moment(event.start).format('ll') === moment(event.end).format('ll') ? (
        <Fragment>
          <span className="date">{moment(event.start).format('ll')}</span>
          <span>{moment(event.start).format('LT') + ' - ' + moment(event.end).format('LT')}</span>
        </Fragment>
      ) : (
        <Fragment>
          <span className="date">{moment(event.start).format('ll')}</span>
          <span>{moment(event.start).format('LT')}</span>
          <br />
          <br />
          <span className="date">{moment(event.end).format('ll')}</span>
          <span>{moment(event.end).format('LT')}</span>
        </Fragment>
      )}
      {event.subTitle ? (
        <>
          <h4>{t('training')}</h4>
          {event.trainingId ? (
            <Link to={`/trainings/${event.trainingId}`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: applyArchiveTagCheck(event.subTitle, event.trainingDeleted, true, true)
                }}></span>
            </Link>
          ) : null}
        </>
      ) : null}

      {event.location ? (
        <>
          <h4>{t('location')}</h4>
          <Link to={event.location.id}>{event.location.name}</Link>
        </>
      ) : null}
    </div>
  );
};

export default withNamespaces(['materials'])(CalendarDetailEvent);
