import React from 'react';
import { Segment, Button, Icon } from 'semantic-ui-react';
import Course from '../../../shared/interfaces/Course';
import { RouteComponentProps } from 'react-router';
import './DetailView.scss';
import DetailGroup from '../../../shared/components/DetailGroup/DetailGroup';
import { WithNamespaces } from 'react-i18next';
import applyArchiveTagCheck from '../../../shared/helpers/archivedFormatter';

interface DetailCourseViewProps {
  courses: Course[];
  trainerId: number | string;
}
export const DetailCourseView = ({ courses, trainerId, history, t }: DetailCourseViewProps & RouteComponentProps & WithNamespaces) => {
  return (
    <DetailGroup
      title={'Pakketten'}
      actions={
        <Button className="primary" onClick={() => history.push(`/trainers/${trainerId}/courses/edit`)}>
          Wijzigen
        </Button>
      }>
      {courses.length > 0
        ? courses.map((course, key) => (
            <Segment key={key} onClick={() => history.push(`/courses/${course.id}`)} raised className="trainer-course-section">
              <Icon name="book" className="primary" />
              <span
                dangerouslySetInnerHTML={{
                  __html: applyArchiveTagCheck(course.title, course.deletedAt, true, true)
                }}></span>
            </Segment>
          ))
        : t('noCourses')}
    </DetailGroup>
  );
};
