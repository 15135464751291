import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import {
  UserOutlined,
  IdcardOutlined,
  BookOutlined,
  FolderOutlined,
  FileProtectOutlined,
  EnvironmentOutlined,
  ReconciliationOutlined
} from '@ant-design/icons';

import DetailForm from './DetailForm/DetailForm';
import { newClientData } from '../../clients/helpers/constants';
import { SimpleFormClientDefinition } from '../../clients/helpers/DetailFormFields';
import { GlobalState } from '../../core/state';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Language from '../interfaces/Language';
import { LoadResources } from '../../core/state/actions';
import Client from '../interfaces/Client';
import { SaveClient } from '../../clients/state/actions';
import { newEntity as newTrainerData } from '../../trainers/helpers/constants';
import { Trainer } from '../interfaces/Trainer';
import { SimpleFormTrainerDefinition } from '../../trainers/helpers/DetailFormFields';
import { SaveTrainer } from '../../trainers/state/actions';
import Course from '../interfaces/Course';
import { newCourseData } from '../../courses/helpers/constants';
import { SimpleFormCourseDefinition } from '../../courses/helpers/DetailFormFields';
import { SaveCourse } from '../../courses/state/actions';
import CourseCategory from '../interfaces/CourseCategory';
import { newCategoryData } from '../../categories/helpers/constants';
import { SimpleFormCategoryDefinition } from '../../categories/helpers/DetailFormFields';
import { SaveCategory } from '../../categories/state/actions';
import { newEntity as newLocationData } from '../../locations/helpers/constants';
import { SimpleFormLocationDefinition } from '../../locations/helpers/DetailFormFields';
import { SaveLocation } from '../../locations/state/actions';
import { CourseLocation } from '../interfaces/CourseLocation';
import { Material } from '../interfaces/Material';
import { newMaterialData } from '../../materials/helpers/constants';
import { SimpleFormMaterialDefinition } from '../../materials/helpers/DetailFormFields';
import { SaveMaterial } from '../../materials/state/actions';
import { TrainingType, Training } from '../interfaces/Training';
import { newTrainingBedrijfsIntern, newTrainingOpenKalender, newTrainingOnderAanneming } from '../../trainings/helpers/constants';
import { SimpleBedrijfsInternFormDefinition, SimpleOpenCalendarDefinition } from '../../trainings/helpers/TrainingEditFields';
import { SaveTraining } from '../../trainings/state/actions';
import SiteSettings from '../interfaces/SiteSettings';

interface Props {
  languages: Language[];
  loadResources: () => void;
  saveClient: (client: Client) => void;
  saveTrainer: (trainer: Trainer) => void;
  saveCourse: (course: Course) => void;
  saveCategory: (category: CourseCategory) => void;
  saveLocation: (location: CourseLocation) => void;
  saveMaterial: (material: Material) => void;
  saveTraining: (training: Training) => void;
  entitySaved: () => void;
  siteSettings: SiteSettings;
  hasMaterialModule: boolean;
}

const QuickAddForm = (props: Props) => {
  enum quickAddTypes {
    customer = 'CUSTOMER',
    trainer = 'TRAINER',
    course = 'COURSE',
    category = 'CATEGORY',
    training = 'TRAINING',
    trainingInterCompany = 'TRAINING_INTER_COMPANY',
    trainingOpenCalendar = 'TRAINING_OPEN_CALENDAR',
    trainingSubCompany = 'TRAINING_SUP_COMPANY',
    location = 'LOCATION',
    material = 'MATERIAL',
    unset = 'UNSET'
  }

  useEffect(() => {
    props.loadResources();
  }, []);

  const [type, setType] = useState<quickAddTypes>(quickAddTypes.unset);

  switch (type) {
    case quickAddTypes.customer:
      return (
        <div className="quick-add-wrapper d-flex justify-content-center mt-5 flex-column">
          <div className="ui container mb-3">
            <h2>Toevoegen klant</h2>
          </div>
          <DetailForm
            onFormSubmit={data => {
              props.saveClient(data as Client);
              setType(quickAddTypes.unset);
              props.entitySaved();
            }}
            selectedEntity={newClientData}
            onCancelBtnClick={() => setType(quickAddTypes.unset)}
            formDefinition={SimpleFormClientDefinition(props.languages)}
          />
        </div>
      );

    case quickAddTypes.trainer:
      return (
        <div className="quick-add-wrapper d-flex justify-content-center mt-5 flex-column">
          <div className="ui container mb-3">
            <h2>Toevoegen lesgever</h2>
          </div>
          <DetailForm
            onFormSubmit={data => {
              props.saveTrainer(data as Trainer);
              setType(quickAddTypes.unset);
              props.entitySaved();
            }}
            selectedEntity={newTrainerData}
            onCancelBtnClick={() => setType(quickAddTypes.unset)}
            formDefinition={SimpleFormTrainerDefinition(props.languages)}
          />
        </div>
      );

    case quickAddTypes.course:
      return (
        <div className="quick-add-wrapper d-flex justify-content-center mt-5 flex-column">
          <div className="ui container mb-3">
            <h2>Toevoegen pakket</h2>
          </div>
          <DetailForm
            onFormSubmit={data => {
              props.saveCourse(data as Course);
              setType(quickAddTypes.unset);
              props.entitySaved();
            }}
            selectedEntity={newCourseData}
            onCancelBtnClick={() => setType(quickAddTypes.unset)}
            formDefinition={SimpleFormCourseDefinition()}
          />
        </div>
      );

    case quickAddTypes.category:
      return (
        <div className="quick-add-wrapper d-flex justify-content-center mt-5 flex-column">
          <div className="ui container mb-3">
            <h2>Toevoegen categorie</h2>
          </div>
          <DetailForm
            onFormSubmit={data => {
              props.saveCategory(data as CourseCategory);
              setType(quickAddTypes.unset);
              props.entitySaved();
            }}
            selectedEntity={newCategoryData}
            onCancelBtnClick={() => setType(quickAddTypes.unset)}
            formDefinition={SimpleFormCategoryDefinition()}
          />
        </div>
      );

    case quickAddTypes.location:
      return (
        <div className="quick-add-wrapper d-flex justify-content-center mt-5 flex-column">
          <div className="ui container mb-3">
            <h2>Toevoegen locatie</h2>
          </div>
          <DetailForm
            onFormSubmit={data => {
              props.saveLocation(data as CourseLocation);
              setType(quickAddTypes.unset);
              props.entitySaved();
            }}
            selectedEntity={newLocationData}
            onCancelBtnClick={() => setType(quickAddTypes.unset)}
            formDefinition={SimpleFormLocationDefinition()}
          />
        </div>
      );

    case quickAddTypes.material:
      return (
        <div className="quick-add-wrapper d-flex justify-content-center mt-5 flex-column">
          <div className="ui container mb-3">
            <h2>Toevoegen materiaal</h2>
          </div>
          <DetailForm
            onFormSubmit={data => {
              props.saveMaterial(data as Material);
              setType(quickAddTypes.unset);
              props.entitySaved();
            }}
            selectedEntity={newMaterialData}
            onCancelBtnClick={() => setType(quickAddTypes.unset)}
            formDefinition={SimpleFormMaterialDefinition()}
          />
        </div>
      );

    case quickAddTypes.training:
      return (
        <div className="ui container">
          <div className="d-flex justify-content-center mt-5 flex-column">
            <h2>Toevoegen opleiding</h2>
            <div className="quick-add-types mt-5 d-flex flex-row">
              <Button onClick={() => setType(quickAddTypes.trainingInterCompany)}>Bedrijfsintern</Button>
              <Button onClick={() => setType(quickAddTypes.trainingOpenCalendar)}>Open kalender</Button>
              <Button onClick={() => setType(quickAddTypes.trainingSubCompany)}>Onderaanneming</Button>
            </div>
            <div className="form-buttons">
              <button className="ui button" onClick={() => setType(quickAddTypes.unset)}>
                {'Annuleren'}
              </button>
            </div>
          </div>
        </div>
      );

    case quickAddTypes.trainingInterCompany:
      return (
        <div className="quick-add-wrapper d-flex justify-content-center mt-5 flex-column">
          <div className="ui container mb-3">
            <h2>Toevoegen opleiding</h2>
          </div>
          <DetailForm
            onFormSubmit={data => {
              props.saveTraining(data as Training);
              setType(quickAddTypes.unset);
              props.entitySaved();
            }}
            selectedEntity={newTrainingBedrijfsIntern}
            onCancelBtnClick={() => setType(quickAddTypes.training)}
            formDefinition={SimpleBedrijfsInternFormDefinition()}
          />
        </div>
      );

    case quickAddTypes.trainingOpenCalendar:
      return (
        <div className="quick-add-wrapper d-flex justify-content-center mt-5 flex-column">
          <div className="ui container mb-3">
            <h2>Toevoegen opleiding</h2>
          </div>
          <DetailForm
            onFormSubmit={data => {
              props.saveTraining(data as Training);
              setType(quickAddTypes.unset);
              props.entitySaved();
            }}
            selectedEntity={newTrainingOpenKalender}
            onCancelBtnClick={() => setType(quickAddTypes.training)}
            formDefinition={SimpleOpenCalendarDefinition()}
          />
        </div>
      );

    case quickAddTypes.trainingSubCompany:
      return (
        <div className="quick-add-wrapper d-flex justify-content-center mt-5 flex-column">
          <div className="ui container mb-3">
            <h2>Toevoegen opleiding</h2>
          </div>
          <DetailForm
            onFormSubmit={data => {
              props.saveTraining(data as Training);
              setType(quickAddTypes.unset);
              props.entitySaved();
            }}
            selectedEntity={newTrainingOnderAanneming}
            onCancelBtnClick={() => setType(quickAddTypes.training)}
            formDefinition={SimpleBedrijfsInternFormDefinition()}
          />
        </div>
      );

    case quickAddTypes.unset:
    default:
      return (
        <div className="ui container">
          <div className="d-flex justify-content-center mt-5 flex-column">
            <h2>Maak snel een basic type aan</h2>
            <p>Selecteer welke type u wilt toegoeven</p>
            <div className="quick-add-types mt-5 d-flex flex-row">
              {props.siteSettings.CAN_ACCESS_CLIENT_CRUD && (
                <Button onClick={() => setType(quickAddTypes.customer)}>
                  <UserOutlined />
                  Klant
                </Button>
              )}
              {props.siteSettings.CAN_ACCESS_TRAINERS_CRUD && (
                <Button onClick={() => setType(quickAddTypes.trainer)}>
                  <IdcardOutlined />
                  Lesgever
                </Button>
              )}
              {props.siteSettings.CAN_ACCESS_COURSE_CRUD && (
                <Button onClick={() => setType(quickAddTypes.course)}>
                  <BookOutlined />
                  Pakket
                </Button>
              )}
              {props.siteSettings.CAN_ACCESS_COURSE_CATEGORIES_CRUD && (
                <Button onClick={() => setType(quickAddTypes.category)}>
                  <FolderOutlined />
                  Categorie
                </Button>
              )}
              <Button onClick={() => setType(quickAddTypes.training)}>
                <FileProtectOutlined />
                Opleiding
              </Button>
              <Button onClick={() => setType(quickAddTypes.location)}>
                <EnvironmentOutlined />
                Locatie
              </Button>
              {props.hasMaterialModule && (
                <Button onClick={() => setType(quickAddTypes.material)}>
                  <ReconciliationOutlined />
                  Materiaal
                </Button>
              )}
            </div>
          </div>
        </div>
      );
  }
};

const mapStateToProps = (state: GlobalState) => {
  return {
    languages: state.app.languages as Language[],
    siteSettings: state.app.siteSettings,
    hasMaterialModule: state.app.activeModules.materialModule
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadResources: () => dispatch(LoadResources.request()),
  saveClient: (client: Client) => dispatch(SaveClient({ client: client, noredirect: true })),
  saveTrainer: (trainer: Trainer) => dispatch(SaveTrainer({ trainer: trainer, noredirect: true })),
  saveCourse: (course: Course) => dispatch(SaveCourse({ course: course, noredirect: true })),
  saveCategory: (category: CourseCategory) => dispatch(SaveCategory.request({ courseCategory: category, noredirect: true })),
  saveLocation: (location: CourseLocation) => dispatch(SaveLocation.request({ location: location, noredirect: true })),
  saveMaterial: (material: Material) => dispatch(SaveMaterial.request({ material: material, noredirect: true })),
  saveTraining: (training: Training) => dispatch(SaveTraining.request({ training: training, noredirects: true }))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickAddForm);
