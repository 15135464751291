import { createAsyncAction } from 'typesafe-actions';
import { Training, WithTrainingSessions, WithCourse } from '../../shared/interfaces/Training';
import { DashboardRequest } from './state';

export const LoadDashboard = createAsyncAction(
  '[Dashboard] Loading dashboard...',
  '[Dashboard] Loading dashboard Success',
  '[Dashboard] Loading dashboard Fail'
)<
  void,
  {
    latestNewRequests: DashboardRequest[];
    latestWonRequests: DashboardRequest[];
    latestLostRequests: DashboardRequest[];
    upcomingTrainings: Training<WithCourse & WithTrainingSessions>[] | undefined;
  },
  { errorMessage: string }
>();
