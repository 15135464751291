import CountryCode from './CountryCode';
import Entity, { EntityReference } from './Entity';
import Language from './Language';

export type ClientReference = EntityReference;
export type ContactReference = EntityReference;

export enum ClientType {
  company = 1,
  private = 2,
  selfEmployed = 3
}

export type WithLanguagesReference = {
  languages: EntityReference[];
};

export type WithLanguages = {
  languages: Language[];
};

export type Client<T = WithLanguagesReference> = T &
  Entity & {
    id: number;
    companyName: string;
    type: ClientType;
    code?: string;
    street?: string;
    houseNr?: string;
    bus?: string;
    postalCode?: string;
    city?: string;
    country?: CountryCode;
    email: string;
    phone?: string;
    website?: string;
    remarks?: string;
    contacts: ContactReference[];
    accountNr?: string;
    vatNr?: string;
    hasNewsletter?: boolean;
    locations: EntityReference[];
    createdAt?: string;
    deletedAt?: string;
    upcomingRequestFollowupDate?: string;
    lastRequestDate?: string;
  };

export default Client;
