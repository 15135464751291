import React from 'react';
import { Loader } from 'semantic-ui-react';

const InitialLoader = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}>
      <Loader active size="big" inline style={{ maxWidth: '50%', flex: 'none' }}>
        Gegevens laden...
      </Loader>
    </div>
  );
};

export default InitialLoader;
