import { createAction, createAsyncAction } from 'typesafe-actions';
import Client from '../../shared/interfaces/Client';
import Contact from '../../shared/interfaces/Contact';
import { CourseLocation } from '../../shared/interfaces/CourseLocation';
import { GQLTraining, GQLRequest } from './state';
import QueryParams from '../../shared/interfaces/QueryParams';
import { Participant, WithTraining, WithParticipations } from '../../shared/interfaces/participant';

export enum ClientsActionTypes {
  FetchClients = '[Clients] Fetch Clients...',
  FetchClientsSuccess = '[Clients] Fetch Clients Success',
  FetchClientsFail = '[Clients] Fetch Clients Fail',
  FetchOneClient = '[Clients] Fetch One Client...',
  FetchOneClientSuccess = '[Clients] Fetch One Client Success',
  FetchOneClientFail = '[Clients] Fetch One Client Fail',
  FetchClientResources = '[Clients] Fetch Client resources...',
  FetchClientResourcesSuccess = '[Clients] Fetch Client resources Success',
  FetchClientResourcesFail = '[Clients] Fetch Client resources Fail',
  FetchClientContacts = '[Clients] Fetch Client Contacts...',
  FetchClientContactsSuccess = '[Clients] Fetch Client Contacts Success',
  FetchClientContactsFail = '[Clients] Fetch Client Contacts Fail',
  SaveClient = '[Clients] Saving Client...',
  SaveClientSuccess = '[Clients] Saving Client Success',
  SaveClientFail = '[Clients] Saving Client Fail',
  FetchClientLocations = '[Clients] Fetch Client locations...',
  FetchClientLocationsSuccess = '[Clients] Fetch Client locations Success',
  FetchClientLocationsFail = '[Clients] Fetch Client locations Fail',
  FetchClientTrainings = '[Clients] Fetch Client trainings...',
  FetchClientTrainingsSuccess = '[Clients] Fetch Client trainings Success',
  FetchClientTrainingsFail = '[Clients] Fetch Client trainings Fail',
  FetchClientRequests = '[Clients] Fetch Client requests...',
  FetchClientRequestsSuccess = '[Clients] Fetch Client requests Success',
  FetchClientRequestsFail = '[Clients] Fetch Client requests Fail',
  UnlinkLocationFromClient = '[Clients] Unlink Location From Client...',
  UnlinkLocationFromClientSuccess = '[Clients] Unlink Location From Client Success',
  UnlinkLocationFromClientFail = '[Clients] Unlink Location From Client Fail'
}

export const FetchClients = createAction(ClientsActionTypes.FetchClients, resolve => {
  return (value: { page?: number; sortOrder?: string; sortField?: string; searchText?: string }) => resolve(value);
});
export const FetchClientsSuccess = createAction(ClientsActionTypes.FetchClientsSuccess, resolve => {
  return (value: { clients: Client[]; totalClients: number }) => resolve(value);
});
export const FetchClientsFail = createAction(ClientsActionTypes.FetchClientsFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const FetchOneClient = createAction(ClientsActionTypes.FetchOneClient, resolve => {
  return (value: { clientId: number }) => resolve(value);
});
export const FetchOneClientSuccess = createAction(ClientsActionTypes.FetchOneClientSuccess, resolve => {
  return (value: { client: Client }) => resolve(value);
});
export const FetchOneClientFail = createAction(ClientsActionTypes.FetchOneClientFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const FetchClientContacts = createAsyncAction(
  ClientsActionTypes.FetchClientContacts,
  ClientsActionTypes.FetchClientContactsSuccess,
  ClientsActionTypes.FetchClientContactsFail
)<number, Contact[], { errorMessage: string }>();

export const FetchClientLocations = createAsyncAction(
  ClientsActionTypes.FetchClientLocations,
  ClientsActionTypes.FetchClientLocationsSuccess,
  ClientsActionTypes.FetchClientLocationsFail
)<number, CourseLocation[], { errorMessage: string }>();

export const FetchClientTrainings = createAsyncAction(
  ClientsActionTypes.FetchClientTrainings,
  ClientsActionTypes.FetchClientTrainingsSuccess,
  ClientsActionTypes.FetchClientTrainingsFail
)<number, GQLTraining[], { errorMessage: string }>();

export const FetchClientRequests = createAsyncAction(
  ClientsActionTypes.FetchClientRequests,
  ClientsActionTypes.FetchClientRequestsSuccess,
  ClientsActionTypes.FetchClientRequestsFail
)<number, GQLRequest[], { errorMessage: string }>();

export const SaveClient = createAction(ClientsActionTypes.SaveClient, resolve => {
  return (value: { client: Client; noredirect?: boolean }) => resolve(value);
});
export const SaveClientSuccess = createAction(ClientsActionTypes.SaveClientSuccess, resolve => {
  return (value: { client: Client }) => resolve(value);
});
export const SaveClientFail = createAction(ClientsActionTypes.SaveClientFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const DeleteClient = createAsyncAction(
  '[Clients] Delete client...',
  '[Clients] Delete client success',
  '[Clients] Delete client failure'
)<{ clientId: number; queryParams: QueryParams }, void, { errorMessage: string }>();

export const FetchContactParticipations = createAsyncAction(
  '[Clients] Fetch contact participations...',
  '[Clients] Fetch contact participations success',
  '[Clients] Fetch contact participations failure'
)<{ contact: Contact }, Participant<WithTraining & WithParticipations>[], { errorMessage: string }>();

export const SetClientTabIndex = createAction('[Clients] Set client tab index', resolve => {
  return (value: { index: number; client: Client }) => resolve(value);
});

export const UnlinkLocationFromClient = createAsyncAction(
  ClientsActionTypes.UnlinkLocationFromClient,
  ClientsActionTypes.UnlinkLocationFromClientSuccess,
  ClientsActionTypes.UnlinkLocationFromClientFail
)<{ id: number; clientId: number }, void, { errorMessage: string }>();
