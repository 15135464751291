import { createSelector } from 'reselect';
import { LocationsState } from './state';
import { GlobalState } from '../../core/state';
import { LocationsResources } from '../components/Detail/DetailShell';

export const getLocations = createSelector(
  (state: LocationsState) => state,
  state => state.locations
);

export const getSelectedLocationId = createSelector(
  (state: LocationsState) => state,
  state => state.selectedLocationId
);

export const getSelectedLocation = createSelector(
  (state: LocationsState) => state,
  state => state.selectedLocation
);

export const getLoadingLocations = createSelector(
  (state: LocationsState) => state,
  state => state.loadingLocations
);

export const getSavingLocations = createSelector(
  (state: LocationsState) => state,
  state => state.savingLocation
);

export const getLocationSaved = createSelector(
  (state: LocationsState) => state,
  state => state.locationSaved
);

export const getAmountPages = createSelector(
  (state: LocationsState) => state,
  state => Math.ceil(state.totalLocations / state.pageSize)
);

export const getResources = createSelector(
  (state: GlobalState) => state,
  state => ({ languages: state.app.languages } as LocationsResources)
);
