import React, { Component } from 'react';

interface DetailGroupProps {
  title?: string;
  actions?: JSX.Element;
}

class DetailGroup extends Component<DetailGroupProps> {
  render() {
    return (
      <div className="display-group">
        <div className="row header">
          <div className="col-9">
            <label>{this.props.title}</label>
          </div>
          <div className="col-3">
            <div className="action-button">{this.props.actions ? this.props.actions : null}</div>
          </div>
        </div>
        {this.props.children ? this.props.children : null}
      </div>
    );
  }
}

export default DetailGroup;
