import React, { useState } from 'react';
import { Segment, Icon, Modal, Button } from 'semantic-ui-react';
import Contact from '../../../../shared/interfaces/Contact';
import { ContactType, newContactData } from '../../../helpers/constants';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import '../../../clients.scss';
import { FetchContactParticipations, FetchOneClient } from '../../../state/actions';
import { GlobalState } from '../../../../core/state';
import { Participant, WithTraining, WithParticipations } from '../../../../shared/interfaces/participant';
import ProgressStatus from '../../../../shared/interfaces/ProgressStatus';
import DetailForm from '../../../../shared/components/DetailForm/DetailForm';
import saveContact from '../../../state/api/saveContact';
import removeContact from '../../../state/api/removeContact';
import { contactFormDefinition } from '../../../helpers/DetailFormFields';
import Client from '../../../../shared/interfaces/Client';
import { ConfirmActionParameters, ConfirmAction } from '../../../../core/state/actions';
import { Link } from 'react-router-dom';

interface ContactSectionProps {
  contacts: Contact[];
  client: Client;
  participants: Participant<WithTraining & WithParticipations>[] | undefined;
  participantsStatus: ProgressStatus;
  fetchContactParticipants: (contact: Contact) => void;
  fetchClient: (clientId: number) => void;
  askConfirmation: (params: ConfirmActionParameters) => void;
}

type ContactViewOptions = 'view' | 'edit' | 'create';

interface State {
  contact: Contact | undefined;
  viewOption: ContactViewOptions | undefined;
}

const ContactsSection = ({
  contacts,
  client,
  fetchContactParticipants,
  fetchClient,
  participants,
  participantsStatus,
  askConfirmation,
  t
}: ContactSectionProps & WithNamespaces) => {
  const [openAction, setOpenActions] = useState(false);
  const [contactList, setContactList] = useState(contacts);
  const [state, setState] = useState<State>({
    contact: undefined,
    viewOption: undefined
  });

  const selectContact = (option: ContactViewOptions, contact?: Contact) => {
    if ('view' === option) {
      fetchContactParticipants(contact!);
    }

    if (contact) {
      setState({ contact: contact, viewOption: option });
      return;
    }

    setState({ ...state, viewOption: option });
  };

  const renderAttendenceCount = (participant: Participant<WithParticipations>) => {
    const max = participant.participations.length;
    const attendedCount = participant.participations.filter(participation => participation.attended).length;
    return attendedCount + '/' + max;
  };

  const renderParticipation = () => {
    switch (participantsStatus) {
      case ProgressStatus.InProgress:
        return;
      case ProgressStatus.Done:
        return undefined === participants || 0 === participants.length ? (
          <p>{t('clients.contacts.no-participations-found')}</p>
        ) : (
          participants.map((participant, index) => {
            return (
              <div className="participant-list-item" key={index}>
                <Link to={'/trainings/' + participant.training.id}>
                  {participant.training.course!.title}
                  <span className="label gray ml-4">
                    <Icon name="checkmark" /> {renderAttendenceCount(participant)}
                  </span>
                </Link>
                <p>
                  {participant.training.firstSessionDate
                    ? participant.training.firstSessionDate
                    : t('clients.contacts.start-date-not-found')}
                </p>
                {participants.length === index + 1 ? null : <hr />}
              </div>
            );
          })
        );
      case ProgressStatus.Error:
        return <p>{t('clients.contacts.no-participations-found')}</p>;
      case ProgressStatus.Uninitialized:
        return null;
    }
  };

  const renderModal = () => {
    switch (state.viewOption) {
      case 'view':
        return state.contact ? (
          <>
            <h3>
              {state.contact.firstName} {state.contact.lastName}
            </h3>
            <br />
            {state.contact.email ? (
              <p>
                <span className="text-gray mx-1">
                  <Icon name="envelope" />
                </span>
                {state.contact.email}
              </p>
            ) : null}
            
            {state.contact.phone ? (
              <p>
                <span className="text-gray mx-1">
                  <Icon name="phone" />
                </span>
                {state.contact.phone}
              </p>
            ) : null}
            
            {state.contact.cellphone ? (
            <p>
              <span className="text-gray mx-1">
                <Icon name="phone" />
              </span>
              {state.contact.cellphone}
            </p>
            ): null}
            
            <br />
            <h5><b>{t('participations')}</b></h5>
            <div className="participant-list">{renderParticipation()}</div>
          </>
        ) : null;
      case 'edit':
        return (
          <DetailForm
            onFormSubmit={data => {
              saveContact(data as Contact).then(() => fetchClient(client.id));
            }}
            selectedEntity={state.contact!}
            onCancelBtnClick={() => setState({ ...state, viewOption: undefined })}
            formDefinition={contactFormDefinition}
          />
        );
      case 'create':
        return (
          <DetailForm
            onFormSubmit={data => {
              saveContact(data as Contact).then(() => fetchClient(client.id));
            }}
            selectedEntity={{ ...newContactData, customer: client['@id'] }}
            onCancelBtnClick={() => setState({ ...state, viewOption: undefined })}
            formDefinition={contactFormDefinition}
          />
        );
      case undefined:
        return null;
    }
  };

  return (
    <div className="contact-teaser-list">
      <Button className="primary mb-4" onClick={() => selectContact('create')}>
        {t('clients.contacts.new-contact.label')}
      </Button>
      <Button icon onClick={() => setOpenActions(!openAction)}>
        <Icon name="cog" />
      </Button>
      <div className="row">
        {contactList.map(contact => {
          return (
            <div className="col-12 col-md-6 col-lg-4" key={contact.id}>
              <Segment className="contact-blok" onClick={() => (openAction ? null : selectContact('view', contact))}>
                {contact.isLeading && (
                  <div className="important">
                    <Icon name="star" />
                  </div>
                )}
                <div className="user-image">
                  <Icon name="user" />
                </div>
                {contact.firstName} {contact.lastName}
                {openAction ? (
                  <div className="actions">
                    <Button icon onClick={() => selectContact('edit', contact)}>
                      <Icon name="pencil" />
                    </Button>
                    {/*<Button
                      icon
                      className="red"
                      onClick={() =>
                        askConfirmation({
                          title: 'clients.contacts.remove-contact.modal.title',
                          content: 'clients.contacts.remove-contact.modal.content',
                          action: () => {
                            removeContact(contact).then(() => setContactList(contacts.filter(c => c.id !== contact.id)));
                          }
                        })
                      }>
                      <Icon name="delete" />
                    </Button>*/}
                  </div>
                ) : null}
              </Segment>
            </div>
          );
        })}
        <Modal
          className="contact-detail-modal"
          open={state.viewOption ? true : false}
          onClose={() => setState({ ...state, viewOption: undefined })}
          closeOnEscape={true}
          closeIcon>
          <Modal.Content>{renderModal()}</Modal.Content>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    participants: state.clients.participants as Participant<WithTraining & WithParticipations>[] | undefined,
    participantsStatus: state.clients.loadingParticipantsStatus as ProgressStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchContactParticipants: (contact: Contact) => dispatch(FetchContactParticipations.request({ contact })),
  fetchClient: (id: number) => dispatch(FetchOneClient({ clientId: id as number })),
  askConfirmation: (params: ConfirmActionParameters) => dispatch(ConfirmAction.request(params))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['resources', 'common', 'trainings', 'clients'], { nsMode: 'fallback' })(ContactsSection));
