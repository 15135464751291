import { TrainingRequest } from '../../interfaces/TrainingRequest';
import Course from '../../interfaces/Course';

export enum templates {
  'infomail' = 'infomail',
  'confirm_planning' = 'confirm_planning',
  'ic_order' = 'ic_order',
  'oc_order' = 'oc_order',
  'info_form_mail' = 'info_form_mail',
  'trainer_contract' = 'trainer_contract',
  'trainer_info' = 'trainer_info',
  'participant_day_confirmation_mail' = 'participant_day_confirmation_mail',
  'participant_evening_confirmation_mail' = 'participant_evening_confirmation_mail'
}

export const infomailTemplate = (trainingRequest: TrainingRequest, course: Course | undefined) => {
  return `
  <p>Geachte ${trainingRequest.firstName},<p><br>
<p>Hartelijk dank voor uw interesse in ons opleidingscentrum.</p><br>
<p>Hierbij vindt u alle informatie betreft de opleiding ${course ? course.title : '...'}:</p><br>
  `;
};

export const orderTemplate = (trainingRequest: TrainingRequest) => {
  return `
<p>Geachte ${trainingRequest.firstName},</p><br>

<p>Hartelijk dank voor deze inschrijving.</p>
<p>Kan u ons order in bijlage aangevuld en ondertekend terugbezorgen om de administratie in orde te brengen aub?</p>
<p>OPGELET: de factuur wordt opgemaakt aan de gegevens die u op het order vermeld!</p><br>

<p>Mocht u nog een vraag of opmerking hebben, kan u steeds terecht per kerende mail.</p>
  `;
};

export const offerteTemplate = (trainingRequest: TrainingRequest, course: Course | undefined) => {
  return `
<p>Beste ${trainingRequest.firstName},</p><br>

<p>Hartelijk dank om ons opnieuw te contacteren.</p>

<p>In bijlage vindt u onze offerte voor ${course ? course.title : '...'}.</p>

<p>Mocht u nog een vraag of opmerking hebben, kan u steeds terecht per kerende mail of op 03/239.54.67.</p>
<p>Graag vernemen wij uw beslissing!</p>
 `;
};

export const infoFormMailTemplate = (link: string) => {
  return `<p>Beste,</p><br>
  <p>Wij hebben uw bevestiging goed ontvangen, waarvoor dank. Graag informeren wij u hierbij over het verloop van het project. Om de opleiding zo optimaal mogelijk voor te bereiden vragen wij u het formulier aan te vullen. Indien u nog extra informatie wenst te geven, kan u dit steeds bij opmerkingen. De vragen die niet van toepassing zijn mag u “NVT” vermelden.</p>
  <a target="_blanc" href="${link}">${link}</a><br /><br />
  <p>Opgelet! Gelieve de KMO-Portefeuille tijdig aan te vragen.</p>
  <p>Dit kan u best doen vóór aanvang van de lessen op <a href="https://www.kmo-portefeuille.be">www.kmo-portefeuille.be</a>.</p><br /><br />
  <p>Indien u vragen of opmerkingen heeft kan u ons steeds contacteren via 03/239.54.67 of per kerende mail.</p>
  <p>U kan ook ten allen tijde een online evaluatieformulier invullen via <a href="https://www.ect.be/cursusevaluatie">www.ect.be/cursusevaluatie</a>.</p>
  <p>Dit zowel vóór, tijdens als na de opleiding.</p>
  <br />
    <p>Hartelijk dank bij voorbaat!<p><br />
    <p>Met vriendelijke groeten,<br />
    Sara Geijsbregts<br />
    Training Coördinator<br />
    Tel.: 03/239.54.67</p>
  `;
};

export const trainerContractMailTemplate = () => {
  return `
  <p>Beste,</p><br />

  <p>In bijlage je contract dat ik afdruk ter ondertekening.</p>
  <p>Bezorg je het getekend terug en zet je de data definitief in je agenda aub?</p>
  <p>Bedankt!</p>
  <br />

  <p>Wij hebben bepaalde documenten nodig, omwille van controles.</p>
  <p>Alleen zo kunnen wij gecertificeerd blijven voor het KMO-portefeuille kwaliteitscertificaat.</p>
  <p>Daarom willen wij u vragen om aan volgende documenten te denken:</p>
  <br />
  <ul>
  <li>Aanwezigheidslijst</li>
  <li>Evaluatie door deelnemers</li>
  <li>Evaluatie door u als trainer</li>
  </ul><br />
  <p>Bedankt voor uw begrip!</p>
  `;
};

export const infoTrainerMailTemplate = (course: Course, location: string, dates: string) => {
  return `
    <p>Beste,</p>
    <br />
    <p>Als bijlage vind je de infofiche voor de opleiding ${course.title}${location ? ' bij ' + location : ''}${
    dates ? ' op ' + dates : ''
  }.</p>
    <p>Zorg jij ervoor dat we de AL getekend terugkrijgen en dat alle deelnemers evalueren op <a href="https://www.ect.be/evaluatie">www.ect.be/evaluatie</a>?</p>
    <p>Mocht je nog een vraag of opmerking hebben, lees ik deze graag!</p>
    <br />
    <p>Hartelijk dank bij voorbaat!<p><br />
    <p>Met vriendelijke groeten,<br />
    Sara Geijsbregts<br />
    Training Coördinator<br />
    Tel.: 03/239.54.67</p>
  `;
};

export const participantDayConfirmationMailTemplate = (course: Course, location: string, dates: string) => {
  return `
    <p>Beste,</p>
    <br />
    <p>Wij hebben uw bevestiging goed ontvangen, waarvoor dank. U heeft zich ingeschreven voor de opleiding ${course.title}.</p>
    <p>Wat mag u van ons verwachten:</p>
    <ul>
    <li>De opleiding zal doorgaan op ${dates}${location ? ' te ' + location : ''}.</li>
    <li>
        <p>Wij mailen u twee weken voor de opleiding een uitnodiging met wegbeschrijving.</p>
        <p>Mocht u geen uitnodiging ontvangen hebben 1 week voor de opleiding, gelieve ons dan te contacteren!</p>
        <p>Indien u de wegbeschrijving reeds op voorhand wenst te raadplegen kan dat op <a href="https://www.ect.be">www.ect.be</a>.</p>
    </li>
    <li>We bezorgen u tijdens de opleiding een getuigschrift en evaluatieformulier.</li>
    </ul>
    <br />
    <br />
    <p>Opgelet! Indien u de <a href="https://www.vlaio.be/nl/subsidies-financiering/kmo-portefeuille">KMO-Portefeuille</a> aanvraagt kan u dit best doen vóór aanvang van de lessen.</p>
    <p>U dient een projectaanvraag in voor €... .</p>
    <br />
    <p>Indien u vragen of opmerkingen heeft kan u ons steeds contacteren via 03/239.54.67 of per kerende mail.</p>
    <p>U kan ook ten allen tijde een online evaluatieformulier invullen via <a href="https://www.ect.be/cursusevaluatie">www.ect.be/cursusevaluatie</a>.</p>
    <p>Dit zowel vóór, tijdens als na de opleiding.</p>
    <br />
    <p>Met vriendelijke groeten,<br />
    Sara Geijsbregts<br />
    Training Coördinator<br />
    Tel.: 03/239.54.67</p>
  `;
};

export const participantEveningConfirmationMailTemplate = (course: Course, location: string, dates: string) => {
  return `
    <p>Beste,</p>
    <br />
    <p>Wij hebben uw bevestiging goed ontvangen, waarvoor dank. U heeft zich ingeschreven voor de opleiding ${course.title}.</p>
    <p>Wat mag u van ons verwachten:</p>
    <ul>
    <li>De opleiding zal doorgaan op ${dates}${location ? ' te ' + location : ''}.</li>
    <li>
        <p>Wij mailen u twee weken voor de opleiding een uitnodiging met wegbeschrijving.</p>
        <p>Mocht u geen uitnodiging ontvangen hebben 1 week voor de opleiding, gelieve ons dan te contacteren!</p>
        <p>Indien u de wegbeschrijving reeds op voorhand wenst te raadplegen kan dat op <a href="https://www.ect.be">www.ect.be</a>.</p>
    </li>
    <li>We bezorgen u tijdens de opleiding een getuigschrift en evaluatieformulier.</li>
    </ul>
    <br />
    <br />
    <p>Opgelet! Indien u de <a href="https://www.vlaio.be/nl/subsidies-financiering/kmo-portefeuille">KMO-Portefeuille</a> aanvraagt kan u dit best doen vóór aanvang van de lessen.</p>
    <p>U dient een projectaanvraag in voor €... .</p>
    <br />
    <p>Indien u vragen of opmerkingen heeft kan u ons steeds contacteren via 03/239.54.67 of per kerende mail.</p>
    <p>U kan ook ten allen tijde een online evaluatieformulier invullen via <a href="https://www.ect.be/cursusevaluatie">www.ect.be/cursusevaluatie</a>.</p>
    <p>Dit zowel vóór, tijdens als na de opleiding.</p>
    <br />
    <p>Met vriendelijke groeten,<br />
    Sara Geijsbregts<br />
    Training Coördinator<br />
    Tel.: 03/239.54.67</p>
  `;
};
