import React, { Component, Fragment } from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { WithNamespaces, translate } from 'react-i18next';
import PageHeader from '../../../shared/components/PageHeader';
import isEqual from 'lodash/isEqual';
import CalendarShell from './CalendarShell';
import ListView from './ListView';

type LocationShellProps = {
  pathName: string;
} & RouteComponentProps;

class LocationShell extends Component<LocationShellProps & WithNamespaces & RouteComponentProps> {
  state = {
    drawerOpen: false
  };

  isPositive = (path: string) => {
    return isEqual(this.props.location.pathname, this.props.match.path + path);
  };

  toggleFilter = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <PageHeader breadCrumbParts={[t('locations')]} title={t('moduleTitle')} {...this.props} />
        <Button.Group className="m-r-sm button-switch">
          <Button icon onClick={() => this.props.history.push(this.props.match.path)} disabled={this.isPositive('')}>
            <Icon name="list" />
          </Button>
          <Button icon onClick={() => this.props.history.push(this.props.match.path + '/calendar')} disabled={this.isPositive('/calendar')}>
            <Icon name="calendar alternate" />
          </Button>
        </Button.Group>
        <Button
          positive
          className="primary"
          content="Nieuwe locatie"
          onClick={() => this.props.history.push(this.props.match.path + '/create')}
        />
        {this.isPositive('/calendar') ? (
          <div className="float-right">
            <Button icon onClick={this.toggleFilter}>
              <Icon name="filter" />
            </Button>
          </div>
        ) : null}
        <br /> <br />
        <Switch>
          <Route
            path={this.props.match.path + '/calendar'}
            render={props => (
              <CalendarShell {...props} drawerOpen={this.state.drawerOpen} closeDrawer={() => this.setState({ drawerOpen: false })} />
            )}
          />
          <Route path={this.props.match.path} component={ListView} />
        </Switch>
      </Fragment>
    );
  }
}

export default translate(['locations', 'menu'], { nsMode: 'fallback' })(LocationShell);
