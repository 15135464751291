import * as actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import CourseCategory, { WithParent } from '../../shared/interfaces/CourseCategory';

export type CategoryState = {
  errorMessage: string | null;
  savingCategoryStatus: ProgressStatus;
  deletingCategoryStatus: ProgressStatus;
  loadingCategoriesStatus: ProgressStatus;
  categories: CourseCategory[];
  category: CourseCategory<WithParent> | undefined;
};

const initialState: CategoryState = {
  errorMessage: null,
  savingCategoryStatus: ProgressStatus.Uninitialized,
  deletingCategoryStatus: ProgressStatus.Uninitialized,
  loadingCategoriesStatus: ProgressStatus.Uninitialized,
  categories: [],
  category: undefined
};

export type CategoryAction = ActionType<typeof actions>;

export function categories(state: CategoryState = initialState, action: CategoryAction): CategoryState {
  switch (action.type) {
    case getType(actions.SaveCategory.request):
      return {
        ...state,
        savingCategoryStatus: ProgressStatus.InProgress
      };

    case getType(actions.SaveCategory.success):
      return {
        ...state,
        savingCategoryStatus: ProgressStatus.Done,
        category: action.payload
      };

    case getType(actions.SaveCategory.failure):
      return {
        ...state,
        savingCategoryStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchCategories.request):
      return {
        ...state,
        loadingCategoriesStatus: ProgressStatus.InProgress,
        categories: []
      };
    case getType(actions.FetchCategories.success):
      return {
        ...state,
        loadingCategoriesStatus: ProgressStatus.Done,
        categories: action.payload.categories
      };
    case getType(actions.FetchCategories.failure):
      return {
        ...state,
        loadingCategoriesStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchOneCategory.request):
      return {
        ...state,
        category: undefined
      };
    case getType(actions.FetchOneCategory.success):
      return {
        ...state,
        category: action.payload.category
      };
    case getType(actions.FetchOneCategory.failure):
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.DeleteCategory.request):
      return { ...state, deletingCategoryStatus: ProgressStatus.InProgress };

    case getType(actions.DeleteCategory.success):
      return {
        ...state,
        deletingCategoryStatus: ProgressStatus.Done
      };

    case getType(actions.DeleteCategory.failure):
      return {
        ...state,
        deletingCategoryStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };

    default:
      return { ...state };
  }
}
