import React, { SyntheticEvent } from 'react';
import { Input, Button, Form } from 'semantic-ui-react';
import { translate, WithNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router';

type DataTableFilterBoxProps = WithNamespaces &
  RouteComponentProps & {
    onSubmit: (value: string | number) => void;
  };

interface DataTableFilterState {
  searchValue: string | number;
}

class DataTableFilterBox extends React.Component<DataTableFilterBoxProps, DataTableFilterState> {
  constructor(props: DataTableFilterBoxProps) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('searchValue');

    this.state = {
      searchValue: myParam ? myParam : ''
    };
  }

  handleOnChange = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    this.setState({
      searchValue: value
    });
  };

  render() {
    return (
      <div>
        <Form onSubmit={() => this.props.onSubmit(this.state.searchValue)}>
          <Input type="text" placeholder={this.props.t('search...')} fluid action>
            <input
              value={this.state.searchValue}
              onChange={e => {
                this.handleOnChange(e);
              }}
            />
            <Button type="submit">{this.props.t('search')}</Button>
          </Input>
        </Form>
      </div>
    );
  }
}

export default translate(['search'], { nsMode: 'fallback' })(DataTableFilterBox);
