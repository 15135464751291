import React from 'react';
import { Table } from 'semantic-ui-react';
import { GQLRequest } from '../../../state/state';
import ProgressStatus from '../../../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../../../shared/components/InitialLoader';
import { WithNamespaces } from 'react-i18next';
import DetailGroup from '../../../../shared/components/DetailGroup/DetailGroup';
import { $enum } from 'ts-enum-util';
import { TrainingType } from '../../../../shared/interfaces/Training';
import moment from 'moment';
import { TrainingRequestStatus } from '../../../../shared/interfaces/TrainingRequest';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import applyArchiveTagCheck from '../../../../shared/helpers/archivedFormatter';

interface RequestsSectionProps {
  requests: GQLRequest[] | undefined;
  loadingRequestsStatus: ProgressStatus;
}

export const RequestsSection = ({
  requests,
  t,
  loadingRequestsStatus,
  history
}: RequestsSectionProps & WithNamespaces & RouteComponentProps) => {
  if (loadingRequestsStatus === ProgressStatus.InProgress) {
    return <InitialLoader />;
  }

  return (
    <DetailGroup title={t('requests-history')}>
      {requests && requests.length > 0 ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('requestDate')}</Table.HeaderCell>
              <Table.HeaderCell>{t('request')}</Table.HeaderCell>
              <Table.HeaderCell>{t('trainingType')}</Table.HeaderCell>
              <Table.HeaderCell>{t('status')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {requests.reverse().map((request, key) => (
              <Table.Row key={key}>
                <Table.Cell>
                  <Link to={`/requests/${request._id}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: applyArchiveTagCheck(moment(request.createdAt).format('DD/MM/YYYY'), request.deletedAt, true, true)
                      }}></span>
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {request.training && request.training.course
                    ? request.training.course.title
                    : request.course && request.course.title
                    ? request.course.title
                    : '-'}
                </Table.Cell>
                <Table.Cell>{request.trainingType ? t($enum(TrainingType).getKeyOrDefault(request.trainingType)!) : '-'}</Table.Cell>
                <Table.Cell>{t($enum(TrainingRequestStatus).getKeyOrDefault(request.status)!)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        t('no-requests-history')
      )}
    </DetailGroup>
  );
};
