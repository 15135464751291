import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import { configConstants } from '../../config/constants';
import * as actions from './actions';
import { fetch } from '../../core/state/fetch';
import {
  TrainingRequest,
  WithTrainingRequestSessions,
  WithCourse,
  WithTraining,
  WithTrainingSessions
} from '../../shared/interfaces/TrainingRequest';
import queryString from 'query-string';
import { push } from 'connected-react-router';
import { getType } from 'typesafe-actions';
import { FetchOneCourse } from '../../courses/state/actions';
import { TrainingType, Training } from '../../shared/interfaces/Training';
import { RequestDocument, WithRequestDocument, RequestDocumentType } from '../../shared/interfaces/RequestDocument';
import { DbFile } from '../../shared/interfaces/DbFile';
import moment from 'moment';
import Course from '../../shared/interfaces/Course';
import { Mail, WithTrainingRequestDocuments } from '../../shared/interfaces/Mail';
import AlertService from '../../core/alert/AlertService';

export function* loadTrainingRequests(action: ReturnType<typeof actions.FetchTrainingRequests.request>) {
  try {
    const { sort, order } = action.payload;
    delete action.payload.sort;
    delete action.payload.order;
    let query = queryString.stringify(action.payload);

    query += sort ? `&order[${sort}]=` + (order || 'asc') : '&order[updatedAt]=desc';
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}training_requests?${query}&embed[]=course&embed[]=trainingrequestsession&embed[]=training&embed[]=trainingsession`
    );
    const responseCourses = yield call(fetch, `${configConstants.apiUrl}courses?pagination=false&order[title]=asc`);

    if (response.status >= 200 && response.status < 300 && responseCourses.status >= 200 && responseCourses.status < 300) {
      const data = yield response.json();
      const trainingRequests = data['hydra:member'] as TrainingRequest<
        WithTrainingRequestSessions & WithCourse & WithTraining & WithTrainingSessions
      >[];
      const totalTrainingRequests = data['hydra:totalItems'] as number;

      const dataCourses = yield responseCourses.json();
      const courses = dataCourses['hydra:member'] as Course[];

      yield put(
        actions.FetchTrainingRequests.success({
          trainingRequests,
          totalTrainingRequests,
          courses
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchTrainingRequests.failure({ errorMessage: error }));
  }
}

export function* loadOneTrainingRequest(action: ReturnType<typeof actions.FetchOneTrainingRequest.request>) {
  try {
    const { id } = action.payload;
    const response = yield call(fetch, `${configConstants.apiUrl}training_requests/${id}`);
    if (response.status >= 200 && response.status < 300) {
      const data: TrainingRequest = yield response.json();

      let selectedClient;
      let selectedCourse;

      if (data.customer) {
        const id = data.customer.replace(/\D+/g, '');
        const customerResponse = yield call(fetch, `${configConstants.apiUrl}customers/${id}`);
        if (response.ok) {
          const customer = yield customerResponse.json();
          selectedClient = customer;
        }
      }

      if (data.trainingType === TrainingType.OpenKalender) {
        if (data.training) {
          const traingingId = parseInt(data.training.replace(/\D+/g, ''));
          const response = yield call(fetch, `${configConstants.apiUrl}trainings/${traingingId}`);
          if (response.status >= 200 && response.status < 300) {
            const trainingData: Training = yield response.json();
            yield put(actions.FetchTrainingRequestTraining.success({ selectedTrainingRequestTraining: trainingData }));

            const id = trainingData.course!.replace(/\D+/g, '');
            const courseResponse = yield call(fetch, `${configConstants.apiUrl}courses/${id}`);
            if (response.ok) {
              const course = yield courseResponse.json();
              selectedCourse = course;
            }

            const sessionResponse = yield call(
              fetch,
              `${configConstants.apiUrl}training_sessions?training=${traingingId}&embed[]=location&embed[]=trainer`
            );

            if (sessionResponse.status >= 200 && sessionResponse.status < 300) {
              const trainingSessions = yield sessionResponse.json();

              yield put(actions.FetchTrainingRequestSessions.success({ requestSessions: trainingSessions['hydra:member'] }));
            }
          }
        }
      } else {
        if (data.course) {
          const id = data.course.replace(/\D+/g, '');
          const courseResponse = yield call(fetch, `${configConstants.apiUrl}courses/${id}`);
          if (response.ok) {
            const course = yield courseResponse.json();
            selectedCourse = course;
          }

          const sessionResponse = yield call(
            fetch,
            `${configConstants.apiUrl}training_request_sessions?trainingRequest=${data.id}&embed[]=location&embed[]=trainer`
          );

          if (sessionResponse.status >= 200 && sessionResponse.status < 300) {
            const trainingSessions = yield sessionResponse.json();

            yield put(actions.FetchTrainingRequestSessions.success({ requestSessions: trainingSessions['hydra:member'] }));
          }
        }
      }
      yield put(
        actions.FetchOneTrainingRequest.success({
          selectedTrainingRequest: data,
          selectedClient: selectedClient,
          selectedCourse: selectedCourse
        })
      );
    } else {
      throw response;
    }
    return null;
  } catch (error) {
    yield put(actions.FetchOneTrainingRequest.failure({ errorMessage: error }));
  }
}

export function* saveTrainingRequest(action: ReturnType<typeof actions.SaveTrainingRequest.request>) {
  try {
    const { trainingRequest } = action.payload;

    const isNewTrainingRequest = trainingRequest.id === 0;
    if (!trainingRequest.followUpDate) {
      delete trainingRequest.followUpDate;
    }

    const response = yield call(
      fetch,
      isNewTrainingRequest
        ? `${configConstants.apiUrl}training_requests`
        : `${configConstants.apiUrl}training_requests/${trainingRequest.id}`,
      {
        method: isNewTrainingRequest ? 'POST' : 'PUT',
        body: JSON.stringify(isNewTrainingRequest ? { ...trainingRequest, id: undefined } : { ...trainingRequest }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const result = yield response.json();
      yield put(actions.SaveTrainingRequest.success({ trainingRequest: trainingRequest }));
      AlertService.setMessage({ title: 'Aanvraag opgeslagen', messageText: 'Uw aanvraag is succesvol opgeslagen.', type: 'success' });
      if (isNewTrainingRequest || action.payload.redirectAfterSave) {
        yield put(push(`/requests/${result.id}`));
      }
    } else {
      throw response;
    }
  } catch (error) {
    AlertService.setMessage({
      title: 'Aanvraag niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
      type: 'error'
    });
    yield put(actions.SaveTrainingRequest.failure({ errorMessage: error }));
  }
}

function* deleteTrainingRequest(action: ReturnType<typeof actions.DeleteTrainingRequest.request>) {
  try {
    const url = `training_requests/${action.payload.requestId}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });
    yield put(actions.FetchTrainingRequests.request(action.payload.queryParams));
    AlertService.setMessage({ title: 'Aanvraag verwijderd', messageText: 'Uw aanvraag is succesvol verwijderd.', type: 'success' });
  } catch (errorMessage) {
    yield put(actions.DeleteTrainingRequest.failure({ errorMessage }));
    AlertService.setMessage({
      title: 'Aanvraag niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
      type: 'error'
    });
  }
}

function setRequestStatus(request: TrainingRequest, endpoint: string) {
  return call(fetch, configConstants.apiUrl + `training_request/${request.id}/status/${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

function* setRequestStatusToWon(action: ReturnType<typeof actions.SetRequestToWon.request>) {
  try {
    yield setRequestStatus(action.payload, 'won');
    yield all([put(actions.FetchOneTrainingRequest.request({ id: action.payload.id })), put(actions.SetRequestToWon.success())]);
  } catch (error) {
    yield put(actions.SetRequestToWon.failure({ errorMessage: error }));
  }
}

function* setRequestStatusToLost(action: ReturnType<typeof actions.SetRequestToLost.request>) {
  try {
    yield setRequestStatus(action.payload, 'lost');
    yield all([put(actions.FetchOneTrainingRequest.request({ id: action.payload.id })), put(actions.SetRequestToLost.success())]);
  } catch (error) {
    yield put(actions.SetRequestToLost.failure({ errorMessage: error }));
  }
}
function* FetchTrainingRequestDocument(action: ReturnType<typeof actions.FetchTrainingRequestDocument.request>) {
  try {
    const type: {
      urlKey: string;
      documentKey: 'offerDocument' | 'orderDocument';
    } =
      action.payload.type === 'offerDocument'
        ? { urlKey: 'offer', documentKey: 'offerDocument' }
        : { urlKey: 'order', documentKey: 'orderDocument' };

    const response = yield call(fetch, `${configConstants.apiUrl}files/training_request/${action.payload.id}/generate_${type.urlKey}`);
    const file = (yield response.json())[`${type.documentKey}`];

    yield put(
      actions.FetchTrainingRequestDocument.success({
        fileUrl: file.accessUrl as string,
        type: type.documentKey
      })
    );
  } catch (error) {
    yield put(actions.FetchTrainingRequestDocument.failure({ errorMessage: error }));
  }
}

function* fetchRequestFiles(action: ReturnType<typeof actions.FetchRequestFiles.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}training_request_documents?trainingRequest=${action.payload}&embed[]=file`
    );
    const files = (yield response.json())['hydra:member'] as RequestDocument<WithRequestDocument>[];

    yield put(actions.FetchRequestFiles.success(files));
  } catch (error) {
    yield put(actions.FetchRequestFiles.failure({ errorMessage: error }));
  }
}

async function saveDbFile(file: DbFile) {
  const isNew = file.id === 0;
  const fileResponse = await fetch(`${configConstants.apiUrl}files${isNew ? '' : '/' + file.id}`, {
    method: isNew ? 'POST' : 'PUT',
    body: JSON.stringify({
      ...file,
      '@id': isNew ? undefined : file['@id'],
      id: isNew ? undefined : file.id
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return (await fileResponse.json()) as DbFile;
}

async function saveRequestDocument(requestDocument: RequestDocument) {
  const isNew = requestDocument.id === 0;
  const fileResponse = await fetch(`${configConstants.apiUrl}training_request_documents${isNew ? '' : '/' + requestDocument.id}`, {
    method: isNew ? 'POST' : 'PUT',
    body: JSON.stringify({
      ...requestDocument,
      '@id': isNew ? undefined : requestDocument['@id'],
      id: isNew ? undefined : requestDocument.id
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return (await fileResponse.json()) as RequestDocument;
}

function* addFileToRequest(action: ReturnType<typeof actions.AddFileToRequest.request>) {
  try {
    const { file } = action.payload;

    const dbFile: DbFile = yield call(saveDbFile, {
      '@id': '',
      id: 0,
      originalFilename: file.name,
      mimeType: file.type,
      fileSize: file.size,
      fileName: file.name,
      filePath: file.name,
      accessUrl: null,
      savePath: null,
      uploadCompletedAt: null,
      uploadUrl: null
    });

    yield call(window.fetch, dbFile.uploadUrl!, {
      method: 'PUT',
      body: file
    });

    yield saveDbFile({
      ...dbFile,
      uploadCompletedAt: moment().toISOString(true)
    });

    yield saveRequestDocument({
      '@id': '',
      id: 0,
      trainingRequest: `/training_requests/${action.payload.requestId}`,
      trainingRequestDocument: dbFile['@id'],
      type: RequestDocumentType.Document
    });

    yield put(actions.FetchRequestFiles.request(action.payload.requestId));
    yield put(actions.AddFileToRequest.success());
    AlertService.setMessage({ title: 'File toegevoegd', messageText: 'Uw file is succesvol opgeslagen.', type: 'success' });
  } catch (errorMessage) {
    yield put(actions.AddFileToRequest.failure({ errorMessage }));
    AlertService.setMessage({ title: 'File niet toegevoegd', messageText: 'Oeps, er liep iets mis.', type: 'error' });
  }
}

function* fetchRequestMails(action: ReturnType<typeof actions.FetchRequestMails.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}training_request_mails?mailEntityId=${action.payload}&embed[]=trainingrequestdocument&embed[]=file&order[createdAt]=desc`
    );

    const mails = (yield response.json())['hydra:member'] as Mail<WithTrainingRequestDocuments>[];

    yield put(actions.FetchRequestMails.success(mails));
  } catch (error) {
    yield put(actions.FetchRequestMails.failure({ errorMessage: error }));
  }
}

function* deleteRequestFile(action: ReturnType<typeof actions.DeleteRequestFile.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}training_request_documents?trainingRequestDocument=${action.payload.fileId}`
    );

    const result = yield response.json();
    const trainingDocument = result['hydra:member'];
    const id = trainingDocument[0].id;
    const deleteConnection = yield call(fetch, `${configConstants.apiUrl}training_request_documents/${id}`, { method: 'DELETE' });
    if (deleteConnection.status === 204) {
      yield call(fetch, `${configConstants.apiUrl}files/${action.payload.fileId}`, { method: 'DELETE' });

      yield put(actions.FetchRequestFiles.request(action.payload.requestId));

      AlertService.setMessage({
        title: 'Document verwijderd',
        messageText: 'Het document is succesvol verwijderd.',
        type: 'success'
      });
    } else {
      throw deleteConnection;
    }
  } catch (errorMessage) {
    yield put(actions.DeleteRequestFile.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Document niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
      type: 'error'
    });
  }
}

export const TrainingRequestSaga = [
  takeLatest(getType(actions.FetchTrainingRequests.request), loadTrainingRequests),
  takeLatest(getType(actions.FetchOneTrainingRequest.request), loadOneTrainingRequest),
  takeLatest(getType(actions.SaveTrainingRequest.request), saveTrainingRequest),
  takeLatest(getType(actions.DeleteTrainingRequest.request), deleteTrainingRequest),
  takeLatest(getType(actions.SetRequestToWon.request), setRequestStatusToWon),
  takeLatest(getType(actions.SetRequestToLost.request), setRequestStatusToLost),
  takeEvery(getType(actions.FetchTrainingRequestDocument.request), FetchTrainingRequestDocument),
  takeLatest(getType(actions.FetchRequestFiles.request), fetchRequestFiles),
  takeLatest(getType(actions.AddFileToRequest.request), addFileToRequest),
  takeLatest(getType(actions.FetchRequestMails.request), fetchRequestMails),
  takeLatest(getType(actions.DeleteRequestFile.request), deleteRequestFile)
];
