import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Button, Icon, Tab, Menu, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GQLTrainingSession, GQLTrainingClient, GQLTrainingParticipant, GQLTrainingTask, trainings } from '../../state/state';
import { GlobalState } from '../../../core/state';
import { Training } from '../../../shared/interfaces/Training';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import * as actions from '../../state/actions';
import { RouteComponentProps } from 'react-router';
import InitialLoader from '../../../shared/components/InitialLoader';
import NoDataFound from '../../../shared/components/NoDataFound';
import Course from '../../../shared/interfaces/Course';
import SessionView from './components/Sessions/SessionView/SessionView';
import ParticipantsView from './components/Participants/Section';
import TasksView from './components/TasksView';
import { EntityReference } from '../../../shared/interfaces/Entity';
import TrainingDetails from './components/TrainingDetails';
import { TrainingRouteInfo } from './DetailShell';
import PageHeader from '../../../shared/components/PageHeader';
import FileView from './components/FileView';
import { WithTrainingDocument, TrainingDocument } from '../../../shared/interfaces/TrainingDocument';
import { ConfirmAction } from '../../../core/state/actions';
import Mailer from '../../../shared/components/Mailer';
import {
  templates,
  infoFormMailTemplate,
  trainerContractMailTemplate,
  infoTrainerMailTemplate,
  participantDayConfirmationMailTemplate,
  participantEveningConfirmationMailTemplate
} from '../../../shared/components/Mailer/templates';
import { Mail, WithDocumentReferences, WithTrainingDocuments } from '../../../shared/interfaces/Mail';
import TrainersView from './components/TrainersView';
import Contract from '../../../shared/interfaces/Contract';
import DetailViewMails from './components/DetailViewMails';
import { Auth0Context } from '../../../core/auth/AuthWrapper';
import moment from 'moment';

type DetailViewProps = {
  loadingTrainingStatus: ProgressStatus;
  sessionsStatus: ProgressStatus;
  participantsStatus: ProgressStatus;
  tasksStatus: ProgressStatus;
  saveTaskStatus: ProgressStatus;
  addFileStatus: ProgressStatus;
  loadFilesStatus: ProgressStatus;
  training: Training | undefined;
  course: Course | undefined;
  client: GQLTrainingClient | undefined;
  sessions: GQLTrainingSession[] | undefined;
  participants: GQLTrainingParticipant[] | undefined;
  tasks: GQLTrainingTask[] | undefined;
  files: TrainingDocument<WithTrainingDocument>[] | undefined;
  loadSessions: (trainingId: number) => void;
  loadParticipants: (trainingId: number) => void;
  loadParticipantsList: (trainingId: number) => void;
  loadTasks: (trainingId: number) => void;
  loadFiles: (trainingId: number) => void;
  saveTask: (data: { taskId: EntityReference; executed?: boolean; dueDate?: string }) => void;
  deleteSession: (sessionId: number, trainingId: number) => void;
  deleteParticipant: (participantId: number, trainingId: number) => void;
  addFileToTraining: (trainingId: number, file: File) => void;
  sendInformationForm: (trainingId: number) => void;
  informationFormLink: string | undefined;
  clearSessionData: () => void;
  setTabIndex: (index: number, training: Training) => void;
  activeTab: { index: number; training: Training } | undefined;
  allTrainerContracts: Contract[];
  getTrainerContractsForTraining: (trainingId: number) => void;
  mails: Mail<WithTrainingDocument & WithTrainingDocuments>[] | [];
  loadMails: (trainingId: number) => void;
  sendMail: (mail: Mail, trainingId: number) => void;
  deleteTrainingFile: (fileId: number, trainingId: number) => void;
} & WithNamespaces &
  RouteComponentProps<TrainingRouteInfo>;

interface DetailViewState {
  isSendModalOpen: boolean;
}

class DetailView extends React.Component<DetailViewProps, DetailViewState> {
  constructor(props: DetailViewProps) {
    super(props);

    this.state = {
      isSendModalOpen: false
    };
  }

  componentWillUnmount() {
    this.props.clearSessionData();
  }

  componentDidMount() {
    const { match, loadSessions, loadParticipants, loadTasks, loadFiles, getTrainerContractsForTraining, loadMails } = this.props;
    const trainingId = parseInt(match.params.trainingId);
    loadSessions(trainingId);
    loadParticipants(trainingId);
    loadTasks(trainingId);
    loadFiles(trainingId);
    getTrainerContractsForTraining(trainingId);
    loadMails(trainingId);
  }

  toggleSendMail = () => {
    this.setState({ isSendModalOpen: !this.state.isSendModalOpen });
  };

  render() {
    const {
      training,
      loadingTrainingStatus,
      addFileStatus,
      addFileToTraining,
      course,
      client,
      files,
      loadFilesStatus,
      saveTask,
      saveTaskStatus,
      deleteParticipant,
      sessions,
      allTrainerContracts,
      sendMail,
      deleteTrainingFile,
      participants,
      t
    } = this.props;

    if (loadingTrainingStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    if (training === undefined || course === undefined) {
      return <NoDataFound />;
    }

    const editButton = () => {
      return (
        <div className="button-group">
          <Button className="primary" onClick={() => this.props.history.push(`/trainings/${training.id}/edit`)}>
            {t('edit')}
          </Button>
        </div>
      );
    };

    const getTrainer = (sessions: GQLTrainingSession[]) => {
      const trainers: { value: string; text: string }[] = [];

      sessions.forEach(session => {
        if (session.trainer) {
          trainers[session.trainer.id.split('/')[2] as any] = {
            value: session.trainer.email,
            text: session.trainer.firstName + ' ' + session.trainer.lastName + ' (' + session.trainer.email + ')'
          };
        }
      });

      return trainers;
    };

    const getParticipants = () => {
      return this.props.participants
        ? this.props.participants.map(participant => {
            if (participant.contact && participant.customer) {
              return {
                value: participant.contact.email,
                text:
                  participant.contact.firstName +
                  ' ' +
                  participant.contact.firstName +
                  ' - ' +
                  participant.customer.companyName +
                  ' (' +
                  participant.contact.email +
                  ')'
              };
            }

            return {
              value: participant.email,
              text:
                'PRE: ' +
                participant.firstName +
                ' ' +
                participant.lastName +
                ' - ' +
                participant.customer!.companyName +
                ' (' +
                participant.email +
                ')'
            };
          })
        : [];
    };

    const getReceivers = (sessions: GQLTrainingSession[]) => {
      const receivers = [];
      if (getTrainer(sessions)) {
        receivers.push({
          title: t('trainers'),
          children: getTrainer(sessions)
        });
      }

      if (client) {
        receivers.push({
          title: t('participants'),
          children: getParticipants()
        });
      }

      return receivers;
    };

    const renderTrainers = <TrainersView {...this.props} contracts={allTrainerContracts} />;

    const userPanes = [
      {
        menuItem: 'Algemeen',
        render: () => (
          <Tab.Pane attached={false} key="info">
            <TrainingDetails training={training} course={course} {...this.props} />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item name="sessions" key="sessions">
            <span className="tab-title">{t('sessions')}</span>
            <Label circular size={'tiny'} className="counter">
              {sessions ? sessions.length : 0}
            </Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false} key="sessions">
            <SessionView {...this.props} sessions={this.props.sessions} training={training} course={course} />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item name="participants" key="participants">
            <span className="tab-title">{t('participants')}</span>
            {this.props.participants ? (
              <Label circular size={'tiny'} className="counter">
                {this.props.participants.filter((participant: GQLTrainingParticipant) => participant.deletedAt === null).length}
              </Label>
            ) : null}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false} key="participants">
            <ParticipantsView
              {...this.props}
              training={training}
              course={course}
              deleteParticipant={deleteParticipant}
              sessions={sessions}
            />
          </Tab.Pane>
        )
      }
      /*{
        menuItem: (
          <Menu.Item name="mails" key="mails">
            <span className="tab-title">{t('emails')}</span>
            <Label circular size={'tiny'} className="mails-count">
              {this.props.mails ? `${this.props.mails.length}` : ''}
            </Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false}>
            <DetailViewMails {...this.props} mails={this.props.mails ? [...this.props.mails] : []} />
          </Tab.Pane>
        )
      }*/
    ];

    const getTemplates = () => {
      const temps = [];

      if (training.type === 1 && training.formToken) {
        temps.push({
          key: templates.info_form_mail,
          body: infoFormMailTemplate(`${window.location.host}/customer-form?token=${training.formToken}`)
        });
      }

      let sessionDates: string[] = [];

      if (sessions && sessions.length) {
        sessionDates = sessions.map((session, i) => {
          if (sessions.length === i + 1) {
            return ` en ${moment(session.startDate).format('DD/MM/YYYY')}`;
          }

          if (i === 0) {
            return `${moment(session.startDate).format('DD/MM/YYYY')}`;
          }

          return `, ${moment(session.startDate).format('DD/MM/YYYY')} `;
        });
        
        let locationName = sessions ? (sessions[0].location ? sessions[0].location.name : '') : '';

        temps.push({
          key: templates.trainer_info,
          body: infoTrainerMailTemplate(
            course,
            locationName,
            sessions && sessionDates ? sessionDates.join('') : ''
          )
        });

        sessionDates = sessions.map((session, i) => {
          if (sessions.length === i + 1) {
            return ` en ${moment(session.startDate).format('DD/MM/YYYY')} ${moment(session.startDate).format('HH')}-${moment(
              session.endDate
            ).format('HH')}h`;
          }

          if (i === 0) {
            return `${moment(session.startDate).format('DD/MM/YYYY')} ${moment(session.startDate).format('HH')}-${moment(
              session.endDate
            ).format('HH')}h`;
          }

          return `, ${moment(session.startDate).format('DD/MM/YYYY')} ${moment(session.startDate).format('HH')}-${moment(
            session.endDate
          ).format('HH')}h`;
        });

        temps.push({
          key: templates.participant_day_confirmation_mail,
          body: participantDayConfirmationMailTemplate(
            course,
            locationName,
            sessions && sessionDates ? sessionDates.join('') : ''
          )
        });

        temps.push({
          key: templates.participant_evening_confirmation_mail,
          body: participantEveningConfirmationMailTemplate(
            course,
            locationName,
            sessions && sessionDates ? sessionDates.join('') : ''
          )
        });
      }

      temps.push({
        key: templates.trainer_contract,
        body: trainerContractMailTemplate()
      });

      return temps;
    };

    return (
      <Auth0Context.Consumer>
        {props => (
          <>
            <PageHeader
              breadCrumbParts={[this.props.t('trainings'), course!.title + ' ' + (client ? ` — ${client.companyName} ` : '')]}
              title={
                <>
                  {course!.title + ' ' + (client ? ` — ${client.companyName} ` : '')}
                  {training.deletedAt ? (
                    <span className="archived"> (Gearchiveerd op {moment(training.deletedAt).format('DD/MM/YY')})</span>
                  ) : null}
                </>
              }
              rightBlock={editButton()}
              {...this.props}
            />
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-8">
                <Tab menu={{ secondary: true, pointing: true }} panes={[...userPanes]} />
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <TasksView {...this.props} training={training} saveTask={saveTask} saveTaskStatus={saveTaskStatus} />

                <FileView
                  training={training}
                  addFileToTraining={addFileToTraining}
                  addFileStatus={addFileStatus}
                  filesStatus={loadFilesStatus}
                  files={files}
                  deleteTrainingFile={deleteTrainingFile}
                />
              </div>
            </div>

            <Mailer
              isOpen={this.state.isSendModalOpen}
              closeModal={this.toggleSendMail}
              entityReference={training['@id']}
              receiverOptions={sessions ? getReceivers(sessions) : undefined}
              files={(this.props.files || []).map(f => {
                return { key: f['@id'], file: f.trainingDocument };
              })}
              templates={getTemplates()}
              submit={(mail: Mail<WithDocumentReferences>) => {
                const trainingId = parseInt(this.props.match.params.trainingId);
                sendMail(mail, trainingId);
                this.setState({
                  isSendModalOpen: !this.state.isSendModalOpen
                });
              }}
            />
          </>
        )}
      </Auth0Context.Consumer>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    loadingTrainingStatus: state.trainings.loadingTrainingsStatus,
    sessionsStatus: state.trainings.loadingTrainingSessionsStatus,
    participantsStatus: state.trainings.loadingTrainingParticipantsStatus,
    tasksStatus: state.trainings.loadingTrainingTasksStatus,
    saveTaskStatus: state.trainings.saveTrainingTaskStatus,
    addFileStatus: state.trainings.addFileStatus,
    loadFilesStatus: state.trainings.loadFilesStatus,
    training: state.trainings.selectedTraining as Training | undefined,
    course: state.trainings.selectedTrainingCourse as Course | undefined,
    client: state.trainings.selectedTrainingClient as GQLTrainingClient | undefined,
    sessions: state.trainings.selectedTrainingSessions as GQLTrainingSession[] | undefined,
    participants: state.trainings.selectedTrainingParticipants as GQLTrainingParticipant[] | undefined,
    tasks: state.trainings.selectedTrainingTasks as GQLTrainingTask[] | undefined,
    files: state.trainings.selectedTrainingFiles as TrainingDocument<WithTrainingDocument>[] | undefined,
    informationFormLink: state.trainings.informationFormLink,
    allTrainerContracts: state.trainings.allTrainerContracts as Contract[],
    mails: state.trainings.mails as Mail<WithTrainingDocument & WithTrainingDocuments>[]
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadSessions: (id: number) =>
    dispatch(
      actions.FetchTrainingSessions.request({
        params: { id },
        fields: ['location', 'trainer']
      })
    ),
  loadParticipants: (id: number) => dispatch(actions.FetchTrainingParticipants.request(id)),
  loadTasks: (id: number) => dispatch(actions.FetchTrainingTasks.request(id)),
  saveTask: (data: { taskId: EntityReference; executed?: boolean; dueDate?: string }) => dispatch(actions.SaveTrainingTask.request(data)),

  deleteSession: (sessionId: number, trainingId: number) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteSession',
        content: 'confirmDeleteSession',
        action: () =>
          dispatch(
            actions.DeleteTrainingSession.request({
              sessionId,
              trainingId
            })
          )
      })
    ),

  deleteParticipant: (participantId: number, trainingId: number) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteParticipant',
        content: 'confirmDeleteParticipant',
        action: () => dispatch(actions.DeleteParticipant.request({ participantId, trainingId }))
      })
    ),

  loadMails: (trainingId: number) => dispatch(actions.FetchTrainingMails.request(trainingId)),
  addFileToTraining: (trainingId: number, file: File) => dispatch(actions.AddFileToTraining.request({ trainingId, file })),
  loadFiles: (trainingId: number) => dispatch(actions.FetchTrainingFiles.request(trainingId)),
  clearSessionData: () => dispatch(actions.ClearTrainingSessions()),
  sendInformationForm: (trainingId: number) => dispatch(actions.SendInformationForm.request({ trainingId })),
  getTrainerContractsForTraining: (trainingId: number) => dispatch(actions.FetchTrainerContractsForTraining.request(trainingId)),
  sendMail: (mail: Mail, trainingId: number) => dispatch(actions.SendMail.request({ mail, trainingId })),

  deleteTrainingFile: (fileId: number, trainingId: number) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteFile',
        content: 'confirmDeleteFile',
        action: () => dispatch(actions.DeleteTrainingFile.request({ fileId, trainingId }))
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['trainings', 'resources', 'menu', 'tasks'], { nsMode: 'fallback' })(DetailView));
