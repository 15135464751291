import React, { useEffect, Fragment } from 'react';
import PageHeader from '../shared/components/PageHeader';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Task } from '../shared/interfaces/Task';
import ProgressStatus from '../shared/interfaces/ProgressStatus';
import { GlobalState } from '../core/state';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as actions from './state/actions';
import InitialLoader from '../shared/components/InitialLoader';
import moment, { Moment } from 'moment';
import { Table, Label, Icon } from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import { Link } from 'react-router-dom';

interface Props extends WithNamespaces, RouteComponentProps {
  tasks: Task[];
  loadingTasksStatus: ProgressStatus;
  loadTasks: () => void;
}

const TasksOverview = (props: Props) => {
  const { t, loadingTasksStatus, tasks } = props;

  useEffect(({ loadTasks } = props) => {
    loadTasks();
  }, []);

  function getDueDateColor(dueDate: Moment): SemanticCOLORS | undefined {
    const now = moment();

    if (now.isAfter(dueDate)) {
      return 'red';
    }

    if (dueDate.subtract(7, 'days').isBefore(now)) {
      return 'orange';
    }

    return undefined;
  }

  if (loadingTasksStatus === ProgressStatus.InProgress) {
    return <InitialLoader />;
  }

  return (
    <>
      <PageHeader breadCrumbParts={[t('tasks')]} title={t('tasks')} {...props} />
      {tasks ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('dueDate')}</Table.HeaderCell>
              <Table.HeaderCell>{t('training')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tasks.map((task, key) => (
              <Table.Row key={key}>
                <Table.Cell>
                  <Icon name="clock outline" color={getDueDateColor(moment(task.dueDate))} />
                  {`${moment(task.dueDate).format('DD/MM/YYYY')}: ${task.description}`}
                </Table.Cell>
                <Table.Cell>
                  {task.training && task.training.course && <Link to={`/trainings/${task.training.id}`}>{task.training.course.title}</Link>}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        t('no-tasks')
      )}
    </>
  );
};
const mapStateToProps = (state: GlobalState) => {
  return {
    tasks: state.tasks.tasks as Task[] | [],
    loadingTasksStatus: state.tasks.loadingTasksStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTasks: () => dispatch(actions.FetchTasks.request())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['menu', 'resources', 'tasks'], { nsMode: 'fallback' })(TasksOverview));
