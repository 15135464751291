import React, { SyntheticEvent } from 'react';

interface NumberInputProps {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: SyntheticEvent) => void;
  value: number;
  label: string;
  keyField: string;
  type?: any;
  disabled?: boolean;
}

const NumberInput = ({ onBlur, onChange, value, label, keyField, disabled }: NumberInputProps) => {
  return <input disabled={disabled} placeholder={label} type="number" name={keyField} onChange={onChange} onBlur={onBlur} value={value} />;
};

export default NumberInput;
