import React from 'react';
import { Table } from 'semantic-ui-react';
import DataTableRow from './DataTableRow';
import DataTableHeader from './DataTableHeader';
import QueryParams from '../../interfaces/QueryParams';

interface DataTableProps {
  rows: any;
  fieldSet: { header: string; key: string }[];
  withActionButtons: boolean;
  onSelect: (key: number) => void;
  activeId?: number;
  onArchive: (key: number) => void;
  onEdit?: (key: number) => void;
  OnHeaderClick: (key: string) => void;
  activeSortHeader: string | undefined;
  direction?: QueryParams['order'];
  enabledSortingHeaders?: string[];
}

const DataTable = ({
  rows,
  fieldSet,
  withActionButtons,
  onSelect,
  activeId,
  onArchive,
  onEdit,
  OnHeaderClick,
  activeSortHeader,
  direction,
  enabledSortingHeaders
}: DataTableProps) => {
  return (
    <Table striped sortable>
      <DataTableHeader
        fieldSet={fieldSet}
        withActionButtons={withActionButtons}
        onHeaderClick={OnHeaderClick}
        activeSortHeader={activeSortHeader}
        direction={direction}
        enabledSortingHeaders={enabledSortingHeaders}
      />
      <Table.Body>
        <DataTableRow
          fieldSet={fieldSet.map(field => field.key)}
          rows={rows}
          withActionButtons={withActionButtons}
          onSelect={onSelect}
          onArchive={onArchive}
          activeId={activeId}
          onEdit={onEdit}
        />
      </Table.Body>
    </Table>
  );
};

export default DataTable;
