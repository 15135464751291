import React from 'react';
import DetailCreate from './DetailCreate';
import { TrainingSessionMaterial } from '../../../../../shared/interfaces/TrainingSessionMaterial';
import ProgressStatus from '../../../../../shared/interfaces/ProgressStatus';
import { GlobalState } from '../../../../../core/state';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router';
import Course from '../../../../../shared/interfaces/Course';
import { connect } from 'react-redux';
import { SessionRouteInfo } from '../Sessions/Shell';
import { WithNamespaces, translate } from 'react-i18next';
import { Dispatch } from 'redux';
import { TrainingsAction, TrainingSessionWithMaterials } from '../../../../state/state';
import * as actions from '../../../../state/actions';
import { newEntity } from './helpers/sessionMaterialFormFields';
import InitialLoader from '../../../../../shared/components/InitialLoader';
import NoDataFound from '../../../../../shared/components/NoDataFound';

interface MaterialRouteInfo {
  sessionMaterialId: number;
}

type Props = RouteComponentProps<SessionRouteInfo & MaterialRouteInfo> &
  WithNamespaces & {
    selectedSession: TrainingSessionWithMaterials;
    course: Course;
    sessionMaterial: TrainingSessionMaterial | undefined;
    sessionMaterialLoadingStatus: ProgressStatus;
    saveSessionMaterial: (sessionMaterial: TrainingSessionMaterial, session: TrainingSessionWithMaterials) => void;
    loadSessionMaterial: (sessionMaterialId: number) => void;
  };

class DetailShell extends React.Component<Props> {
  componentDidMount() {
    const { match, loadSessionMaterial } = this.props;
    if (match.params.sessionMaterialId) {
      loadSessionMaterial(match.params.sessionMaterialId);
    }
  }

  renderIdRoutes() {
    const { course, match, sessionMaterialLoadingStatus, sessionMaterial, saveSessionMaterial, selectedSession, history } = this.props;

    if (sessionMaterialLoadingStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    if (!sessionMaterial) {
      return <NoDataFound />;
    }

    return (
      <Route
        path={`${match.path}/edit`}
        render={() => (
          <DetailCreate
            {...this.props}
            course={course}
            sessionMaterial={sessionMaterial}
            onSave={sessionMaterial => saveSessionMaterial(sessionMaterial, selectedSession)}
            goBack={() => history.push(match.url.replace(/\/edit$/, '').replace(/\/\d+$/, ''))}
          />
        )}
      />
    );
  }

  render() {
    const { match, course, selectedSession, saveSessionMaterial, history } = this.props;

    return (
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          render={() => {
            if (match.params.sessionMaterialId) {
              return <Redirect to={`${match.url}/edit`} />;
            }

            return (
              <DetailCreate
                {...this.props}
                course={course}
                sessionMaterial={{
                  ...newEntity,
                  trainingSession: selectedSession!['@id']
                }}
                onSave={newSessionMaterial => saveSessionMaterial(newSessionMaterial, selectedSession)}
                goBack={() => history.push(match.url.replace(/\/create$/, ''))}
              />
            );
          }}
        />

        {this.renderIdRoutes()}
      </Switch>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  selectedSession: state.trainings.selectedSession as TrainingSessionWithMaterials,
  course: state.trainings.selectedTrainingCourse as Course,
  sessionMaterialLoadingStatus: state.trainings.loadingSessionMaterialStatus,
  sessionMaterial: state.trainings.selectedSessionMaterial as TrainingSessionMaterial
});

const mapDispatchToprops = (dispatch: Dispatch<TrainingsAction>) => ({
  saveSessionMaterial: (sessionMaterial: TrainingSessionMaterial, selectedSession: TrainingSessionWithMaterials) =>
    dispatch(actions.SaveTrainingSessionMaterial.request({ sessionMaterial: sessionMaterial, session: selectedSession })),
  loadSessionMaterial: (sessionMaterialId: number) => dispatch(actions.FetchOneTrainingSessionMaterial.request(sessionMaterialId))
});

export default connect(
  mapStateToProps,
  mapDispatchToprops
)(translate(['trainings', 'common', 'menu', 'resources'], { nsMode: 'fallback' })(DetailShell));
