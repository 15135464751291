import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from '../core/state';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from '../core/state/rootSaga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

const configureStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [thunk, sagaMiddleware, routerMiddleware(history)];

  middleware.push(require('redux-immutable-state-invariant').default());
  // always push redux middle to production per medium articles regarding security
  const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  });

  const store = createStore(reducers(history), initialState, composeEnhancers(applyMiddleware(...middleware)));
  sagaMiddleware.run(rootSaga);
  return store;
};

export default () => configureStore({});
