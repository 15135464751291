import SiteSettings from '../../shared/interfaces/SiteSettings';

const siteAccess = {
  CAN_ACCESS_TRAINERS_CRUD: false,
  CAN_ACCESS_COURSE_CATEGORIES_CRUD: false,
  CAN_ACCESS_COURSE_CRUD: false,
  CAN_ACCESS_CLIENT_CRUD: false,
  CAN_ACCESS_TRAINER_CONTRACTS: false
} as SiteSettings;

const envSettingsDevelopment = {
  REACT_APP_AUTH0_DOMAIN: 'opleidingsplatform-dev.eu.auth0.com',
  REACT_APP_AUTH0_CLIENT_ID: 'Y5E6lw0T0HZc3h3Os810lfC0eGAWAp8D',
  REACT_APP_AUTH0_CALLBACK_URL: 'http://localhost:3000/callback',
  REACT_APP_AUTH0_AUDIENCE: 'https://backend-daadfeb151cf.victhorious.com/',
  REACT_APP_API_URL: 'https://backend-daadfeb151cf.victhorious.com/',
  REACT_APP_AUTH0_LOGOUT_URL: 'http://localhost:3000',
  ...siteAccess
};

const envSettingsLocalDevelopment = {
  REACT_APP_AUTH0_DOMAIN: 'opleidingsplatform-dev.eu.auth0.com',
  REACT_APP_AUTH0_CLIENT_ID: 'Y5E6lw0T0HZc3h3Os810lfC0eGAWAp8D',
  REACT_APP_AUTH0_CALLBACK_URL: 'http://localhost:3000/callback',
  REACT_APP_AUTH0_AUDIENCE: 'https://backend-daadfeb151cf.victhorious.com/',
  REACT_APP_API_URL: 'https://opleidingsplatform.wip/',
  REACT_APP_AUTH0_LOGOUT_URL: 'http://localhost:3000',
  ...siteAccess
};

const envSettingsLocalProduction = {
  REACT_APP_AUTH0_DOMAIN: 'opleidingsplatform-dev.eu.auth0.com',
  REACT_APP_AUTH0_CLIENT_ID: 'Y5E6lw0T0HZc3h3Os810lfC0eGAWAp8D',
  REACT_APP_AUTH0_CALLBACK_URL: 'http://localhost:3000/callback',
  REACT_APP_AUTH0_AUDIENCE: 'https://backend-daadfeb151cf.victhorious.com/',
  REACT_APP_API_URL: 'https://backend-daadfeb151cf.victhorious.com/',
  REACT_APP_AUTH0_LOGOUT_URL: 'http://localhost:3000',
  ...siteAccess
};

const envSettingsStaging = {
  REACT_APP_AUTH0_DOMAIN: 'opleidingsplatform-dev.eu.auth0.com',
  REACT_APP_AUTH0_CLIENT_ID: 'Y5E6lw0T0HZc3h3Os810lfC0eGAWAp8D',
  REACT_APP_AUTH0_CALLBACK_URL: 'https://lester.app/callback',
  REACT_APP_AUTH0_AUDIENCE: 'https://backend-daadfeb151cf.victhorious.com/',
  REACT_APP_API_URL: 'https://backend-daadfeb151cf.victhorious.com/',
  REACT_APP_AUTH0_LOGOUT_URL: 'https://lester.app',
  ...siteAccess
};

const envSettingsProduction = {
  REACT_APP_AUTH0_DOMAIN: 'opleidingsplatform-dev.eu.auth0.com',
  REACT_APP_AUTH0_CLIENT_ID: 'Y5E6lw0T0HZc3h3Os810lfC0eGAWAp8D',
  REACT_APP_AUTH0_CALLBACK_URL: 'https://lester.thehumanlink.be/callback',
  REACT_APP_AUTH0_AUDIENCE: 'https://api-lester.thehumanlink.be',
  REACT_APP_API_URL: 'https://api-lester.thehumanlink.be/',
  REACT_APP_AUTH0_LOGOUT_URL: 'https://lester.thehumanlink.be',
  ...siteAccess
};

export const getEnvSettings = (environment = '') => {
  switch (environment) {
    case 'production':
      return { ...envSettingsProduction, name: environment };
    case 'staging':
      return { ...envSettingsStaging, name: environment };
    case 'local':
      return { ...envSettingsLocalDevelopment, name: environment };
    case 'local-prod':
      return { ...envSettingsLocalProduction, name: environment };
    case 'development':
    default:
      return { ...envSettingsDevelopment, name: environment };
  }
};
