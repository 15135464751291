import { createAction, createAsyncAction } from 'typesafe-actions';
import { CourseLocation, WithClient } from '../../shared/interfaces/CourseLocation';
import { GQLSessionLocations } from '../../shared/interfaces/GQLSessionLocations';
import QueryParams from '../../shared/interfaces/QueryParams';
import CalendarListItem from '../../shared/interfaces/CalendarLocation';
import TrainingSession, { WithTraining } from '../../shared/interfaces/TrainingSession';

export enum LocationsActionTypes {
  FetchLocations = '[Locations] Fetch Locations...',
  FetchLocationsSuccess = '[Locations] Fetch Locations Success',
  FetchLocationsFail = '[Locations] Fetch Locations Fail',
  FetchOneLocation = '[Locations] Fetch One Location...',
  FetchOneLocationSuccess = '[Locations] Fetch One Location Success',
  FetchOneLocationFail = '[Locations] Fetch One Location Fail',
  DeleteLocation = '[Locations] Deleting Location...',
  DeleteLocationSuccess = '[Locations] Deleting Location Success',
  DeleteLocationFail = '[Locations] Deleting Location Fail',
  SaveLocation = '[Locations] Saving Location...',
  SaveLocationSuccess = '[Locations] Saving Location Success',
  SaveLocationFail = '[Locations] Saving Location Fail',
  ClearLocations = '[Locations] Clear Locations',
  FetchTrainingSessionLocations = '[Locations] Fetch Training Session Locations',
  FetchTrainingSessionLocationsSuccess = '[Locations] Fetch Training Session Locations Success',
  FetchTrainingSessionLocationsFail = '[Locations] Fetch Training Session Locations Fail',
  FetchCalendarListLocations = '[Locations] Fetch Calendar Locations...',
  FetchCalendarListLocationsSuccess = '[Locations] Fetch Calendar Locations Success',
  FetchCalendarListLocationsFail = '[Locations] Fetch Calendar Locations Fail'
}

export const FetchLocations = createAsyncAction(
  LocationsActionTypes.FetchLocations,
  LocationsActionTypes.FetchLocationsSuccess,
  LocationsActionTypes.FetchLocationsFail
)<QueryParams, { locations: CourseLocation[]; totalLocations: number }, { errorMessage: string }>();

export const FetchOneLocation = createAsyncAction(
  LocationsActionTypes.FetchOneLocation,
  LocationsActionTypes.FetchOneLocationSuccess,
  LocationsActionTypes.FetchOneLocationFail
)<string, { location: CourseLocation<WithClient> }, { errorMessage: string }>();

export const SaveLocation = createAsyncAction(
  LocationsActionTypes.SaveLocation,
  LocationsActionTypes.SaveLocationSuccess,
  LocationsActionTypes.SaveLocationFail
)<
  {
    location: CourseLocation;
    clientId?: string;
    returnUrl?: string;
    activeIndex?: string;
    noredirect?: boolean;
  },
  { location: CourseLocation },
  { errorMessage: string }
>();

export const FetchTrainingSessionLocations = createAsyncAction(
  LocationsActionTypes.FetchTrainingSessionLocations,
  LocationsActionTypes.FetchTrainingSessionLocationsSuccess,
  LocationsActionTypes.FetchCalendarListLocationsFail
)<QueryParams, { sessionLocations: GQLSessionLocations }, { errorMessage: string }>();

export const FetchCalendarListLocations = createAsyncAction(
  LocationsActionTypes.FetchCalendarListLocations,
  LocationsActionTypes.FetchCalendarListLocationsSuccess,
  LocationsActionTypes.FetchCalendarListLocationsFail
)<{}, { calendarLocationList: CalendarListItem[] }, { errorMessage: string }>();

export const ClearLocations = createAction(LocationsActionTypes.ClearLocations);

export const FetchSessionsForLocation = createAsyncAction(
  '[Locations] Fetch Sessions Location...',
  '[Locations] Fetch Sessions Location Success',
  '[Locations] Fetch Sessions Location Failure'
)<{ id: number }, { sessions: TrainingSession<WithTraining>[] }, { errorMessage: string }>();

export const DeleteLocation = createAsyncAction(
  '[locations] Delete location...',
  '[locations] Delete location success',
  '[locations] Delete location failure'
)<{ locationId: number; queryParams: QueryParams }, void, { errorMessage: string }>();
