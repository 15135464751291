import React from 'react';
import './DetailView.scss';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { RouteComponentProps, Link } from 'react-router-dom';
import { CourseLocation, WithClient } from '../../../shared/interfaces/CourseLocation';
import { TrainingHistory } from './TrainingHistory';
import { GlobalState } from '../../../core/state';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../state/actions';
import TrainingSession, { WithTraining } from '../../../shared/interfaces/TrainingSession';
import applyArchiveTagCheck from '../../../shared/helpers/archivedFormatter';
import { DisplayBox } from '../../../shared/components/DisplayBox';
import { DisplayGroup } from '../../../shared/components/DisplayGroup';

interface DetailViewProps {
  selectedLocation: CourseLocation<WithClient>;
  onEditBtnClick: () => void;
  sessions: TrainingSession<WithTraining>[];
  loadSessionsForLocation: (id: number) => void;
}

interface State {
  activeIndex: number;
}

class DetailView extends React.Component<DetailViewProps & WithNamespaces & RouteComponentProps, State> {
  state: State = { activeIndex: 0 };

  componentDidMount() {
    const { loadSessionsForLocation, selectedLocation } = this.props;
    loadSessionsForLocation(selectedLocation.id);
  }

  handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { selectedLocation, t, sessions } = this.props;

    const renderYesNo = (value?: boolean) => (value ? t('yes') : t('no'));

    const renderGeneral = (
      <div className="row contact">
        <div className="col-6">
          <DisplayBox title={t('Address')}>
            <p>
              {selectedLocation && selectedLocation.street + ' ' + selectedLocation.houseNr}{' '}
              {selectedLocation.bus ? `bus ${selectedLocation.bus}` : ''}
              <br />
              {selectedLocation && selectedLocation.city + ' ' + selectedLocation.postalCode}
              <br />
              {selectedLocation && selectedLocation.country ? t(selectedLocation.country) : '-'}
            </p>
          </DisplayBox>
        </div>
        <div className="col-6">
          <DisplayBox title={t('contactPerson')}>
            <p>
              {selectedLocation && selectedLocation.contactPersonFirstName ? selectedLocation.contactPersonFirstName : null}&nbsp;
              {selectedLocation && selectedLocation.contactPersonLastName ? selectedLocation.contactPersonLastName : null}
              <br />
              {selectedLocation && selectedLocation.contactPersonEmail ? selectedLocation.contactPersonEmail : null}
              <br />
              {selectedLocation && selectedLocation.contactPersonPhoneNr ? selectedLocation.contactPersonPhoneNr : null}
            </p>
          </DisplayBox>
        </div>
        <div className="col-12">
          <DisplayBox>
            <div className="row">
              <div className="col-6">
                <div className="display-group">
                  <label>{t('customer')}</label>
                  <br />
                  {selectedLocation && selectedLocation.customer ? (
                    <Link to={`/clients/${selectedLocation.customer.id}`}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: applyArchiveTagCheck(
                            selectedLocation.customer.companyName,
                            selectedLocation.customer.deletedAt,
                            true,
                            true
                          )
                        }}></span>
                    </Link>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <div className="col-6">
                <DisplayGroup label={t('displayColor')} value={selectedLocation.displayColor} isColor />
              </div>
            </div>
          </DisplayBox>
        </div>
        <div className="col-12 col-md-6">
          <DisplayGroup label={t('travelDirections')} value={selectedLocation.travelDirections} />
        </div>
        <div className="col-12 col-md-6">
          <DisplayGroup label={t('rate')} value={selectedLocation.rate} />
        </div>
        <div className="col-12">
          <DisplayGroup label={t('remarks')} value={selectedLocation.remarks} />
        </div>
        <div className="col-6 gutter-bottom">
          <DisplayGroup label={t('isInternalLocation')} value={renderYesNo(selectedLocation.isInternalLocation)} />
        </div>
        <div className="col-6 gutter-bottom">
          <DisplayGroup label={t('hasBeamer')} value={renderYesNo(selectedLocation.hasBeamer)} />
        </div>
        <div className="col-6 gutter-bottom">
          <DisplayGroup label={t('hasInternet')} value={renderYesNo(selectedLocation.hasInternet)} />
        </div>
        <div className="col-6 gutter-bottom">
          <DisplayGroup label={t('hasDrinks')} value={renderYesNo(selectedLocation.hasDrinks)} />
        </div>
      </div>
    );

    return (
      <div className="trainer-detail">
        <div className="row">
          <div className="col-12 col-md-12 col-xl-6">{renderGeneral}</div>
          <div className="col-12 col-xl-6">
            <TrainingHistory {...this.props} sessions={sessions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    sessions: state.locations.sessions as TrainingSession<WithTraining>[]
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadSessionsForLocation: (id: number) => dispatch(actions.FetchSessionsForLocation.request({ id }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withNamespaces(['locations', 'resources', 'common'], {
    nsMode: 'fallback'
  })(DetailView)
);
