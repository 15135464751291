import React, { SyntheticEvent } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import TextInput from './TextInput';

interface UriProps {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: SyntheticEvent) => void;
  value: any;
  label: string;
  keyField: string;
}

const Uri = ({ onBlur, onChange, value, label, keyField }: UriProps) => {
  return (
    <Input iconPosition="left" type="text">
      <Icon name="globe" />
      <TextInput label={label} type="text" keyField={keyField} onChange={onChange} onBlur={onBlur} value={value} />
    </Input>
  );
};

export default Uri;
