import React, { Fragment } from 'react';
import { Form } from 'semantic-ui-react';
import TextInput from './TextInputs/TextInput';
import Currency from './TextInputs/Currency';
import MultiLineTextInput from './TextInputs/MultiLineTextInput';
import Grading from './TextInputs/Grading';
import Email from './TextInputs/Email';
import Telephone from './TextInputs/Telephone';
import Uri from './TextInputs/Uri';
import DropDownList from './TextInputs/DropDownList';
import Hidden from './TextInputs/Hidden';
import ToggleBox from './TextInputs/ToggleBox';
import { SingleEntitySelector, MultipleEntitySelector } from './EntitySelector';
import Entity from '../../interfaces/Entity';
import NumberInput from '../NumberInputs/NumberInput';
import ColorInput from '../ColorInputs/ColorInput';
import DatePicker from './TextInputs/DatePicker';
import moment from 'moment-timezone';
import { FormFieldDefinition } from '../../interfaces/FormFieldDefinition';
import { MultiFilePicker, SingleFilePicker } from './FilePicker';
import Label from './Label';
import EditorInput from './TextInputs/EditorInput';
import Distance from './TextInputs/Distance';
import Discount from './TextInputs/Discount';
import VatInput from './TextInputs/VatInput';

type DetailFormFieldProps = {
  onBlur: any;
  onChange: any;
  value: any;
  error?: string | undefined;
  touched?: boolean;
  selectedEntity: Entity;
  disabled?: boolean;
} & FormFieldDefinition;

export enum DetailFormFieldTypes {
  number = 'number',
  rate = 'rate',
  color = 'color',
  text = 'text',
  vat = 'vat',
  editor = 'editor',
  currency = 'currency',
  discount = 'discount',
  textbox = 'textbox',
  email = 'email',
  telephone = 'telephone',
  uri = 'uri',
  dropdownlist = 'dropdownlist',
  dropdownsearchlist = 'dropdownsearchlist',
  grading = 'grading',
  hidden = 'hidden',
  checkbox = 'checkbox',
  entitySelector = 'entitySelector',
  datePicker = 'datePicker',
  header = 'header',
  file = 'file',
  distance = 'distance'
}

const renderElement = (fieldProps: DetailFormFieldProps) => {
  const { type, label, onChange, onBlur, value, selectedEntity } = fieldProps;
  switch (fieldProps.type) {
    case DetailFormFieldTypes.hidden:
      return <Hidden value={value} />;
    case DetailFormFieldTypes.text:
      return (
        <TextInput label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} {...fieldProps} />
      );
    case DetailFormFieldTypes.vat:
      return (
        <VatInput label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} {...fieldProps} />
      );
    case DetailFormFieldTypes.rate:
    case DetailFormFieldTypes.number:
      return (
        <NumberInput label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} {...fieldProps} />
      );
    case DetailFormFieldTypes.color:
      return <ColorInput label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} type={type} />;
    case DetailFormFieldTypes.currency:
      return (
        <Currency label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} {...fieldProps} />
      );
    case DetailFormFieldTypes.discount:
      return <Discount label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} />;
    case DetailFormFieldTypes.checkbox:
      return <ToggleBox label={''} keyField={fieldProps.propertyName} onChange={onChange} value={value} />;
    case DetailFormFieldTypes.textbox:
      return (
        <MultiLineTextInput
          label={label}
          keyField={fieldProps.propertyName}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder={fieldProps.placeholder}
          {...fieldProps}
        />
      );
    case DetailFormFieldTypes.editor:
      return (
        <EditorInput
          label={label}
          keyField={fieldProps.propertyName}
          onChange={onChange}
          toolbar={fieldProps.toolbar}
          value={value}
          hasToolbar={fieldProps.hasToolbar}
          {...fieldProps}
        />
      );
    case DetailFormFieldTypes.email:
      return <Email label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} {...fieldProps} />;
    case DetailFormFieldTypes.telephone:
      return (
        <Telephone label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} {...fieldProps} />
      );
    case DetailFormFieldTypes.uri:
      return <Uri label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} />;
    case DetailFormFieldTypes.distance:
      return <Distance label={label} keyField={fieldProps.propertyName} onChange={onChange} onBlur={onBlur} value={value} />;
    case DetailFormFieldTypes.dropdownlist:
      return (
        <DropDownList
          label={label}
          keyField={fieldProps.propertyName}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          multiple={fieldProps.multiple}
          options={fieldProps.dropDownOptions}
          {...fieldProps}
        />
      );
    case DetailFormFieldTypes.dropdownsearchlist:
      return (
        <DropDownList
          label={label}
          keyField={fieldProps.propertyName}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          multiple={fieldProps.multiple}
          options={fieldProps.dropDownOptions}
          fluid
          search
        />
      );
    case DetailFormFieldTypes.grading:
      return <Grading onChange={onChange} keyField={fieldProps.propertyName} value={value} />;
    case DetailFormFieldTypes.datePicker:
      return (
        <DatePicker
          onChange={onChange}
          keyField={fieldProps.propertyName}
          value={value ? moment(value).toDate() : undefined}
          onBlur={onBlur}
          label={label}
          hasTimeField={fieldProps.hasTimeField}
        />
      );
    case DetailFormFieldTypes.entitySelector:
      if (fieldProps.multiple) {
        return (
          <MultipleEntitySelector
            keyField={fieldProps.propertyName}
            isNewVersion={fieldProps.isNewVersion}
            value={value}
            onChange={onChange}
            parentEntity={selectedEntity}
            {...fieldProps}
          />
        );
      } else {
        return (
          <SingleEntitySelector
            keyField={fieldProps.propertyName}
            isNewVersion={fieldProps.isNewVersion}
            dataTableFields={fieldProps.dataTableFields}
            dataTableSortable={fieldProps.dataTableSortable}
            filterCondition={fieldProps.filterCondition}
            value={value}
            onChange={onChange}
            parentEntity={selectedEntity}
            {...fieldProps}
          />
        );
      }
    case DetailFormFieldTypes.header:
      return <h2>{label}</h2>;
    case DetailFormFieldTypes.file:
      if (fieldProps.multiple) {
        return <MultiFilePicker value={value} />;
      } else {
        return <SingleFilePicker value={value} />;
      }
    default:
      return null;
  }
};

const DetailFormField = (formProps: DetailFormFieldProps) => {
  if (formProps.type === DetailFormFieldTypes.hidden || formProps.type === DetailFormFieldTypes.header) {
    return renderElement(formProps);
  }

  const { error, touched, required, label } = formProps;

  return (
    <Fragment>
      <Form.Field error={error && touched ? true : false} required={required}>
        <Label value={label} />
        {renderElement(formProps)}
      </Form.Field>
      {error && touched ? (
        <div className="error field">
          <Label value={error} />
        </div>
      ) : null}
    </Fragment>
  );
};

export default DetailFormField;
