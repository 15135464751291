import React, { useEffect } from 'react';
import RequestChannelLabel from '../RequestChannelLabel';
import { Button, Segment, Icon } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';
import { TrainingRequest, RateUnit } from '../../../shared/interfaces/TrainingRequest';
import { $enum } from 'ts-enum-util';
import { TrainingType, Training } from '../../../shared/interfaces/Training';
import { RequestSessionTeaser } from '../../../shared/interfaces/RequestSessionTeaser';
import Client from '../../../shared/interfaces/Client';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import Course from '../../../shared/interfaces/Course';
import TextWrapper from '../../../shared/components/TextWrapper';
import { Link } from 'react-router-dom';
import applyArchiveTagCheck from '../../../shared/helpers/archivedFormatter';

type DetailViewContact = {
  trainingRequest: TrainingRequest;
  selectedClient?: Client;
  selectedCourse?: Course;
  selectedRequest?: TrainingRequest;
  training?: Training;
  sessions?: RequestSessionTeaser[];
  isFinalized?: boolean;
  toggleWizard: Function;
} & WithNamespaces;

export const DetailViewContact = ({
  trainingRequest,
  t,
  selectedClient,
  selectedCourse,
  selectedRequest,
  training,
  sessions,
  isFinalized,
  toggleWizard
}: DetailViewContact & RouteComponentProps) => {
  const toggleWizardHandler = () => {
    toggleWizard();
  };

  useEffect(() => {}, [selectedRequest]);

  return (
    <>
      <h3>Aanvraag gegevens</h3>
      <div className="row request-basic-info-box">
        <div className="col-12 col-lg-5">
          <div className="row">
            <div className="col-6">
              <h5>{t('createdAt')}</h5>
              <p>{moment(trainingRequest.createdAt).format('DD/MM/YY')}</p>
            </div>
            <div className="col-6">
              <h5>{t('followUpDate')}</h5>
              <p>{trainingRequest.followUpDate ? moment(trainingRequest.followUpDate).format('DD/MM/YY') : '-'}</p>
            </div>
            <div className="col-6">
              <h5>{t('telephone')}</h5>
              <p>{trainingRequest.phoneNr ? trainingRequest.phoneNr : '-'}</p>
            </div>
            <div className="col-6">
              <h5>{t('company')}</h5>
              <p>{trainingRequest.companyName ? trainingRequest.companyName : '-'}</p>
            </div>
            <div className="col-6">
              <h5>{t('vat')}</h5>
              <p>{trainingRequest.vatNr ? trainingRequest.vatNr : '-'}</p>
            </div>
            <div className="col-6">
              <h5>{t('channel')}</h5>
              {trainingRequest.channel ? <RequestChannelLabel channel={trainingRequest.channel} /> : '-'}
            </div>
            <div className="col-6">
              <h5>{t('hasKmoWallet')}</h5>
              {trainingRequest.hasKmoWallet ? 'Ja' : 'Nee'}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-7">
          <h5>{t('remarks')}</h5>
          <div>
            {trainingRequest.remarks ? (
              <TextWrapper height={100}>
                <div className="raw-html" dangerouslySetInnerHTML={{ __html: trainingRequest.remarks }} />
              </TextWrapper>
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>

      <div className="col-12">
        {!isFinalized ? (
          <Button disabled={isFinalized} className="primary btn-open-wizard" onClick={toggleWizardHandler}>
            {t('request.continue-wizard')}
          </Button>
        ) : null}
      </div>
      <div className="row">
        <div className="col-12 col-lg-8 col-xl-6">
          <h5>{t('customer')}</h5>
          <p
            dangerouslySetInnerHTML={{
              __html: selectedClient ? applyArchiveTagCheck(selectedClient.companyName, selectedClient.deletedAt, true, true) : '-'
            }}></p>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <h5>{t('trainingType')}</h5>
          <p>{trainingRequest.trainingType ? t($enum(TrainingType).getKeyOrDefault(trainingRequest.trainingType)!) : '-'}</p>
        </div>
        {trainingRequest.trainingType && TrainingType.OpenKalender === trainingRequest.trainingType ? (
          <div className="col-12 col-lg-8 col-xl-6">
            <h5>{t('training')}</h5>
            <Link to={training ? '/trainings/' + training.id : ''}>
              <span
                dangerouslySetInnerHTML={{
                  __html: training && selectedCourse ? applyArchiveTagCheck(selectedCourse.title, training.deletedAt, true, true) : '-'
                }}></span>
              {training && selectedCourse ? (
                <>
                  <Icon name="user" size="small" className="ml-2" />
                  {training.participants.length}
                </>
              ) : null}
            </Link>
          </div>
        ) : (
          <div className="col-12 col-lg-8 col-xl-6">
            <h5>{t('course')}</h5>
            <p
              dangerouslySetInnerHTML={{
                __html: selectedCourse ? applyArchiveTagCheck(selectedCourse.title, selectedCourse.deletedAt, true, true) : '-'
              }}></p>
          </div>
        )}
        <div className="col-12 col-lg-8 col-xl-6">
          <h5>{t('participants')}</h5>
          <p>{trainingRequest.totalParticipants ? trainingRequest.totalParticipants : '-'}</p>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <h5>{t('price')}</h5>
          <p>
            {selectedRequest && selectedRequest.rate ? `€ ${selectedRequest.rate.toFixed(2)} ` : '-'}
            {selectedRequest && selectedRequest.rateUnit ? t($enum(RateUnit).getKeyOrDefault(selectedRequest.rateUnit)!) : ''}
          </p>
        </div>
        <div className="col-12 col-lg-8 col-xl-6">
          <h5>{t('discount')}</h5>
          <p>{selectedRequest && selectedRequest.discount ? `${selectedRequest.discount}%` : '-'}</p>
        </div>
        <div className="col-12">
          {sessions ? sessions[0] ? <h5>{t('sessions')}</h5> : null : null}
          {sessions
            ? sessions.map((session: RequestSessionTeaser) => (
                <Segment key={session.id}>
                  <b
                    dangerouslySetInnerHTML={{
                      __html: selectedCourse ? applyArchiveTagCheck(selectedCourse.title, selectedCourse.deletedAt, true, true) : '-'
                    }}></b>
                  <br />
                  {moment(session.startDate).format('DD/MM/Y H:mm') + ' - ' + moment(session.endDate).format('DD/MM/Y H:mm')}
                  <br />
                  {t('trainer')} :
                  {session.trainer ? (
                    <Link to={`/trainers/${session.trainer.id}`}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: applyArchiveTagCheck(
                            session.trainer.firstName + ' ' + session.trainer.lastName,
                            session.trainer.deletedAt,
                            true,
                            true
                          )
                        }}></span>
                    </Link>
                  ) : (
                    ' -'
                  )}
                  <br />
                  {t('location')} :
                  {session.location ? (
                    <Link to={`/locations/${session.location.id}`}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: applyArchiveTagCheck(session.location.name, session.location.deletedAt, true, true)
                        }}></span>
                    </Link>
                  ) : (
                    ' -'
                  )}
                </Segment>
              ))
            : null}
        </div>
      </div>
    </>
  );
};

export default withNamespaces(['requests', 'menu', 'common', 'resources'], {
  nsMode: 'fallback'
})(DetailViewContact);
