import { Trainer } from '../../shared/interfaces/Trainer';

export class ApiConverter {
  static mapToApi(original: any): Trainer {
    let tempObject = { ...original };
    for (const key in tempObject) {
      if (tempObject[key] && tempObject[key] === '') {
        tempObject = { ...tempObject, [key]: null };
      }
    }
    return {
      ...tempObject,
      gradation: tempObject.gradation ? parseInt(tempObject.gradation as string, 10) : 0,
      routine: tempObject.routine ? parseInt(tempObject.routine as string, 10) : 0,
      pricePerDay: String(tempObject.pricePerDay),
      pricePerKilometer: String(tempObject.pricePerKilometer),
      pricePerSession: String(tempObject.pricePerSession)
    };
  }

  static mapFromApi(original: any): Trainer {
    const tempObject = { ...original };

    return {
      ...tempObject
    };
  }
}
