import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router';
import ListShell from './components/List/ListShell';
import DetailShell from './components/Detail/DetailShell';
import DetailCreate from './components/Detail/DetailCreate';

const Routes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${match.path}/create`} component={DetailCreate} key={`${match.path}/create`} />
      <Route path={`${match.path}/:id`} component={DetailShell} key={`${match.path}/:id`} />
      <Route component={ListShell} key={`${match.path}`} />
    </Switch>
  );
};

export default Routes;
