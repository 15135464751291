import React from 'react';
import { TrainingSessionWithMaterials } from '../../../../state/state';
import { WithNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import DataTable from '../../../../../shared/components/DataTable/DataTable';
import PageHeader from '../../../../../shared/components/PageHeader';
import { SessionRouteInfo } from '../Sessions/Shell';
import Course from '../../../../../shared/interfaces/Course';
import { Button, Icon } from 'semantic-ui-react';
import applyArchiveTagCheck from '../../../../../shared/helpers/archivedFormatter';

type Props = WithNamespaces &
  RouteComponentProps<SessionRouteInfo> & {
    course: Course;
    materials: TrainingSessionWithMaterials['trainingSessionMaterials'];
    deleteSessionMaterial: (sessionMaterialId: number) => void;
  };

export class SessionMaterialsList extends React.Component<Props> {
  render() {
    const { materials, t, match, course, history } = this.props;
    return (
      <>
        <PageHeader
          {...this.props}
          title={t('sessionMaterials')}
          breadCrumbParts={[t('trainings'), course.title, t('sessions'), match.params.sessionId, t('materials')]}
        />
        <button className="back-button back-button-session-materials" onClick={() => history.push(`/trainings/${match.params.trainingId}`)}>
          <Icon name="arrow left" />
        </button>
        <Button positive className="primary" content="Materiaal toevoegen" onClick={() => history.push(match.url + '/create')} />
        <DataTable
          OnHeaderClick={() => {}}
          fieldSet={[
            { header: t('material'), key: 'material' },
            { header: t('rate'), key: 'rate' },
            { header: t('transportType'), key: 'transportType' }
          ]}
          withActionButtons={true}
          rows={materials.map(sessionMaterial => ({
            id: sessionMaterial.id,
            material: (
              <a target="_blank" rel="noopener noreferrer" href={`/materials/${sessionMaterial.material.id}/edit`}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: applyArchiveTagCheck(sessionMaterial.material.name, sessionMaterial.material.deletedAt, true, true)
                  }}></span>
              </a>
            ),
            rate: `€ ${sessionMaterial.rate}`,
            transportType: t(sessionMaterial.transportType)
          }))}
          activeSortHeader={undefined}
          onSelect={id => history.push(`${match.url}/${id}`)}
          onArchive={id => this.props.deleteSessionMaterial(id)}
          enabledSortingHeaders={[]}
        />
      </>
    );
  }
}
