import { DeepReadonly } from 'utility-types';
import { TrainingRequest, WithTraining } from '../../shared/interfaces/TrainingRequest';
import { Training, WithTrainingSessions, WithCourse } from '../../shared/interfaces/Training';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import * as actions from './actions';
import { ActionType, getType } from 'typesafe-actions';

export type DashboardAction = ActionType<typeof actions>;
export type DashboardRequest = TrainingRequest<WithCourse & WithTraining<WithCourse>>;

export type DashboardState = DeepReadonly<{
  dashboardLoadingStatus: ProgressStatus;
  latestNewRequests: DashboardRequest[] | undefined;
  latestWonRequests: DashboardRequest[] | undefined;
  latestLostRequests: DashboardRequest[] | undefined;
  upcomingTrainings: Training<WithCourse & WithTrainingSessions>[] | undefined;
  errorMessage: string | undefined;
}>;

const initialState: DashboardState = {
  dashboardLoadingStatus: ProgressStatus.Uninitialized,
  latestNewRequests: undefined,
  latestLostRequests: undefined,
  latestWonRequests: undefined,
  upcomingTrainings: undefined,
  errorMessage: undefined
};

export function dashboard(state: DashboardState = initialState, action: DashboardAction): DashboardState {
  switch (action.type) {
    case getType(actions.LoadDashboard.request):
      return { ...state, dashboardLoadingStatus: ProgressStatus.InProgress };
    case getType(actions.LoadDashboard.success):
      return {
        ...state,
        dashboardLoadingStatus: ProgressStatus.Done,
        ...action.payload
      };
    case getType(actions.LoadDashboard.failure):
      return {
        ...state,
        dashboardLoadingStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
}
