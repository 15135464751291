import React from 'react';
import { Table } from 'semantic-ui-react';
import { GQLTraining } from '../../../state/state';
import ProgressStatus from '../../../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../../../shared/components/InitialLoader';
import { Link } from 'react-router-dom';
import getTotalPeriod from '../../../../trainings/helpers/getTotalPeriod';
import { WithNamespaces } from 'react-i18next';
import DetailGroup from '../../../../shared/components/DetailGroup/DetailGroup';
import { $enum } from 'ts-enum-util';
import { TrainingType } from '../../../../shared/interfaces/Training';
import applyArchiveTagCheck from '../../../../shared/helpers/archivedFormatter';

interface TrainingsSectionProps {
  trainings: GQLTraining[] | undefined;
  loadingTrainingsStatus: ProgressStatus;
}

export const TrainingsSection = ({ trainings, t, loadingTrainingsStatus }: TrainingsSectionProps & WithNamespaces) => {
  if (loadingTrainingsStatus === ProgressStatus.InProgress) {
    return <InitialLoader />;
  }
  return (
    <DetailGroup title={t('trainings-history')}>
      {trainings && trainings.length > 0 ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('training')}</Table.HeaderCell>
              <Table.HeaderCell>{t('trainingType')}</Table.HeaderCell>
              <Table.HeaderCell>{t('startDate')}</Table.HeaderCell>
              <Table.HeaderCell>{t('endDate')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {trainings.reverse().map((training, key) => {
              const period = getTotalPeriod(training.trainingSessions.edges.map(x => x.node));
              return (
                <Table.Row key={key}>
                  <Table.Cell>
                    <Link to={training.id}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: applyArchiveTagCheck(training.course.title, training.deletedAt, true, true)
                        }}></span>
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{training.type ? t($enum(TrainingType).getKeyOrDefault(training.type)!) : '-'}</Table.Cell>
                  <Table.Cell>{period ? period.start.format('DD/MM/YYYY') : '-'}</Table.Cell>
                  <Table.Cell>{period ? period.end.format('DD/MM/YYYY') : '-'}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        t('no-training-history')
      )}
    </DetailGroup>
  );
};
