import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router';
import CalendarShell from './components/Calendar/CalendarShell';
import DetailCreate from './components/Detail/DetailCreate';
import DetailShell from './components/Detail/DetailShell';

const Routes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${match.path}/create`} component={DetailCreate} key={`${match.path}/create`} />
      <Route path={`${match.path}/:id`} component={DetailShell} key={`${match.path}/:id`} />
      <Route component={CalendarShell} key={`${match.path}`} />
    </Switch>
  );
};
export default Routes;
