import React from 'react';
import DetailForm from '../../../../../shared/components/DetailForm/DetailForm';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../../../shared/components/PageHeader';
import { Training } from '../../../../../shared/interfaces/Training';
import { translate, WithNamespaces } from 'react-i18next';
import Course from '../../../../../shared/interfaces/Course';
import saveContact from '../../../../../clients/state/api/saveContact';
import Contact from '../../../../../shared/interfaces/Contact';
import { contactFormDefinition } from '../../../../../clients/helpers/DetailFormFields';

type Props = {
  contact: Contact;
  course: Course;
  training: Training;
} & RouteComponentProps &
  WithNamespaces;

interface State {
  contact: Contact | undefined;
  course: Course;
  training: Training;
}

class EditForm extends React.Component<Props, State> {
  state: State = {
    contact: this.props.contact,
    course: this.props.course,
    training: this.props.training
  };

  render() {
    const { contact, course, training, t, history} = this.props;
    return (
      <>
        <PageHeader
          {...this.props}
          title={`${t('contact')} ${t('edit')}`}
          breadCrumbParts={[t('trainings'), course.title]}
        />
        { this.state.contact ? (
          <DetailForm
          onFormSubmit={data => {
            saveContact(data as Contact);
          }}
          selectedEntity={contact!}
          onCancelBtnClick={() => history.push(`/trainings/${training.id}`)}
          formDefinition={contactFormDefinition}
        />
        ) : null}
        
      </>
    );
  }
}

export default translate(['menu', 'trainings', 'resources'], { nsMode: 'fallback' })(EditForm);
