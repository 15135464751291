import React, { Dispatch } from 'react';
import { RouteComponentProps } from 'react-router';
import { formDefinition, formDefinitionWithoutCustomer, formDefinitionWithType } from '../../helpers/TrainingEditFields';
import { TrainingsAction } from '../../state/state';
import { GlobalState } from '../../../core/state';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { Training, TrainingType } from '../../../shared/interfaces/Training';
import * as actions from '../../state/actions';
import { FormikValues } from 'formik';
import { TrainingRouteInfo } from './DetailShell';
import PageHeader from '../../../shared/components/PageHeader';

type Props = {
  savingTrainingStatus: ProgressStatus;
  training: Training;
  saveTraining: (training: Training) => void;
} & RouteComponentProps<TrainingRouteInfo> &
  WithNamespaces;

class DetailEdit extends React.Component<Props> {
  saveForm(values: FormikValues) {
    const { training, saveTraining } = this.props;
    const updatedTraining = { ...training, ...(values as Training) };
    saveTraining(updatedTraining);
  }

  render() {
    const { history, match, training, savingTrainingStatus, t } = this.props;

    return (
      <>
        <PageHeader title={t('edit training')} breadCrumbParts={[t('trainings'), t('edit')]} {...this.props} />
        <DetailForm
          onFormSubmit={this.saveForm.bind(this)}
          savingForm={savingTrainingStatus === ProgressStatus.InProgress}
          selectedEntity={training!}
          onCancelBtnClick={() => history.push(match.url.replace('/edit', ''))}
          formDefinition={training!.type === TrainingType.BedrijfsIntern ? formDefinitionWithType.filter(formDefinition => formDefinition.propertyName !== 'trainingPredefinedTaskList') : formDefinitionWithoutCustomer.filter(formDefinition => formDefinition.propertyName !== 'trainingPredefinedTaskList')}
        />
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    training: state.trainings.selectedTraining! as Training,
    savingTrainingStatus: state.trainings.savingTrainingStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TrainingsAction>) => ({
  saveTraining: (training: Training) => dispatch(actions.SaveTraining.request({ training: training }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['trainings', 'resources', 'menu'], { nsMode: 'fallback' })(DetailEdit));
