export type Reference = string;

export interface BaseNode {
  '@id': Reference;
  '@type': string;
  id: number;
  title: string;
  left: number;
  right: number;
  level: number;
  parent: null | Reference;
  createdAt: string;
  updatedAt: string;
  key?: string;
}

export interface FlatNode extends BaseNode {
  children: FlatNode[];
  courses: Reference[];
}

export interface TreeNode extends BaseNode {
  children: (TreeNode | Reference)[];
}

export enum treeNodeTypes {
  Course = 'Course',
  CourseCategory = 'Course-category'
}
