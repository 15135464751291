import React from 'react';
import * as Yup from 'yup';
import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormFieldPosition, FormDefinition } from '../../shared/interfaces/FormFieldDefinition';
import Entity from '../../shared/interfaces/Entity';
import CourseCategory from '../../shared/interfaces/CourseCategory';
import SearchCategoryForm from '../../shared/components/DetailForm/EntitySelector/SelectForms/SearchCategoryForm';
import { editorEmptyValidation } from '../../shared/helpers/editorEmptyValidation';

export const formDefinition: FormDefinition = [
  {
    propertyName: 'id',
    label: 'Id',
    type: DetailFormFieldTypes.hidden,
    orderNr: 1
  },
  {
    propertyName: 'code',
    label: 'Code',
    type: DetailFormFieldTypes.text,
    orderNr: 2,
    disabled: true
  },
  {
    propertyName: 'type',
    label: 'Type',
    type: DetailFormFieldTypes.text,
    orderNr: 2,
    disabled: true
  },
  {
    propertyName: 'title',
    label: 'title',
    type: DetailFormFieldTypes.text,
    orderNr: 5,
    disabled: true
  },
  {
    propertyName: 'description',
    label: 'description',
    type: DetailFormFieldTypes.editor,
    orderNr: 10,
    disabled: true
  },
  {
    propertyName: 'price',
    label: 'Prijs',
    type: DetailFormFieldTypes.currency,
    orderNr: 15,
    disabled: true
  },
  {
    propertyName: 'unit',
    label: 'Eenheid',
    type: DetailFormFieldTypes.text,
    orderNr: 20,
    disabled: true
  },
  {
    propertyName: 'active',
    label: 'active',
    type: DetailFormFieldTypes.checkbox,
    orderNr: 85,
    column: FormFieldPosition.Right
  }
];

export const SimpleFormCourseDefinition = () =>
  [
    {
      propertyName: 'id',
      label: 'Id',
      type: DetailFormFieldTypes.hidden,
      orderNr: 1
    },
    {
      propertyName: 'title',
      label: 'title',
      type: DetailFormFieldTypes.text,
      orderNr: 5,
      validationChecks: Yup.string().required('Titel moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'description',
      label: 'description',
      type: DetailFormFieldTypes.editor,
      orderNr: 10,
      validationChecks: Yup.string()
        .test('is-empty', 'Dit veld mag niet leeg zijn', editorEmptyValidation)
        .required('Omschrijving moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'categories',
      label: 'Categorieën',
      type: DetailFormFieldTypes.entitySelector,
      orderNr: 19,
      validationChecks: Yup.array().of(Yup.string()),
      multiple: true,
      renderEntity: (courseCategory: Entity) => <span key={courseCategory['@id']}>{(courseCategory as CourseCategory).title}</span>,
      searchEntityForm: SearchCategoryForm
    }
  ] as FormDefinition;
