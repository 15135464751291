import { createSelector } from 'reselect';
import { ClientsState } from './state';
import { configConstants } from '../../config/constants';

export const getClients = createSelector(
  (state: ClientsState) => state,
  state => state.clients
);

export const getAmountPages = createSelector(
  (state: ClientsState) => state,
  state => Math.ceil(state.totalClients / configConstants.pageSize)
);
