import React, { Dispatch, useEffect } from 'react';
import { baseUrl } from '../../helpers/constants';
import { formDefinition } from '../../helpers/DetailFormFields';
import { RouteComponentProps } from 'react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { GlobalState } from '../../../core/state';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import CourseCategory, { WithParent } from '../../../shared/interfaces/CourseCategory';
import { translateFormLabels } from '../../../locations/helpers/TranslationHelpers';
import * as actions from '../../state/actions';
import { CategoryAction } from '../../state/state';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import PageHeader from '../../../shared/components/PageHeader';

type Props = {
  savingCategoryStatus: ProgressStatus;
  saveCategory: (category: CourseCategory) => void;
  loadOneCategory: (id: number) => void;
  category: CourseCategory<WithParent> | undefined;
} & WithNamespaces &
  RouteComponentProps<RouteInfo>;

interface RouteInfo {
  id: string;
}

const CategoryEdit = (props: Props) => {
  useEffect(({ match, loadOneCategory } = props) => {
    loadOneCategory(parseInt(match.params.id));
  }, []);

  const { history, saveCategory, t, category } = props;

  return (
    <>
      <PageHeader
        title={` ${t('edit category')}: ${category && category.title !== undefined ? category.title : ''}`}
        breadCrumbParts={[t('courses'), t('categories'), t('edit'), `${category && category.title !== undefined ? category.title : ''}`]}
        {...props}
      />
      {category ? (
        <DetailForm
          onFormSubmit={data => saveCategory(data as CourseCategory)}
          selectedEntity={{ ...category, parent: category.parent ? category.parent['@id'] : null } as CourseCategory}
          onCancelBtnClick={() => history.push(`/${baseUrl}/categories/edit`)}
          formDefinition={translateFormLabels(formDefinition, t)}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    savingCategoryStatus: state.categories.savingCategoryStatus,
    category: state.categories.category
  };
};

const mapDispatchToProps = (dispatch: Dispatch<CategoryAction>) => ({
  saveCategory: (category: CourseCategory) => dispatch(actions.SaveCategory.request({ courseCategory: category })),
  loadOneCategory: (id: number) => dispatch(actions.FetchOneCategory.request({ id: id }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['resources', 'categories', 'menu'], { nsMode: 'fallback' })(CategoryEdit));
