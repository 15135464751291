import React from 'react';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router';
import DetailCreate from './components/Detail/DetailCreate';
import CategoryList from './components/List/CategoryList';
import CategoryEdit from './components/Detail/CategoryEdit';

const Routes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${match.path}/create`} component={DetailCreate} />
      <Route exact path={`${match.path}/edit`} component={CategoryList} />
      <Route path={`${match.path}/edit/:id`} component={CategoryEdit} />
      <Route
        path={`${match.path}`}
        render={props => {
          const parts = props.match.path.split('/');
          parts.pop();
          return <Redirect to={parts.join('/')} />;
        }}
      />
    </Switch>
  );
};

export default Routes;
