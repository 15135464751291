// src/components/PrivateRoute.js

import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from './AuthWrapper';

const PrivateRoute = ({ component: Component, ...props }: any) => {
  const { loading, isAuthenticated, loginWithRedirect, userHasScopes } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: props.path }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, props.path]);

  const render = (renderProps: any) => (isAuthenticated === true ? <Component {...renderProps} /> : null);

  const authorisedRoute = userHasScopes(props.scopes) ? (
    <Route path={props.path} render={render} {...props} />
  ) : (
    <Redirect to={{ pathname: '/unauthorized' }} />
  );

  return isAuthenticated ? authorisedRoute : null;
};

export default PrivateRoute;
