import React from 'react';
import { Switch, RouteComponentProps, Route, Redirect } from 'react-router';
import { GlobalState } from '../../../../../core/state';
import { TrainingsAction, TrainingSessionWithMaterials } from '../../../../state/state';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import DetailForm from '../../../../../shared/components/DetailForm/DetailForm';
import { newSession, baseUrl } from '../../../../helpers/constants';
import { fields } from '../../../../helpers/TrainingSessionEditFields';
import { FormikValues } from 'formik';
import TrainingSession from '../../../../../shared/interfaces/TrainingSession';
import * as actions from '../../../../state/actions';
import ProgressStatus from '../../../../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../../../../shared/components/InitialLoader';
import NoDataFound from '../../../../../shared/components/NoDataFound';
import { TrainingRouteInfo } from '../../DetailShell';
import Participations from './Participations';
import PageHeader from '../../../../../shared/components/PageHeader';
import { WithNamespaces, translate } from 'react-i18next';
import Course from '../../../../../shared/interfaces/Course';
import { SessionMaterialsList } from '../Materials/List';
import { default as MaterialDetailShell } from '../Materials/DetailShell';
import { AppAction } from '../../../../../core/state/app.state';
import { ConfirmAction } from '../../../../../core/state/actions';

export type SessionRouteInfo = {
  sessionId: string;
} & TrainingRouteInfo;

type Props = {
  selectedSession: TrainingSessionWithMaterials | undefined;
  loadingSessionStatus: ProgressStatus;
  savingSessionStatus: ProgressStatus;
  loadingTrainingStatus: ProgressStatus;
  course: Course;
  loadSession: (sessionId: number) => void;
  saveSession: (session: TrainingSession) => void;
  deleteSessionMaterial: (sessionMaterialId: number, sessionId: number) => void;
} & RouteComponentProps<SessionRouteInfo> &
  WithNamespaces;

class SessionShell extends React.Component<Props> {
  componentDidMount() {
    const { match, loadSession } = this.props;
    if (match.params.sessionId) {
      const sessionId = parseInt(match.params.sessionId);
      loadSession(sessionId);
    }
  }

  saveSession(values: FormikValues) {
    this.props.saveSession(values as TrainingSession);
  }

  updateSession(values: FormikValues) {
    const { selectedSession, saveSession } = this.props;
    const updatedSession = { ...selectedSession, ...values } as TrainingSession;
    delete updatedSession.trainingSessionMaterials;
    saveSession(updatedSession);
  }

  renderIdRoutes() {
    const { match, history, loadingSessionStatus, savingSessionStatus, selectedSession, t, deleteSessionMaterial } = this.props;

    if (loadingSessionStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    if (!selectedSession) {
      return <NoDataFound />;
    }

    return (
      <Switch>
        <Route
          exact
          path={`${match.path}/materials`}
          render={props => (
            <SessionMaterialsList
              {...this.props}
              {...props}
              materials={selectedSession.trainingSessionMaterials}
              deleteSessionMaterial={sessionMaterialId => deleteSessionMaterial(sessionMaterialId, selectedSession.id)}
            />
          )}
        />
        <Route path={`${match.path}/materials/create`} component={MaterialDetailShell} />
        <Route path={`${match.path}/materials/:sessionMaterialId(\\d+)`} component={MaterialDetailShell} />

        <Route path={`${match.path}/participants`} component={Participations} />

        <Route
          path={`${match.path}/edit`}
          render={() => (
            <>
              <PageHeader
                {...this.props}
                title={t('edit session')}
                breadCrumbParts={[t('trainings'), this.props.course.title, t('sessions'), t('edit')]}
              />
              <DetailForm
                savingForm={savingSessionStatus === ProgressStatus.InProgress}
                formDefinition={fields}
                selectedEntity={selectedSession}
                onCancelBtnClick={() => history.push(match.url.replace(/\/sessions\/\d+/, ''))}
                onFormSubmit={this.updateSession.bind(this)}
              />
            </>
          )}
        />
      </Switch>
    );
  }

  render() {
    const { match, history, savingSessionStatus, t } = this.props;
    return (
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          render={() => {
            if (match.params.sessionId) {
              return <Redirect to={`${match.url}/edit`} />;
            }

            return (
              <>
                <PageHeader
                  {...this.props}
                  title={t('new session')}
                  breadCrumbParts={[t('trainings'), this.props.course.title, t('sessions'), t('new')]}
                />
                <DetailForm
                  savingForm={savingSessionStatus === ProgressStatus.InProgress}
                  formDefinition={fields}
                  selectedEntity={
                    {
                      ...newSession,
                      training: `/${baseUrl}/` + match.params.trainingId
                    } as TrainingSession
                  }
                  onCancelBtnClick={() => history.push(match.url.replace(/\/sessions\/create/, ''))}
                  onFormSubmit={this.saveSession.bind(this)}
                />
              </>
            );
          }}
        />
        {this.renderIdRoutes()}
      </Switch>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  selectedSession: state.trainings.selectedSession as TrainingSessionWithMaterials | undefined,
  savingSessionStatus: state.trainings.savingTrainingSessionStatus,
  loadingSessionStatus: state.trainings.loadingTrainingSessionStatus,
  course: state.trainings.selectedTrainingCourse as Course
});

const mapDispatchToProps = (dispatch: Dispatch<TrainingsAction | AppAction>) => ({
  loadSession: (id: number) => dispatch(actions.FetchOneTrainingSession.request(id)),
  saveSession: (session: TrainingSession) => dispatch(actions.SaveTrainingSession.request(session)),
  deleteSessionMaterial: (sessionMaterialId: number, sessionId: number) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteSessionMaterial',
        content: 'confirmDeleteSessionMaterial',
        action: () =>
          dispatch(
            actions.DeleteTrainingSessionMaterial.request({
              sessionMaterialId,
              sessionId
            })
          )
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(['common', 'menu', 'resources', 'trainings'], { nsMode: 'fallback' })(SessionShell));
