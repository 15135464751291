import React, { Fragment } from 'react';
import { GQLTrainingClient, GQLTrainingSession } from '../../../state/state';
import { Training, TrainingType } from '../../../../shared/interfaces/Training';
import Course from '../../../../shared/interfaces/Course';
import { WithNamespaces } from 'react-i18next';
import { $enum } from 'ts-enum-util';

type Props = WithNamespaces & {
  client: GQLTrainingClient | undefined;
  sessions: GQLTrainingSession[] | undefined;
  training: Training | undefined;
  course: Course | undefined;
};

export default function(props: Props) {
  const { sessions, course, training, t } = props;
  const firstSession = sessions && sessions.length > 0 ? sessions[0] : undefined;
  const trainer = firstSession ? firstSession.trainer : undefined;

  if (!training) {
    return null;
  }

  const typeKey = $enum(TrainingType).getKeyOrDefault(training.type);

  return (
    <Fragment>
      <div className="row detail-grid">
        <div className="col-3">
          <h4>{t('training.detail.type.label')}</h4>
          <span>{typeKey ? t(typeKey) : training.type}</span>
        </div>
        <div className="col-3">
          <h4>{t('training.detail.participants.label')}</h4>
          <span>
            {training.minParticipants} - {training.maxParticipants}
          </span>
        </div>
        <div className="col-3">
          <h4>{t('lester-code')}</h4>
          <span>{training.id}</span>
        </div>
      </div>
      <br />

      {course ? (
        <div className="row detail-grid">
          <div className="col-10">
            <h4>{t('training.detail.description.label')}</h4>
            {course.description ? <div dangerouslySetInnerHTML={{ __html: course.description }} /> : '-'}
          </div>
        </div>
      ) : null}
      
      <div className="row detail-grid">
        <div className="col-10">
          <h4>{t('evaluationLink')}</h4>
          <span>{training.evaluationLink || '-'}</span>
        </div>
        <div className="col-10">
          <h4>{t('firstMeasurementLink')}</h4>
          <span>{training.firstMeasurementLink || '-'}</span>
        </div>
        <div className="col-10">
          <h4>{t('secondMeasurementLink')}</h4>
          <span>{training.secondMeasurementLink || '-'}</span>
        </div>
      </div>
    </Fragment>
  );
}
