import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router';
import TaskLists from './components/TaskLists';
import CreateList from './components/CreateList';
import EditList from './components/EditList';

const Routes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${match.path}/create`} component={CreateList} />
      <Route path={`${match.path}/:id`} component={EditList} />
      <Route path={match.path} component={TaskLists} />
    </Switch>
  );
};

export default Routes;
