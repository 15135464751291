import React from 'react';
import { DbFile } from '../../interfaces/DbFile';
import { Icon } from 'semantic-ui-react';
import { filenameFormatter } from '../../helpers/fileHelpers';
import moment from 'moment';

export default ({ files }: { files: DbFile[] }) => (
  <div className="row">
    {files.map(f => (
      <div className="col-12" key={f['@id']}>
        <a target="_blanc" href={f.accessUrl || undefined}>
          <Icon name="paperclip" />
          {filenameFormatter(f.originalFilename, 25, moment(f.createdAt).format('DD/MM/YY'))}
        </a>
      </div>
    ))}
  </div>
);
