import SearchTextForm from '../SearchTextForm';
import Client from '../../../../../interfaces/Client';
import { configConstants } from '../../../../../../config/constants';
import { fetch } from '../../../../../../core/state/fetch';

export async function searchClients(
  searchText: string,
  page?: number,
  sortField?: string,
  sortDirection?: 'asc' | 'desc',
  filterCondition?: string
) {
  const url = `${configConstants.apiUrl}customers?search_text=${searchText}&page=${page}&order[${sortField}]=${sortDirection}`;
  const response = await fetch(url).then(r => r.json());
  const entities: Client[] = response['hydra:member'] || [];
  const amount: number = response['hydra:totalItems'] || 1;
  return { entities, amount };
}

export default class SearchClientForm extends SearchTextForm<Client> {
  placeholder = 'Zoek klant';
  searchEntities = searchClients;
}
