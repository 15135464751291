import React from 'react';
import { Table } from 'semantic-ui-react';
import { TrainerTraining } from '../../../shared/interfaces/TrainerTraining';
import { RouteComponentProps } from 'react-router';
import DetailGroup from '../../../shared/components/DetailGroup/DetailGroup';
import moment from 'moment';

import './DetailView.scss';
import { $enum } from 'ts-enum-util';
import { TrainingType } from '../../../shared/interfaces/Training';
import { WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import applyArchiveTagCheck from '../../../shared/helpers/archivedFormatter';

interface DetailTrainingViewProps {
  sessions: TrainerTraining[];
}
export const DetailTrainingView = ({ sessions, history, t }: DetailTrainingViewProps & RouteComponentProps & WithNamespaces) => {
  return (
    <DetailGroup title={t('trainingshistory')}>
      {sessions ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('course')}</Table.HeaderCell>
              <Table.HeaderCell>{t('trainingType')}</Table.HeaderCell>
              <Table.HeaderCell>{t('state')}</Table.HeaderCell>
              <Table.HeaderCell>{t('startDate')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sessions.map((session, key) => (
              <Table.Row key={key}>
                <Table.Cell>
                  <Link to={`/trainings/${session.training._id}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: applyArchiveTagCheck(session.training.course.title, session.training.deletedAt, true, true)
                      }}></span>
                  </Link>
                </Table.Cell>
                <Table.Cell>{session.training.type ? t($enum(TrainingType).getKeyOrDefault(session.training.type)!) : '-'}</Table.Cell>
                <Table.Cell>{session.training.status}</Table.Cell>
                <Table.Cell>{moment(session.startDate).format('DD/MM/YYYY')}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        t('no-trainings-history')
      )}
    </DetailGroup>
  );
};
