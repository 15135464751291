import React from 'react';
import { GlobalState } from '../../../../../core/state';
import { Dispatch } from 'redux';
import { TrainingsAction, GQLTrainingParticipant, GQLParticipation } from '../../../../state/state';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { SessionRouteInfo } from './Shell';
import * as actions from '../../../../state/actions';
import ProgressStatus from '../../../../../shared/interfaces/ProgressStatus';
import { List, Button, Icon } from 'semantic-ui-react';
import InitialLoader from '../../../../../shared/components/InitialLoader';
import { Participation } from '../../../../../shared/interfaces/Participation';
import PageHeader from '../../../../../shared/components/PageHeader';
import { translate, WithNamespaces } from 'react-i18next';
import Course from '../../../../../shared/interfaces/Course';

type Props = {
  loadParticipantsStatus: ProgressStatus;
  loadParticipationsStatus: ProgressStatus;
  saveParticipationStatus: ProgressStatus;
  participants: GQLTrainingParticipant[] | undefined;
  participations: GQLParticipation[] | undefined;
  course: Course;
  loadParticipants: (trainingId: number) => void;
  loadParticipations: (sessionId: number) => void;
  saveParticipation: (participation: Participation, sessionId: number) => void;
} & RouteComponentProps<SessionRouteInfo> &
  WithNamespaces;

interface ParticipantStatus {
  participant: GQLTrainingParticipant;
  participation: GQLParticipation | undefined;
}

class Participations extends React.Component<Props> {
  get trainingId() {
    return parseInt(this.props.match.params.trainingId);
  }

  get sessionId() {
    return parseInt(this.props.match.params.sessionId);
  }

  componentDidMount() {
    const { match, loadParticipants, loadParticipations } = this.props;
    const sessionId = parseInt(match.params.sessionId);
    loadParticipants(this.trainingId);
    loadParticipations(sessionId);
  }

  toggleParticipation(status: ParticipantStatus) {
    const participation = status.participation;
    const updatedParticipation: Participation = {
      '@id': '',
      id: participation ? participation._id : 0,
      attended: participation ? !participation.attended : true,
      participant: participation ? participation.participant.id : status.participant.id,
      trainingSession: participation ? participation.trainingSession.id : `/training_sessions/${this.sessionId}`
    };

    this.props.saveParticipation(updatedParticipation, this.sessionId);
  }

  renderParticipantRow(status: ParticipantStatus) {
    const { saveParticipationStatus } = this.props;
    const contact = status.participant.contact || status.participant;
    const attended = status.participation ? status.participation.attended : false;
    return (
      <List.Item key={status.participant.id}>
        <List.Icon
          name={attended ? 'check square outline' : 'square outline'}
          size="large"
          verticalAlign="middle"
          onClick={() => this.toggleParticipation(status)}
          disabled={saveParticipationStatus === ProgressStatus.InProgress}
          style={{ cursor: 'pointer' }}
        />
        <List.Content>
          <List.Header as="span" />
          <List.Description as="span">
            {contact.firstName} {contact.lastName}
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }

  render() {
    const { participants, loadParticipantsStatus, participations, loadParticipationsStatus, history, t } = this.props;

    if (loadParticipantsStatus === ProgressStatus.InProgress || loadParticipationsStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    const participantStatuses = (participants || [])
      .filter(participant => {
        return participant.deletedAt === null;
      })
      .map(
        participant =>
          ({
            participant,
            participation: (participations || []).find(x => x.participant.id === participant.id)
          } as ParticipantStatus)
      );

    return (
      <>
        <PageHeader
          {...this.props}
          title={t('participations')}
          breadCrumbParts={[t('trainings'), this.props.course.title, t('participations')]}
        />

        <button className="back-button back-button-session-materials" onClick={() => history.push(`/trainings/${this.trainingId}`)}>
          <Icon name="arrow left" />
        </button>

        <List divided relaxed>
          {participantStatuses.length > 0 ? (
            participantStatuses.map(this.renderParticipantRow.bind(this))
          ) : (
            <List.Item>
              <List.Content>
                <List.Description as="span">{t('noParticipants')}</List.Description>
              </List.Content>
            </List.Item>
          )}
        </List>
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  loadParticipantsStatus: state.trainings.loadingParticipantStatus,
  loadParticipationsStatus: state.trainings.loadingParticipationsStatus,
  saveParticipationStatus: state.trainings.saveParticipationStatus,
  participants: state.trainings.selectedTrainingParticipants as GQLTrainingParticipant[] | undefined,
  participations: state.trainings.participations as GQLParticipation[] | undefined,
  course: state.trainings.selectedTrainingCourse as Course
});

const mapDispatchToProps = (dispatch: Dispatch<TrainingsAction>) => ({
  loadParticipants: (trainingId: number) => dispatch(actions.FetchTrainingParticipants.request(trainingId)),
  loadParticipations: (sessionId: number) => dispatch(actions.FetchParticipations.request(sessionId)),
  saveParticipation: (participation: Participation, sessionId: number) =>
    dispatch(actions.SaveParticipation.request({ participation, sessionId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(['menu', 'trainings', 'resources'], { nsMode: 'fallback' })(Participations));
