import React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { RouteComponentProps, Switch, Route } from 'react-router';
import ListView from './ListView';
import CalendarShell from '../Calendar/CalendarShell';
import { Button, Icon, Dropdown } from 'semantic-ui-react';
import isEqual from 'lodash/isEqual';
import PageHeader from '../../../shared/components/PageHeader';

class ListShell extends React.Component<RouteComponentProps & WithNamespaces> {
  state = {
    drawerOpen: false
  };

  isPositive = (path: string) => {
    return isEqual(this.props.location.pathname, this.props.match.path + path);
  };

  toggleFilter = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <PageHeader {...this.props} breadCrumbParts={[t('trainings')]} title={t('trainings')} />
        <Button.Group className="m-r-sm button-switch">
          <Button icon onClick={() => this.props.history.push(this.props.match.path)} disabled={this.isPositive('')}>
            <Icon name="list" />
          </Button>
          <Button icon onClick={() => this.props.history.push(this.props.match.path + '/calendar')} disabled={this.isPositive('/calendar')}>
            <Icon name="calendar alternate" />
          </Button>
        </Button.Group>
        <Dropdown text={t('training.list.create-training.label')} floating button className="primary">
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => this.props.history.push(this.props.match.path + '/create?type=1')}>
              {t('BedrijfsIntern')}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => this.props.history.push(this.props.match.path + '/create?type=2')}>
              {t('OpenKalender')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {this.isPositive('/calendar') ? (
          <div className="float-right">
            <Button icon onClick={this.toggleFilter}>
              <Icon name="filter" />
            </Button>
          </div>
        ) : null}
        <br /> <br />
        <Switch>
          <Route
            path={this.props.match.path + '/calendar'}
            render={props => (
              <CalendarShell {...props} drawerOpen={this.state.drawerOpen} closeDrawer={() => this.setState({ drawerOpen: false })} />
            )}
          />
          <Route path={this.props.match.path} component={ListView} />
        </Switch>
      </div>
    );
  }
}

export default withNamespaces(['trainings', 'menu', 'resources'], { nsMode: 'fallback' })(ListShell);
