import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router';
import TasksOverview from './TasksOverview';

const Routes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={match.path} component={TasksOverview} />
    </Switch>
  );
};

export default Routes;
