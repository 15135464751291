import React, { useState } from 'react';
import { Tag, Checkbox, DatePicker, Button } from 'antd';
import moment, { Moment } from 'moment';
import { CalendarOutlined } from '@ant-design/icons';

interface Props {
  executed: boolean;
  description: string;
  dueDate: Moment | null;
  onToggle: () => void;
  onDateChange: (date: Moment) => void;
}

export const Task = (props: Props) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const onChange = (date: any, dateString: string) => {
    props.onDateChange(date);
    setIsDatePickerOpen(false);
  };

  const getDueDateColor = (dueDate: Moment) => {
    const now = moment();

    // This needs to be done so we won't change the duedate with the subtract function
    const deadline = moment(dueDate.toDate());
    if (now.isAfter(dueDate)) {
      return 'danger';
    }

    if (deadline.subtract(7, 'days').isBefore(now)) {
      return 'warning';
    }

    return undefined;
  };

  return (
    <div className={props.executed ? 'task complete' : 'task'}>
      <Tag>
        <Checkbox checked={props.executed} onChange={props.onToggle}>
          {props.description}
        </Checkbox>
        <Button
          onClick={() => (props.executed ? null : setIsDatePickerOpen(!isDatePickerOpen))}
          className={props.dueDate ? getDueDateColor(props.dueDate) : ''}>
          {props.dueDate ? props.dueDate.format('DD-MM-Y') : <CalendarOutlined />}
        </Button>
      </Tag>
      <DatePicker
        onChange={onChange}
        open={isDatePickerOpen}
        defaultValue={props.dueDate ? props.dueDate : moment()}
        onOpenChange={() => setIsDatePickerOpen(false)}
      />
    </div>
  );
};
