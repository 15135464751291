import React, { Component, SyntheticEvent } from 'react';
import { GlobalState } from '../../../core/state';
import { getLocations, getSelectedLocation, getSelectedLocationId } from '../../state/selectors';
import { CourseLocation, WithClient } from '../../../shared/interfaces/CourseLocation';
import { getAmountPages } from '../../../locations/state/selectors';
import { getPathName } from '../../../core/state/selectors';
import QueryParams from '../../../shared/interfaces/QueryParams';
import * as actions from '../../state/actions';
import { Dispatch } from 'redux';
import { RouteComponentProps, match } from 'react-router';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { queryParams, updateQueryString } from '../../../shared/helpers/queryStringHelpers';
import InitialLoader from '../../../shared/components/InitialLoader';
import DataTableWrapper from '../../../shared/components/DataTable/DataTableWrapper';
import NoDataFound from '../../../shared/components/NoDataFound';
import { dataTableModel } from '../../helpers/DataTableFields';
import { ConfirmAction } from '../../../core/state/actions';
import isEqual from 'lodash/isEqual';
import { PaginationProps } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

type ListViewProps = {
  locations: CourseLocation<WithClient>[];
  selectedLocation: CourseLocation<WithClient> | null;
  selectedLocationId: number;
  clearLocations: () => void;
  loadLocations: (queryParams: QueryParams) => void;
  locationsLoaded: boolean;
  match: match;
  history: History;
  onHeaderClick: (key: string) => void;
  pages: number;
  pathName: string;
  locationStatus: ProgressStatus;
  deleteLocation: (locationId: number, queryParams: QueryParams) => void;
} & RouteComponentProps &
  WithNamespaces;

interface ListViewState {
  open: boolean;
  isCalendarView: boolean;
  locationIdToDelete: number;
}

class ListView extends Component<ListViewProps, ListViewState> {
  state: ListViewState = {
    isCalendarView: false,
    open: false,
    locationIdToDelete: -1
  };

  componentDidMount = () => {
    this.props.loadLocations(this.queryParams());
  };

  componentWillUpdate(nextProps: ListViewProps) {
    const nextQueryparams = this.queryParams(nextProps);
    const queryChanged = !isEqual(this.queryParams(), nextQueryparams);
    if (queryChanged) {
      this.props.loadLocations(nextQueryparams);
    }
  }

  queryParams(props: ListViewProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  handleCancel = () => this.setState({ open: false });

  filterResults = (value: string | number) => {
    updateQueryString(this.props, {
      searchValue: value,
      page: 1,
      sort: undefined,
      order: undefined
    });
  };

  handlePaginationChange = (e: SyntheticEvent, { activePage }: PaginationProps) => {
    updateQueryString(this.props, { page: activePage });
  };

  render() {
    const { locationStatus, locations, selectedLocationId, history, match, pages, t, deleteLocation } = this.props;
    const { page, sort } = this.queryParams();

    const locationsWithLink = locations.map(location => ({
      ...location,
      isInternalLocation: location.isInternalLocation ? t('yes') : t('no'),
      displayColor: location.displayColor ? (
        <div className="material-color-list-item" style={{ backgroundColor: location.displayColor }} />
      ) : null,
      name: <Link to={`/locations/${location.id}`}>{location.name}</Link>
    }));

    switch (locationStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        return (
          <DataTableWrapper
            {...this.props}
            onDialogCancel={this.handleCancel}
            onDialogClose={this.handleCancel}
            onDialogConfirm={() => {}}
            onPageChange={this.handlePaginationChange}
            onSubmit={this.filterResults}
            dialogOpen={this.state.open}
            defaultActivePage={page || 1}
            dialogContent={t('confirmArchiveBody')}
            dialogHeader={t('confirmArchiveHeader')}
            tableData={locationsWithLink}
            totalPages={pages}
            withActionButtons={true}
            activeId={selectedLocationId}
            activeSortHeader={sort}
            fieldSet={dataTableModel.map(header => ({
              header: t(header),
              key: header
            }))}
            onSelect={(id: number) => history.push(`${match.path}/${id}`)}
            onArchive={(locationId: number) => deleteLocation(locationId, this.queryParams())}
          />
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  }
}
const mapStateToProps = (state: GlobalState) => {
  return {
    locations: getLocations(state.locations) as CourseLocation<WithClient>[],
    selectedLocation: getSelectedLocation(state.locations) as CourseLocation<WithClient> | null,
    selectedLocationId: getSelectedLocationId(state.locations) as number,
    pages: getAmountPages(state.locations) as number,
    locationStatus: state.locations.locationStatus,
    pathName: getPathName(state.router)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadLocations: (queryParams: QueryParams) => dispatch(actions.FetchLocations.request(queryParams)),
  deleteLocation: (locationId: number, queryParams: QueryParams) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteLocation',
        content: 'confirmDeleteLocation',
        action: () => dispatch(actions.DeleteLocation.request({ locationId, queryParams }))
      })
    )
});
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('locations')(ListView));
