import React, { FunctionComponent } from 'react';
import { linkFormatter } from '../helpers/linkFormatter';

type DisplayGroupProps = {
  label?: string;
  value?: string | number;
  isRaw?: boolean;
  isCurrency?: boolean;
  isLink?: boolean;
  isColor?: boolean;
};

export const DisplayGroup: FunctionComponent<DisplayGroupProps> = ({
  label,
  value,
  isRaw = false,
  isCurrency = false,
  isLink = false,
  isColor = false,
  ...rest
}: DisplayGroupProps) => {
  let valueElement = <p>{value || '-'}</p>;

  if (isRaw && value && typeof value === 'string') {
    valueElement = <div className="raw-html" dangerouslySetInnerHTML={{ __html: value }} />;
  }

  if (isCurrency) {
    valueElement = <p>€ {value || '-'}</p>;
  }

  if (isColor && value && typeof value === 'string') {
    valueElement = <div className="material-color-list-item" style={{ backgroundColor: value || 'gray' }} />;
  }

  if (isLink && value && typeof value === 'string') {
    valueElement = (
      <a href={linkFormatter(value)} rel="noopener noreferrer" target="_blank">
        {linkFormatter(value)}
      </a>
    );
  }

  return (
    <div className="display-group" {...rest}>
      {label && <label>{label}</label>}
      {valueElement}
    </div>
  );
};
