import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';

import { Material } from '../../../shared/interfaces/Material';
import { GlobalState } from '../../../core/state';
import * as actions from '../../state/actions';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { Spin, Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import QueryParams from '../../../shared/interfaces/QueryParams';
import { ConfirmAction } from '../../../core/state/actions';

interface Props {
  fetchAllMaterials: () => void;
  fetchingMaterialsStatus: ProgressStatus;
  materials: Material[];
  deleteMaterial: (materialId: number) => void;
}

const MaterialList = (props: Props & WithNamespaces) => {
  useEffect(() => {
    props.fetchAllMaterials();
  }, []);

  const columns = [
    {
      render: (material: Material) => <div className="material-color-list-item" style={{ backgroundColor: material.displayColor }} />,
      width: '40px'
    },
    {
      title: props.t('materials.list.title.name.label'),
      width: '300px',
      render: (material: Material) => <Link to={`/materials/${material.id}/edit`}>{material.name}</Link>
    },
    {
      title: props.t('materials.list.title.rate.label'),
      render: (material: Material) => `€ ${material.rate}`
    },
    {
      title: '',
      render: (material: Material) => (
        <Button className="ui icon button float-right" onClick={() => props.deleteMaterial(material.id)}>
          <i className="archive icon"></i>
        </Button>
      )
    }
  ];

  switch (props.fetchingMaterialsStatus) {
    case ProgressStatus.InProgress:
      return (
        <div className="text-center mt-4">
          <Spin size="large" />
        </div>
      );
    case ProgressStatus.Done:
      return <Table columns={columns} dataSource={props.materials} />;
    default:
      return <div />;
  }
};

const mapStateToProps = (state: GlobalState) => {
  return {
    fetchingMaterialsStatus: state.materials.fetchingMaterialsStatus,
    materials: state.materials.materials as Material[]
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllMaterials: () => dispatch(actions.FetchAllMaterials.request()),
  deleteMaterial: (materialId: number) =>
    dispatch(
      ConfirmAction.request({
        title: 'Materiaal verwijderen',
        content: 'Ben je zeker dat je dit materiaal wil verwijderen?',
        action: () => dispatch(actions.DeleteMaterial.request({ materialId }))
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['materials', 'resources', 'menu'], { nsMode: 'fallback' })(MaterialList));
