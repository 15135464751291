import React from 'react';
import Router from './Router';
import './App.scss';
import FixedMenuLayout from './components/layout/FixedMenuLayout';
import { Auth0Provider } from './auth/AuthWrapper';
import { getEnvSettings } from './environments/environments';
import LogRocket from 'logrocket';

const envSettings = getEnvSettings(process.env.REACT_APP_ENVIRONMENT);

const onRedirectCallback = (appState: { targetUrl: string | null | undefined }) => {
  window.history.replaceState({}, document.title, appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const App = () => {
  /*
  LogRocket.init('ue9jwp/lester');
*/

  return (
    <Auth0Provider
      domain={envSettings.REACT_APP_AUTH0_DOMAIN}
      client_id={envSettings.REACT_APP_AUTH0_CLIENT_ID}
      redirect_uri={window.location.origin}
      audience={envSettings.REACT_APP_AUTH0_AUDIENCE}
      scope="openid profile role:admin role:user"
      onRedirectCallback={onRedirectCallback}>
      <FixedMenuLayout>
        <Router />
      </FixedMenuLayout>
    </Auth0Provider>
  );
};

export default App;
