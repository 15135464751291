import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GlobalState } from '../../../core/state';
import { CourseActionTypes, FetchOneCourse, SaveCourse } from '../../state/actions';
import Course from '../../../shared/interfaces/Course';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { RouteComponentProps, Switch, Route } from 'react-router';
import InitialLoader from '../../../shared/components/InitialLoader';
import { formDefinition } from '../../../courses/helpers/DetailFormFields';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import DetailView from './DetailView';
import PageHeader from '../../../shared/components/PageHeader';

type DetailShellProps = {
  loadCourse: (
    id: number | string
  ) => {
    type: CourseActionTypes.FetchOneCourse;
    payload: { courseId: number };
  };
  saveCourse: (course: Course) => void;
  selectedCourse: Course | undefined;
  loadingCourses: boolean;
};

interface RouteInfo {
  id: string;
}

class DetailShell extends Component<DetailShellProps & WithNamespaces & RouteComponentProps<RouteInfo>> {
  componentDidMount = ({ loadCourse, match } = this.props) => {
    loadCourse(match.params.id);
  };

  handleEdit = (formValues: any) => {
    this.props.saveCourse({
      ...(this.props.selectedCourse! as Course),
      ...formValues
    });
  };

  render() {
    const { selectedCourse, match } = this.props;

    if (!selectedCourse) {
      return <InitialLoader />;
    }

    return (
      <Switch>
        <Route
          exact
          path={`${match.path}/edit`}
          render={() => (
            <>
              <PageHeader
                {...this.props}
                breadCrumbParts={[this.props.t('courses'), selectedCourse.title]}
                title={
                  <span>
                    {selectedCourse.title}
                    {selectedCourse.active ? null : (
                      <>
                        &nbsp;-&nbsp;<span className="deactivated">{this.props.t('deactived')}</span>
                      </>
                    )}
                  </span>
                }
              />
              <DetailForm
                onFormSubmit={this.handleEdit}
                selectedEntity={selectedCourse as Course}
                onCancelBtnClick={() => this.props.history.push(match.url)}
                formDefinition={formDefinition}
              />
            </>
          )}
        />
        <Route render={() => <DetailView key={selectedCourse!.id} {...this.props} selectedCourse={selectedCourse!} />} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    selectedCourse: state.courses.selectedCourse as Course | undefined
  };
};

const mapDispatchToProps = (dispatch: Dispatch, getState: () => GlobalState) => ({
  loadCourse: (id: number | string) => dispatch(FetchOneCourse(id as number)),
  saveCourse: (course: Course) => dispatch(SaveCourse({ course }))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces(['courses', 'menu'], { nsMode: 'fallback' })(DetailShell));
