import { createAsyncAction } from 'typesafe-actions';
import TaskList from '../../shared/interfaces/TaskList';
import QueryParams from '../../shared/interfaces/QueryParams';
import PredefinedTask from '../../shared/interfaces/PredefinedTask';

export const FetchTaskLists = createAsyncAction(
  '[Tasks] Fetch Tasklists...',
  '[Tasks] Fetch Tasklists success',
  '[Tasks] Fetch Tasklists failure'
)<QueryParams, { taskLists: TaskList[]; totalTaskLists: number }, { errorMessage: string }>();

export const FetchTaskList = createAsyncAction(
  '[Tasks] Fetch Tasklist...',
  '[Tasks] Fetch Tasklist success',
  '[Tasks] Fetch Tasklist failure'
)<number, TaskList, { errorMessage: string }>();

export const SaveTaskList = createAsyncAction(
  '[Tasks] Saving Tasklist...',
  '[Tasks] Saving Tasklist success',
  '[Tasks] Saving Tasklist failure'
)<TaskList, void, { errorMessage: string }>();

export const SavePredefinedTask = createAsyncAction(
  '[Tasks] Saving Predefined Task...',
  '[Tasks] Saving Predefined Task success',
  '[Tasks] Saving Predefined Task failure'
)<{ taskList: TaskList; task: PredefinedTask }, void, { errorMessage: string }>();

export const DeletePredefinedTask = createAsyncAction(
  '[Tasks] Deleting Predefined Task...',
  '[Tasks] Deleting Predefined Task success',
  '[Tasks] Deleting Predefined Task failure'
)<{ taskList: TaskList; task: PredefinedTask }, void, { errorMessage: string }>();

export const DeleteTaskList = createAsyncAction(
  '[locations] Delete task list...',
  '[locations] Delete task list success',
  '[locations] Delete task list failure'
)<{ taskListId: number; queryParams: QueryParams }, void, { errorMessage: string }>();
