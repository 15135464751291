import React, { SyntheticEvent } from 'react';
import { Dropdown } from 'semantic-ui-react';

interface DropDownListProps {
  onBlur: (e: any) => void;
  onChange: (e: any) => void;
  value: any;
  label: string;
  keyField: string;
  type?: any;
  multiple?: boolean;
  fluid?: boolean;
  search?: boolean;
  selection?: boolean;
  options: { key: number; text: string; value: any }[] | undefined;
  disabled?: boolean;
}

const DropDownList = ({ onBlur, onChange, value, label, keyField, multiple, options, search, fluid, disabled }: DropDownListProps) => {
  const handleChange = (e: SyntheticEvent, { value, name }: any) => {
    onChange({
      target: {
        name: name,
        value: value
      }
    });
  };
  const handleBlur = (e: SyntheticEvent, { value, name }: any) => {
    onBlur({
      target: {
        name: name,
        value: value
      }
    });
  };
  const selectedOption = options ? options.find(option => option.value === value) : undefined;
  return (
    <Dropdown
      onBlur={handleBlur}
      onChange={handleChange}
      options={options}
      selection
      value={value}
      name={keyField}
      multiple={multiple ? true : false}
      search={search ? true : false}
      fluid={fluid ? true : false}
      text={selectedOption ? selectedOption.text : undefined}
      disabled={disabled}
    />
  );
};

export default DropDownList;
