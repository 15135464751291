import React from 'react';
import Dropzone, { DropzoneState } from 'react-dropzone';
import { Icon } from 'semantic-ui-react';
import ProgressStatus from '../../../interfaces/ProgressStatus';
import { translate, WithNamespaces } from 'react-i18next';
import { Button, Spin } from 'antd';

type FileArity = (File | undefined) | File[];

type Props<T extends FileArity> = WithNamespaces & {
  value: T;
  type?: 'zone' | 'button';
  onChange?: (value: T) => void;
  onFileDrop?: (files: File[]) => void;
  uploadStatus?: ProgressStatus;
};

abstract class BaseFilePicker<T extends FileArity> extends React.Component<Props<T>> {
  abstract get isEmpty(): boolean;
  abstract get isMultiple(): boolean;

  abstract renderValue(): JSX.Element;

  onFileDrop(acceptedFiles: File[]) {
    if (acceptedFiles.length === 0) {
      return;
    }

    if (this.props.onFileDrop) {
      this.props.onFileDrop(acceptedFiles);
    }
  }

  renderDragResult({ isDragAccept, isDragReject }: DropzoneState) {
    if (this.props.uploadStatus === ProgressStatus.InProgress) {
      return <Spin />;
    }

    if (isDragAccept) {
      return <Icon disabled name="plus square outline" size="big" />;
    }

    if (isDragReject) {
      return <Icon disabled name="close" size="big" />;
    }

    if (this.props.type === 'button') {
      return <Button className="upload-button">{this.props.t('upload')}</Button>;
    }

    return <Icon name="plus" />;
  }

  render() {
    if (this.props.type && this.props.type === 'button') {
      return (
        <Dropzone onDrop={this.onFileDrop.bind(this)} multiple={this.isMultiple}>
          {dragProps => (
            <div {...dragProps.getRootProps()} className="d-inline">
              <input {...dragProps.getInputProps()} />
              {this.renderDragResult(dragProps)}
              {this.renderValue()}
            </div>
          )}
        </Dropzone>
      );
    }

    return (
      <Dropzone onDrop={this.onFileDrop.bind(this)} multiple={this.isMultiple}>
        {dragProps => (
          <div {...dragProps.getRootProps()} className="upload-zone">
            <input {...dragProps.getInputProps()} />
            <div style={{ textAlign: 'center' }}>
              {this.renderDragResult(dragProps)}
              {this.renderValue()}
            </div>
          </div>
        )}
      </Dropzone>
    );
  }
}

class _SingleFilePicker extends BaseFilePicker<File | undefined> {
  get isMultiple() {
    return false;
  }

  get isEmpty() {
    return this.props.value === undefined;
  }

  renderValue() {
    const { value, type, t } = this.props;

    if (!value) {
      return <Icon name="plus" />;
    }

    return <div>{value!.name}</div>;
  }
}

class _MultiFilePicker extends BaseFilePicker<File[]> {
  get isMultiple() {
    return true;
  }

  get isEmpty() {
    return this.props.value.length === 0;
  }

  renderValue() {
    const { value } = this.props;

    return (
      <>
        {value.map(value => (
          <div key={value.name}>{value.name}</div>
        ))}
      </>
    );
  }
}

export const SingleFilePicker = translate(['common'])(_SingleFilePicker);
export const MultiFilePicker = translate(['common'])(_MultiFilePicker);
