import React, { Component, Fragment, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { getClients, getAmountPages } from '../../state/selectors';
import { getPathName } from '../../../core/state/selectors';
import { GlobalState } from '../../../core/state';
import InitialLoader from '../../../shared/components/InitialLoader';
import { PaginationProps, Button } from 'semantic-ui-react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import PageHeader from '../../../shared/components/PageHeader';
import DataTableWrapper from '../../../shared/components/DataTable/DataTableWrapper';
import { Dispatch } from 'redux';
import * as actions from '../../state/actions';
import Client from '../../../shared/interfaces/Client';
import { queryParams, updateQueryString } from '../../../shared/helpers/queryStringHelpers';
import DataTableAdvancedFilter from '../../../shared/components/DataTable/DataTableAdvancedFilter';
import CountryCode from '../../../shared/interfaces/CountryCode';
import { searchColumnFields } from '../../helpers/DataSearchFields';
import Language from '../../../shared/interfaces/Language';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { ConfirmAction } from '../../../core/state/actions';
import QueryParams from '../../../shared/interfaces/QueryParams';
import moment from 'moment';
import SiteSettings from '../../../shared/interfaces/SiteSettings';

type ListShellProps = {
  siteSettings: SiteSettings;
  pathName: string;
  clients: Client[];
  loadingClientsStatus: ProgressStatus;
  loadingClientStatus: ProgressStatus;
  totalPages: number;
  resources: {
    countryCodes: CountryCode[];
    languages: Language[];
  };
  loadClients: (queryParams: QueryParams) => void;
  deleteClient: (clientId: number, queryParams: QueryParams) => void;
} & WithNamespaces &
  RouteComponentProps;

class ListShell extends Component<ListShellProps> {
  queryParams(props: ListShellProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  get page() {
    return this.queryParams().page || 1;
  }

  componentDidMount = () => {
    this.props.loadClients(this.queryParams());
  };

  componentWillUpdate(nextProps: ListShellProps) {
    const nextQueryparams = this.queryParams(nextProps);
    const queryChanged = !isEqual(this.queryParams(), nextQueryparams);
    if (queryChanged) {
      this.props.loadClients(nextQueryparams);
    }
  }

  handlePaginationChange = (e: SyntheticEvent, { activePage }: PaginationProps) => {
    updateQueryString(this.props, { page: activePage });
  };

  filterResults = (value: string | number) => {
    updateQueryString(this.props, {
      searchValue: value,
      page: 1,
      sort: undefined,
      order: undefined
    });
  };

  setSearchValue = (e: SyntheticEvent) => {
    e.preventDefault();
    const { value } = e.target as HTMLInputElement;
    updateQueryString(this.props, {
      searchValue: value,
      page: 1,
      sort: undefined,
      order: undefined
    });
  };

  renderDataTable() {
    const { clients, loadingClientsStatus, t, history, match, totalPages, resources } = this.props;

    if (loadingClientsStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    const translatedClients = clients.map(client => ({
      ...client,
      companyName: <Link to={`clients/${client.id}`}>{client.companyName}</Link>,
      createdAt: moment(client.createdAt).format('DD/MM/Y'),
      lastRequestDate: client.lastRequestDate ? moment(client.lastRequestDate).format('DD/MM/Y') : '-',
      upcomingRequestFollowupDate: client.upcomingRequestFollowupDate ? moment(client.upcomingRequestFollowupDate).format('DD/MM/Y') : '-'
    }));

    return (
      <>
        {this.props.siteSettings.CAN_ACCESS_CLIENT_CRUD && (
          <Button
            className="primary m-b-lg"
            content="Klant toevoegen"
            onClick={() => this.props.history.push(this.props.match.path + '/create')}
          />
        )}

        <DataTableWrapper
          {...this.props}
          onDialogCancel={() => {}}
          onDialogClose={() => {}}
          onDialogConfirm={() => {}}
          onPageChange={this.handlePaginationChange}
          onSubmit={this.filterResults}
          dialogOpen={false}
          defaultActivePage={this.page}
          dialogContent={t('confirmArchiveBody')}
          dialogHeader={t('confirmArchiveHeader')}
          tableData={translatedClients}
          totalPages={totalPages}
          withActionButtons={true}
          activeId={0}
          activeSortHeader={this.queryParams().sort}
          fieldSet={['companyName','vatNr', 'createdAt'].map(header => ({
            header: t(header),
            key: header
          }))}
          onSelect={(id: number | string) => history.push(`${match.path}/${id}`)}
          onArchive={(clientId: number) => this.props.deleteClient(clientId, this.queryParams())}>
          <DataTableAdvancedFilter
            {...this.props}
            fieldSet={['id', 'companyName', 'vatNr', 'createdAt']}
            searchFields={searchColumnFields(resources.languages)}
          />
        </DataTableWrapper>
      </>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <PageHeader {...this.props} breadCrumbParts={[t('clients')]} title={t('clients')} />
        {this.renderDataTable()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    siteSettings: state.app.siteSettings,
    clients: getClients(state.clients) as Client[],
    loadingClientsStatus: state.clients.loadingClientsStatus,
    loadingClientStatus: state.clients.loadingClientStatus,
    pathName: getPathName(state.router),
    totalPages: getAmountPages(state.clients),
    resources: {
      countryCodes: state.app.countryCodes as CountryCode[],
      languages: state.app.languages as Language[]
    }
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadClients: (queryParams: QueryParams) => dispatch(actions.FetchClients(queryParams)),
  deleteClient: (clientId: number, queryParams: QueryParams) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteClient',
        content: 'confirmDeleteClient',
        action: () => dispatch(actions.DeleteClient.request({ clientId, queryParams }))
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['clients', 'menu', 'resources'], { nsMode: 'fallback' })(ListShell));
