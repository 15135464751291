import React, { FunctionComponent } from 'react';

type DisplayBoxProps = {
  title?: string;
  children: JSX.Element;
};

export const DisplayBox: FunctionComponent<DisplayBoxProps> = ({ title, children, ...rest }: DisplayBoxProps) => {
  return (
    <div className="display-box" {...rest}>
      {title && <div className="display-box-header">{title}</div>}
      <div className="display-box-content">{children}</div>
    </div>
  );
};
