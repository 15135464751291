import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import Language from '../../shared/interfaces/Language';
import { languagesToOptions, enumToOption } from '../../shared/helpers/dropDownHelpers';
import { FormDefinition, FormFieldPosition } from '../../shared/interfaces/FormFieldDefinition';
import { courseActiveStates } from '../../courses/helpers/constants';

export const searchColumnFields = (languages: Language[]) =>
  [
    {
      propertyName: 'course',
      label: 'course',
      type: DetailFormFieldTypes.dropdownsearchlist,
      orderNr: 30,
      column: FormFieldPosition.Left
    },
    {
      propertyName: 'active',
      label: 'active',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 40,
      column: FormFieldPosition.Right,
      dropDownOptions: enumToOption(courseActiveStates)
    }
  ] as FormDefinition;
