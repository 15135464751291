import Entity, { EntityReference } from './Entity';
import { DbFile } from './DbFile';
import { TrainingRequest } from './TrainingRequest';

export enum RequestDocumentType {
  Document = 'document'
}

interface WithRequestReference {
  trainingRequest: EntityReference;
}

interface WithRequestDocumentReference {
  trainingRequestDocument: EntityReference;
}

export interface WithRequest {
  trainingRequest: TrainingRequest;
}

export interface WithRequestDocument {
  trainingRequestDocument: DbFile;
}

export type RequestDocument<T = WithRequestReference & WithRequestDocumentReference> = T &
  Entity & {
    id?: number;
    type: RequestDocumentType;
  };
