import { createAsyncAction } from 'typesafe-actions';
import QueryParams from '../../shared/interfaces/QueryParams';
import {
  TrainingRequest,
  WithTrainingRequestSessions,
  WithCourse,
  WithTraining,
  WithTrainingSessions
} from '../../shared/interfaces/TrainingRequest';
import { RequestSessionTeaser } from '../../shared/interfaces/RequestSessionTeaser';
import { RequestDocument, WithRequestDocument } from '../../shared/interfaces/RequestDocument';
import Course from '../../shared/interfaces/Course';
import { Mail, WithTrainingRequestDocuments } from '../../shared/interfaces/Mail';
import { Training } from '../../shared/interfaces/Training';
import Client from '../../shared/interfaces/Client';

export const FetchTrainingRequests = createAsyncAction(
  '[Requests] Fetch Training Requests...',
  '[Requests] Fetch Training Requests Success',
  '[Requests] Fetch Training Requests Fail'
)<
  QueryParams,
  {
    trainingRequests: TrainingRequest<WithTrainingRequestSessions & WithCourse & WithTraining & WithTrainingSessions>[];
    totalTrainingRequests: number;
    courses: Course[];
  },
  { errorMessage: string }
>();

export const FetchOneTrainingRequest = createAsyncAction(
  '[Requests] Fetch One Training Request...',
  '[Requests] Fetch One Training Request Success',
  '[Requests] Fetch One Training Request Fail'
)<
  { id: number },
  { selectedTrainingRequest: TrainingRequest; selectedCourse?: Course; selectedClient?: Client },
  { errorMessage: string }
>();

export const FetchTrainingRequestTraining = createAsyncAction(
  '[Requests] Fetch One Training Request training...',
  '[Requests] Fetch One Training Request training Success',
  '[Requests] Fetch One Training Request training Fail'
)<{ id: number }, { selectedTrainingRequestTraining: Training }, { errorMessage: string }>();

export const SaveTrainingRequest = createAsyncAction(
  '[Requests] Saving Training Request...',
  '[Requests] Save Training Request Success',
  '[Requests] Save Training Request Fail'
)<{ trainingRequest: TrainingRequest; redirectAfterSave: boolean }, { trainingRequest: TrainingRequest }, { errorMessage: string }>();

export const DeleteTrainingRequest = createAsyncAction(
  '[Requests] Delete Training Request...',
  '[Requests] Delete Training Request Success',
  '[Requests] Delete Training Request Fail'
)<{ requestId: number; queryParams: QueryParams }, void, { errorMessage: string }>();

export const SetRequestToWon = createAsyncAction(
  '[Requests] Set Training Request to won...',
  '[Requests] Set Training Request to won Success',
  '[Requests] Set Training Request to won Fail'
)<TrainingRequest, void, { errorMessage: string }>();

export const SetRequestToLost = createAsyncAction(
  '[Requests] Set Training Request to lost...',
  '[Requests] Set Training Request to lost Success',
  '[Requests] Set Training Request to lost Fail'
)<TrainingRequest, void, { errorMessage: string }>();

export const FetchTrainingRequestDocument = createAsyncAction(
  '[Requests] Fetch Training Request Document...',
  '[Requests] Fetch Training Request Document Success',
  '[Requests] Fetch Training Request Document Fail'
)<{ id: number; type: string }, { fileUrl: string; type: 'offerDocument' | 'orderDocument' }, { errorMessage: string }>();

export const FetchTrainingRequestSessions = createAsyncAction(
  '[Requests] Fetch sessions of request...',
  '[Requests] Fetch sessions of request success',
  '[Requests] Fetch sessions of request failed'
)<{ training: any }, { requestSessions: RequestSessionTeaser[] }, { errorMessage: string }>();

export const FetchRequestFiles = createAsyncAction(
  '[Requests] Fetch request files...',
  '[Requests] Fetch request files success',
  '[Requests] Fetch request files failure'
)<number, RequestDocument<WithRequestDocument>[], { errorMessage: string }>();

export const AddFileToRequest = createAsyncAction(
  '[Requests] Add file to request ...',
  '[Requests] Add file to request success',
  '[Requests] Add file to request failure'
)<{ requestId: number; file: File }, void, { errorMessage: string }>();

export const FetchRequestMails = createAsyncAction(
  '[Requests] Fetch Request Mails...',
  '[Requests] Fetch Request Mails Success',
  '[Requests] Fetch Request Mails Fail'
)<number, Mail<WithTrainingRequestDocuments>[], { errorMessage: string }>();

export const DeleteRequestFile = createAsyncAction(
  '[Requests] Delete request file...',
  '[Requests] Delete request file success',
  '[Requests] Delete request file failure'
)<{ fileId: number; requestId: number }, void, { errorMessage: string }>();
