import React from 'react';
import { baseUrl, newClientData } from '../../helpers/constants';
import { formDefinition } from '../../helpers/DetailFormFields';
import { RouteComponentProps } from 'react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { GlobalState } from '../../../core/state';
import { SaveClient } from '../../state/actions';
import Client from '../../../shared/interfaces/Client';
import Language from '../../../shared/interfaces/Language';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import PageHeader from '../../../shared/components/PageHeader';

interface Props {
  languages: Language[];
  saveClient: (client: Client) => void;
}

class DetailCreate extends React.Component<Props & RouteComponentProps & WithNamespaces> {
  render() {
    const { history, saveClient, t } = this.props;

    return (
      <>
        <PageHeader {...this.props} breadCrumbParts={[t('clients'), t('new')]} title={t('new client')} />
        <DetailForm
          onFormSubmit={data => saveClient(data as Client)}
          // savingForm={savingForm}
          selectedEntity={newClientData}
          onCancelBtnClick={() => history.push(`/${baseUrl}`)}
          formDefinition={formDefinition(this.props.languages)}
        />
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  languages: state.app.languages as Language[]
});

const mapDispatchToProps = (dispatch: any) => ({
  saveClient: (client: Client) => dispatch(SaveClient({ client }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['resources', 'clients', 'menu'], { nsMode: 'fallback' })(DetailCreate));
