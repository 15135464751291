import React from 'react';
import { Button, Modal, Loader } from 'semantic-ui-react';
import { configConstants } from '../../../../config/constants';
import isEqual from 'lodash/isEqual';
import Entity, { EntityReference } from '../../../interfaces/Entity';
import { SelectSingleEntityModal, SelectMultipleEntityModal, EntityReferenceArity } from './SelectEntityModal';
import { EntitySelectorFieldDefinition } from '../../../interfaces/FormFieldDefinition';
import EditEntityModal from './EditEntityModal';
import { translate, WithNamespaces } from 'react-i18next';
import { fetch } from '../../../../core/state/fetch';

export type EntityArity = (Entity | undefined) | Entity[];

function getEntityFromReference(id: EntityReference) {
  return fetch(configConstants.apiUrl.replace(/\/$/, '') + id).then(x => x.json()) as Promise<Entity>;
}

enum EntityFormMode {
  Select,
  Edit,
  Add,
  NotUsed
}

type Props<T extends EntityReferenceArity> = WithNamespaces & {
  keyField: string;
  onChange: (value: any) => void;
  value: T;
  parentEntity: Entity;
} & EntitySelectorFieldDefinition;

interface State<T extends EntityArity> {
  entity: T;
  loading: boolean;
  formMode: EntityFormMode;
  selectedEntity: Entity | undefined;
}

abstract class EntitySelector<P extends EntityReferenceArity, S extends EntityArity> extends React.Component<Props<P>, State<S>> {
  abstract get isMultiple(): boolean;
  abstract renderEntitySection(): JSX.Element;
  abstract renderSelectEntityModal(): JSX.Element;
  abstract async loadEntities(reference: P): Promise<void>;
  abstract removeReference(entity: Entity): void;
  abstract addEntity(entity: Entity): void;
  abstract selectEntity(reference: P): void;
  abstract value(props: Props<P>): P;
  abstract clearSelection(): void;

  get getEntityFromReference() {
    return this.props.getEntityFromReference || getEntityFromReference;
  }

  componentDidMount() {
    this.loadEntities(this.value(this.props));
  }

  componentWillReceiveProps(nextProps: Props<P>) {
    if (!isEqual(this.value(nextProps), this.value(this.props))) {
      this.loadEntities(this.value(nextProps));
    }
  }

  setFormValue(value: EntityReferenceArity) {
    this.props.onChange({
      target: {
        name: this.props.keyField,
        value: value
      }
    });
  }

  closeModal() {
    this.setState({ formMode: EntityFormMode.NotUsed });
  }

  renderEntity(entity: Entity, index?: number) {
    return (
      <div key={entity['@id']} className="entity-reference">
        {this.props.renderEntity(entity, index)}
        <div className="entity-reference-actions">
          {this.props.edit ? (
            <Button
              icon="pencil"
              type="button"
              onClick={() =>
                this.setState({
                  formMode: EntityFormMode.Edit,
                  selectedEntity: entity
                })
              }
            />
          ) : null}
          <Button icon="trash alternate outline" type="button" onClick={() => this.removeReference(entity)} />
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loader active inline />;
    }

    const { formMode, selectedEntity } = this.state;
    const { add, edit, t } = this.props;
    const isNew = selectedEntity === undefined;

    return (
      <div className="enitiy-reference-buttons">
        {add || edit ? (
          <Modal open={formMode === EntityFormMode.Add || formMode === EntityFormMode.Edit} onClose={this.closeModal.bind(this)} closeIcon>
            <EditEntityModal
              {...this.props}
              afterAdd={this.addEntity.bind(this)}
              afterEdit={() => {
                this.closeModal();
                this.loadEntities(this.value(this.props));
              }}
              cancel={this.closeModal.bind(this)}
              selectedEntity={selectedEntity}
              saveEntity={isNew ? add!.saveEntity : edit!.saveEntity}
              title={isNew ? t('add') : t('edit')}
            />
          </Modal>
        ) : null}
        {this.props.searchEntityForm ? (
          <Modal open={formMode === EntityFormMode.Select} onClose={this.closeModal.bind(this)} closeIcon>
            {this.renderSelectEntityModal()}
          </Modal>
        ) : null}

        {this.renderEntitySection()}
        <br />
        {this.isMultiple ? (
          <Button type="button" onClick={() => this.clearSelection()}>
            {this.props.t('clear')}
          </Button>
        ) : null}

        {this.props.searchEntityForm ? (
          <Button type="button" onClick={() => this.setState({ formMode: EntityFormMode.Select })}>
            {this.props.t('select')}
          </Button>
        ) : null}
        {this.props.add ? (
          <Button type="button" onClick={() => this.setState({ formMode: EntityFormMode.Add, selectedEntity: undefined })}>
            {this.props.t('add')}
          </Button>
        ) : null}
      </div>
    );
  }
}

class _MultipleEntitySelector extends EntitySelector<EntityReference[], Entity[]> {
  state: State<Entity[]> = {
    formMode: EntityFormMode.NotUsed,
    entity: [],
    loading: false,
    selectedEntity: undefined
  };

  get isMultiple() {
    return true;
  }

  value(props: Props<EntityReference[]> = this.props) {
    return props.value || [];
  }

  renderEntitySection() {
    if (this.state.entity.length === 0) {
      return <div>{this.props.t('noEntitySelected')}</div>;
    }

    return <React.Fragment>{this.state.entity.map((entity, i) => this.renderEntity(entity, i))}</React.Fragment>;
  }

  renderSelectEntityModal() {
    return (
      <SelectMultipleEntityModal
        parentEntity={this.props.parentEntity}
        renderReference={this.props.renderEntity}
        afterSelect={this.selectEntity.bind(this)}
        cancel={this.closeModal.bind(this)}
        searchEntityForm={this.props.searchEntityForm!}
      />
    );
  }

  async loadEntities(references: EntityReference[]) {
    this.setState({ loading: true });
    const entities = await Promise.all(references.map(this.getEntityFromReference));
    this.setState({ entity: entities, loading: false });
  }

  removeReference(entity: Entity) {
    const { add } = this.props;
    const deleteCall = add && add.deleteEntity ? add.deleteEntity : null;
    const newReferences = this.value().filter((x: EntityReference) => x !== entity['@id']);
    this.setState(
      {
        entity: this.state.entity.filter(e => e['@id'] !== entity['@id'])
      },
      () => {
        this.setFormValue(newReferences);
        if (deleteCall) {
          deleteCall(entity);
        }
      }
    );
  }

  addEntity(entity: Entity) {
    this.setFormValue([...this.value(), entity['@id']]);
    this.closeModal();
  }

  selectEntity(reference: EntityReference[]) {
    const newReferenceList = new Set([...this.value(), ...reference]);
    this.setFormValue(Array.from(newReferenceList));
    this.closeModal();
  }

  clearSelection() {
    this.setFormValue([]);
  }
}

class _SingleEntitySelector extends EntitySelector<EntityReference | undefined, Entity | undefined> {
  state: State<Entity | undefined> = {
    entity: undefined,
    loading: false,
    formMode: EntityFormMode.NotUsed,
    selectedEntity: undefined
  };

  get isMultiple() {
    return false;
  }

  value(props: Props<EntityReference | undefined> = this.props) {
    return props.value;
  }

  renderEntitySection() {
    const entity = this.state.entity;

    if (!entity) {
      return <span>{this.props.t('noEntitySelected')}</span>;
    }

    return this.renderEntity(entity);
  }

  renderSelectEntityModal() {
    return (
      <SelectSingleEntityModal
        parentEntity={this.props.parentEntity}
        renderReference={this.props.renderEntity}
        afterSelect={this.selectEntity.bind(this)}
        cancel={this.closeModal.bind(this)}
        searchEntityForm={this.props.searchEntityForm!}
        isNewVersion={this.props.isNewVersion}
        dataTableFields={this.props.dataTableFields}
        dataTableSortable={this.props.dataTableSortable}
        filterCondition={this.props.filterCondition}
      />
    );
  }

  async loadEntities(reference: EntityReference | undefined) {
    if (!reference) {
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: true });
    const entities = await this.getEntityFromReference(reference);
    this.setState({ entity: entities, loading: false });
  }

  removeReference(entity: Entity) {
    const { add } = this.props;
    const deleteCall = add && add.deleteEntity ? add.deleteEntity : null;
    this.setState(
      {
        entity: undefined
      },
      () => {
        this.setFormValue(undefined);
        if (deleteCall) {
          deleteCall(entity);
        }
      }
    );
  }

  addEntity(entity: Entity) {
    this.setFormValue(entity['@id']);
    this.closeModal();
  }

  selectEntity(reference: EntityReference) {
    this.setFormValue(reference);
    this.closeModal();
  }

  clearSelection() {
    this.setFormValue(undefined);
  }
}

export const SingleEntitySelector = translate(['entitySelector'])(_SingleEntitySelector);
export const MultipleEntitySelector = translate(['entitySelector'])(_MultipleEntitySelector);
