export function editorEmptyValidation(content: string) {
  if (content) {
    const contentStript = content.replace(/<\/?[^>]+(>|$)/g, '');

    if (contentStript.length) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
