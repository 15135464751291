import React, { ReactNode, useEffect, useState, useRef } from 'react';
import { Icon } from 'semantic-ui-react';

interface Props {
  children: ReactNode;
  height?: number;
  open?: boolean; // Not yet inplemented TODO
  onToggle?: () => void; // Not yet inplemented TODO
}

export default function TextWrapper(props: Props) {
  const [contentHeight, setContentHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [getHeight, setHeight] = useState<number | string>(props.height ? props.height : 200);
  const [needToggle, setNeedToggle] = useState(false);

  useEffect(() => {
    if (ref.current && contentHeight === 0) {
      setContentHeight(ref.current.clientHeight);

      if (ref.current.clientHeight > getHeight) {
        setNeedToggle(true);
      }
    }
  }, []);

  const needReduce = () => {
    if (ref.current) {
      if (contentHeight > getHeight) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  const toggleHeight = () => {
    if (getHeight === 'auto') {
      if (props.height) {
        setHeight(props.height);
        return;
      }

      setHeight(200);
      return;
    }

    setHeight('auto');
  };

  const isWrapped = (height: string | number) => {
    return getHeight !== 'auto';
  };

  return (
    <div className="text-wrapper">
      <div
        className={'content' + (needReduce() ? (isWrapped(getHeight) ? ' wrapped' : '') : '')}
        style={{ height: needReduce() ? (getHeight === 'auto' ? getHeight : getHeight + 'px') : 'auto' }}
        ref={ref}>
        {props.children}
      </div>
      {needToggle ? (
        <button onClick={() => toggleHeight()}>
          <Icon name={isWrapped(getHeight) ? 'arrow down' : 'arrow up'} />
        </button>
      ) : null}
    </div>
  );
}
