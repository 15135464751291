import React, { Dispatch, Fragment } from 'react';
import { RouteComponentProps } from 'react-router';
import { formDefinition, formDefinitionWithoutCustomer } from '../../helpers/TrainingEditFields';
import { TrainingsAction } from '../../state/state';
import { GlobalState } from '../../../core/state';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { Training } from '../../../shared/interfaces/Training';
import { FormikValues } from 'formik';
import { newTrainingBedrijfsIntern, newTrainingOnderAanneming, newTrainingOpenKalender } from '../../helpers/constants';
import * as actions from '../../state/actions';
import { TrainingRouteInfo } from './DetailShell';
import PageHeader from '../../../shared/components/PageHeader';
import queryString from 'query-string';

type Props = {
  savingTrainingStatus: ProgressStatus;
  saveTraining: (training: Training) => void;
} & RouteComponentProps<TrainingRouteInfo> &
  WithNamespaces;

class DetailCreate extends React.Component<Props> {
  saveForm(values: FormikValues) {
    const { saveTraining } = this.props;
    saveTraining(values as Training);
  }

  render() {
    const { history, match, savingTrainingStatus, t, location } = this.props;

    const url = location.search;
    const params = queryString.parse(url);

    const checkTrainingType = () => {
      switch (params['type']) {
        case '1':
          return newTrainingBedrijfsIntern;
        case '2':
          return newTrainingOpenKalender;
        case '3':
          return newTrainingOnderAanneming;
        default:
          return newTrainingOpenKalender;
      }
    };

    return (
      <Fragment>
        <PageHeader {...this.props} breadCrumbParts={[t('trainings'), t('new')]} title={t('new training')} />
        <DetailForm
          formDefinition={params['type'] === '1' ? formDefinition : formDefinitionWithoutCustomer}
          selectedEntity={checkTrainingType()}
          onCancelBtnClick={() => history.push(match.url.replace('/create', ''))}
          onFormSubmit={this.saveForm.bind(this)}
          savingForm={savingTrainingStatus === ProgressStatus.InProgress}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  savingTrainingStatus: state.trainings.savingTrainingStatus
});

const mapDispatchToProps = (dispatch: Dispatch<TrainingsAction>) => ({
  saveTraining: (training: Training) => dispatch(actions.SaveTraining.request({ training: training }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['trainings', 'resources', 'menu'], { nsMode: 'fallback' })(DetailCreate));
