import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { TrainingRequest } from '../../../shared/interfaces/TrainingRequest';
import FileList from '../../../shared/components/FileList';
import { MultiFilePicker } from '../../../shared/components/DetailForm/FilePicker';
import { WithRequestDocument, RequestDocument } from '../../../shared/interfaces/RequestDocument';
import { DeleteRequestFile } from '../../state/actions';

type DetailViewDocuments = {
  trainingRequest: TrainingRequest;
  addFileToRequest: (requestId: number, file: File) => void;
  addFileStatus: ProgressStatus;
  files: RequestDocument<WithRequestDocument>[] | undefined;
  deleteRequestFile: (fileId: number, requestId: number) => void;
} & WithNamespaces;

export const DetailViewDocuments = ({
  trainingRequest,
  t,
  addFileToRequest,
  deleteRequestFile,
  addFileStatus,
  files
}: DetailViewDocuments) => {
  return (
    <>
      <div className="row header">
        <div className="col-6">
          <h4 className="text-uppercase">{t('documents')}</h4>
        </div>
      </div>
      <div className="file-container m-t-lg">
        <div className="row">
          <div className="col-12 col-md-4 m-b-lg">
            <MultiFilePicker
              value={[]}
              onFileDrop={files => {
                addFileToRequest(trainingRequest.id, files[0]);
              }}
              uploadStatus={addFileStatus}
            />
          </div>

          <FileList
            blockViewOn={true}
            files={(files || []).map(f => f.trainingRequestDocument)}
            uploadingStatus={addFileStatus}
            deleteFile={deleteRequestFile}
            componentId={trainingRequest.id}
          />
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(DetailViewDocuments);
