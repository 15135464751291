import React from 'react';
import { Label } from 'semantic-ui-react';
import { TrainingRequestChannel } from '../../shared/interfaces/TrainingRequest';

const toLabelProps = (channel: TrainingRequestChannel): { content: string } => {
  switch (channel) {
    case TrainingRequestChannel.Email:
      return { content: 'E-mail' };
    case TrainingRequestChannel.Telephone:
      return { content: 'Telefoon' };
    case TrainingRequestChannel.Website:
      return { content: 'Website' };
    case TrainingRequestChannel.Others:
      return { content: 'Andere' };
  }
};

const RequestChannelLabel = ({ channel }: { channel: TrainingRequestChannel }) => {
  return <Label color="grey" {...toLabelProps(channel)} />;
};

export default RequestChannelLabel;
