import * as Yup from 'yup';
import { DetailFormFieldTypes } from '../components/DetailForm/DetailFormField';
import SearchEntityForm from '../components/DetailForm/EntitySelector/SearchEntityForm';
import Entity, { EntityReference } from './Entity';

export interface DropDownOption {
  key: number;
  text: string;
  value: any;
}

export enum FormFieldPosition {
  Left,
  Right
}

export enum FormFieldSize {
  Small,
  Medium,
  Large
}

interface BaseFieldDefinition {
  propertyName: string;
  label: string;
  validationChecks?: Yup.Schema<any> | Yup.Ref;
  column?: FormFieldPosition;
  orderNr: number;
  required?: boolean;
  disabled?: boolean;
}

interface TextFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.text;
  size?: FormFieldSize;
}

interface VatFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.vat;
  size?: FormFieldSize;
}

interface NumberFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.number;
}

interface RateFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.rate;
}

interface ColorFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.color;
}

interface CurrencyFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.currency;
}

interface DiscountFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.discount;
}

interface TextboxFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.textbox;
  placeholder?: string;
}

interface EditorFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.editor;
  toolbar?: any[];
  hasToolbar?: boolean;
}

interface EmailFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.email;
}

interface TelephoneFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.telephone;
}

interface UriFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.uri;
}

export interface DropdownlistFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.dropdownlist;
  multiple?: boolean;
  dropDownOptions: DropDownOption[];
}

export interface DropdownsearchlistFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.dropdownsearchlist;
  multiple?: boolean;
  dropDownOptions: DropDownOption[];
}

interface GradingFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.grading;
}

interface HiddenFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.hidden;
}

interface CheckboxFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.checkbox;
}
interface DistanceFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.distance;
}

interface DatePickerFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.datePicker;
  hasTimeField?: boolean;
}

export interface EntitySelectorFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.entitySelector;
  multiple?: boolean;
  searchEntityForm?: typeof SearchEntityForm;
  renderEntity: (entity: Entity, index?: number) => JSX.Element;
  add?: {
    formDefinition: FormFieldDefinition[];
    defaultEntity: Entity;
    setParentIdOnForm: (parentEntity: Entity, formEntity: Entity) => Entity;
    saveEntity: (entity: Entity) => Promise<Entity>;
    deleteEntity?: (entity: Entity) => Promise<boolean>;
  };
  edit?: {
    formDefinition: FormFieldDefinition[];
    saveEntity: (entity: Entity) => Promise<Entity>;
  };
  getEntityFromReference?: (reference: EntityReference) => Promise<Entity>;
  isNewVersion?: boolean;
  dataTableFields?: { key: string; label?: string }[];
  dataTableSortable?: boolean;
  filterCondition?: string;
}

type HeaderFieldDefinition = {
  propertyName?: undefined;
  validationChecks?: undefined;
  type: DetailFormFieldTypes.header;
  label: string;
  column?: FormFieldPosition;
  orderNr: number;
};

interface FileFieldDefinition extends BaseFieldDefinition {
  type: DetailFormFieldTypes.file;
  multiple?: boolean;
  instantUpload?: boolean;
  dropzone?: boolean;
}

export type FormFieldDefinition =
  | TextFieldDefinition
  | VatFieldDefinition
  | NumberFieldDefinition
  | RateFieldDefinition
  | ColorFieldDefinition
  | CurrencyFieldDefinition
  | DiscountFieldDefinition
  | TextboxFieldDefinition
  | EditorFieldDefinition
  | EmailFieldDefinition
  | TelephoneFieldDefinition
  | UriFieldDefinition
  | DropdownlistFieldDefinition
  | DropdownsearchlistFieldDefinition
  | GradingFieldDefinition
  | HiddenFieldDefinition
  | CheckboxFieldDefinition
  | DatePickerFieldDefinition
  | EntitySelectorFieldDefinition
  | HeaderFieldDefinition
  | DistanceFieldDefinition
  | FileFieldDefinition;

export type FormDefinition = FormFieldDefinition[];
