import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormDefinition } from '../../shared/interfaces/FormFieldDefinition';
import Language from '../../shared/interfaces/Language';

export const searchColumnFields = (languages: Language[]) =>
  [
    {
      propertyName: 'companyName',
      label: 'Klantnaam',
      type: DetailFormFieldTypes.text,
      orderNr: 20
    },
    {
      propertyName: 'vatNr',
      label: 'BTW-nummer',
      type: DetailFormFieldTypes.text,
      orderNr: 160
    }
  ] as FormDefinition;
