import React, { SyntheticEvent } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import TextInput from './TextInput';

interface EmailProps {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: SyntheticEvent) => void;
  value: any;
  label: string;
  keyField: string;
  disabled?: boolean;
}

const Email = ({ onBlur, onChange, value, label, keyField, disabled }: EmailProps) => {
  return (
    <Input iconPosition="left" type="text">
      <Icon name="at" />
      <TextInput disabled={disabled} label={label} type="email" keyField={keyField} onChange={onChange} onBlur={onBlur} value={value} />
    </Input>
  );
};

export default Email;
