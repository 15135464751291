import * as Yup from 'yup';
import TaskList from '../../shared/interfaces/TaskList';
import PredefinedTask from '../../shared/interfaces/PredefinedTask';

export const TaskListInitialValue: TaskList = {
  '@id': '',
  id: undefined,
  name: '',
  description: '',
  trainingPredefinedTasks: []
};

export const PredefinedTaskInitialValue: PredefinedTask = {
  '@id': '',
  id: undefined,
  description: '',
  daysBeforeFirstSession: 1
};

export const TaskListValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Te kort!')
    .max(50, 'Te Lang!')
    .required('Verplicht in te vullen'),
  description: Yup.string().max(200, 'Te Lang!')
});
