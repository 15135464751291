import React from 'react';
import { Modal } from 'semantic-ui-react';
import Entity from '../../../../interfaces/Entity';
import { EntitySelectorFieldDefinition } from '../../../../interfaces/FormFieldDefinition';
import DetailForm from '../../DetailForm';
import { FormikValues } from 'formik';

type EditEntityModalProps = {
  afterAdd: (entity: Entity) => void;
  afterEdit: (entity: Entity) => void;
  cancel: () => void;
  selectedEntity: Entity | undefined;
  parentEntity: Entity;
  saveEntity: (entity: Entity) => Promise<Entity>;
  title: string;
} & EntitySelectorFieldDefinition;

export default (props: EditEntityModalProps) => {
  const { selectedEntity, add, edit, parentEntity, saveEntity, title } = props;
  const isNew = !selectedEntity;
  const entity = selectedEntity || add!.defaultEntity;

  return (
    <>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <DetailForm
            selectedEntity={isNew && add ? add.setParentIdOnForm(parentEntity!, entity) : entity}
            onFormSubmit={async (formValues: FormikValues) => {
              const result = await saveEntity(formValues as Entity);
              if (isNew) {
                props.afterAdd(result);
              } else {
                props.afterEdit(result);
              }
            }}
            formDefinition={isNew ? add!.formDefinition : edit!.formDefinition}
            isSubForm={true}
          />
        </Modal.Description>
      </Modal.Content>
    </>
  );
};
