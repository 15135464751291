import TrainingSession from '../../shared/interfaces/TrainingSession';
import moment from 'moment';
import { Training, TrainingType, TrainingStatus } from '../../shared/interfaces/Training';
import TrainingParticipant from '../../shared/interfaces/TrainingParticipant';
import { Participant } from '../../shared/interfaces/participant';
import * as Yup from 'yup';
import { vatValidation } from '../../shared/helpers/vatValidation';
import { emailValidation } from '../../shared/helpers/emailValidation';

export const baseUrl = 'trainings';

export const newSession: TrainingSession = {
  '@id': '0',
  id: 0,
  training: undefined,
  trainer: undefined,
  location: undefined,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),
  isDaytimeSession: true,
  participations: [],
  trainingSessionMaterials: [],
  courseLink: ''
};

export const newParticipant: Participant = {
  '@id': '0',
  training: undefined,
  contact: undefined,
  customer: undefined,
  id: 0,
  email: '',
  firstName: '',
  lastName: '',
  cellphone: '',
  phone: '',
  participations: []
};

export const NewParticipantContact = {
  '@id': '0',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  cellphone: '',
  hasNewsletter: false,
  type: 'bedrijf'
};

export const ParticipantContactValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(50, 'Te Lang!')
    .required('Verplicht in te vullen'),
  lastName: Yup.string()
    .max(50, 'Te Lang!')
    .required('Verplicht in te vullen'),
  email: Yup.string().test('email', 'Dit email is niet geldig', emailValidation)
});

export const NewParticipantClient = {
  '@id': '0',
  companyName: '',
  companyStreet: '',
  companyStreetNumber: '',
  companyBus: '',
  companyCity: '',
  companyPostal: '',
  companyCountry: '',
  companyVat: '',
  companyPO: '',
  companyEmail: ''
};

export const ParticipantClientValidationSchema = Yup.object().shape({
  companyName: Yup.string()
    .max(50, 'Te Lang!')
    .required('Verplicht in te vullen'),
  street: Yup.string()
    .max(50, 'Te Lang!')
    .required('Verplicht in te vullen'),
  houseNr: Yup.string()
    .max(30, 'Te Lang!')
    .required('Verplicht in te vullen'),
  city: Yup.string()
    .max(50, 'Te Lang!')
    .required('Verplicht in te vullen'),
  postalCode: Yup.string()
    .max(50, 'Te Lang!')
    .required('Verplicht in te vullen'),
  country: Yup.string()
    .max(50, 'Te Lang!')
    .required('Verplicht in te vullen'),
  vatNr: Yup.string().test('vat', 'Dit BTW-nummer is niet geldig', vatValidation),
  accountNr: Yup.string(),
  email: Yup.string().test('email', 'Dit email is niet geldig', emailValidation)
});

export const newTrainingOpenKalender: Training = {
  '@id': '0',
  id: 0,
  type: TrainingType.OpenKalender,
  course: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  customer: undefined,
  maxParticipants: 10,
  minParticipants: 1,
  status: TrainingStatus.Active,
  participants: [],
  trainingComplete: false,
  hasPredefinedTasks: false,
  evaluationLink: '',
  firstMeasurementLink: '',
  secondMeasurementLink: '',
  discount: 0
};

export const newTrainingBedrijfsIntern: Training = {
  '@id': '0',
  id: 0,
  type: TrainingType.BedrijfsIntern,
  course: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  customer: undefined,
  maxParticipants: 10,
  minParticipants: 1,
  status: TrainingStatus.Active,
  participants: [],
  trainingComplete: false,
  hasPredefinedTasks: false,
  evaluationLink: '',
  firstMeasurementLink: '',
  secondMeasurementLink: '',
  discount: 0
};

export const newTrainingOnderAanneming: Training = {
  '@id': '0',
  id: 0,
  type: TrainingType.OnderAanneming,
  course: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  customer: undefined,
  maxParticipants: 10,
  minParticipants: 1,
  status: TrainingStatus.Active,
  participants: [],
  trainingComplete: false,
  hasPredefinedTasks: false,
  evaluationLink: '',
  firstMeasurementLink: '',
  secondMeasurementLink: '',
  discount: 0
};
