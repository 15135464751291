import { DeepReadonly } from 'utility-types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import Language from '../../shared/interfaces/Language';
import Competency from '../../shared/interfaces/Competency';
import Sex from '../../shared/interfaces/Sex';
import CountryCode from '../../shared/interfaces/CountryCode';
import Modules from '../../shared/interfaces/Modules';
import { getEnvSettings } from '../environments/environments';
import SiteSettings from '../../shared/interfaces/SiteSettings';

export type AppAction = ActionType<typeof actions>;

export interface ConfirmationModalTexts {
  title: string;
  content?: string;
}
export type ConfirmationModalOptions = ConfirmationModalTexts & {
  action: Function;
};

// Interface
export type AppState = DeepReadonly<{
  loading: boolean;
  languages: Language[];
  competencies: Competency[];
  sexes: Sex[];
  countryCodes: CountryCode[];
  activeModules: Modules;
  siteSettings: SiteSettings;
  confirmationDialog: ConfirmationModalOptions | undefined;
}>;

// Initial State
const initialState: AppState = {
  loading: false,
  countryCodes: [],
  sexes: [],
  languages: [],
  competencies: [],
  activeModules: { requestModule: false, materialModule: false },
  siteSettings: {
    CAN_ACCESS_TRAINERS_CRUD: getEnvSettings(process.env.REACT_APP_ENVIRONMENT).CAN_ACCESS_TRAINERS_CRUD,
    CAN_ACCESS_COURSE_CATEGORIES_CRUD: getEnvSettings(process.env.REACT_APP_ENVIRONMENT).CAN_ACCESS_COURSE_CATEGORIES_CRUD,
    CAN_ACCESS_COURSE_CRUD: getEnvSettings(process.env.REACT_APP_ENVIRONMENT).CAN_ACCESS_COURSE_CRUD,
    CAN_ACCESS_CLIENT_CRUD: getEnvSettings(process.env.REACT_APP_ENVIRONMENT).CAN_ACCESS_CLIENT_CRUD,
    CAN_ACCESS_TRAINER_CONTRACTS: getEnvSettings(process.env.REACT_APP_ENVIRONMENT).CAN_ACCESS_TRAINER_CONTRACTS
  },
  confirmationDialog: undefined
};

// Reducer
export function app(state: AppState = initialState, action: AppAction): AppState {
  switch (action.type) {
    case getType(actions.SetLoading):
      return { ...state, loading: action.payload.loading };
    case getType(actions.LoadResources.request):
      return {
        ...state,
        loading: true
      };
    case getType(actions.LoadResources.success):
      return {
        ...state,
        loading: false,
        languages: action.payload.languages,
        competencies: action.payload.competencies,
        sexes: action.payload.sexes,
        countryCodes: action.payload.countryCodes
      };
    case getType(actions.LoadResources.failure):
      return {
        ...state,
        loading: false
      };
    case getType(actions.ConfirmAction.request):
      return {
        ...state,
        confirmationDialog: action.payload
      };
    case getType(actions.ConfirmAction.success):
    case getType(actions.ConfirmAction.failure):
      return {
        ...state,
        confirmationDialog: undefined
      };
    case getType(actions.RequestActiveModules.success):
      return {
        ...state,
        activeModules: action.payload
      };
    default:
      return { ...state };
  }
}
