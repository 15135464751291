import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import translationsNL from '../core/locales/nl.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  nl: translationsNL
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    whitelist: ['nl'],
    nonExplicitWhitelist: false,
    load: 'languageOnly',
    lng: 'nl',
    fallbackLng: 'nl',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        return value;
      }
    },
    ns: ['common', 'menu'],
    defaultNS: 'common',
    debug: false
  });

export default i18n;
