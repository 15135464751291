import React, { useState } from 'react';
import { GlobalState } from '../../../../../core/state';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import PageHeader from '../../../../../shared/components/PageHeader';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import Course from '../../../../../shared/interfaces/Course';
import { Form, Formik } from 'formik';
import {
  NewParticipantContact,
  ParticipantClientValidationSchema,
  ParticipantContactValidationSchema
} from '../../../../helpers/constants';
import DetailFormField, { DetailFormFieldTypes } from '../../../../../shared/components/DetailForm/DetailFormField';
import Entity, { EntityReference } from '../../../../../shared/interfaces/Entity';
import Client from '../../../../../shared/interfaces/Client';
import SearchClientForm from '../../../../../shared/components/DetailForm/EntitySelector/SelectForms/SearchClientForm';
import Contact from '../../../../../shared/interfaces/Contact';
import { SearchContactForParticipantForm } from '../../../../../shared/components/DetailForm/EntitySelector/SelectForms/SearchContactForm';
import * as Yup from 'yup';
import { emailValidation } from '../../../../../shared/helpers/emailValidation';
import { vatValidation } from '../../../../../shared/helpers/vatValidation';
import { Button } from 'semantic-ui-react';
import {
  CreateParticipantFromScratch,
  CreateParticipantWithKnownClient,
  CreateParticipantWithKnownContact
} from '../../../../state/actions';
import { Training } from '../../../../../shared/interfaces/Training';
import { newClientData, newContactData } from '../../../../../clients/helpers/constants';
import { arrayToOption } from '../../../../../shared/helpers/dropDownHelpers';
import { countryCodes } from '../../../../../shared/constants';

type ParticipantFormProps = {
  course: Course;
  training: Training;
  createParticipantWithKnownContact: (trainingId: string, contactId: string) => void;
  createParticipantWithKnownClient: (trainingId: string, clientId: string, contactForm: Contact) => void;
  createParticipantFromScratch: (trainingId: string, clientForm: Client, contactForm: Contact, participantTypeCompany: boolean) => void;
} & RouteComponentProps &
  WithNamespaces;

const ParticipantForm = (props: ParticipantFormProps) => {
  const { t } = props;

  const [participantKnown, setParticipantKnown] = useState<boolean | undefined>(undefined);
  const [clientKnown, setClientKnown] = useState<boolean | undefined>(undefined);
  const [clientSet, setClientSet] = useState<boolean>(false);
  const [participantTypeCompany, setParticipantTypeCompany] = useState<boolean | undefined>(undefined);

  const [client, setClient] = useState<EntityReference>();
  const [newClient, setNewClient] = useState(newClientData);
  const [contact, setContact] = useState<EntityReference>();

  return (
    <>
      <PageHeader
        {...props}
        title={`${t('participant')} ${t('create')}`}
        breadCrumbParts={[t('trainings'), props.course.title, t('participants'), t('new')]}
      />

      {(() => {
        switch (participantKnown) {
          default:
            return (
              <div className="participant-form-question">
                <b>Is de deelnemer al gekend?</b>
                <div className="actions">
                  <Button className="secondary" onClick={() => setParticipantKnown(true)}>
                    {t('yes')}
                  </Button>
                  <Button className="secondary" onClick={() => setParticipantKnown(false)}>
                    {t('no')}
                  </Button>
                </div>
              </div>
            );
          case true:
            return (
              <>
                <div className="ui form">
                  <DetailFormField
                    key={'contact-key'}
                    onChange={(data: any) => setContact(data.target.value)}
                    value={contact}
                    type={DetailFormFieldTypes.entitySelector}
                    renderEntity={(entity: Entity) => {
                      const contact = entity as Contact;
                      return (
                        <span key={contact['@id']}>
                          {contact.firstName} {contact.lastName}
                        </span>
                      );
                    }}
                    searchEntityForm={SearchContactForParticipantForm}
                    propertyName="contact"
                    label={props.t('chooseContact')}
                    orderNr={2}
                    onBlur={() => {}}
                    selectedEntity={{ '@id': '0' }}
                    isNewVersion={true}
                    dataTableFields={[
                      { key: 'firstName', label: props.t('firstname') },
                      { key: 'lastName', label: props.t('lastname') },
                      { key: 'email', label: props.t('email') }
                    ]}
                    dataTableSortable={true}
                  />
                  <div className="d-flex mt-3 justify-content-between">
                    <Button onClick={() => setParticipantKnown(undefined)}>{t('back')}</Button>
                    {contact && (
                      <Button className="primary" onClick={() => props.createParticipantWithKnownContact(props.training['@id'], contact)}>
                        {t('next')}
                      </Button>
                    )}
                  </div>
                </div>
              </>
            );
          case false:
            return (
              <>
                {clientKnown === undefined && (
                  <div className="participant-form-question">
                    <b>Is de klant al gekend?</b>
                    <div className="actions">
                      <Button className="secondary" onClick={() => setClientKnown(true)}>
                        {t('yes')}
                      </Button>
                      <Button className="secondary" onClick={() => setClientKnown(false)}>
                        {t('no')}
                      </Button>
                    </div>
                    <div className="d-flex mt-3 justify-content-between w-100">
                      <Button onClick={() => setParticipantKnown(undefined)}>{t('back')}</Button>
                    </div>
                  </div>
                )}
                {clientKnown === true && (
                  <>
                    {clientSet && client ? (
                      <>
                        <Formik
                          initialValues={newContactData}
                          validationSchema={ParticipantContactValidationSchema}
                          onSubmit={values => props.createParticipantWithKnownClient(props.training['@id'], client, values)}>
                          {formProps => (
                            <Form className="ui form">
                              <DetailFormField
                                key={'firstName'}
                                touched={(formProps.touched as any)['firstName' || '']}
                                error={(formProps.errors as any)['firstName' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['firstName' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'firstName'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'firstName'}
                                validationChecks={Yup.string().required('Voornaam moet ingevuld worden.')}
                                required={true}
                              />
                              <DetailFormField
                                key={'lastName'}
                                touched={(formProps.touched as any)['lastName' || '']}
                                error={(formProps.errors as any)['lastName' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['lastName' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'lastName'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'lastName'}
                                validationChecks={Yup.string().required('Familienaam moet ingevuld worden.')}
                                required={true}
                              />
                              <DetailFormField
                                key={'email'}
                                touched={(formProps.touched as any)['email' || '']}
                                error={(formProps.errors as any)['email' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['email' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'email'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'email'}
                                validationChecks={Yup.string().test('email', 'Dit email is niet geldig', emailValidation)}
                                required={true}
                              />
                              <DetailFormField
                                key={'phone'}
                                touched={(formProps.touched as any)['phone' || '']}
                                error={(formProps.errors as any)['phone' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['phone' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'phone'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'phone'}
                                required={false}
                              />
                              <DetailFormField
                                key={'cellphone'}
                                touched={(formProps.touched as any)['cellphone' || '']}
                                error={(formProps.errors as any)['cellphone' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['cellphone' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'cellphone'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'cellphone'}
                                required={false}
                              />
                              <DetailFormField
                                key={'hasNewsletter'}
                                touched={(formProps.touched as any)['hasNewsletter' || '']}
                                error={(formProps.errors as any)['hasNewsletter' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['hasNewsletter' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'hasNewsletter'}
                                type={DetailFormFieldTypes.checkbox}
                                orderNr={10}
                                propertyName={'hasNewsletter'}
                                required={false}
                              />
                              <div className="d-flex mt-3 justify-content-between">
                                <Button onClick={() => setClientSet(false)}>{t('back')}</Button>
                                <Button className="primary" type="submit">
                                  {t('next')}
                                </Button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </>
                    ) : (
                      <>
                        <div className="ui form">
                          <DetailFormField
                            key={'client-key-2'}
                            onChange={(data: any) => setClient(data.target.value)}
                            value={client}
                            type={DetailFormFieldTypes.entitySelector}
                            searchEntityForm={SearchClientForm}
                            renderEntity={(entity: Entity) => <span>{(entity as Client).companyName}</span>}
                            propertyName="client"
                            label={props.t('chooseCustomer')}
                            orderNr={0}
                            onBlur={() => {}}
                            selectedEntity={{ '@id': '0' }}
                            isNewVersion={true}
                            dataTableFields={[
                              { key: 'companyName', label: props.t('companyName') },
                              { key: 'language', label: props.t('language') }
                            ]}
                            dataTableSortable={true}
                          />
                          <div className="d-flex mt-3 justify-content-between">
                            <Button onClick={() => setClientKnown(undefined)}>{t('back')}</Button>
                            <Button className="primary" onClick={() => setClientSet(true)}>
                              {t('next')}
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {clientKnown === false && (
                  <>
                    {clientSet ? (
                      <>
                        <Formik
                          initialValues={newContactData}
                          validationSchema={ParticipantContactValidationSchema}
                          onSubmit={values =>
                            props.createParticipantFromScratch(
                              props.training['@id'],
                              newClient,
                              values,
                              participantTypeCompany ? participantTypeCompany : false
                            )
                          }>
                          {formProps => (
                            <Form className="ui form">
                              <DetailFormField
                                key={'firstName'}
                                touched={(formProps.touched as any)['firstName' || '']}
                                error={(formProps.errors as any)['firstName' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['firstName' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'firstName'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'firstName'}
                                validationChecks={Yup.string().required('Voornaam moet ingevuld worden.')}
                                required={true}
                              />
                              <DetailFormField
                                key={'lastName'}
                                touched={(formProps.touched as any)['lastName' || '']}
                                error={(formProps.errors as any)['lastName' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['lastName' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'lastName'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'lastName'}
                                validationChecks={Yup.string().required('Familienaam moet ingevuld worden.')}
                                required={true}
                              />
                              <DetailFormField
                                key={'email'}
                                touched={(formProps.touched as any)['email' || '']}
                                error={(formProps.errors as any)['email' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['email' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'email'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'email'}
                                validationChecks={Yup.string().test('email', 'Dit email is niet geldig', emailValidation)}
                                required={true}
                              />
                              <DetailFormField
                                key={'phone'}
                                touched={(formProps.touched as any)['phone' || '']}
                                error={(formProps.errors as any)['phone' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['phone' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'phone'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'phone'}
                                required={false}
                              />
                              <DetailFormField
                                key={'cellphone'}
                                touched={(formProps.touched as any)['cellphone' || '']}
                                error={(formProps.errors as any)['cellphone' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['cellphone' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'cellphone'}
                                type={DetailFormFieldTypes.text}
                                orderNr={10}
                                propertyName={'cellphone'}
                                required={false}
                              />
                              <DetailFormField
                                key={'hasNewsletter'}
                                touched={(formProps.touched as any)['hasNewsletter' || '']}
                                error={(formProps.errors as any)['hasNewsletter' || '']}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                value={formProps.values['hasNewsletter' || '']}
                                selectedEntity={NewParticipantContact}
                                label={'hasNewsletter'}
                                type={DetailFormFieldTypes.checkbox}
                                orderNr={10}
                                propertyName={'hasNewsletter'}
                                required={false}
                              />
                              <div className="d-flex mt-3 justify-content-between">
                                <Button
                                  onClick={() => {
                                    setClientSet(false);
                                  }}>
                                  {t('back')}
                                </Button>
                                <Button className="primary" type="submit">
                                  {t('next')}
                                </Button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </>
                    ) : (
                      <>
                        {(participantTypeCompany === undefined || participantTypeCompany === false) && (
                          <div className="participant-form-question">
                            <b>Kies uw type klant:</b>
                            <div className="actions">
                              <Button className="secondary" onClick={() => setParticipantTypeCompany(true)}>
                                {t('company')}
                              </Button>
                              <Button
                                className="secondary"
                                onClick={() => {
                                  setClientSet(true);
                                  setParticipantTypeCompany(false);
                                }}>
                                {t('private')}
                              </Button>
                            </div>
                            <div className="d-flex mt-3 justify-content-between w-100">
                              <Button onClick={() => setClientKnown(undefined)}>{t('back')}</Button>
                            </div>
                          </div>
                        )}
                        {participantTypeCompany === true && (
                          <>
                            <Formik
                              initialValues={newClient ? newClient : newClientData}
                              validationSchema={ParticipantClientValidationSchema}
                              onSubmit={values => {
                                setNewClient(values);
                                setClientSet(true);
                              }}>
                              {formProps => (
                                <Form className="ui form">
                                  <div className="row mt-3">
                                    <div className="col-12">
                                      <DetailFormField
                                        key={'companyName'}
                                        touched={(formProps.touched as any)['companyName' || '']}
                                        error={(formProps.errors as any)['companyName' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['companyName' || '']}
                                        selectedEntity={newClientData}
                                        label={'companyName'}
                                        type={DetailFormFieldTypes.text}
                                        orderNr={10}
                                        propertyName={'companyName'}
                                        validationChecks={Yup.string().required('Bedrijfsnaam moet ingevuld worden.')}
                                        required={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-12">
                                      <DetailFormField
                                        key={'email'}
                                        touched={(formProps.touched as any)['email' || '']}
                                        error={(formProps.errors as any)['email' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['email' || '']}
                                        selectedEntity={newClientData}
                                        label={'email'}
                                        type={DetailFormFieldTypes.text}
                                        orderNr={10}
                                        propertyName={'email'}
                                        validationChecks={Yup.string().test('email', 'Dit email is niet geldig', emailValidation)}
                                        required={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-12 col-md-8">
                                      <DetailFormField
                                        key={'street'}
                                        touched={(formProps.touched as any)['street' || '']}
                                        error={(formProps.errors as any)['street' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['street' || '']}
                                        selectedEntity={newClientData}
                                        label={'street'}
                                        type={DetailFormFieldTypes.text}
                                        orderNr={10}
                                        propertyName={'street'}
                                        validationChecks={Yup.string().required('Straat moet ingevuld worden.')}
                                        required={true}
                                      />
                                    </div>
                                    <div className="col-12 col-md-2">
                                      <DetailFormField
                                        key={'houseNr'}
                                        touched={(formProps.touched as any)['houseNr' || '']}
                                        error={(formProps.errors as any)['houseNr' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['houseNr' || '']}
                                        selectedEntity={newClientData}
                                        label={'houseNr'}
                                        type={DetailFormFieldTypes.text}
                                        orderNr={10}
                                        propertyName={'houseNr'}
                                        required={true}
                                        validationChecks={Yup.string().required('Straatnummer moet ingevuld worden.')}
                                      />
                                    </div>
                                    <div className="col-12 col-md-2">
                                      <DetailFormField
                                        key={'bus'}
                                        touched={(formProps.touched as any)['bus' || '']}
                                        error={(formProps.errors as any)['bus' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['bus' || '']}
                                        selectedEntity={newClientData}
                                        label={'bus'}
                                        type={DetailFormFieldTypes.text}
                                        orderNr={10}
                                        propertyName={'bus'}
                                        required={false}
                                        validationChecks={Yup.string().required('Bus moet ingevuld worden.')}
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-12 col-md-4">
                                      <DetailFormField
                                        key={'city'}
                                        touched={(formProps.touched as any)['city' || '']}
                                        error={(formProps.errors as any)['city' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['city' || '']}
                                        selectedEntity={newClientData}
                                        label={'city'}
                                        type={DetailFormFieldTypes.text}
                                        orderNr={10}
                                        propertyName={'city'}
                                        required={true}
                                        validationChecks={Yup.string().required('Stad moet ingevuld worden.')}
                                      />
                                    </div>
                                    <div className="col-12 col-md-4">
                                      <DetailFormField
                                        key={'postalCode'}
                                        touched={(formProps.touched as any)['postalCode' || '']}
                                        error={(formProps.errors as any)['postalCode' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['postalCode' || '']}
                                        selectedEntity={newClientData}
                                        label={'postalCode'}
                                        type={DetailFormFieldTypes.text}
                                        orderNr={10}
                                        propertyName={'postalCode'}
                                        required={true}
                                        validationChecks={Yup.string().required('Postcode moet ingevuld worden.')}
                                      />
                                    </div>
                                    <div className="col-12 col-md-4">
                                      <DetailFormField
                                        key={'country'}
                                        touched={(formProps.touched as any)['country' || '']}
                                        error={(formProps.errors as any)['country' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['country' || '']}
                                        selectedEntity={newClientData}
                                        label={'country'}
                                        type={DetailFormFieldTypes.dropdownlist}
                                        dropDownOptions={arrayToOption(countryCodes)}
                                        orderNr={10}
                                        propertyName={'country'}
                                        required={true}
                                        validationChecks={Yup.string().required('Land moet ingevuld worden.')}
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-12">
                                      <DetailFormField
                                        key={'vatNr'}
                                        touched={(formProps.touched as any)['vatNr' || '']}
                                        error={(formProps.errors as any)['vatNr' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['vatNr' || '']}
                                        selectedEntity={newClientData}
                                        label={'vatNr'}
                                        type={DetailFormFieldTypes.vat}
                                        orderNr={10}
                                        propertyName={'vatNr'}
                                        required={true}
                                        validationChecks={Yup.string().test('vat', 'Dit BTW-nummer is niet geldig', vatValidation)}
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-3">
                                    <div className="col-12">
                                      <DetailFormField
                                        key={'accountNr'}
                                        touched={(formProps.touched as any)['accountNr' || '']}
                                        error={(formProps.errors as any)['accountNr' || '']}
                                        onChange={formProps.handleChange}
                                        onBlur={formProps.handleBlur}
                                        value={formProps.values['accountNr' || '']}
                                        selectedEntity={newClientData}
                                        label={'accountNr'}
                                        type={DetailFormFieldTypes.text}
                                        orderNr={10}
                                        propertyName={'accountNr'}
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex mt-3 justify-content-between">
                                    <Button onClick={() => setParticipantTypeCompany(undefined)}>{t('back')}</Button>
                                    <Button className="primary" type="submit">
                                      {t('next')}
                                    </Button>
                                  </div>
                                </Form>
                              )}
                            </Formik>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            );
        }
      })()}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createParticipantWithKnownContact: (trainingId: string, contactId: string) =>
    dispatch(CreateParticipantWithKnownContact.request({ trainingId, contactId })),
  createParticipantWithKnownClient: (trainingId: string, clientId: string, contactForm: Contact) =>
    dispatch(CreateParticipantWithKnownClient.request({ trainingId, clientId, contactForm })),
  createParticipantFromScratch: (trainingId: string, clientForm: Client, contactForm: Contact, participantTypeCompany: boolean) =>
    dispatch(CreateParticipantFromScratch.request({ trainingId, clientForm, contactForm, participantTypeCompany }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['trainings', 'resources', 'menu'], { nsMode: 'fallback' })(ParticipantForm));
