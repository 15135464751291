import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { DeepReadonly } from 'utility-types';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import { configConstants } from '../../config/constants';
import {
  TrainingRequest,
  WithTrainingRequestSessions,
  WithCourse,
  WithTraining,
  WithTrainingSessions
} from '../../shared/interfaces/TrainingRequest';
import { RequestSessionTeaser } from '../../shared/interfaces/RequestSessionTeaser';
import { RequestDocument, WithRequestDocument } from '../../shared/interfaces/RequestDocument';
import Course from '../../shared/interfaces/Course';
import { Mail, WithTrainingRequestDocuments } from '../../shared/interfaces/Mail';
import { Training } from '../../shared/interfaces/Training';
import Client from '../../shared/interfaces/Client';

export type TrainingRequestsAction = ActionType<typeof actions>;
const { pageSize } = configConstants;

export type TrainingRequestsState = DeepReadonly<{
  loadTrainingRequestStatus: ProgressStatus;
  savingTrainingRequestStatus: ProgressStatus;
  fetchTrainingRequestDocumentStatus: ProgressStatus;
  loadFilesStatus: ProgressStatus;
  addFileToRequestStatus: ProgressStatus;
  selectedRequestFiles: RequestDocument<WithRequestDocument>[] | undefined;
  trainingRequests: TrainingRequest<WithTrainingRequestSessions & WithCourse & WithTraining & WithTrainingSessions>[] | null;
  selectedTrainingRequest: TrainingRequest | null;
  selectedTrainingRequestStatus: ProgressStatus;
  selectedTrainingRequestSessions: any;
  selectedTrainingRequestTraining: Training | undefined;
  selectedRequestSessions: RequestSessionTeaser[];
  offerDocument: string | undefined;
  orderDocument: string | undefined;
  totalTrainingRequests: number;
  pageSize: number;
  errorMessage: string | null;
  courses: Course[];
  mails: Mail<WithTrainingRequestDocuments>[];
  selectedCourse: Course | undefined;
  selectedClient: Client | undefined;
}>;

const InitialState: TrainingRequestsState = {
  loadTrainingRequestStatus: ProgressStatus.Uninitialized,
  selectedTrainingRequestStatus: ProgressStatus.Uninitialized,
  savingTrainingRequestStatus: ProgressStatus.Uninitialized,
  fetchTrainingRequestDocumentStatus: ProgressStatus.Uninitialized,
  loadFilesStatus: ProgressStatus.Uninitialized,
  addFileToRequestStatus: ProgressStatus.Uninitialized,
  selectedRequestFiles: undefined,
  trainingRequests: null,
  selectedTrainingRequest: null,
  selectedTrainingRequestSessions: undefined,
  selectedTrainingRequestTraining: undefined,
  selectedRequestSessions: [],
  pageSize,
  totalTrainingRequests: 0,
  errorMessage: null,
  orderDocument: undefined,
  offerDocument: undefined,
  courses: [],
  mails: [],
  selectedCourse: undefined,
  selectedClient: undefined
};

export function requests(state: TrainingRequestsState = InitialState, action: TrainingRequestsAction): TrainingRequestsState {
  switch (action.type) {
    case getType(actions.FetchTrainingRequests.request):
      return {
        ...state,
        loadTrainingRequestStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchTrainingRequests.success):
      return {
        ...state,
        selectedRequestSessions: [],
        selectedRequestFiles: undefined,
        selectedTrainingRequestTraining: undefined,
        loadTrainingRequestStatus: ProgressStatus.Done,
        trainingRequests: action.payload.trainingRequests,
        totalTrainingRequests: action.payload.totalTrainingRequests,
        courses: action.payload.courses
      };
    case getType(actions.FetchTrainingRequests.failure):
      return {
        ...state,
        loadTrainingRequestStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchOneTrainingRequest.request):
      return {
        ...state,
        selectedTrainingRequestStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchOneTrainingRequest.success):
      return {
        ...state,
        selectedTrainingRequestStatus: ProgressStatus.Done,
        selectedTrainingRequest: action.payload.selectedTrainingRequest,
        selectedClient: action.payload.selectedClient,
        selectedCourse: action.payload.selectedCourse
      };
    case getType(actions.FetchOneTrainingRequest.failure):
      return {
        ...state,
        selectedTrainingRequestStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainingRequestTraining.success):
      return {
        ...state,
        selectedTrainingRequestTraining: action.payload.selectedTrainingRequestTraining
      };
    case getType(actions.FetchTrainingRequestTraining.failure):
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveTrainingRequest.request):
      return {
        ...state,
        savingTrainingRequestStatus: ProgressStatus.InProgress
      };
    case getType(actions.SaveTrainingRequest.success):
      return {
        ...state,
        selectedTrainingRequest: action.payload.trainingRequest,
        savingTrainingRequestStatus: ProgressStatus.Done
      };
    case getType(actions.SaveTrainingRequest.failure):
      return {
        ...state,
        savingTrainingRequestStatus: ProgressStatus.Error
      };
    case getType(actions.FetchTrainingRequestDocument.request):
      return {
        ...state,
        fetchTrainingRequestDocumentStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchTrainingRequestDocument.success):
      return {
        ...state,
        fetchTrainingRequestDocumentStatus: ProgressStatus.Done,
        [action.payload.type]: action.payload.fileUrl
      };
    case getType(actions.FetchTrainingRequestDocument.failure):
      return {
        ...state,
        fetchTrainingRequestDocumentStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainingRequestSessions.success):
      return {
        ...state,
        selectedRequestSessions: action.payload.requestSessions
      };
    case getType(actions.FetchRequestFiles.request):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchRequestFiles.success):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.Done,
        selectedRequestFiles: action.payload
      };
    case getType(actions.FetchRequestFiles.failure):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchRequestMails.request):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchRequestMails.success):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.Done,
        mails: action.payload
      };
    case getType(actions.FetchRequestMails.failure):
      return {
        ...state,
        loadFilesStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.AddFileToRequest.request):
      return {
        ...state,
        addFileToRequestStatus: ProgressStatus.InProgress
      };
    case getType(actions.AddFileToRequest.success):
      return {
        ...state,
        addFileToRequestStatus: ProgressStatus.Done
      };
    case getType(actions.AddFileToRequest.failure):
      return {
        ...state,
        addFileToRequestStatus: ProgressStatus.Error
      };

    case getType(actions.DeleteRequestFile.failure):
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };

    default:
      return { ...state };
  }
}
