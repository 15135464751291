import moment from 'moment';

interface WithPeriod {
  startDate: string;
  endDate: string;
}

export default function(items: WithPeriod[] | undefined) {
  if (!items || items.length === 0) {
    return undefined;
  }

  const initialStart = moment(items[0].startDate);
  const initialEnd = moment(items[0].endDate);

  return items.reduce(
    ({ start, end }, item) => {
      const itemStart = moment(item.startDate);
      const itemEnd = moment(item.endDate);
      return {
        start: itemStart.isBefore(start) ? itemStart : start,
        end: itemEnd.isAfter(end) ? itemEnd : end
      };
    },
    { start: initialStart, end: initialEnd }
  );
}
