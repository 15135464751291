import React from 'react';
import SelectEntityForm from '../SearchEntityForm';
import { Button, Icon } from 'semantic-ui-react';
import Entity from '../../../../interfaces/Entity';

interface State {
  searchText: string;
  page: number;
  changedSortField?: string;
  changedSortDirection?: 'asc' | 'desc';
  changedPage?: number;
  changedSearchText?: string;
}

export default abstract class SearchTextForm<T extends Entity, TProps = {}> extends SelectEntityForm<TProps, State> {
  abstract searchEntities(
    searchText: string,
    page?: number,
    sortField?: string,
    sortDirection?: 'asc' | 'desc',
    filterCondition?: string
  ): Promise<{ entities: T[]; amount: number }>;
  abstract placeholder: string;

  state: State = {
    searchText: '',
    page: 1
  };

  componentDidMount() {
    this.doSearch('', 1, 'updatedAt', 'asc', this.props.filterCondition);
  }

  componentDidUpdate(prevProps: any, prevState: State) {
    if (this.props.sortField !== prevProps.sortField) {
      this.state.changedSortField = this.props.sortField as string;
      this.doSearch(this.state.searchText, this.props.page, this.props.sortField, 'asc', this.props.filterCondition);
    }
    if (this.props.sortDirection !== prevProps.sortDirection && this.props.sortField === prevProps.sortField) {
      this.state.changedSortDirection = this.props.sortDirection === 'ascending' ? 'asc' : 'desc';

      this.doSearch(
        this.state.searchText,
        this.props.page,
        this.props.sortField,
        this.state.changedSortDirection,
        this.props.filterCondition
      );
    }
    if (this.props.page !== prevProps.page) {
      this.state.changedPage = this.props.page as number;
      this.state.changedSortDirection = this.props.sortDirection === 'ascending' ? 'asc' : 'desc';
      this.doSearch(
        this.state.searchText,
        this.props.page,
        this.props.sortField,
        this.state.changedSortDirection,
        this.props.filterCondition
      );
    }
    if (this.state.searchText !== prevState.searchText) {
      this.state.changedSearchText = this.state.searchText;
    }
  }

  async doSearch(searchText: string, page = 1, sortField?: string, sortDirection?: 'asc' | 'desc', filterCondition?: string) {
    const results = await this.searchEntities(searchText, page, sortField, sortDirection, filterCondition);
    this.props.onResult(results);
  }

  handleKeyUp = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      this.doSearch(
        this.state.changedSearchText || this.state.searchText,
        1,
        this.state.changedSortField || this.props.sortField,
        this.state.changedSortDirection,
        this.props.filterCondition
      );
    }
  };

  render() {
    const className = this.props.isNewVersion ? 'ui action input fluid' : 'ui action input';
    return (
      <div>
        <div className={className}>
          <input
            type="text"
            value={this.state.searchText}
            onChange={e => this.setState({ searchText: e.target.value })}
            onKeyUp={this.handleKeyUp}
            placeholder={this.placeholder}
          />
          <Button
            icon
            type="button"
            onClick={() =>
              this.doSearch(
                this.state.changedSearchText || this.state.searchText,
                1,
                this.state.changedSortField || this.props.sortField,
                this.state.changedSortDirection,
                this.props.filterCondition
              )
            }>
            <Icon name="search" />
          </Button>
        </div>
      </div>
    );
  }
}
