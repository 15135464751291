import SearchTextForm from '../SearchTextForm';
import { configConstants } from '../../../../../../config/constants';
import { Material } from '../../../../../interfaces/Material';
import { fetch } from '../../../../../../core/state/fetch';

export async function searchMaterials(
  searchText: string,
  page?: number,
  sortField?: string,
  sortDirection?: 'asc' | 'desc',
  filterCondition?: string
) {
  let url = configConstants.apiUrl + `materials?page=${page}&search_text=${searchText}`;

  if (filterCondition) {
    url = configConstants.apiUrl + `materials/${filterCondition}/unbooked?page=${page}&search_text=${searchText}`;
  }

  const response = await fetch(url).then(r => r.json());
  const entities: Material[] = response['hydra:member'] || [];
  const amount: number = response['hydra:totalItems'] || 1;
  return { entities, amount };
}

export default class SearchMaterialForm extends SearchTextForm<Material> {
  placeholder = 'Zoek materiaal';
  searchEntities = searchMaterials;
}
