import SearchTextForm from '../SearchTextForm';
import Course from '../../../../../interfaces/Course';
import { configConstants } from '../../../../../../config/constants';
import { fetch } from '../../../../../../core/state/fetch';

export async function searchCourses(
  searchText: string,
  page?: number,
  sortField?: string,
  sortDirection?: 'asc' | 'desc',
  filterCondition?: string
) {
  const url = `${configConstants.apiUrl}courses?active=1&search_text=${searchText}&page=${page}`;
  const response = await fetch(url).then(r => r.json());
  let entities: Course[] = response['hydra:member'] || [];

  // DIRTY QUICKFIX
  entities = entities.map((course: Course) => {
    return {
      ...course,
      createdAt: new Date(course.createdAt).toLocaleDateString("nl-BE", { day: '2-digit', month: '2-digit', year: 'numeric' })
    }
  });

  const amount: number = response['hydra:totalItems'] || 1;
  return { entities, amount };
}

export default class SearchCourseForm extends SearchTextForm<Course> {
  placeholder = 'Zoek pakket';
  searchEntities = searchCourses;
}
