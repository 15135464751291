import React, { Fragment } from 'react';
import { Table, Rating } from 'semantic-ui-react';
import DataTableActionButtons from './DataTableActionButtons';
import { _DeepReadonlyArray } from 'utility-types/dist/mapped-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface DataTableRowProps {
  rows: _DeepReadonlyArray<any>;
  fieldSet: string[];
  withActionButtons: boolean;
  onSelect: (key: number) => void;
  activeId?: number;
  onArchive: (key: number) => void;
  onEdit?: (key: number) => void;
}

const view = (cellType: string, row: any) => {
  switch (cellType) {
    case 'gradation':
    case 'routine':
      return <Rating icon="star" defaultRating={row[cellType]} maxRating={5} disabled />;
    case 'active':
      return row[cellType] ? <CheckOutlined /> : <CloseOutlined />;
    default:
      return row[cellType];
  }
};

const DataTableRow = ({ rows, fieldSet, withActionButtons, onSelect, activeId, onArchive, onEdit }: DataTableRowProps) => (
  <Fragment>
    {rows.map((row: any) => (
      <Table.Row key={row.id}>
        {fieldSet.map(fieldKey => (
          <Table.Cell key={`${fieldKey}-${row.id}`}>{view(fieldKey, row)}</Table.Cell>
        ))}
        {withActionButtons ? (
          <DataTableActionButtons id={row.id} activeId={activeId} onSelect={onSelect} onArchive={onArchive} onEdit={onEdit} />
        ) : null}
      </Table.Row>
    ))}
  </Fragment>
);

export default DataTableRow;
