import Entity, { EntityReference } from './Entity';
import TrainingSession from './TrainingSession';
import Course from './Course';
import Client from './Client';

// Deprecaded
export enum TrainingType {
  OpenKalender = 2,
  BedrijfsIntern = 1,
  OnderAanneming = 3
}

// Deprecaded 
export enum TrainingStatus {
  All = '',
  Archived = 'gearchiveerd',
  Ended = 'afgelopen',
  Confirmed = 'bevestigd',
  Active = 'actief',
  Unconfirmed = 'onbevestigd'
}

export type WithTrainingSessions<T = {}> = {
  trainingSessions: TrainingSession<T>[];
};

export type WithTrainingSessionsReference = {
  trainingSessions: EntityReference[];
};

export type WithCourseReference = {
  course: EntityReference | undefined;
};

export type WithCourse = {
  course: Course | undefined;
};

export type WithCustomerReference = {
  customer: EntityReference | undefined;
};

export type WithCustomer = {
  customer: Client;
};

export type Training<T = WithCourseReference & WithCustomerReference> = Entity &
  T & {
    id: number;
    type: TrainingType;
    customer: EntityReference | undefined;
    minParticipants: number;
    maxParticipants: number;
    participants: EntityReference[];
    status: TrainingStatus;
    firstSessionDate?: string;
    lastSessionDate?: string;
    trainingForm?: EntityReference;
    formToken?: string | undefined;
    createdAt: string | undefined;
    updatedAt: string | undefined;
    deletedAt?: string;
    trainingComplete: boolean;
    hasPredefinedTasks: boolean;
    discount?: number;
    trainingSessionType?: string;
    evaluationLink: string | undefined;
    firstMeasurementLink: string | undefined;
    secondMeasurementLink: string | undefined;
  };
