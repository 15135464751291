import React from 'react';
import { DbFile } from '../../interfaces/DbFile';
import { Icon, Loader } from 'semantic-ui-react';
import ProgressStatus from '../../interfaces/ProgressStatus';
import { filenameFormatter } from '../../../shared/helpers/fileHelpers';
import moment from 'moment';
import { Empty } from 'antd';
import { WithNamespaces, withNamespaces } from 'react-i18next';

function getIcon(mineType: string) {
  if ('application/pdf' === mineType) {
    return <Icon name="file pdf" />;
  }

  if (mineType.includes('image')) {
    return <Icon name="image" />;
  }

  return <Icon name="file" />;
}

interface Props {
  files: DbFile[];
  blockViewOn: boolean;
  uploadingStatus?: ProgressStatus;
  deleteFile?: (id: number, parentId: number) => void;
  componentId?: number;
}

const fileList = ({ files, blockViewOn, uploadingStatus, deleteFile, componentId, t }: Props & WithNamespaces) => (
  <>
    {files.map(f => (
      <div className={'col-12 m-b-lg ' + (blockViewOn ? 'col-md-4' : 'col-md-12')} key={f['@id']}>
        <div className="document">
          {getIcon(f.mimeType)}
          <a
            target="_blanc"
            href={f.accessUrl || undefined}
            className="file-list-item"
            style={{
              display: 'block',
              color: '#404040',
              fontWeight: 'bold'
            }}>
            <span>{filenameFormatter(f.originalFilename, 25, moment(f.createdAt).format('DD/MM/YY'))}</span>
          </a>
          {deleteFile && <Icon name="trash alternate outline" className="delete-document" onClick={() => deleteFile(f.id, componentId!)} />}
        </div>
      </div>
    ))}

    {!files.length ? (
      <div className="m-auto">
        <Empty description={<span>{t('noFiles')}</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    ) : null}

    {uploadingStatus === ProgressStatus.InProgress ? (
      <div className={'col-12 m-b-lg ' + (blockViewOn ? 'col-md-4' : 'col-md-12')}>
        <div className="document-temp">
          <Icon name="file" />
          <Loader active inline size="small" />
        </div>
      </div>
    ) : null}
  </>
);

export default withNamespaces(['common', 'resources'], { nsMode: 'fallback' })(fileList);
