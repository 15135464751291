import React, { Component } from 'react';
import { Button, Modal, Dropdown, Tab, Menu, Label, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { TrainingRequest, TrainingRequestStatus } from '../../../shared/interfaces/TrainingRequest';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../../shared/components/InitialLoader';
import NoDataFound from '../../../shared/components/NoDataFound';
import { RouteComponentProps, Link } from 'react-router-dom';
import PageHeader from '../../../shared/components/PageHeader';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { ConfirmActionParameters, ConfirmAction } from '../../../core/state/actions';
import { sendInfo } from './api';
import DetailFormField, { DetailFormFieldTypes } from '../../../shared/components/DetailForm/DetailFormField';
import { FormikValues } from 'formik';
import Client from '../../../shared/interfaces/Client';
import Course from '../../../shared/interfaces/Course';
import RequestStatusLabel from '../RequestStatusLabel';
import { RequestSessionTeaser } from '../../../shared/interfaces/RequestSessionTeaser';
import { GlobalState } from '../../../core/state';
import * as actions from '../../state/actions';
import { RequestDocument, WithRequestDocument } from '../../../shared/interfaces/RequestDocument';
import { Mail, WithTrainingRequestDocuments, WithDocumentReferences } from '../../../shared/interfaces/Mail';
import Mailer from '../../../shared/components/Mailer';
import { DetailViewContact } from './DetailViewContact';
import { DetailViewDocuments } from './DetailViewDocuments';
import DetailViewMails from './DetailViewMails';
import { Training } from '../../../shared/interfaces/Training';
import { infomailTemplate, templates, orderTemplate, offerteTemplate } from '../../../shared/components/Mailer/templates';
import AlertService from '../../../core/alert/AlertService';
import moment from 'moment';

type DetailViewProps = {
  toggleWizard?: Function;
  trainingRequest: TrainingRequest;
  sessions?: RequestSessionTeaser[];
  training?: Training;
  trainingRequestOrderDocument: string | undefined;
  trainingRequestOfferDocument: string | undefined;
  trainingRequestStatus: ProgressStatus;
  saveRequest: (request: TrainingRequest, redirectAfterSave: boolean) => void;
  reloadRequest: () => void;
  askConfirmation: (params: ConfirmActionParameters) => void;
  setRequestToWon: () => void;
  setRequestToLost: (TrainingRequest: TrainingRequest) => void;
  loadFiles: (trainingId: number) => void;
  loadMails: (trainingId: number) => void;
  loadFilesStatus: ProgressStatus;
  files: RequestDocument<WithRequestDocument>[] | undefined;
  mails: Mail<WithTrainingRequestDocuments>[] | undefined;
  addFileStatus: ProgressStatus;
  addFileToRequest: (requestId: number, file: File) => void;
  deleteRequestFile: (fileId: number, requestId: number) => void;
  selectedClient: Client;
  selectedCourse: Course | undefined;
  selectedRequest: TrainingRequest;
} & WithNamespaces &
  RouteComponentProps;

type DetailViewState = {
  wizardOpen: boolean;
  trainingRequest: TrainingRequest;
  isSendModalOpen: boolean;
  isUploadModalOpen: boolean;
  isLostModalOpen: boolean;
  selectedRequest: TrainingRequest;
};

class DetailView extends Component<DetailViewProps, DetailViewState> {
  constructor(props: DetailViewProps) {
    super(props);

    this.state = {
      wizardOpen: false,
      trainingRequest: this.props.trainingRequest,
      isSendModalOpen: false,
      isUploadModalOpen: false,
      isLostModalOpen: false,
      selectedRequest: this.props.selectedRequest
    };
  }

  componentDidMount() {
    const { loadFiles } = this.props;
    const { loadMails } = this.props;
    const requestId = this.props.trainingRequest.id;
    loadFiles(requestId);
    loadMails(requestId);
  }

  toggleWizard = () => {
    this.setState({ wizardOpen: !this.state.wizardOpen });
  };

  toggleSendMail = () => {
    if (this.props.selectedClient && this.props.selectedClient.email) {
      this.setState({ isSendModalOpen: !this.state.isSendModalOpen });
    } else {
      AlertService.setMessage({
        title: 'Geen ontvangend e-mailadres',
        messageText: 'De geselecteede klant heeft geen toegekend e-mailadres, gelieven dit aan te vullen om e-mails te versturen.',
        type: 'warning'
      });
    }
  };

  toggleLostModal = () => {
    this.setState({ isLostModalOpen: !this.state.isLostModalOpen });
  };

  render() {
    const {
      saveRequest,
      reloadRequest,
      trainingRequest,
      trainingRequestStatus,
      t,
      askConfirmation,
      trainingRequestOfferDocument,
      trainingRequestOrderDocument,
      deleteRequestFile
    } = this.props;

    const isFinalized =
      trainingRequest.status === TrainingRequestStatus.WonRequest || trainingRequest.status === TrainingRequestStatus.LostRequest;

    const panes = [
      {
        menuItem: `${t('info')}`,
        render: () => (
          <Tab.Pane attached={false}>
            <DetailViewContact
              {...this.props}
              selectedRequest={this.state.selectedRequest}
              toggleWizard={this.toggleWizard}
              isFinalized={isFinalized}
            />
          </Tab.Pane>
        )
      },

      {
        menuItem: (
          <Menu.Item name="documents" key="documents">
            <span className="tab-title">{t('documents')}</span>
            <Label circular size={'tiny'} className="documents-count">
              {this.props.files ? `${this.props.files.length}` : ''}
            </Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false}>
            <DetailViewDocuments {...this.props} files={this.props.files} deleteRequestFile={deleteRequestFile} />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item name="mails" key="mails">
            <span className="tab-title">{t('emails')}</span>
            <Label circular size={'tiny'} className="mails-count">
              {this.props.mails ? `${this.props.mails.length}` : ''}
            </Label>
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false}>
            <DetailViewMails {...this.props} mails={this.props.mails ? this.props.mails : []} />
          </Tab.Pane>
        )
      }
    ];

    switch (trainingRequestStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        return (
          <>
            <PageHeader
              {...this.props}
              breadCrumbParts={[t('requests'), trainingRequest.firstName + ' ' + trainingRequest.lastName]}
              title={
                <>
                  {t('moduleTitle')}
                  {trainingRequest.deletedAt ? (
                    <span className="archived"> (Gearchiveerd op {moment(trainingRequest.deletedAt).format('DD/MM/YY')})</span>
                  ) : null}
                </>
              }
              rightBlock={
                <Button onClick={() => this.props.history.push(`/requests/${trainingRequest.id}/edit`)} className="primary">
                  {t('edit')}
                </Button>
              }
            />
            <div className="request-detail">
              {this.state.wizardOpen ? (
                <></>
              ) : (
                /*<ProposalWizard
                  request={trainingRequest}
                  saveRequest={request => saveRequest(request, false)}
                  askConfirmation={askConfirmation}
                  goBack={() => {
                    this.toggleWizard();
                    reloadRequest();
                  }}
                />*/
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-6">
                        <h2>
                          {trainingRequest.firstName + ' ' + trainingRequest.lastName}

                          <span style={{ position: 'relative', bottom: '5px', marginLeft: '10px' }}>
                            <RequestStatusLabel status={trainingRequest.status} as="span" ribbon={false} />
                          </span>
                        </h2>
                        <span className="subtitle">{trainingRequest.email}</span>
                      </div>
                      <div className="col-6 col-md-7 col-lg-6">
                        <Button icon="envelope" onClick={this.toggleSendMail} />
                        <a download href={trainingRequestOrderDocument}>
                          <Button>
                            <Icon name="arrow alternate circle down"></Icon>
                            {t('generateOrder')}
                          </Button>
                        </a>
                        <a download href={trainingRequestOfferDocument}>
                          <Button>
                            <Icon name="arrow alternate circle down"></Icon>
                            {t('generateOffer')}
                          </Button>
                        </a>
                        <Button
                          className="float-right"
                          disabled={isFinalized}
                          onClick={this.toggleLostModal}
                          color="red"
                          style={{ marginright: '2em' }}>
                          {t('lost')}
                        </Button>
                        <Modal open={this.state.isLostModalOpen} closeIcon onClose={this.toggleLostModal}>
                          <Modal.Header>{t('setRequestToLost')}</Modal.Header>
                          <Modal.Content>
                            <Modal.Description>
                              <p>{t('confirmSetRequestToLost')}</p>
                              <DetailFormField
                                onChange={(data: FormikValues) =>
                                  this.setState({
                                    trainingRequest: {
                                      ...trainingRequest,
                                      cancellationReason: data.target.value
                                    }
                                  })
                                }
                                value={this.state.trainingRequest.cancellationReason}
                                type={DetailFormFieldTypes.editor}
                                propertyName="cancellationReason"
                                label={t('cancellationReason')}
                                toolbar={['bold', 'italic', 'clean']}
                                orderNr={0}
                                onBlur={() => {}}
                                selectedEntity={trainingRequest}
                              />
                            </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button onClick={this.toggleSendMail}>{t('close')}</Button>
                            <Button
                              onClick={() => {
                                this.props.setRequestToLost(this.state.trainingRequest);
                                this.toggleLostModal();
                              }}
                              positive>
                              {t('setAsLost')}
                            </Button>
                          </Modal.Actions>
                        </Modal>
                        <Button className="float-right" disabled={isFinalized} onClick={() => this.props.setRequestToWon()} color="green">
                          {t('won')}
                        </Button>
                        <Mailer
                          recipients={this.props.trainingRequest.email}
                          isOpen={this.state.isSendModalOpen}
                          closeModal={this.toggleSendMail}
                          entityReference={this.props.trainingRequest['@id']}
                          files={(this.props.files || []).map(f => {
                            return { key: f['@id'], file: f.trainingRequestDocument };
                          })}
                          templates={[
                            {
                              key: templates.infomail,
                              body: infomailTemplate(this.props.trainingRequest, this.props.selectedCourse)
                            },
                            {
                              key: templates.confirm_planning,
                              body: orderTemplate(this.props.trainingRequest)
                            },
                            {
                              key: templates.ic_order,
                              body: offerteTemplate(this.props.trainingRequest, this.props.selectedCourse)
                            }
                          ]}
                          submit={(Mail: Mail<WithDocumentReferences>) => {
                            this.setState({
                              isSendModalOpen: !this.state.isSendModalOpen
                            });

                            sendInfo(Mail).then(() => this.props.loadMails(this.props.trainingRequest.id));
                          }}
                        />
                      </div>
                    </div>
                    {TrainingRequestStatus.LostRequest === trainingRequest.status ? (
                      <div className="row">
                        {trainingRequest.cancellationReason ? (
                          <div
                            className="col-12 offset-md-2 col-md-8 cancellation-reason raw-html"
                            dangerouslySetInnerHTML={{ __html: trainingRequest.cancellationReason }}
                          />
                        ) : (
                          <div className="col-12 offset-md-2 col-md-8 cancellation-reason">
                            {t('training-request.no-lost-reason-given')}
                          </div>
                        )}
                      </div>
                    ) : null}

                    {TrainingRequestStatus.WonRequest === trainingRequest.status && trainingRequest.designatedTraining ? (
                      <div className="row">
                        <div className="col-12 offset-md-2 col-md-8 d-flex justify-content-center">
                          <Link to={`${trainingRequest.designatedTraining}`}>Ga naar de Opleiding</Link>
                        </div>
                      </div>
                    ) : null}

                    <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
                  </div>
                </div>
              )}
            </div>
          </>
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    files: state.requests.selectedRequestFiles as RequestDocument<WithRequestDocument>[] | undefined,
    mails: state.requests.mails as Mail<WithTrainingRequestDocuments>[],
    loadFilesStatus: state.requests.loadFilesStatus,
    addFileStatus: state.requests.addFileToRequestStatus,
    selectedCourse: state.requests.selectedCourse as Course,
    selectedClient: state.requests.selectedClient as Client,
    selectedRequest: state.requests.selectedTrainingRequest as TrainingRequest
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadFiles: (requestId: number) => dispatch(actions.FetchRequestFiles.request(requestId)),
  loadMails: (requestId: number) => dispatch(actions.FetchRequestMails.request(requestId)),
  addFileToRequest: (requestId: number, file: File) => dispatch(actions.AddFileToRequest.request({ requestId, file })),

  deleteRequestFile: (fileId: number, requestId: number) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteFile',
        content: 'confirmDeleteFile',
        action: () => dispatch(actions.DeleteRequestFile.request({ fileId, requestId }))
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withNamespaces(['requests', 'menu', 'common', 'resources'], {
    nsMode: 'fallback'
  })(DetailView)
);
