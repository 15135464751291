import React, { SyntheticEvent } from 'react';
import { TextArea } from 'semantic-ui-react';
interface MultiLineTextInputProps {
  onBlur: any;
  onChange: (e: SyntheticEvent) => void;
  value: any;
  label: string;
  keyField: string;
  placeholder?: string;
  disabled?: boolean;
}

const MultiLineTextInput = ({ onChange, value, label, keyField, placeholder, disabled }: MultiLineTextInputProps) => {
  return <TextArea disabled={disabled} name={keyField} onChange={onChange} value={value} rows={5} style={{ resize: 'none' }} />;
};

export default MultiLineTextInput;
