import React, { Fragment } from 'react';
import { Button, Tab, Menu, Label } from 'semantic-ui-react';
import Client from '../../../shared/interfaces/Client';
import Contact from '../../../shared/interfaces/Contact';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import PageHeader from '../../../shared/components/PageHeader';
import ContactsSection from './components/ContactsSection';
import LocationsSection from './components/LocationsSection';
import { CourseLocation } from '../../../shared/interfaces/CourseLocation';
import { GQLTraining, GQLRequest } from '../../state/state';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { TrainingsSection } from './components/TrainingsSection';
import { RouteComponentProps } from 'react-router';
import BasicInfoSection from './components/BasicInfoSection';
import { GlobalState } from '../../../core/state';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RequestsSection } from './components/RequestsSection';
import { useAuth0 } from '../../../core/auth/AuthWrapper';
import Language from '../../../shared/interfaces/Language';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getMaterialModuleStatus } from '../../../core/state/selectors';

type DetailViewProps = {
  loadingTrainingsStatus: ProgressStatus;
  loadingRequestsStatus: ProgressStatus;
  client: Client;
  clientContacts: Contact[];
  locations: CourseLocation[];
  trainings: GQLTraining[] | undefined;
  requests: GQLRequest[] | undefined;
  unlinkLocation: (id: number) => void;
  onEditBtnClick: () => void;
  languages: Language[];
  hasRequestModule: boolean;
} & WithNamespaces &
  RouteComponentProps;

const DetailView = (props: DetailViewProps) => {
  const { client, languages, clientContacts, onEditBtnClick, t, locations, unlinkLocation } = props;
  const { hasAdminAccess } = useAuth0();

  const userPanes = [
    {
      menuItem: t('basic'),
      render: () => (
        <Tab.Pane attached={false}>
          <BasicInfoSection client={client} languages={languages} />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        <Menu.Item name="contacts" key="contacts">
          <span className="tab-title">{t('contacts')}</span>
          {props.clientContacts ? (
            <Label circular size={'tiny'} className="counter">
              {props.clientContacts.length}
            </Label>
          ) : null}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <ContactsSection contacts={clientContacts} client={client} />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        <Menu.Item name="trainingLocations" key="trainingLocations">
          <span className="tab-title">{t('trainingLocations')}</span>

          {props.locations ? (
            <Label circular size={'tiny'} className="counter">
              {props.locations.length}
            </Label>
          ) : null}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <LocationsSection locations={locations} client={client} unlinkLocation={unlinkLocation} />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        <Menu.Item name="trainings" key="trainings">
          <span className="tab-title">{t('trainings')}</span>
          {props.trainings ? (
            <Label circular size={'tiny'} className="counter">
              {props.trainings.length}
            </Label>
          ) : null}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <TrainingsSection {...props} trainings={props.trainings} loadingTrainingsStatus={props.loadingTrainingsStatus} />
        </Tab.Pane>
      )
    }
  ];

  const requestModulePane = [
    {
      menuItem: (
        <Menu.Item name="requests" key="requests">
          <span className="tab-title">{t('requests')}</span>
          {props.requests ? (
            <Label circular size={'tiny'} className="counter">
              {props.requests.length}
            </Label>
          ) : null}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane attached={false}>
          <RequestsSection {...props} requests={props.requests} loadingRequestsStatus={props.loadingRequestsStatus} />
        </Tab.Pane>
      )
    }
  ];

  let panes = [...userPanes];

  if (props.hasRequestModule) {
    panes = [...requestModulePane];
  }

  return (
    <Fragment>
      <PageHeader
        {...props}
        breadCrumbParts={[t('clients'), client.companyName]}
        title={
          <>
            {client.companyName}
            {client.deletedAt ? <span className="archived"> (Gearchiveerd op {moment(client.deletedAt).format('DD/MM/YY')})</span> : null}
          </>
        }
      />
      <div className="row">
        <div className="col-12">
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    hasRequestModule: getMaterialModuleStatus(state.app)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withNamespaces(['clients', 'resources', 'common', 'menu'], {
    nsMode: 'fallback'
  })(DetailView)
);
