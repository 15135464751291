import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import Language from '../../shared/interfaces/Language';
import { FormDefinition } from '../../shared/interfaces/FormFieldDefinition';
import { courseActiveStates } from './constants';
import { enumToOption } from '../../shared/helpers/dropDownHelpers';

export const ShowInActive = () =>
  [
    {
      propertyName: 'active',
      label: 'active',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 40,
      dropDownOptions: enumToOption(courseActiveStates)
    }
  ] as FormDefinition;
