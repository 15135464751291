import React, { SyntheticEvent } from 'react';
import { Confirm } from 'semantic-ui-react';

interface ConfirmDialogProps {
  open: boolean;
  onClose: (e: SyntheticEvent) => void;
  onCancel: (e: SyntheticEvent) => void;
  onConfirm: (e: SyntheticEvent) => void;
  content: string;
  header: string;
}

const ConfirmDialog = ({ open, onClose, onCancel, onConfirm, content, header }: ConfirmDialogProps) => {
  return (
    <Confirm
      open={open}
      closeOnEscape
      closeOnDimmerClick
      header={header}
      onClose={onClose}
      content={content}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};
// "Weet je zeker dat je deze trainer wil archiveren?"
export default ConfirmDialog;
