export const toTrainingSessionQuery = (fields: string[]) => {
  let query = '';
  fields.forEach(field => {
    if (field === 'training') {
      query += `training {
          _id,
          course {
            _id,
            title
          }
      } `;
    }
    if (field === 'location') {
      query += `location {
           id,
           name,
           displayColor,
           deletedAt,
        } `;
    }
    if (field === 'trainer') {
      query += `trainer {
          id,
          firstName,
          lastName,
          email,
          deletedAt
        } `;
    }
  });

  return query;
};
