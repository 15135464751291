import { getTokenSilently } from '../auth/AuthWrapper';

export async function fetch(input: RequestInfo, init: RequestInit | undefined = {}, skipAuth = false) {
  const userHeaders = init ? init.headers || {} : {};
  const token = await getTokenSilently();
  const initWithAuth = {
    ...init,
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      ...userHeaders
    })
  };
  const response = await window.fetch(input, initWithAuth);

  if (!response.ok) {
    throw response;
  }

  return response;
}
