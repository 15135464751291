import React from 'react';

interface HiddenProps {
  value: any;
}

const Hidden = ({ value }: HiddenProps) => {
  return <input type="hidden" value={value} />;
};

export default Hidden;
