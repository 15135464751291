import React from 'react';
import Entity from '../../../interfaces/Entity';

interface SearchEntityFormProps {
  onResult: (result: { entities: Entity[]; amount: number }) => void;
  parentEntity: Entity;
  isNewVersion?: boolean;
  page?: number;
  sortField?: string;
  sortDirection?: 'ascending' | 'descending';
  filterCondition?: string;
}

export default abstract class SearchEntityForm<P = {}, S = {}> extends React.Component<SearchEntityFormProps & P, S> {}
