import { configConstants } from '../../../config/constants';
import { CourseLocation } from '../../../shared/interfaces/CourseLocation';
import { fetch } from '../../../core/state/fetch';

export default function saveLocation(location: CourseLocation): Promise<CourseLocation> {
  const isNew = location.id === 0;
  const data = {
    method: isNew ? 'POST' : 'PUT',
    body: JSON.stringify({
      ...location,
      id: isNew ? undefined : location.id,
      '@id': isNew ? undefined : location['@id']
    }),
    headers: { 'Content-Type': 'application/json' }
  };
  return fetch(configConstants.apiUrl + 'locations' + (isNew ? '' : '/' + location.id), data).then(r => r.json());
}
