import React from 'react';
import * as Yup from 'yup';
import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormDefinition, FormFieldPosition } from '../../shared/interfaces/FormFieldDefinition';
import { arrayToOption } from '../../shared/helpers/dropDownHelpers';
import { countryCodes } from '../../shared/constants';
import Entity from '../../shared/interfaces/Entity';
import SearchClientForm from '../../shared/components/DetailForm/EntitySelector/SelectForms/SearchClientForm';
import Client from '../../shared/interfaces/Client';
import { emailValidation } from '../../shared/helpers/emailValidation';
import { phoneValidation } from '../../shared/helpers/phoneValidation';

export const formDefinition: FormDefinition = [
  {
    propertyName: 'id',
    label: 'Id',
    type: DetailFormFieldTypes.hidden,
    orderNr: 1
  },
  {
    propertyName: 'name',
    label: 'Naam',
    type: DetailFormFieldTypes.text,
    orderNr: 10,
    validationChecks: Yup.string().required('Naam moet ingevuld worden.'),
    required: true
  },
  {
    propertyName: 'street',
    label: 'Straat',
    type: DetailFormFieldTypes.text,
    orderNr: 20,
    validationChecks: Yup.string().required('Straat moet ingevuld worden.'),
    required: true
  },
  {
    propertyName: 'houseNr',
    label: 'Huisnummer',
    type: DetailFormFieldTypes.text,
    orderNr: 30,
    validationChecks: Yup.string().required('Huisnummer moet ingevuld worden.'),
    required: true
  },
  {
    propertyName: 'bus',
    label: 'Bus',
    type: DetailFormFieldTypes.text,
    orderNr: 40
  },
  {
    propertyName: 'postalCode',
    label: 'Postcode',
    type: DetailFormFieldTypes.text,
    orderNr: 50,
    validationChecks: Yup.string().required('Postcode moet ingevuld worden.'),
    required: true
  },
  {
    propertyName: 'city',
    label: 'Plaats',
    type: DetailFormFieldTypes.text,
    orderNr: 60,
    validationChecks: Yup.string().required('Plaats moet ingevuld worden.'),
    required: true
  },
  {
    propertyName: 'country',
    label: 'Land',
    type: DetailFormFieldTypes.dropdownlist,
    orderNr: 70,
    validationChecks: Yup.string().required('Land moet ingevuld worden.'),
    required: true,
    dropDownOptions: arrayToOption(countryCodes)
  },
  {
    propertyName: 'rate',
    label: 'Tarief',
    type: DetailFormFieldTypes.textbox,
    orderNr: 110
  },
  {
    propertyName: 'travelDirections',
    label: 'Routebeschrijving',
    type: DetailFormFieldTypes.textbox,
    orderNr: 120
  },
  {
    propertyName: 'displayColor',
    label: 'Kleur',
    type: DetailFormFieldTypes.color,
    orderNr: 130,
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'isInternalLocation',
    label: 'Interne Locatie',
    type: DetailFormFieldTypes.checkbox,
    orderNr: 195,
    validationChecks: Yup.boolean().required('Interne locatie moet een waarde hebben'),
    required: false,
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'remarks',
    label: 'Opmerkingen',
    type: DetailFormFieldTypes.textbox,
    orderNr: 150
  },
  {
    propertyName: 'contactPersonFirstName',
    label: 'Voornaam van contactpersoon',
    type: DetailFormFieldTypes.text,
    orderNr: 160
  },
  {
    propertyName: 'contactPersonLastName',
    label: 'Naam van contactpersoon',
    type: DetailFormFieldTypes.text,
    orderNr: 170
  },
  {
    propertyName: 'contactPersonEmail',
    label: 'E-mailadres van contactpersoon',
    type: DetailFormFieldTypes.email,
    validationChecks: Yup.string().test('email', 'Dit email is niet geldig', emailValidation),
    orderNr: 180
  },
  {
    propertyName: 'contactPersonPhoneNr',
    label: 'Telefoonnummer van contactpersoon',
    type: DetailFormFieldTypes.telephone,
    validationChecks: Yup.string().test('phone', 'Dit telefoonnummer is niet geldig', phoneValidation),
    orderNr: 190
  },
  {
    propertyName: 'hasInternet',
    label: 'Internet op locatie',
    type: DetailFormFieldTypes.checkbox,
    orderNr: 200,
    validationChecks: Yup.boolean().required('Internet op locatie moet een waarde hebben'),
    required: false,
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'hasBeamer',
    label: 'Beamer op locatie',
    type: DetailFormFieldTypes.checkbox,
    orderNr: 220,
    validationChecks: Yup.boolean().required('Beamer aanwezig op locatie moet een waarde hebben'),
    required: false,
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'hasDrinks',
    label: 'Drank op locatie',
    type: DetailFormFieldTypes.checkbox,
    orderNr: 230,
    validationChecks: Yup.boolean().required('Drank op locatie moet een waarde hebben'),
    required: false,
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'customer',
    label: 'Klant',
    required: false,
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 80,
    multiple: false,
    renderEntity: (course: Entity) => <span key={course['@id']}>{(course as Client).companyName}</span>,
    isNewVersion: true,
    dataTableFields: [{ key: 'companyName' }],
    searchEntityForm: SearchClientForm
  }
];

export const SimpleFormLocationDefinition = () =>
  [
    {
      propertyName: 'id',
      label: 'Id',
      type: DetailFormFieldTypes.hidden,
      orderNr: 1
    },
    {
      propertyName: 'name',
      label: 'Naam',
      type: DetailFormFieldTypes.text,
      orderNr: 10,
      validationChecks: Yup.string().required('Naam moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'street',
      label: 'Straat',
      type: DetailFormFieldTypes.text,
      orderNr: 20,
      validationChecks: Yup.string().required('Straat moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'houseNr',
      label: 'Huisnummer',
      type: DetailFormFieldTypes.text,
      orderNr: 30,
      validationChecks: Yup.string().required('Huisnummer moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'bus',
      label: 'Bus',
      type: DetailFormFieldTypes.text,
      orderNr: 40
    },
    {
      propertyName: 'postalCode',
      label: 'Postcode',
      type: DetailFormFieldTypes.text,
      orderNr: 50,
      validationChecks: Yup.string().required('Postcode moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'city',
      label: 'Plaats',
      type: DetailFormFieldTypes.text,
      orderNr: 60,
      validationChecks: Yup.string().required('Plaats moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'country',
      label: 'Land',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 70,
      validationChecks: Yup.string().required('Land moet ingevuld worden.'),
      required: true,
      dropDownOptions: arrayToOption(countryCodes)
    },
    {
      propertyName: 'displayColor',
      label: 'Kleur',
      type: DetailFormFieldTypes.color,
      orderNr: 130,
      column: FormFieldPosition.Right
    },
    {
      propertyName: 'customer',
      label: 'Klant',
      required: false,
      type: DetailFormFieldTypes.entitySelector,
      orderNr: 80,
      multiple: false,
      renderEntity: (course: Entity) => <span key={course['@id']}>{(course as Client).companyName}</span>,
      isNewVersion: true,
      dataTableFields: [{ key: 'companyName' }],
      searchEntityForm: SearchClientForm
    }
  ] as FormDefinition;
