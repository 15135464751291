export const toLocationQuery = (cursor: string) => {
  return `{
      locations(first: 500 ${cursor ? `after: "${cursor}"` : ''}) {
        edges {
          node {
            _id
            name
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }`;
};
