import React from 'react';
import * as Yup from 'yup';
import { FormFieldDefinition } from '../../shared/interfaces/FormFieldDefinition';
import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import Entity from '../../shared/interfaces/Entity';
import { Trainer } from '../../shared/interfaces/Trainer';
import { CourseLocation } from '../../shared/interfaces/CourseLocation';
import SearchTrainerForm from '../../shared/components/DetailForm/EntitySelector/SelectForms/SearchTrainerForm';
import SearchLocationForm from '../../shared/components/DetailForm/EntitySelector/SelectForms/SearchLocationForm';

export const fields: Array<FormFieldDefinition> = [
  {
    propertyName: 'startDate',
    label: 'Start datum',
    type: DetailFormFieldTypes.datePicker,
    orderNr: 10,
    validationChecks: Yup.string().required(),
    required: true
  },
  {
    propertyName: 'endDate',
    label: 'Eind datum',
    type: DetailFormFieldTypes.datePicker,
    orderNr: 20,
    validationChecks: Yup.string().required(),
    required: true
  },
  {
    propertyName: 'isDaytimeSession',
    label: 'Tijdens daguren',
    type: DetailFormFieldTypes.checkbox,
    orderNr: 30,
    validationChecks: Yup.string().required(),
    required: true
  },

  {
    propertyName: 'training',
    label: 'Training',
    type: DetailFormFieldTypes.hidden,
    orderNr: 40
  },
  {
    propertyName: 'trainer',
    required: true,
    label: 'Trainer',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 50,
    validationChecks: Yup.string().required('Er moet een trainer gekozen worden.'),
    multiple: false,
    renderEntity: (entity: Entity) => {
      const trainer = entity as Trainer;
      return <span key={trainer['@id']}>{trainer.fullName}</span>;
    },
    searchEntityForm: SearchTrainerForm,
    isNewVersion: true,
    dataTableFields: [{ key: 'fullName' }, { key: 'competencies' }]
  },
  {
    propertyName: 'location',
    required: true,
    label: 'Locatie',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 60,
    multiple: false,
    renderEntity: (entity: Entity) => {
      const location = entity as CourseLocation;
      return (
        <span key={location['@id']}>
          {location.name}
          <br />
          {location.street} {location.houseNr} {location.bus}
          <br />
          {location.postalCode} {location.city}
          <br />
          {location.country}
        </span>
      );
    },
    searchEntityForm: SearchLocationForm,
    isNewVersion: true,
    dataTableFields: [{ key: 'name' }]
  },
  {
    propertyName: 'courseLink',
    label: 'Link naar de online cursus',
    type: DetailFormFieldTypes.uri,
    orderNr: 70,
  }
];
