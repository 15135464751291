import { configConstants } from '../../../../../../config/constants';
import { CourseLocation } from '../../../../../interfaces/CourseLocation';
import SearchTextForm from '../SearchTextForm';
import { fetch } from '../../../../../../core/state/fetch';

export async function searchLocations(
  searchText: string,
  page?: number,
  sortField?: string,
  sortDirection?: 'asc' | 'desc',
  filterCondition?: string
) {
  const url = `${configConstants.apiUrl}locations?search_text=${searchText}&page=${page}`;
  const response = await fetch(url).then(r => r.json());
  const entities: CourseLocation[] = response['hydra:member'] || [];
  const amount: number = response['hydra:totalItems'] || 1;
  return { entities, amount };
}

export default class SearchLocationForm extends SearchTextForm<CourseLocation> {
  placeholder = 'Zoek locatie';
  searchEntities = searchLocations;
}
