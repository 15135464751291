import CourseCategory, { WithParent } from '../../shared/interfaces/CourseCategory';
import { createAsyncAction } from 'typesafe-actions';
import QueryParams from '../../shared/interfaces/QueryParams';

export const SaveCategory = createAsyncAction(
  '[Categories] Saving category...',
  '[Categories] Saving category Success',
  '[Categories] Saving category Fail'
)<{ courseCategory: CourseCategory; noredirect?: boolean }, CourseCategory<WithParent>, { errorMessage: string }>();

export const FetchCategories = createAsyncAction(
  '[Requests] Fetch Categories...',
  '[Requests] Fetch Categories Success',
  '[Requests] Fetch Categories Fail'
)<
  QueryParams,
  {
    categories: CourseCategory[];
  },
  { errorMessage: string }
>();

export const FetchOneCategory = createAsyncAction(
  '[Categories] Fetch One Category...',
  '[Categories] Fetch One Category Success',
  '[Categories] Fetch One Category Fail'
)<{ id: number }, { category: CourseCategory<WithParent> }, { errorMessage: string }>();

export const DeleteCategory = createAsyncAction(
  '[Categories] Delete Category...',
  '[Categories] Delete Category Success',
  '[Categories] Delete Category Fail'
)<{ categoryId: number; queryParams: QueryParams }, void, { errorMessage: string }>();
