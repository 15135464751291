import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormFieldDefinition } from '../../shared/interfaces/FormFieldDefinition';
import { arrayToOption, enumToOption } from '../../shared/helpers/dropDownHelpers';
import { TrainingType, TrainingStatus } from '../../shared/interfaces/Training';

enum SearchTrainingTypes {
  All = '',
  OpenKalender = 2,
  BedrijfsIntern = 1,
}

enum SearchTrainingStatus {
  All = '',
  Archived = 'gearchiveerd',
}

export const searchColumnFields: Array<FormFieldDefinition> = [
  {
    propertyName: 'type',
    label: 'Type opleiding',
    type: DetailFormFieldTypes.dropdownlist,
    orderNr: 5,
    dropDownOptions: enumToOption(SearchTrainingTypes)
  },
  {
    propertyName: 'status',
    label: 'Status',
    type: DetailFormFieldTypes.dropdownlist,
    orderNr: 5,
    dropDownOptions: enumToOption(SearchTrainingStatus)
  }
];
