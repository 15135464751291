import React from 'react';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import { newTrainingRequest } from '../../helpers/constants';
import PageHeader from '../../../shared/components/PageHeader';
import { RouteComponentProps } from 'react-router';
import { translate, WithNamespaces } from 'react-i18next';
import { formDefinition } from '../../helpers/formDefinitions';
import { connect } from 'react-redux';
import { TrainingRequest } from '../../../shared/interfaces/TrainingRequest';
import { TrainingRequestsAction } from '../../state/state';
import { Dispatch } from 'redux';
import * as actions from '../../state/actions';

type Props = RouteComponentProps &
  WithNamespaces & {
    saveTrainingRequest: (request: TrainingRequest) => void;
  };

function DetailCreate(props: Props) {
  const { t, saveTrainingRequest } = props;
  return (
    <>
      <PageHeader {...props} breadCrumbParts={[t('requests'), t('new')]} title={t('createRequest')} />
      <DetailForm
        selectedEntity={newTrainingRequest}
        formDefinition={formDefinition}
        onFormSubmit={values => saveTrainingRequest(values as TrainingRequest)}
      />
    </>
  );
}

const mapDispatchProps = (dispatch: Dispatch<TrainingRequestsAction>) => ({
  saveTrainingRequest: (request: TrainingRequest) =>
    dispatch(actions.SaveTrainingRequest.request({ trainingRequest: request, redirectAfterSave: true }))
});

export default connect(undefined, mapDispatchProps)(translate(['requests', 'resources', 'menu'], { nsMode: 'fallback' })(DetailCreate));
