import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { TrainersActionTypes } from './actions';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router';
import { ApiConverter } from '../helpers/apiConverter';
import { configConstants } from '../../config/constants';
import { Trainer, WithCompetencies, WithLanguages } from '../../shared/interfaces/Trainer';
import queryString from 'query-string';
import { TrainerTrainingResponse } from '../../shared/interfaces/TrainerTrainingResponse';
import { fetch } from '../../core/state/fetch';
import Course from '../../shared/interfaces/Course';
import AlertService from '../../core/alert/AlertService';
import { WithCourse } from '../../shared/interfaces/Training';

export type TrainersAction = ActionType<typeof actions>;

export function* loadTrainers(action: AnyAction) {
  try {
    const { sort, order } = action.payload;
    delete action.payload.sort;
    delete action.payload.order;

    if (action.payload.course) {
      const course = action.payload.course;
      delete action.payload.course;
      action.payload = { ...action.payload, 'courses.id': course };
    }

    const showActive = action.payload.active;
    delete action.payload.active;

    let query = queryString.stringify(action.payload);
    query += sort ? `&order[${sort}]=` + (order || 'asc') : '&order[firstName]=asc';

    if (!showActive) {
      query += '&active=1';
    }

    if (showActive === 'false') {
      query += '&active=0';
    }

    const response = yield call(fetch, `${configConstants.apiUrl}trainers?${query}&embed[]=course&embed[]=competency`);
    const responseCourses = yield call(fetch, `${configConstants.apiUrl}courses?pagination=false&order[title]=asc`);

    if (response.status >= 200 && response.status < 300 && responseCourses.status >= 200 && responseCourses.status < 300) {
      const trainersRawData = yield response.json();
      const trainersData = yield trainersRawData['hydra:member'].map((trainer: Trainer<WithCompetencies>) =>
        ApiConverter.mapFromApi(trainer)
      );
      const dataCourses = yield responseCourses.json();
      const courses = dataCourses['hydra:member'] as Course[];
      yield put(
        actions.FetchTrainersSuccess({
          trainers: trainersData,
          totalTrainers: trainersRawData['hydra:totalItems'],
          courses
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchTrainersFail({ errorMessage: error }));
  }
}

export function* loadOneTrainer(action: AnyAction) {
  try {
    const trainerId = action.payload.trainerId as number;
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}trainers/${action.payload.trainerId}?embed[]=language&embed[]=competency&embed[]=course`
    );
    if (response.status >= 200 && response.status < 300) {
      const trainersWithLangAndCourse = yield response.json() as Trainer<WithLanguages & WithCompetencies & WithCourse>;
      const courses = trainersWithLangAndCourse.courses as Course[];
      const trainerWithLang = { ...trainersWithLangAndCourse, courses: courses.map(course => course['@id']) };

      yield put(actions.FetchTrainerCoursesSuccess({ courses: courses }));
      yield put(actions.FetchTrainerTrainings({ trainerId }));
      yield put(
        actions.FetchOneTrainerSuccess({
          trainer: (ApiConverter.mapFromApi(trainerWithLang) as unknown) as Trainer<WithLanguages & WithCompetencies>
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchOneTrainerFail({ errorMessage: error }));
  }
}

// TODO deprecated
export function* loadTrainerCourses(action: AnyAction) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl + 'trainers/' + action.payload.trainerId}/courses`, {
      headers: new Headers({
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    });
    if (response.status >= 200 && response.status < 300) {
      //const data = yield response.json();
      //yield put(actions.FetchTrainerCoursesSuccess({ courses: data['hydra:member'] }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchTrainerCoursesFail({ errorMessage: error }));
  }
}

export function* loadTrainerTrainings(action: AnyAction) {
  try {
    const query = `
    {trainingSessions(trainer: "/trainers/${action.payload.trainerId}") { 
      edges { 
        node { 
          startDate
          training {
            deletedAt,
            type,
             _id,
             status,
             course { title } 
          }
        }
      }
    }
  }`;

    const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);

    if (response.status >= 200 && response.status < 300) {
      const data: TrainerTrainingResponse = yield response.json();

      const sessions = data.data.trainingSessions.edges.map(val => val.node);

      yield put(actions.FetchTrainerTrainingsSuccess({ trainings: sessions }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchTrainerTrainingsFail({ errorMessage: error }));
  }
}

export function* saveTrainer(action: AnyAction) {
  try {
    const { trainer } = action.payload;

    if (!trainer.birthDate) {
      trainer.birthDate = null;
    }
    const response = yield call(
      fetch,
      trainer.id === 0 ? `${configConstants.apiUrl}trainers` : `${configConstants.apiUrl}trainers/${trainer.id}`,
      {
        method: trainer.id === 0 ? 'POST' : 'PUT',
        body: JSON.stringify(trainer),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (response.status >= 200 && response.status < 300) {
      const result = yield response.json() as Trainer;
      yield put(actions.SaveTrainerSuccess());
      yield put(actions.FetchOneTrainer({ trainerId: result.id }));

      AlertService.setMessage({
        title: 'Trainer opgeslagen',
        messageText: 'De trainer is succesvol opgeslagen.',
        type: 'success'
      });

      yield put(actions.FetchOneTrainer({ trainerId: trainer.id }));

      if (!action.payload.noredirect) {
        if (trainer.id === 0) {
          yield put(push(`/trainers/`));
        } else {
          yield put(push(`/trainers/${trainer.id}`));
        }
      }
    } else {
      AlertService.setMessage({
        title: 'Trainer niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
        type: 'error'
      });

      throw response;
    }
  } catch (error) {
    yield put(actions.SaveTrainerFail({ errorMessage: error }));

    AlertService.setMessage({
      title: 'Trainer niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
      type: 'error'
    });
  }
}

function* deleteTrainer(action: ReturnType<typeof actions.DeleteTrainer.request>) {
  try {
    const url = `trainers/${action.payload.trainerId}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });
    yield put(actions.FetchTrainers(action.payload.queryParams));

    AlertService.setMessage({
      title: 'Trainer verwijderd',
      messageText: 'De trainer is succesvol verwijderd.',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.DeleteTrainer.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Trainer niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
      type: 'error'
    });
  }
}

export const trainersSaga = [
  takeLatest(TrainersActionTypes.FetchTrainers, loadTrainers),
  takeLatest(TrainersActionTypes.FetchOneTrainer, loadOneTrainer),
  takeLatest(TrainersActionTypes.FetchTrainerCourses, loadTrainerCourses),
  takeLatest(TrainersActionTypes.FetchTrainerTrainings, loadTrainerTrainings),
  takeEvery(TrainersActionTypes.SaveTrainer, saveTrainer),
  takeLatest(getType(actions.DeleteTrainer.request), deleteTrainer)
];
