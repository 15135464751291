import React, { Component } from 'react';
import { History } from 'history';

interface UnauthorizedProps {
  history: History;
}

export default class Unauthorized extends Component<UnauthorizedProps> {
  componentDidMount = ({ history } = this.props) => {
    setTimeout(() => history.push('/'), 3000);
  };

  render() {
    return <div>Onvoldoende rechten om de pagina te raadplegen. U wordt omgeleid naar de startpagina...</div>;
  }
}
