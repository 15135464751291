import React, { Component } from 'react';
import { DbFile } from '../../interfaces/DbFile';
import { Segment, Icon } from 'semantic-ui-react';
import { filenameFormatter } from '../../helpers/fileHelpers';
import moment from 'moment';

interface Props {
  files: { key: string; file: DbFile }[];
  selectedFiles: (selectedFiles: string[]) => void;
}

type State = {
  selectedFiles: string[];
};

export default class FileSelector extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedFiles: []
    };
  }

  getIcon(mineType: string) {
    if ('application/pdf' === mineType) {
      return <Icon name="file pdf" />;
    }

    if (mineType.includes('image')) {
      return <Icon name="image" />;
    }

    return <Icon name="file" />;
  }

  addSelectedFile = (id: string) => {
    let newSelection = this.state.selectedFiles;
    if (this.state.selectedFiles.includes(id)) {
      newSelection = newSelection.filter(file => {
        return file !== id;
      });
    } else {
      newSelection.push(id);
    }

    this.setState({ selectedFiles: newSelection }, () => {
      this.props.selectedFiles(this.state.selectedFiles);
    });
  };

  render() {
    return (
      <Segment className="fileSelector">
        {this.props.files.map((f, i) => (
          <>
            <div className="file-row" key={f.key} onClick={() => this.addSelectedFile(f.key)}>
              <Icon
                className={this.state.selectedFiles.includes(f.key) ? 'fileChecker active' : 'fileChecker'}
                name={this.state.selectedFiles.includes(f.key) ? 'check circle' : 'circle'}
              />
              <p>
                {this.getIcon(f.file.mimeType)}
                {filenameFormatter(f.file.originalFilename, 25, moment(f.file.createdAt).format('DD/MM/YY'))}
              </p>
              {this.props.files.length !== i + 1 ? <hr /> : null}
            </div>
          </>
        ))}
      </Segment>
    );
  }
}
