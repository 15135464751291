import React, { Component } from 'react';
import CalendarSelectionList from './CalendarSelectionList';
import { CalendarEvent } from '../../interfaces/CalendarEvent';
import Calendar from './Calendar';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { updateQueryString } from '../../helpers/queryStringHelpers';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import QueryParams from '../../../shared/interfaces/QueryParams';
import { queryParams } from '../../../shared/helpers/queryStringHelpers';
import ProgressStatus from '../../interfaces/ProgressStatus';
import { View, stringOrDate } from 'react-big-calendar';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import CalendarListItem from '../../interfaces/CalendarLocation';
import CalendarDetailEvent from './CalendarDetailEvent';
import CalendarLoader from './CalendarLoader';
import Drawer from '../Drawer';

const moment = extendMoment(Moment);

type CalendarViewProps = {
  events: CalendarEvent[];
  name: string;
  items: CalendarListItem[];
  status: ProgressStatus;
  action: (queryParams: QueryParams) => void;
  drawerOpen: boolean;
  closeDrawer: () => void;
  deleteAction?: (id: number) => void;
  entityPath?: string;
} & RouteComponentProps;

interface StartAndEndDate {
  start: stringOrDate;
  end: stringOrDate;
}

interface CalendarViewState {
  eventSelected: boolean;
  event: CalendarEvent | null;
  displayedEvents: number[];
  clearSelections: boolean;
}

enum CalendarViews {
  month = 'month',
  week = 'week',
  day = 'day',
  agenda = 'agenda'
}

class CalendarView extends Component<CalendarViewProps & WithNamespaces, CalendarViewState> {
  state = {
    eventSelected: false,
    event: null,
    displayedEvents: [] as number[],
    clearSelections: false
  };

  queryParams(props: CalendarViewProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  componentDidMount = () => {
    const { items, name, action } = this.props;
    if (isEmpty(this.queryParams())) {
      const startOfMonth = moment()
        .startOf('month')
        .toISOString();
      const endOfMonth = moment()
        .endOf('month')
        .toISOString();
      action({
        end: endOfMonth,
        start: startOfMonth,
        [name]: [...items.map(x => x._id)].join(','),
        view: 'month'
      });
      updateQueryString(this.props, { view: 'month' });
    }
    const selectedIds = this.queryParams()[name]
      ? (this.queryParams()[name] as string).split(',').map(Number)
      : [...items.map(item => item._id)];
    this.setState({ displayedEvents: selectedIds });
  };

  handleChange = (id: number) => {
    const { name, items } = this.props;

    if (this.state.displayedEvents.length === 0) {
      const selectedIds = [];
      updateQueryString(this.props, { [name]: id });
      selectedIds.push(id);
      this.setState({ displayedEvents: selectedIds, clearSelections: false });
    } else {
      const selectedIds = this.queryParams()[name]
        ? (this.queryParams()[name] as string).split(',').map(Number)
        : [...items.map(item => item._id)];

      updateQueryString(this.props, {
        [name]: (selectedIds.includes(id) ? selectedIds.filter(x => x !== id) : [...selectedIds, id]).join(',')
      });

      selectedIds.includes(id) ? selectedIds.filter(id => id !== id) : selectedIds.push(id);
      this.setState({ displayedEvents: selectedIds });
    }
  };

  handleViewChange = (view: View) => {
    updateQueryString(this.props, { view });
  };

  handleClearSelection = () => {
    const selectedIds: number[] = [];
    updateQueryString(this.props, { Locations: selectedIds });
    this.setState({ displayedEvents: [], clearSelections: true });
  };

  toStartAndEndFormat = (range: StartAndEndDate | stringOrDate[]) => {
    switch (this.queryParams().view) {
      case CalendarViews.month:
      case CalendarViews.agenda:
        const dateRange = range as StartAndEndDate;
        return {
          start: moment(dateRange.start).toISOString(),
          end: moment(dateRange.end).toISOString()
        } as StartAndEndDate;
      case CalendarViews.week:
      case CalendarViews.day:
        const dates = range as stringOrDate[];
        return {
          start: moment(dates[0]).toISOString(),
          end: moment(dates[dates.length - 1])
            .add('1', 'days')
            .toISOString()
        };
      default:
        return null;
    }
  };

  handleDateChange = (range: StartAndEndDate | stringOrDate[]) => {
    setTimeout(() => {
      const dates = this.toStartAndEndFormat(range);
      updateQueryString(this.props, dates);
    }, 50);
  };

  handleEventSelect = (event: CalendarEvent) => {
    this.props.closeDrawer();

    this.setState(prevState => ({
      ...prevState,
      eventSelected: true,
      event
    }));
  };

  renderCalendar = ({ status, events } = this.props) => {
    const hasRangeDates = this.queryParams().start && this.queryParams().end;

    const ids = this.state.displayedEvents;

    const displayedEvents = events.filter(function(event) {
      return ids.find(function(id) {
        return event.entityId === id;
      });
    });

    return (
      <div className="calendar">
        {isEqual(ProgressStatus.InProgress, status) && (
          <div className="calendar-loader">
            <CalendarLoader />
          </div>
        )}
        <Calendar
          events={displayedEvents}
          onViewChange={this.handleViewChange}
          onEventSelect={this.handleEventSelect}
          onDateRangeChange={this.handleDateChange}
          view={this.queryParams().view ? (this.queryParams().view as View) : CalendarViews.month}
          defaultDate={
            hasRangeDates
              ? moment
                  .range(moment(this.queryParams().start), moment(this.queryParams().end))
                  .center()
                  .toDate()
              : moment().toDate()
          }
        />
      </div>
    );
  };

  renderCalendarSelectionList = ({ name, t, items } = this.props) => {
    return (
      <CalendarSelectionList
        name={t(name)}
        onSelectionChange={this.handleChange}
        clearSelection={this.handleClearSelection}
        selectedIds={
          !isEmpty(this.queryParams()[name])
            ? (this.queryParams()[name] as string).split(',').map(Number)
            : [...items.map(item => item._id)]
        }
        items={[...items].sort((a: CalendarListItem, b: CalendarListItem) => a.name.localeCompare(b.name))}
        deleteAction={this.props.deleteAction}
        clearCheckboxes={this.state.clearSelections}
      />
    );
  };

  render() {
    const { eventSelected, event } = this.state;
    const { drawerOpen, entityPath } = this.props;

    return (
      <div className="row">
        <div className="col-12">{this.renderCalendar()}</div>
        <Drawer isOpen={drawerOpen} closeDrawer={this.props.closeDrawer}>
          <div className="side-panel-list">{this.renderCalendarSelectionList()}</div>
        </Drawer>
        <Drawer isOpen={eventSelected} closeDrawer={() => this.setState({ eventSelected: false })}>
          {eventSelected && <CalendarDetailEvent event={event!} entityPath={entityPath} />}
        </Drawer>
      </div>
    );
  }
}

export default withNamespaces(['calendar', 'common'])(CalendarView);
