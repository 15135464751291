import React, { SyntheticEvent } from 'react';
import { CirclePicker } from 'react-color';

interface ColorInputProps {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: any) => void;
  value: any;
  label: string;
  keyField: string;
  type?: any;
}
const ColorInput = ({ onChange, value, keyField }: ColorInputProps) => {
  const handleColorChange = (color: { hex: string }) => {
    onChange({ target: { name: keyField, value: color.hex } });
  };
  const colors = ['#f44336', '#673ab7', '#03a9f4', '#8bc34a', '#ffeb3b', '#ff9800'];

  return <CirclePicker color={value} colors={colors} onChange={handleColorChange} />;
};

export default ColorInput;
