import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import RequestsRoutes from '../requests/Routes';
import TrainersRoutes from '../trainers/Routes';
import LocationsRoutes from '../locations/Routes';
import ClientsRoutes from '../clients/Routes';
import MaterialRoutes from '../materials/Routes';
import CourseRoutes from '../courses/Routes';
import TrainingRoutes from '../trainings/Routes';
import TaskRoutes from '../tasks/Routes';
import TaskListRoutes from '../tasklists/Routes';
import Unauthorized from './auth/Unauthorized';
import Reports from '../reports/Reports';
import Dashboard from '../dashboard/components/Dashboard';
import { useAuth0 } from './auth/AuthWrapper';
import PrivateRoute from './auth/PrivateRoute';
import InitialLoader from '../shared/components/InitialLoader';
import { connect } from 'react-redux';
import { GlobalState } from './state';
import { getMaterialModuleStatus, getRequestModuleStatus } from './state/selectors';
import { RequestActiveModules } from './state/actions';

type RouterProps = {
  hasMaterialModule: boolean;
  hasRequestModule: boolean;
  requestActiveModules: () => void;
};

const Routes = ({ hasMaterialModule, hasRequestModule, requestActiveModules }: RouterProps) => {
  const { loading } = useAuth0();

  useEffect(() => {
    requestActiveModules();
  }, []);

  if (loading === true) {
    return <InitialLoader />;
  } else {
    return (
      <Switch>
        <PrivateRoute exact path={'/'} component={Dashboard} scopes={['role:user', 'role:admin']} />
        {hasRequestModule && <PrivateRoute path={'/requests'} component={RequestsRoutes} scopes={['role:user', 'role:admin']} />}
        <PrivateRoute path={'/trainers'} component={TrainersRoutes} scopes={['role:user', 'role:admin']} />
        <PrivateRoute path={'/locations'} component={LocationsRoutes} scopes={['role:user', 'role:admin']} />
        {hasMaterialModule && <PrivateRoute path={'/materials'} component={MaterialRoutes} scopes={['role:user', 'role:admin']} />}
        <PrivateRoute path={'/clients'} component={ClientsRoutes} scopes={['role:user', 'role:admin']} />
        <PrivateRoute path={'/courses'} component={CourseRoutes} scopes={['role:user', 'role:admin']} />
        <PrivateRoute path={'/trainings'} component={TrainingRoutes} scopes={['role:user', 'role:admin']} />
        <PrivateRoute path={'/tasks'} component={TaskRoutes} scopes={['role:user', 'role:admin']} />
        <PrivateRoute path={'/task-lists'} component={TaskListRoutes} scopes={['role:user', 'role:admin']} />
        <PrivateRoute path={'/reports'} component={Reports} scopes={['role:admin']} />
        <Route path={'/unauthorized'} render={props => <Unauthorized {...props} />} />
      </Switch>
    );
  }
};

const mapStateToProps = (state: GlobalState) => {
  return {
    hasMaterialModule: getMaterialModuleStatus(state.app),
    hasRequestModule: getRequestModuleStatus(state.app)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  requestActiveModules: () => dispatch(RequestActiveModules.request())
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
