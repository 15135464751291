import React, { SyntheticEvent } from 'react';
import { FormFieldSize } from '../../../interfaces/FormFieldDefinition';

interface TextInputProps {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: SyntheticEvent) => void;
  value: any;
  label: string;
  keyField: string;
  type?: any;
  size?: FormFieldSize;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
}

function sizePercent(size: FormFieldSize) {
  switch (size) {
    case FormFieldSize.Small:
      return 25;
    case FormFieldSize.Medium:
      return 50;
    case FormFieldSize.Large:
      return 75;
  }
}

const TextInput = ({ onBlur, onChange, value, label, keyField, type, size, maxLength, placeholder, disabled }: TextInputProps) => {
  return (
    <input
      type={type ? type : 'text'}
      name={keyField}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      style={size !== undefined ? { width: `${sizePercent(size)}%` } : {}}
      maxLength={maxLength ? maxLength : undefined}
      placeholder={placeholder ? placeholder : undefined}
      disabled={disabled}
    />
  );
};

export default TextInput;
