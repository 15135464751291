import React, { Dispatch } from 'react';
import { baseUrl, newCategoryData } from '../../helpers/constants';
import { formDefinition } from '../../helpers/DetailFormFields';
import { RouteComponentProps } from 'react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { GlobalState } from '../../../core/state';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import CourseCategory from '../../../shared/interfaces/CourseCategory';
import { translateFormLabels } from '../../../locations/helpers/TranslationHelpers';
import * as actions from '../../state/actions';
import { CategoryAction } from '../../state/state';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import PageHeader from '../../../shared/components/PageHeader';

interface Props {
  savingCategoryStatus: ProgressStatus;
  saveCategory: (category: CourseCategory) => void;
}

class DetailCreate extends React.Component<Props & RouteComponentProps & WithNamespaces> {
  render() {
    const { history, saveCategory, t, savingCategoryStatus } = this.props;

    return (
      <>
        <PageHeader title={t('new category')} breadCrumbParts={[t('courses'), t('categories'), t('new')]} {...this.props} />
        <DetailForm
          onFormSubmit={data => saveCategory(data as CourseCategory)}
          selectedEntity={newCategoryData}
          onCancelBtnClick={() => history.push(`/${baseUrl}`)}
          formDefinition={translateFormLabels(formDefinition, t)}
          savingForm={savingCategoryStatus === ProgressStatus.InProgress}
        />
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    savingCategoryStatus: state.categories.savingCategoryStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch<CategoryAction>) => ({
  saveCategory: (category: CourseCategory) => dispatch(actions.SaveCategory.request({ courseCategory: category }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['resources', 'categories', 'menu'], { nsMode: 'fallback' })(DetailCreate));
