import { FormFieldDefinition } from '../../shared/interfaces/FormFieldDefinition';

export const translateFormLabels = (fieldDefinition: any, t: any) => {
  return fieldDefinition.map((x: FormFieldDefinition) => ({
    ...x,
    label: t(`${x.propertyName}`)
  }));
};

export const translateDropdownLabels = (resources: any, t: any) => {
  const translatedResources = { ...resources };

  for (const key in resources) {
    translatedResources[key] = translatedResources[key].map((x: any) => ({
      ...x,
      text: t(`resources:${x.key}`)
    }));
  }

  return translatedResources;
};
