import Entity, { EntityReference } from './Entity';
import { Material } from './Material';

export enum TransportType {
  Customer = 'klant',
  Trainer = 'lesgever',
  Courier = 'courier'
}

export interface WithMaterialReference {
  material: EntityReference | undefined;
}

export interface WithMaterial {
  material: Material;
}

export type TrainingSessionMaterial<T = WithMaterialReference> = T &
  Entity & {
    id: number;
    trainingSession: EntityReference | undefined;
    rate: number;
    transportType: TransportType;
    deletedAt?: string;
  };
