import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';

type DrawerProps = {
  isOpen: boolean;
  closeDrawer: () => void;
};

export default class Drawer extends Component<DrawerProps> {
  render() {
    return (
      <div className={this.props.isOpen ? 'side-panel open' : 'side-panel'}>
        <Button className="side-panel-close" icon onClick={this.props.closeDrawer}>
          <Icon name="close" />
        </Button>
        {this.props.children}
      </div>
    );
  }
}
