import React, { SyntheticEvent } from 'react';
import { FormFieldSize } from '../../../interfaces/FormFieldDefinition';

interface VatInputProps {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: SyntheticEvent) => void;
  value: any;
  label: string;
  keyField: string;
  type?: any;
  size?: FormFieldSize;
  maxLength?: number;
  placeholder?: string;
}

function sizePercent(size: FormFieldSize) {
  switch (size) {
    case FormFieldSize.Small:
      return 25;
    case FormFieldSize.Medium:
      return 50;
    case FormFieldSize.Large:
      return 75;
  }
}

function forceInputUppercase(e: any) {
  const end = e.target.selectionEnd;
  e.target.value = e.target.value.toUpperCase();
  e.target.setSelectionRange(end, end);
}

const VatInput = ({ onBlur, onChange, value, keyField, type, size, maxLength, placeholder }: VatInputProps) => {
  return (
    <input
      type={'text'}
      name={keyField}
      onChange={onChange}
      onKeyUp={forceInputUppercase}
      onBlur={onBlur}
      value={value}
      style={size !== undefined ? { width: `${sizePercent(size)}%` } : {}}
      maxLength={maxLength ? maxLength : undefined}
      placeholder={placeholder ? placeholder : undefined}
    />
  );
};

export default VatInput;
