import React, { FormEvent, Fragment, useState } from 'react';
import { Item, Checkbox, CheckboxProps, Button } from 'semantic-ui-react';
import CalendarListItem from '../../interfaces/CalendarLocation';
import { WithNamespaces, withNamespaces } from 'react-i18next';

interface CalendarSelectionListProps extends WithNamespaces {
  items: CalendarListItem[];
  onSelectionChange: (e: any) => void;
  clearSelection?: () => void;
  clearCheckboxes: boolean;
  selectedIds: number[];
  name: string;
  deleteAction?: (id: number) => void;
}

const CalendarSelectionList = ({
  items,
  onSelectionChange,
  selectedIds,
  name,
  deleteAction,
  clearSelection,
  clearCheckboxes,
  t
}: CalendarSelectionListProps) => {
  const onChange = (e: FormEvent<HTMLInputElement>, checkBoxProps: CheckboxProps) => {
    onSelectionChange(checkBoxProps.value);
  };

  const handleClearSelection = () => {
    clearSelection && clearSelection();
  };

  return (
    <Fragment>
      <h4>{name}</h4>
      {clearSelection ? (
        <Button onClick={() => handleClearSelection()} className="mb-3">
          {t('clearSelection')}
        </Button>
      ) : null}
      <div className="calendar-selection">
        <Item.Group divided>
          {items.map((event, key) => (
            <Item key={key}>
              <Item.Content verticalAlign="middle">
                <Checkbox
                  value={event._id}
                  checked={clearCheckboxes ? false : selectedIds.includes(event._id)}
                  onChange={onChange}
                  label={event.name}
                />

                {deleteAction ? (
                  <Button
                    icon="trash alternate outline"
                    type="button"
                    className="light"
                    floated="right"
                    onClick={() => deleteAction(event._id)}
                  />
                ) : null}
              </Item.Content>
            </Item>
          ))}
        </Item.Group>
      </div>
    </Fragment>
  );
};

export default withNamespaces(['calendar'])(CalendarSelectionList);
