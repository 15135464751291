import moment from 'moment';
import { CalendarEvent } from '../../shared/interfaces/CalendarEvent';
import { GQLSessionLocations } from '../../shared/interfaces/GQLSessionLocations';

export const toLocationEvents = (sessionlocations: GQLSessionLocations): CalendarEvent[] => {
  const events: CalendarEvent[] = sessionlocations.trainingSessions.edges.map(sessionLocation => {
    const node = sessionLocation.node;
    return {
      ...events,
      '@id': `/trainingSessions/${sessionLocation.node._id}`,
      id: node._id,
      title: node.location.name,
      entityId: node.location._id,
      subTitle: node.training.course.title,
      start: moment(node.startDate, 'YYYY/MM/DD HH:mm').toDate(),
      end: moment(node.endDate, 'YYYY/MM/DD HH:mm').toDate(),
      color: node.location.displayColor,
      trainingId: node.training._id,
      trainingDeleted: node.training.deletedAt
    };
  });
  return events;
};
