import React from 'react';
import { TrainingRequestStatus } from '../../shared/interfaces/TrainingRequest';
import { SemanticCOLORS } from 'semantic-ui-react';

const toLabelProps = (status: TrainingRequestStatus): { content: string; color: SemanticCOLORS } => {
  switch (status) {
    case TrainingRequestStatus.InAnticipation:
      return { content: 'In afwachting', color: 'purple' };
    case TrainingRequestStatus.NewRequest:
      return { content: 'Nieuw', color: 'teal' };
    case TrainingRequestStatus.LostRequest:
      return { content: 'Verloren', color: 'red' };
    case TrainingRequestStatus.WonRequest:
      return { content: 'Gewonnen', color: 'green' };
  }
};

const RequestStatusLabel = ({ status }: { status: TrainingRequestStatus; as: string; ribbon: boolean }) => {
  return <div className={'label ' + toLabelProps(status).color}>{toLabelProps(status).content}</div>;
};

export default RequestStatusLabel;
