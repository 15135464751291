import React from 'react';
import { Table } from 'semantic-ui-react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import QueryParams from '../../interfaces/QueryParams';

interface DataTableHeaderProps {
  fieldSet: { header: string; key: string }[];
  withActionButtons: boolean;
  onHeaderClick: (key: string) => void;
  activeSortHeader: string | undefined;
  direction?: QueryParams['order'];
  enabledSortingHeaders?: string[];
}

function convertSortOrder(order: QueryParams['order']) {
  if (order === 'asc') {
    return 'ascending';
  }
  if (order === 'desc') {
    return 'descending';
  }
  return undefined;
}

const DataTableHeader = ({
  fieldSet,
  withActionButtons,
  onHeaderClick,
  activeSortHeader,
  direction,
  t,
  enabledSortingHeaders
}: DataTableHeaderProps & WithNamespaces) => {
  return (
    <Table.Header>
      <Table.Row>
        {fieldSet.map(({ key, header }: { header: string; key: string }) => {
          const canSort = enabledSortingHeaders ? enabledSortingHeaders.includes(key) : true;
          return (
            <Table.HeaderCell
              disabled={!canSort}
              onClick={canSort ? () => onHeaderClick(key) : undefined}
              sorted={activeSortHeader === key ? convertSortOrder(direction) : undefined}
              key={header}>
              {header}
            </Table.HeaderCell>
          );
        })}
        {withActionButtons ? (
          <Table.HeaderCell disabled key={'action'} className="table-row-actions">
            {t('Actions')}
          </Table.HeaderCell>
        ) : null}
      </Table.Row>
    </Table.Header>
  );
};

export default withNamespaces(['headerCell'])(DataTableHeader);
