import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Dispatch } from 'redux';
import QueryParams from '../../../shared/interfaces/QueryParams';
import { GQLSessionLocations } from '../../../shared/interfaces/GQLSessionLocations';
import { GlobalState } from '../../../core/state';
import CalendarListItem from '../../../shared/interfaces/CalendarLocation';
import * as actions from '../../state/actions';
import { RouteComponentProps } from 'react-router';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { queryParams } from '../../../shared/helpers/queryStringHelpers';
import isEqual from 'lodash/isEqual';
import { toLocationEvents } from '../../helpers/toLocationEvents';
import InitialLoader from '../../../shared/components/InitialLoader';
import CalendarView from '../../../shared/components/Calendar/CalendarView';
import NoDataFound from '../../../shared/components/NoDataFound';

type CalendarShellProps = {
  sessionLocations: GQLSessionLocations | null;
  calendarListLocations: CalendarListItem[] | null;
  loadSessionLocations: (queryParams: QueryParams) => void;
  loadCalendarListLocations: () => void;
  calendarListStatus: ProgressStatus;
  sessionLocationStatus: ProgressStatus;
  drawerOpen: boolean;
  closeDrawer: () => void;
} & RouteComponentProps &
  WithNamespaces;

class CalendarShell extends Component<CalendarShellProps> {
  componentDidMount = ({ loadCalendarListLocations } = this.props) => {
    loadCalendarListLocations();
  };

  queryParams(props: CalendarShellProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  componentDidUpdate(prevProps: CalendarShellProps) {
    const { loadSessionLocations } = this.props;
    const prevQueryparams = this.queryParams(prevProps).locations;
    const locationsChanged = !isEqual(this.queryParams().locations, prevQueryparams);
    const locationsInitialized = prevProps.calendarListLocations === null && this.props.calendarListLocations !== null;
    const endDateChanged = !isEqual(this.queryParams(prevProps).end, this.queryParams().end);
    const startDateChanged = !isEqual(this.queryParams(prevProps).start, this.queryParams().start);
    const dateChanged = endDateChanged || startDateChanged;
    const viewChangedWithNewDates = !isEqual(this.queryParams(prevProps).view, this.queryParams().view) && dateChanged;
    const queryParamsChanged = !isEqual(this.queryParams(prevProps), this.queryParams());
    if (locationsChanged || locationsInitialized || viewChangedWithNewDates || dateChanged || queryParamsChanged) {
      loadSessionLocations(this.queryParams());
    }
  }

  renderCalendarView = () => {
    const { calendarListStatus, sessionLocations, calendarListLocations, sessionLocationStatus, loadSessionLocations, t } = this.props;
    switch (calendarListStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        const events = toLocationEvents(sessionLocations || { trainingSessions: { edges: [] } });
        return (
          <CalendarView
            {...this.props}
            events={events}
            items={calendarListLocations!}
            status={sessionLocationStatus}
            name={t('locations')}
            action={loadSessionLocations}
            drawerOpen={this.props.drawerOpen}
            closeDrawer={this.props.closeDrawer}
            entityPath={'/locations/{id}'}
          />
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  };

  render() {
    return <div>{this.renderCalendarView()}</div>;
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    calendarListLocations: state.locations.calendarLocationList as CalendarListItem[] | null,
    calendarListStatus: state.locations.calendarListStatus,
    sessionLocations: state.locations.sessionLocations as GQLSessionLocations | null,
    sessionLocationStatus: state.locations.sessionLocationStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadSessionLocations: (queryParams: QueryParams) => dispatch(actions.FetchTrainingSessionLocations.request(queryParams)),
  loadCalendarListLocations: () => dispatch(actions.FetchCalendarListLocations.request({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('locations', { nsMode: 'fallback' })(CalendarShell));
