import React, { useState } from 'react';
import { TextArea, Icon, Button } from 'semantic-ui-react';
import { Form, Formik } from 'formik';
import { TaskListInitialValue, TaskListValidationSchema, PredefinedTaskInitialValue } from '../helpers/definitions';
import PageHeader from '../../shared/components/PageHeader';
import { RouteComponentProps } from 'react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { GlobalState } from '../../core/state';
import { Dispatch } from 'redux';

import Label from '../../shared/components/DetailForm/Label';
import { SaveTaskList } from '../state/actions';
import { push } from 'connected-react-router';
import TaskList from '../../shared/interfaces/TaskList';

type CreateListProps = {
  saveTaskList: (taskList: TaskList) => void;
  navigatePush: (values: string) => void;
} & RouteComponentProps &
  WithNamespaces;

const CreateList = (props: CreateListProps) => {
  const { t } = props;

  return (
    <>
      <PageHeader {...props} breadCrumbParts={[t('task-lists')]} title={t('task-lists')} />
      <Formik
        initialValues={TaskListInitialValue}
        validationSchema={TaskListValidationSchema}
        onSubmit={values => {
          props.saveTaskList(values);
        }}>
        {formProps => (
          <Form className="ui form">
            <div className="d-flex justify-content-center aligned-items-center w-100">
              <div className="task-list-creation">
                <div className="form-group">
                  <label>{t('title-of-list')}</label>
                  <input
                    type={'text'}
                    name={'name'}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    value={formProps.values['name' || '']}
                  />
                  {(formProps.errors as any)['name' || ''] && (formProps.touched as any)['name' || ''] ? (
                    <div className="error field">
                      <Label value={(formProps.errors as any)['name' || '']} />
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>
                    <i>{t('description')}</i>
                  </label>
                  <TextArea
                    name={'description'}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    value={formProps.values['description' || '']}
                    rows={3}
                    style={{ resize: 'none' }}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <Button icon type="button" onClick={() => props.navigatePush(`/task-lists`)}>
                    {t('back-to-task-lists')}
                  </Button>
                  <Button icon type="submit" className="primary" disabled={!formProps.values['name']}>
                    <Icon name="long arrow alternate right" />
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveTaskList: (taskList: TaskList) => dispatch(SaveTaskList.request(taskList)),
  navigatePush: (path: string) => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['tasks', 'resources', 'menu'], { nsMode: 'fallback' })(CreateList));
