import React, { useEffect, useState, SyntheticEvent } from 'react';
import Axios, { AxiosResponse } from 'axios';
import './styles.scss';
import { FormikValues } from 'formik';
import { Button, Input, TextArea, Select } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import logo from './ect-logo.png';
import AlertService from '../core/alert/AlertService';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { Spin } from 'antd';
import { configConstants } from '../config/constants';

interface TrainerForm {
  token?: string;
  language?: string;
  softwareLanguage?: string;
  softwareVersion?: string;
  course?: string;
  participants?: string;
  date?: string;
  invoiceData?: string;
  applicationPoint?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
  responsibleName?: string;
  responsiblePhone?: string;
  responsibleEmail?: string;
  roomType?: string;
  driveType?: string;
  trainerHasParkingSpot?: boolean;
  trainerHasLunch?: boolean;
  useOfProtables?: boolean;
  useOfFlipChart?: boolean;
  useOfBoard?: boolean;
  useOfMailbox?: boolean;
  isInternetAvailable?: boolean;
}

const CustomerForm = (props: RouteComponentProps) => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [trainingForm, setTrainingForm] = useState<TrainerForm>();
  const [courses, setCourses] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [token, setToken] = useState();

  useEffect(() => {
    const url = props.location.search;
    const params = queryString.parse(url);

    if (params['token']) {
      setToken(params['token']);

      const fetchData = async () => {
        const result = await Axios(`${configConstants.apiUrl}customer-form?token=${params['token']}`);
        setTrainingForm(result.data.form);
        setCourses(result.data.courses);
        setResponse(result);
      };

      fetchData();
    }
  }, []);

  const push = () => {
    setIsSaving(true);
    Axios.post(`${configConstants.apiUrl}customer-form?token=${token}`, {
      ...trainingForm
    }).then(res => {
      setIsSaving(false);
      if (res.status >= 200 && res.status < 300) {
        AlertService.setMessage({ title: 'Formulier opgeslagen', messageText: 'Uw gegevens zijn succesvol opgeslagen.', type: 'success' });
      } else {
        AlertService.setMessage({
          title: 'Formulier niet opgeslagen',
          messageText: 'Oeps, er liep iets mis tijdens het opslagen van uw gegevens. Probeer het later opnieuw.',
          type: 'error'
        });
      }
    });
  };

  return (
    <div className="body">
      <div className="container">
        {!response ? (
          <h1 className="text-center">
            <Spin size="large" />
          </h1>
        ) : null}
        {response && trainingForm && response.status === 200 ? (
          <div className="row">
            <div className="col-12">
              <img src={logo} alt="" />
              <p className="mt-4">Beste Klant,</p>
              <p>
                Om de opleiding zo optimaal mogelijk voor te bereiden vragen wij u volgende fiche in te vullen.
                <br />
                Aarzel niet om toelichting te vragen via opmerkingen of telefonisch.
              </p>
              <p>
                Bij de vragen die niet van toepassing zijn mag u “NVT” vermelden.
                <br />
                <br />
                Hartelijk dank,
                <br />
                Het ECT-team
                <br />
                Sara, Samiollah, Kevin, Cristophe, Machteld & Anja
              </p>
            </div>
            <div className="mt-4 col-12 col-md-4">
              <h5>Lestaal</h5>
              <Select
                options={[
                  { key: 'NL', value: 'NL', text: 'Nederlands' },
                  { key: 'EN', value: 'EN', text: 'Engels' },
                  { key: 'FR', value: 'FR', text: 'Frans' }
                ]}
                value={trainingForm ? trainingForm.language : 'NL'}
                onChange={(e: SyntheticEvent, { value }: any) => {
                  setTrainingForm({ ...trainingForm, language: value });
                }}
              />
            </div>
            <div className="mt-4 col-12 col-md-4">
              <h5>Software taal</h5>
              <Select
                options={[
                  { key: 'NL', value: 'NL', text: 'Nederlands' },
                  { key: 'EN', value: 'EN', text: 'Engels' },
                  { key: 'FR', value: 'FR', text: 'Frans' }
                ]}
                value={trainingForm ? trainingForm.softwareLanguage : 'NL'}
                onChange={(e: SyntheticEvent, { value }: any) => {
                  setTrainingForm({ ...trainingForm, softwareLanguage: value });
                }}
              />
            </div>
            <div className="mt-4 col-12 col-md-4">
              <h5>Software version</h5>
              <Input
                type="text"
                value={response ? response.data.form.softwareVersion : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, softwareVersion: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Pakket</h5>
              <Select
                options={courses.map(course => ({
                  key: course['id'],
                  value: '/courses/' + course['id'],
                  text: course['title']
                }))}
                value={trainingForm ? trainingForm.course : '1'}
                onChange={(e: SyntheticEvent, { value }: any) => {
                  setTrainingForm({ ...trainingForm, course: value });
                }}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Datum</h5>
              <ReactDatePicker
                selected={trainingForm.date ? moment(trainingForm.date).toDate() : new Date()}
                onChange={(value: Date) => setTrainingForm({ ...trainingForm, date: moment(value).toISOString(false) })}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="dd/MM/yyyy - H:mm"
              />
            </div>
            <div className="mt-4 col-12">
              <h5 className="withSubtitle">Gegevens deelnemers (naam + e-mail)</h5>
              <span className="subTitle">
                De deelnemers krijgen een e-mail omtrent onze evaluatie, die we nodig hebben voor onze kwaliteitscontrole.
              </span>
              <TextArea
                value={response ? response.data.form.participants : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, participants: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12">
              <h5>Factuur data</h5>
              <TextArea
                value={response ? response.data.form.invoiceData : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, invoiceData: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12">
              <h5>Aanmeldingspunt voor docent</h5>
              <TextArea
                value={response ? response.data.form.applicationPoint : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, applicationPoint: data.target.value })}
              />
            </div>
            <div className="mt-4  col-12">
              <hr />
            </div>
            <div className="col-12">
              <h4>Contact</h4>
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Naam</h5>
              <Input
                type="text"
                value={response ? response.data.form.contactName : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, contactName: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Telefoon</h5>
              <Input
                type="text"
                value={response ? response.data.form.contactPhone : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, contactPhone: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Email</h5>
              <Input
                type="text"
                value={response ? response.data.form.contactEmail : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, contactEmail: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12">
              <hr />
            </div>
            <div className="col-12">
              <h4>Verantwoordelijke</h4>
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Naam</h5>
              <Input
                type="text"
                value={response ? response.data.form.responsibleName : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, responsibleName: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Telefoon</h5>
              <Input
                type="text"
                value={response ? response.data.form.responsiblePhone : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, responsiblePhone: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Email</h5>
              <Input
                type="text"
                value={response ? response.data.form.responsibleEmail : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, responsibleEmail: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12">
              <hr />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Lokaal type</h5>
              <Input
                type="text"
                value={response ? response.data.form.roomType : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, roomType: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Extern opslagmedium (USB stick, cd-rom ...) </h5>
              <Input
                type="text"
                value={response ? response.data.form.driveType : ''}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, driveType: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Lesgever heeft een parking plaats voorzien</h5>
              <Input
                type="checkbox"
                checked={response ? response.data.form.trainerHasParkingSpot : false}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, trainerHasParkingSpot: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Er is lunch voorzien voor de lesgever</h5>
              <Input
                type="checkbox"
                checked={response ? response.data.form.trainerHasLunch : false}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, trainerHasLunch: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>U maakt gebruik van onze mobiele klas</h5>
              <Input
                type="checkbox"
                checked={response ? response.data.form.useOfProtables : false}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, useOfProtables: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Er is een flipchart aanwezig</h5>
              <Input
                type="checkbox"
                checked={response ? response.data.form.useOfFlipChart : false}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, useOfFlipChart: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Er is een bord aanwezig</h5>
              <Input
                type="checkbox"
                checked={response ? response.data.form.useOfBoard : false}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, useOfBoard: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Kunnen de deelnemers via webmail op hun eigen mailbox</h5>
              <Input
                type="checkbox"
                checked={response ? response.data.form.useOfMailbox : false}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, useOfMailbox: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12 col-md-6">
              <h5>Is er internet voorzien</h5>
              <Input
                type="checkbox"
                checked={response ? response.data.form.isInternetAvailable : false}
                onChange={(data: FormikValues) => setTrainingForm({ ...trainingForm, isInternetAvailable: data.target.value })}
              />
            </div>
            <div className="mt-4 col-12">
              <p>
                <b>BELANGRIJK</b> U voorziet:
              </p>
              <p>
                - een pc (met de nodige software er op geïnstalleerd) met beamer voor de docent
                <br />
                - een pc (met de nodige software er op geïnstalleerd) voor elke cursist
                <br />
                - voldoende rechten voor de cursisten om acties te kunnen uitvoeren
                <br />
                - rechten om via een extern opslagmedium (USB stick, cd-rom ...) oefeningen op de pc’s te plaatsen
                <br />- water voor de docent
              </p>
              <b>Pakketmateriaal</b>
              <p>Uitgebreid standaard Nederlandstalig naslagwerk wordt digitaal voorzien.</p>
              <b>ECT contactgegevens</b>
              <p>
                Indien er tijdens de opleiding vragen of opmerkingen zijn kan u ons steeds contacteren op{' '}
                <span className="highlight">03/239.54.67</span> of mailen naar
                <span className="highlight">info@ect.be</span>.
              </p>
              <b>Evaluatiefiche voor de deelnemers</b>
              <p>
                Iedere deelnemer vult bij afloop van de training een evaluatie in. Dit gebeurt online:{' '}
                <span className="highlight">www.ect.be/evaluatie</span>. Indien er geen internettoegang is, kunnen de deelnemers een
                papieren evaluatie invullen, die u aan de docent kan vragen. De cursisten kunnen de evaluatieformulieren aan de docent
                meegeven.
              </p>
              <b>KMO-Portefeuille:</b>
              <p>
                Opgelet! Indien u de KMO-Portefeuille wenst aan te vragen dient dit bij voorkeur te gebeuren vooraleer de opleiding start.
                <br />
                Vraag tijdig uw paswoord aan, en vraag enkel de subsidie aan voor het bedrag zonder BTW. Uitgebreide informatie kan u
                terugvinden op
                <br />
                <span className="highlight">www.ect.be</span> en op <span className="highlight">www.kmo-portefeuille.be</span>.
                <br />
                <br />
                ECT heeft als dienstverlener het erkenningsnummer: DV.O105389
                <br />
                Respecteer de betalingstermijn die de overheid vermeldt in de bevestigingsmail.
              </p>
            </div>
            <div className="col-12 col-md-6 mt-4">
              <Button loading={isSaving} className="primary" onClick={push}>
                Opslaan
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomerForm;
