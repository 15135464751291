import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import * as actions from './actions';
import { configConstants } from '../../config/constants';
import Client from '../../shared/interfaces/Client';
import Contact from '../../shared/interfaces/Contact';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { fetch } from '../../core/state/fetch';
import { getType } from 'typesafe-actions';
import { CourseLocation } from '../../shared/interfaces/CourseLocation';
import { GQLTraining, GQLRequest } from './state';
import { Participant, WithParticipations, WithTraining } from '../../shared/interfaces/participant';
import AlertService from '../../core/alert/AlertService';

export function* loadEntities(action: AnyAction) {
  try {
    const { sort, order } = action.payload;
    delete action.payload.sort;
    delete action.payload.order;
    let query = queryString.stringify(action.payload);

    query += sort ? `&order[${sort}]=` + (order || 'asc') : '&order[updatedAt]=desc';

    const response = yield call(fetch, `${configConstants.apiUrl}customers?${query}`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();
      yield put(
        actions.FetchClientsSuccess({
          clients: data['hydra:member'],
          totalClients: data['hydra:totalItems']
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(
      actions.FetchClientsFail({
        errorMessage: error
      })
    );
  }
}

export function* loadOneClient(action: ReturnType<typeof actions.FetchOneClient>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}customers/${action.payload.clientId}`);
    if (response.status >= 200 && response.status < 300) {
      const clientsData = (yield response.json()) as Client;

      yield put(actions.FetchClientContacts.request(action.payload.clientId));
      yield put(actions.FetchClientLocations.request(action.payload.clientId));
      yield put(actions.FetchOneClientSuccess({ client: clientsData }));
    } else {
      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.FetchOneClientFail({ errorMessage }));
  }
}

export function* loadClientContacts(action: ReturnType<typeof actions.FetchClientContacts.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}customers/${action.payload}/contacts`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();

      yield put(actions.FetchClientContacts.success(data['hydra:member'] as Contact[]));
    } else {
      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.FetchClientContacts.failure({ errorMessage }));
  }
}

export function* loadClientLocations(action: ReturnType<typeof actions.FetchClientLocations.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}customers/${action.payload}/locations`);

    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();
      yield put(actions.FetchClientLocations.success(data['hydra:member'] as CourseLocation[]));
    } else {
      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.FetchClientLocations.failure({ errorMessage }));
  }
}

interface ClientTrainingResponse {
  data: { trainings: { edges: { node: GQLTraining }[] } };
}

export function* loadClientTrainings(action: ReturnType<typeof actions.FetchClientTrainings.request>) {
  const query = `{
    trainings(customer: "/customers/${action.payload}") {
      edges {
        node {
          _id,
          id,
          type,
          course {
            id,
            title
          },
          status,
          deletedAt,
          trainingSessions {
            edges {
              node {
                startDate,
                endDate
              }
            }
          }
        }
      }
    }
  }`;

  try {
    const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);

    if (response.status >= 200 && response.status < 300) {
      const json = (yield response.json()) as ClientTrainingResponse;
      const trainings = json.data.trainings.edges.map(x => x.node);
      yield put(actions.FetchClientTrainings.success(trainings));
    } else {
      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.FetchClientTrainings.failure({ errorMessage }));
  }
}

interface ClientRequestResponse {
  data: { trainingRequests: { edges: { node: GQLRequest }[] } };
}

export function* loadClientRequests(action: ReturnType<typeof actions.FetchClientRequests.request>) {
  const query = `{
    trainingRequests(customer: "/customers/${action.payload}") {
      edges {
        node {
          _id,
          id,
          course {
            title
          },
          createdAt,
          deletedAt,
          trainingType,
          status,
          training{
            course {
              title
            }
          }
        }
      }
    }
  }`;

  try {
    const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);

    if (response.status >= 200 && response.status < 300) {
      const json = (yield response.json()) as ClientRequestResponse;
      const requests = json.data.trainingRequests.edges.map(x => x.node);
      yield put(actions.FetchClientRequests.success(requests));
    } else {
      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.FetchClientRequests.failure({ errorMessage }));
  }
}

export function* saveClient(action: AnyAction) {
  try {
    const { client } = action.payload as { client: Client; noredirect?: boolean };
    const isNew = client.id === 0;
    const response = yield call(fetch, isNew ? `${configConstants.apiUrl}customers` : `${configConstants.apiUrl}customers/${client.id}`, {
      method: isNew ? 'POST' : 'PUT',
      body: JSON.stringify(isNew ? { ...client, id: undefined } : client),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const result = yield response.json() as Client;
      yield put({
        type: actions.ClientsActionTypes.SaveClientSuccess,
        payload: { client: result }
      });
      AlertService.setMessage({ title: 'Klant opgeslagen', messageText: 'Uw klant is succesvol opgeslagen.', type: 'success' });

      if (!action.payload.noredirect) {
        yield put(push(`/clients/${result.id}`));
      }
    } else {
      throw response;
    }
  } catch (error) {
    yield put({
      type: actions.ClientsActionTypes.SaveClientFail,
      payload: { errorMessage: error }
    });
    AlertService.setMessage({
      title: 'Klant niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen van uw klant.',
      type: 'error'
    });
  }
}

function* deleteClient(action: ReturnType<typeof actions.DeleteClient.request>) {
  try {
    const url = `customers/${action.payload.clientId}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });
    yield put(actions.FetchClients(action.payload.queryParams));
    AlertService.setMessage({
      title: 'Klant verwijderd',
      messageText: 'Uw klant is succesvol verwijderd',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.DeleteClient.failure({ errorMessage }));
    AlertService.setMessage({
      title: 'Klant niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen van uw klant.',
      type: 'error'
    });
  }
}

function* unlinkLocationFromClient(action: ReturnType<typeof actions.UnlinkLocationFromClient.request>) {
  try {
    const url = `locations/${action.payload.id}`;
    const response = yield call(fetch, configConstants.apiUrl + url, {
      method: 'PUT',
      body: JSON.stringify({ customer: null }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.status >= 200 && response.status < 300) {
      yield put(actions.FetchClientLocations.request(action.payload.clientId));
      // yield put(push(`/clients/${result.id}`));
      yield put(actions.UnlinkLocationFromClient.success());
      AlertService.setMessage({
        title: 'Klant losgekoppeld',
        messageText: 'De locatie is losgekoppeld van uw klant.',
        type: 'success'
      });
    } else {
      AlertService.setMessage({
        title: 'Klant niet losgekoppeld',
        messageText: 'Er liep iets mis tijdens het loskoppelen van uw locatie.',
        type: 'error'
      });
      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.UnlinkLocationFromClient.failure({ errorMessage }));
    AlertService.setMessage({
      title: 'Klant niet losgekoppeld',
      messageText: 'Er liep iets mis tijdens het loskoppelen van uw locatie.',
      type: 'error'
    });
  }
}

function* fetchContactParticipations(action: ReturnType<typeof actions.FetchContactParticipations.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}participants?contact=${action.payload.contact.id}&embed[]=training&embed[]=course&embed[]=participation`
    );
    if (response.status >= 200 && response.status < 300) {
      const participantData = yield response.json();
      yield put(
        actions.FetchContactParticipations.success(participantData['hydra:member'] as Participant<WithTraining & WithParticipations>[])
      );
    }
  } catch (errorMessage) {
    yield put(actions.DeleteClient.failure({ errorMessage }));
  }
}

export const clientsSaga = [
  takeLatest(actions.ClientsActionTypes.FetchClients, loadEntities),
  takeLatest(actions.ClientsActionTypes.FetchOneClient, loadOneClient),
  takeLatest(actions.ClientsActionTypes.FetchClientContacts, loadClientContacts),
  takeLatest(getType(actions.FetchClientLocations.request), loadClientLocations),
  takeLatest(getType(actions.FetchClientTrainings.request), loadClientTrainings),
  takeLatest(getType(actions.FetchClientRequests.request), loadClientRequests),
  takeEvery(actions.ClientsActionTypes.SaveClient, saveClient),
  takeLatest(getType(actions.DeleteClient.request), deleteClient),
  takeLatest(getType(actions.UnlinkLocationFromClient.request), unlinkLocationFromClient),
  takeLatest(getType(actions.FetchContactParticipations.request), fetchContactParticipations)
];
