import React, { Component, Fragment } from 'react';
import { Dispatch } from 'redux';
import { Button, Icon } from 'semantic-ui-react';
import { GlobalState } from '../../../core/state';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { GQLSessionMaterials } from '../../../shared/interfaces/GQLSessionMaterials';
import { toCalendarEvents } from '../../helpers/toCalendarEvents';
import InitialLoader from '../../../shared/components/InitialLoader';
import NoDataFound from '../../../shared/components/NoDataFound';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import CalendarView from '../../../shared/components/Calendar/CalendarView';
import CalendarListItem from '../../../shared/interfaces/CalendarLocation';
import QueryParams from '../../../shared/interfaces/QueryParams';
import { queryParams } from '../../../shared/helpers/queryStringHelpers';
import isEqual from 'lodash/isEqual';
import * as actions from '../../state/actions';
import PageHeader from '../../../shared/components/PageHeader';
import { ConfirmAction } from '../../../core/state/actions';
import MaterialList from './MaterialList';

type CalendarShellProps = {
  sessionMaterials: GQLSessionMaterials | null;
  calendarMaterialList: CalendarListItem[] | null;
  materialStatus: ProgressStatus;
  materialListStatus: ProgressStatus;
  loadSessionMaterials: (queryParams: QueryParams) => void;
  loadCalendarListMaterials: () => void;
  deleteMaterial: (materialId: number, queryParams: QueryParams) => void;
} & RouteComponentProps;

class CalendarShell extends Component<CalendarShellProps & WithNamespaces> {
  state = {
    drawerOpen: false,
    calendarOpen: false
  };

  componentDidMount = ({ loadCalendarListMaterials } = this.props) => {
    loadCalendarListMaterials();
  };

  queryParams(props: CalendarShellProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  componentDidUpdate(prevProps: CalendarShellProps) {
    const { loadSessionMaterials } = this.props;
    const prevQueryparams = this.queryParams(prevProps).materials;
    const materialsChanged = !isEqual(this.queryParams().materials, prevQueryparams);
    const materialsInitialized = prevProps.calendarMaterialList === null && this.props.calendarMaterialList !== null;
    const endDateChanged = !isEqual(this.queryParams(prevProps).end, this.queryParams().end);
    const startDateChanged = !isEqual(this.queryParams(prevProps).start, this.queryParams().start);
    const dateChanged = endDateChanged || startDateChanged;
    const viewChangedWithNewDates = !isEqual(this.queryParams(prevProps).view, this.queryParams().view) && dateChanged;

    if (materialsChanged || materialsInitialized || viewChangedWithNewDates || dateChanged) {
      loadSessionMaterials(this.queryParams());
    }
  }

  renderCalendarView = () => {
    const { materialListStatus, sessionMaterials, materialStatus, calendarMaterialList, loadSessionMaterials } = this.props;
    switch (materialListStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        const events = toCalendarEvents(sessionMaterials || { trainingSessionMaterials: { edges: [] } });
        return (
          <CalendarView
            {...this.props}
            events={events}
            items={calendarMaterialList!}
            status={materialStatus}
            name={'materials'}
            action={loadSessionMaterials}
            drawerOpen={this.state.drawerOpen}
            closeDrawer={() => this.setState({ drawerOpen: false })}
            deleteAction={(materialId: number) => this.props.deleteMaterial(materialId, this.queryParams())}
            entityPath={'/materials/{id}'}
          />
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  };

  toggleFilter = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <PageHeader {...this.props} title={t('materials')} breadCrumbParts={[t('materials')]} />
        <div style={{ marginBottom: '30px' }}>
          <Button.Group className="m-r-sm button-switch">
            <Button icon onClick={() => this.setState({ calendarOpen: false })} disabled={!this.state.calendarOpen}>
              <Icon name="list" />
            </Button>
            <Button icon onClick={() => this.setState({ calendarOpen: true })} disabled={this.state.calendarOpen}>
              <Icon name="calendar alternate" />
            </Button>
          </Button.Group>
          <Button
            positive
            className="primary"
            content="Nieuw materiaal"
            onClick={() => this.props.history.push(this.props.match.path + '/create')}
          />
          <div className="float-right">
            {this.state.calendarOpen ? (
              <Button icon onClick={this.toggleFilter}>
                <Icon name="filter" />
              </Button>
            ) : null}
          </div>
        </div>
        {this.state.calendarOpen ? this.renderCalendarView() : <MaterialList />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    sessionMaterials: state.materials.sessionMaterials as GQLSessionMaterials | null,
    calendarMaterialList: state.materials.calendarMaterialList as CalendarListItem[] | null,
    materialStatus: state.materials.materialStatus,
    materialListStatus: state.materials.calendarMaterialStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadSessionMaterials: (queryParams: QueryParams) => dispatch(actions.FetchTrainingSessionMaterials.request(queryParams)),
  loadCalendarListMaterials: () => dispatch(actions.FetchCalendarListMaterials.request({})),
  deleteMaterial: (materialId: number, queryParams: QueryParams) =>
    dispatch(
      ConfirmAction.request({
        title: 'Materiaal verwijderen',
        content: 'Ben je zeker dat je dit materiaal wil verwijderen?',
        action: () => dispatch(actions.DeleteMaterial.request({ materialId, queryParams }))
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['materials', 'resources', 'menu'], { nsMode: 'fallback' })(CalendarShell));
