import { all, call, put, takeLatest } from 'redux-saga/effects';
import { MaterialActionTypes } from './actions';
import { push } from 'connected-react-router';
import { configConstants } from '../../config/constants';
import * as actions from './actions';
import { Material } from '../../shared/interfaces/Material';
import { GQLSessionMaterials } from '../../shared/interfaces/GQLSessionMaterials';
import CalendarListItem from '../../shared/interfaces/CalendarLocation';
import { toMaterialQuery } from '../helpers/toMaterialQuery';
import { fetch } from '../../core/state/fetch';
import { getType } from 'typesafe-actions';
import AlertService from '../../core/alert/AlertService';

export function* saveEntity(action: ReturnType<typeof actions.SaveMaterial.request>) {
  try {
    const { material } = action.payload as { material: Material; noredirect?: boolean };
    const isNew = material.id === 0;
    const response = yield call(fetch, isNew ? `${configConstants.apiUrl}materials` : `${configConstants.apiUrl}materials/${material.id}`, {
      method: isNew ? 'POST' : 'PUT',
      body: JSON.stringify(isNew ? { ...material, id: undefined } : material),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.status >= 200 && response.status < 300) {
      const result = yield response.json();

      AlertService.setMessage({
        title: 'Materiaal opgeslagen',
        messageText: 'Het materiaal is succesvol opgeslagen.',
        type: 'success'
      });

      yield put(actions.SaveMaterial.success({ material: result }));
      yield put(actions.FetchAllMaterials.request());

      if (!action.payload.noredirect) {
        yield put(push(`/materials`));
      }
    } else {
      AlertService.setMessage({
        title: 'Materiaal niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
        type: 'error'
      });

      throw response;
    }
  } catch (error) {
    yield put(actions.SaveMaterial.failure({ errorMessage: error }));

    AlertService.setMessage({
      title: 'Materiaal niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
      type: 'error'
    });
  }
}

export function* loadOneMaterial(action: ReturnType<typeof actions.FetchOneMaterial.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}materials/${action.payload.materialId}`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();
      yield put(actions.FetchOneMaterial.success({ material: data }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchOneMaterial.failure({ errorMessage: error }));
  }
}

export function* loadSessionMaterials(action: ReturnType<typeof actions.FetchTrainingSessionMaterials.request>) {
  const { start, end, materials } = action.payload;
  const query = `{
    trainingSessionMaterials(
      first: 1000
      material_list: ${materials ? JSON.stringify((materials as string).split(',')) : '[]'} 
      trainingSession_startDate: ${start ? `{ after: ${JSON.stringify(start)} }` : '{}'}
      trainingSession_endDate: ${end ? `{ before: ${JSON.stringify(end)} }` : '{}'}
       ) {
      edges {
        node {
          _id
          rate
          trainingSession {
            _id
            startDate
            endDate
            training {
              _id
              course {
                _id
                title
              },
              deletedAt
            }
          }
          material {
            _id
            name
            displayColor
          }
        }
      }
    }
  }`;
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);
    if (response.status >= 200 && response.status < 300) {
      const result = yield response.json();
      yield put(actions.FetchTrainingSessionMaterials.success({ sessionMaterials: result.data as GQLSessionMaterials }));
    }
  } catch (error) {
    yield put(actions.FetchTrainingSessionMaterials.failure({ errorMessage: error }));
  }
}

export function* loadCalendarListMaterials(action: ReturnType<typeof actions.FetchCalendarListMaterials.request>) {
  try {
    let cursor = '';
    let hasNextPage = false;
    let materials: CalendarListItem[] = [];
    do {
      const query = toMaterialQuery(cursor);
      const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);
      if (response.status >= 200 && response.status < 300) {
        const result: any = yield response.json();
        cursor = result.data.materials.edges.slice(-1)[0].cursor;
        hasNextPage = result.data.materials.pageInfo.hasNextPage;
        materials = [...materials, ...result.data.materials.edges.map((item: any) => item.node)];
      } else {
        break;
      }
    } while (hasNextPage);

    if (materials.length >= 1) {
      yield put(actions.FetchCalendarListMaterials.success({ calendarMaterialList: materials }));
    }
  } catch (error) {
    yield put(actions.FetchCalendarListMaterials.failure({ errorMessage: error }));
  }
}

function* deleteMaterial(action: ReturnType<typeof actions.DeleteMaterial.request>) {
  try {
    const url = `materials/${action.payload.materialId}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });

    if (action.payload.queryParams) {
      yield all([
        put(actions.FetchTrainingSessionMaterials.request(action.payload.queryParams)),
        put(actions.FetchCalendarListMaterials.request({}))
      ]);
    }

    yield put(actions.FetchAllMaterials.request());

    AlertService.setMessage({
      title: 'Materiaal verwijderd',
      messageText: 'Het materiaal is succesvol verwijderd.',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.DeleteMaterial.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Materiaal niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
      type: 'error'
    });
  }
}

function* FetchAllMaterials(action: ReturnType<typeof actions.FetchAllMaterials.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}materials?pagination=false`, { method: 'GET' });

    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();
      const results = data['hydra:member'];
      yield put(actions.FetchAllMaterials.success(results));
    }
  } catch (error) {
    yield put(actions.FetchAllMaterials.failure(error));
  }
}

export const materialsSaga = [
  takeLatest(MaterialActionTypes.SaveMaterial, saveEntity),
  takeLatest(MaterialActionTypes.FetchOneMaterial, loadOneMaterial),
  takeLatest(MaterialActionTypes.FetchTrainingSessionMaterials, loadSessionMaterials),
  takeLatest(MaterialActionTypes.FetchCalendarListMaterials, loadCalendarListMaterials),
  takeLatest(getType(actions.DeleteMaterial.request), deleteMaterial),
  takeLatest(getType(actions.FetchAllMaterials.request), FetchAllMaterials)
];
