import { createAction, createAsyncAction } from 'typesafe-actions';
import Language from '../../shared/interfaces/Language';
import Sex from '../../shared/interfaces/Sex';
import CountryCode from '../../shared/interfaces/CountryCode';
import Modules from '../../shared/interfaces/Modules';
import Competency from '../../shared/interfaces/Competency';

export const SetLoading = createAction('[App] Set Loading...', resolve => {
  return (value: { loading: boolean }) => resolve(value);
});

export const RequestActiveModules = createAsyncAction(
  '[App] Requesting modules...',
  '[App] Requesting modules success...',
  '[App] Requesting modules fail...'
)<void, Modules, void>();

export const LoadResources = createAsyncAction(
  '[App] Loading Resources...',
  '[App] Loading Resources Success',
  '[App] Loading Resources Fail'
)<
  void,
  {
    languages: Language[];
    competencies: Competency[];
    sexes: Sex[];
    countryCodes: CountryCode[];
  },
  { errorMessage: string }
>();

export interface ConfirmActionParameters {
  title: string;
  content?: string;
  action: Function;
}
export const ConfirmAction = createAsyncAction('[App] Confirm action', '[App] Confirm action accepted', '[App] Confirm action rejected')<
  ConfirmActionParameters,
  Function,
  void
>();
