import Entity, { EntityReference } from './Entity';
import Sex from './Sex';
import Language from './Language';
import Competency from './Competency';

export enum TrainerType {
  Freelancer = 'freelancer',
  Institute = 'institute',
  NaturalPerson = 'naturalperson'
}

export type WithLanguagesReference = {
  languages: EntityReference[];
};

export type WithLanguages = {
  languages: Language[];
};

export type WithCompetenciesReference = {
  competencies: EntityReference[];
};

export type WithCompetencies = {
  competencies: Competency[];
};

export type Trainer<T = WithLanguagesReference & WithCompetenciesReference> = T &
  Entity & {
    active?: boolean;
    id: number;
    firstName: string;
    lastName: string;
    department?: string;
    fullName?: string;
    email?: string;
    street: string;
    houseNr: string;
    bus?: string;
    postalCode: string;
    city: string;
    country: string;
    remarks?: string;
    type?: TrainerType;
    phoneNr?: string;
    mobileNr?: string;
    courses?: [];
    trainings?: [];
    deletedAt?: string;
  };
