import { DeepReadonly } from 'utility-types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import Client from '../../shared/interfaces/Client';
import Contact from '../../shared/interfaces/Contact';
import { CourseLocation } from '../../shared/interfaces/CourseLocation';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import { TrainingType, TrainingStatus } from '../../shared/interfaces/Training';
import { EntityReference } from '../../shared/interfaces/Entity';
import { Participant, WithTraining, WithParticipations } from '../../shared/interfaces/participant';
import { TrainingRequestStatus } from '../../shared/interfaces/TrainingRequest';
export type ClientsAction = ActionType<typeof actions>;

export interface GQLTraining {
  _id: number;
  id: EntityReference;
  type: TrainingType;
  course: {
    id: number;
    title: string;
    deletedAt: string;
  };
  status: TrainingStatus;
  deletedAt: string;
  trainingSessions: {
    edges: {
      node: {
        startDate: string;
        endDate: string;
      };
    }[];
  };
}

export interface GQLRequest {
  _id: number;
  id: EntityReference;
  course: {
    title: string;
  };
  createdAt: string;
  deletedAt?: string;
  trainingType: TrainingType;
  status: TrainingRequestStatus;
  training: {
    course: {
      title: string;
    };
  };
}

export type ClientsState = DeepReadonly<{
  loadingClientsStatus: ProgressStatus;
  loadingClientStatus: ProgressStatus;
  loadingTrainingsStatus: ProgressStatus;
  loadingRequestsStatus: ProgressStatus;
  saveClientStatus: ProgressStatus;
  loadingParticipantsStatus: ProgressStatus;
  clients: Client[];
  totalClients: number;
  errorMessage: string | null;
  selectedClient: Client | undefined;
  clientContacts: Contact[] | undefined;
  clientLocations: CourseLocation[] | undefined;
  clientTrainings: GQLTraining[] | undefined;
  clientRequests: GQLRequest[] | undefined;
  participants: Participant<WithTraining & WithParticipations>[];
  activeTabIndex: { index: number; client: Client } | undefined;
}>;

const initialState: ClientsState = {
  loadingClientsStatus: ProgressStatus.Uninitialized,
  loadingClientStatus: ProgressStatus.Uninitialized,
  loadingTrainingsStatus: ProgressStatus.Uninitialized,
  saveClientStatus: ProgressStatus.Uninitialized,
  loadingRequestsStatus: ProgressStatus.Uninitialized,
  loadingParticipantsStatus: ProgressStatus.Uninitialized,
  clients: [],
  totalClients: 0,
  errorMessage: null,
  selectedClient: undefined,
  clientContacts: undefined,
  clientLocations: undefined,
  clientTrainings: undefined,
  clientRequests: undefined,
  participants: [],
  activeTabIndex: undefined
};

export function clients(state: ClientsState = initialState, action: ClientsAction): ClientsState {
  switch (action.type) {
    case getType(actions.FetchClients):
      return {
        ...state,
        loadingClientsStatus: ProgressStatus.InProgress,
        clients: []
      };
    case getType(actions.FetchClientsSuccess):
      return {
        ...state,
        loadingClientsStatus: ProgressStatus.Done,
        clients: action.payload.clients,
        totalClients: action.payload.totalClients
      };
    case getType(actions.FetchClientsFail):
      return {
        ...state,
        loadingClientsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchClientContacts.request):
    case getType(actions.FetchOneClient):
      return {
        ...state,
        loadingClientStatus: ProgressStatus.InProgress,
        selectedClient: undefined,
        clientContacts: undefined,
        clientLocations: undefined
      };
    case getType(actions.FetchOneClientSuccess):
      return {
        ...state,
        loadingClientStatus: ProgressStatus.Done,
        selectedClient: action.payload.client
      };
    case getType(actions.FetchClientContacts.success):
      return {
        ...state,
        loadingClientStatus: ProgressStatus.Done,
        clientContacts: action.payload
      };
    case getType(actions.FetchClientLocations.success):
      return {
        ...state,
        loadingClientStatus: ProgressStatus.Done,
        clientLocations: action.payload
      };
    case getType(actions.SaveClient):
      return {
        ...state,
        saveClientStatus: ProgressStatus.InProgress
      };
    case getType(actions.SaveClientSuccess):
      return {
        ...state,
        saveClientStatus: ProgressStatus.Done,
        selectedClient: action.payload.client
      };
    case getType(actions.SaveClientFail):
      return {
        ...state,
        saveClientStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchClientTrainings.request):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.InProgress,
        clientTrainings: undefined
      };
    case getType(actions.FetchClientTrainings.success):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.Done,
        clientTrainings: action.payload
      };
    case getType(actions.FetchClientTrainings.failure):
      return {
        ...state,
        loadingTrainingsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchClientRequests.request):
      return {
        ...state,
        loadingRequestsStatus: ProgressStatus.InProgress,
        clientRequests: undefined
      };
    case getType(actions.FetchClientRequests.success):
      return {
        ...state,
        loadingRequestsStatus: ProgressStatus.Done,
        clientRequests: action.payload
      };
    case getType(actions.FetchClientRequests.failure):
      return {
        ...state,
        loadingRequestsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchContactParticipations.request):
      return {
        ...state,
        loadingParticipantsStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchContactParticipations.success):
      return {
        ...state,
        participants: action.payload,
        loadingParticipantsStatus: ProgressStatus.Done
      };
    case getType(actions.FetchContactParticipations.failure):
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        loadingParticipantsStatus: ProgressStatus.Error
      };
    case getType(actions.SetClientTabIndex):
      return {
        ...state,
        activeTabIndex: { index: action.payload.index, client: action.payload.client }
      };
    default:
      return { ...state };
  }
}
