import Entity, { EntityReference } from '../../../shared/interfaces/Entity';
import { configConstants } from '../../../config/constants';
import { TrainingRequest } from '../../../shared/interfaces/TrainingRequest';
import { fetch } from '../../../core/state/fetch';
import TrainingRequestSession from '../../../shared/interfaces/TrainingRequestSession';
import { Mail } from '../../../shared/interfaces/Mail';
import AlertService from '../../../core/alert/AlertService';

export async function loadEntity<T extends Entity>(reference: EntityReference, embed: string[] = []) {
  const embedPart = embed.map(x => `embed[]=${x}`).join('&');
  const url = configConstants.apiUrl.replace(/\/$/, '') + reference + (embedPart.length > 0 ? `?${embedPart}` : '');
  const response = await fetch(url).then(x => x.json());
  return response as T;
}

export function saveTrainingRequestSession(session: TrainingRequestSession): Promise<TrainingRequestSession> {
  const isNew = session.id === 0;
  const data = {
    method: isNew ? 'POST' : 'PUT',
    body: JSON.stringify({
      ...session,
      id: isNew ? undefined : session.id,
      '@id': isNew ? undefined : session['@id']
    }),
    headers: { 'Content-Type': 'application/json' }
  };
  return fetch(configConstants.apiUrl + 'training_request_sessions' + (isNew ? '' : '/' + session.id), data).then(r => r.json());
}

export function deleteTrainingRequestSession(id: EntityReference) {
  const data = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  };
  return fetch(configConstants.apiUrl.replace(/\/$/, '') + id, data).then(r => {
    return true;
  });
}

export async function generateOrder(request: TrainingRequest): Promise<TrainingRequest> {
  const r = await fetch(configConstants.apiUrl + 'files/training_request/' + request.id + '/generate_order');
  const { orderDocument }: any = await r.json();

  if (orderDocument.accessUrl) {
    return orderDocument.accessUrl;
  }
  throw new Error('No Document found');
}
export function generateOffer(request: TrainingRequest): Promise<TrainingRequest> {
  return fetch(configConstants.apiUrl + 'files/training_request/' + request.id + '/generate_offer').then(r => {
    const { offerDocument }: any = r.json();

    if (offerDocument.accessUrl) {
      return offerDocument.accessUrl;
    }
    throw new Error('No Document found');
  });
}

export function sendInfo(mail: Mail): Promise<Mail> {
  const data = {
    method: 'POST',
    body: JSON.stringify(mail),
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(configConstants.apiUrl + 'training_request_mails', data).then(r => {
    if (r.status >= 200 && r.status < 300) {
      AlertService.setMessage({ title: 'E-mail verstuurd', messageText: 'De e-mail is succesvol verstuurd.', type: 'success' });
      return r.json();
    }

    AlertService.setMessage({ title: 'E-mail niet verstuurd', messageText: 'Oeps er liep iets mis tijdens het versturen.', type: 'error' });
    return r.json();
  });
}
