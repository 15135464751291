import React from 'react';
import { Form, Formik, FormikValues, FormikProps } from 'formik';
import { Button, Container } from 'semantic-ui-react';
import * as Yup from 'yup';
import _ from 'lodash';
import DetailFormField, { DetailFormFieldTypes } from './DetailFormField';
import { FormFieldDefinition, FormDefinition, FormFieldPosition } from '../../interfaces/FormFieldDefinition';
import Entity from '../../interfaces/Entity';
import ErrorFocus from './ErrorFocus';

interface DetailFormProps<T> {
  selectedEntity: T;
  onFormSubmit?: (updatedEntity: FormikValues) => void;
  savingForm?: boolean;
  onCancelBtnClick?: () => void;
  formDefinition: FormDefinition;
  submitText?: string;
  cancelText?: string;
  isSubForm?: boolean;
}

export const convertToDetailFormFields = function(original: { [key: string]: any | any[] }, formDefinition: FormDefinition) {
  const initialValues = { ...original };

  return Object.entries(original).reduce((result, [propertyName, value]) => {
    const definition = formDefinition.find(x => x.propertyName === propertyName);

    if (!definition) {
      return result;
    }

    if (value) {
      if (definition.type === DetailFormFieldTypes.dropdownlist && definition.multiple) {
        return {
          ...result,
          [propertyName]: value.map((x: string) => {
            const numberValue = parseInt(x);
            return isNaN(numberValue) ? x : numberValue;
          })
        };
      }

      if (definition.type === DetailFormFieldTypes.dropdownlist || definition.type === DetailFormFieldTypes.dropdownsearchlist) {
        if (isNaN(parseInt(result[propertyName]))) {
          return { ...result, [propertyName]: result[propertyName] };
        }

        return { ...result, [propertyName]: parseInt(result[propertyName]) };
      }

      return result;
    }

    if (definition.type === DetailFormFieldTypes.checkbox) {
      return { ...result, [propertyName]: false };
    }

    if (original[propertyName] === 0) {
      return { ...result, [propertyName]: 0 };
    }

    if (definition.type === DetailFormFieldTypes.entitySelector) {
      return { ...result, [propertyName]: original[propertyName] };
    }

    return { ...result, [propertyName]: '' };
  }, initialValues);
};

export const convertToDetailFormValidation = function(formDefinition: FormFieldDefinition[]) {
  const valid = formDefinition
    .filter(definition => !!definition.validationChecks)
    .reduce((result, { propertyName, validationChecks }) => ({ ...result, [propertyName || '']: validationChecks }), {});

  return valid;
};

function renderFields(selectedEntity: Entity, formProps: FormikProps<any>, formDefinition: FormDefinition) {
  const { touched, errors, handleChange, handleBlur, values } = formProps;
  return _.sortBy(formDefinition, ['orderNr']).map((definition: FormFieldDefinition) => {
    const { propertyName } = definition;
    return (
      <DetailFormField
        key={propertyName}
        touched={(touched as any)[propertyName || '']}
        error={(errors as any)[propertyName || '']}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[propertyName || '']}
        selectedEntity={selectedEntity}
        {...definition}
      />
    );
  });
}

const DetailForm = <TEntity extends Entity = Entity>({
  selectedEntity,
  onFormSubmit,
  savingForm,
  onCancelBtnClick,
  formDefinition,
  submitText,
  cancelText,
  isSubForm
}: DetailFormProps<TEntity>) => {
  const hiddenFields = formDefinition.filter(d => d.type === DetailFormFieldTypes.hidden);
  const otherFields = formDefinition.filter(d => !hiddenFields.includes(d));
  const leftFields = otherFields.filter(d => d.column === FormFieldPosition.Left || !d.column);
  const rightFields = otherFields.filter(d => d.column === FormFieldPosition.Right);
  const has2Columns = leftFields.length > 0 && rightFields.length > 0;
  return (
    <div>
      <Formik
        initialValues={convertToDetailFormFields(selectedEntity, formDefinition)}
        validationSchema={Yup.object().shape(convertToDetailFormValidation(formDefinition))}
        onSubmit={values => {
          if (onFormSubmit) {
            onFormSubmit(values);
          }
        }}>
        {formProps => (
          <Container>
            <Form
              className="ui form"
              onSubmitCapture={
                isSubForm
                  ? e => {
                      e.stopPropagation();
                      formProps.handleSubmit(e);
                    }
                  : undefined
              }>
              <div className="row">
                <div className={has2Columns ? 'col-md-8' : 'col-md-12'}>{renderFields(selectedEntity, formProps, leftFields)}</div>
                {has2Columns ? (
                  <div className="col-12 col-md-4">
                    <div className="column-devider">{renderFields(selectedEntity, formProps, rightFields)}</div>
                  </div>
                ) : null}
              </div>
              <ErrorFocus />

              {/* Hidden fields are rendered at the bottom because
              they still have a height and mess up the layout */}
              {renderFields(selectedEntity, formProps, hiddenFields)}

              <div className="form-buttons">
                <div>
                  {onCancelBtnClick ? (
                    <Button type="button" onClick={onCancelBtnClick}>
                      {cancelText || 'Annuleren'}
                    </Button>
                  ) : null}
                </div>
                <Button loading={savingForm} disabled={savingForm} type="submit" className="primary">
                  {submitText || 'Opslaan'}
                </Button>
              </div>
            </Form>
          </Container>
        )}
      </Formik>
    </div>
  );
};

export default DetailForm;
