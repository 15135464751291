import * as actions from './actions';
import { configConstants } from '../../config/constants';
import { getType } from 'typesafe-actions';
import { TreeNode } from '../../shared/components/Tree/CourseTree';
import Course from '../../shared/interfaces/Course';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';

const { pageSize } = configConstants;

export type CourseState = {
  coursesStatus: ProgressStatus;
  courseStatus: ProgressStatus;
  loadingCourses: boolean;
  loadingCourse: boolean;
  coursesLoaded: boolean;
  courseLoaded: boolean;
  courseCategories: TreeNode[];
  errorMessage: string | null;
  pageSize: number;
  selectedCourse: Course | undefined;
  courses: Course[];
  savingCourse: boolean;
  courseSaved: boolean;
  totalCourses: number;
  selectedCourseId: number;
  allCourses: Course[];
};

const initialState: CourseState = {
  coursesStatus: ProgressStatus.Uninitialized,
  courseStatus: ProgressStatus.Uninitialized,
  courses: [],
  loadingCourse: false,
  loadingCourses: false,
  coursesLoaded: false,
  courseCategories: [],
  errorMessage: null,
  pageSize: pageSize,
  selectedCourse: undefined,
  selectedCourseId: -1,
  totalCourses: 0,
  savingCourse: false,
  courseSaved: false,
  courseLoaded: false,
  allCourses: []
};

export function courses(state: CourseState = initialState, action: any): CourseState {
  switch (action.type) {
    case getType(actions.FetchCourseCategories):
      return {
        ...state,
        loadingCourses: true,
        coursesLoaded: false,
        courseCategories: []
      };
    case getType(actions.FetchCourseCategoriesSuccess):
      return {
        ...state,
        loadingCourses: false,
        coursesLoaded: true,
        courseCategories: action.payload.courseCategories
      };
    case getType(actions.FetchCourseCategoriesFail):
      return {
        ...state,
        loadingCourses: false,
        coursesLoaded: true,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchCourses):
      return {
        ...state,
        coursesStatus: ProgressStatus.InProgress,
        courses: []
      };
    case getType(actions.FetchCoursesSuccess):
      return {
        ...state,
        coursesStatus: ProgressStatus.Done,
        courses: action.payload.courses,
        totalCourses: action.payload.totalCourses
      };
    case getType(actions.FetchCoursesFail):
      return {
        ...state,
        coursesStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveCourse):
      return { ...state, savingCourse: true, courseSaved: false };
    case getType(actions.SaveCourseSuccess):
      return {
        ...state,
        savingCourse: false,
        selectedCourse: action.payload.course,
        courseSaved: true
      };
    case getType(actions.SaveCourseFail):
      return {
        ...state,
        savingCourse: false,
        errorMessage: action.payload.errorMessage,
        courseSaved: false
      };
    case getType(actions.FetchOneCourse):
      return {
        ...state,
        courseStatus: ProgressStatus.InProgress,
        selectedCourse: undefined
      };
    case getType(actions.FetchOneCourseSuccess):
      return {
        ...state,
        courseStatus: ProgressStatus.Done,
        selectedCourse: action.payload.course
      };
    case getType(actions.FetchAllCourses.request):
      return {
        ...state,
        coursesStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchAllCourses.success):
      return {
        ...state,
        coursesStatus: ProgressStatus.Done,
        allCourses: action.payload
      };
    case getType(actions.FetchAllCourses.failure):
      return {
        ...state,
        coursesStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    default:
      return { ...state };
  }
}
