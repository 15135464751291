import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CustomerForm from '../CustomerForm/CustomerForm';
import App from './App';
import AlertService from './alert/AlertService';
import { RouteComponentProps } from 'react-router';
import Webhook from '../webhook/Webhook';

export default () => (
  <Switch>
    <Route path={'/webhook/:c'} render={props => <Webhook {...props} />} />
    <Route path={'/webhook'} render={props => <Webhook {...props} />} />
    <Route path={'/customer-form'} render={props => <CustomerForm {...props} />} />
    <Route render={() => <App />} />
  </Switch>
);
