import { DropDownOption } from '../interfaces/FormFieldDefinition';
import i18next from '../../config/i18';
import Language from '../interfaces/Language';
import { $enum } from 'ts-enum-util';
import Competency from '../interfaces/Competency';

const t = (text: string) => i18next.t(text, { ns: ['resources'] });

export const enumToOption = (options: { [key: string]: any }): DropDownOption[] => {
  return $enum(options)
    .getEntries()
    .map((option, key) => ({
      key,
      value: option[1],
      text: t(option[0])
    }));
};

export const arrayToOption = (options: string[]): DropDownOption[] => {
  return options.map((option, key) => ({
    key,
    value: option,
    text: t(option)
  }));
};

export const languagesToOptions = (languages: Language[]): DropDownOption[] => {
  return languages.map((value: Language) => ({
    key: value.id,
    value: value['@id'],
    text: t(value.iso)
  }));
};

export const competenciesToOptions = (competencies: Competency[]): DropDownOption[] => {
  return competencies.map((value: Competency) => ({
    key: value.id,
    value: value['@id'],
    text: t(value.value)
  }));
};

export const languageCodesToOptions = (languages: Language[]): DropDownOption[] => {
  return languages.map((value: Language) => ({
    key: value.id,
    value: value.iso,
    text: t(value.iso)
  }));
};
