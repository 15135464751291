import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actions from './actions';
import { getType } from 'typesafe-actions';
import PredefinedTask from '../../shared/interfaces/PredefinedTask';
import TaskList from '../../shared/interfaces/TaskList';
import { push } from 'connected-react-router';
import { fetch } from '../../core/state/fetch';
import { configConstants } from '../../config/constants';
import AlertService from '../../core/alert/AlertService';
import queryString from 'query-string';
import Course from '../../shared/interfaces/Course';

export function* saveTaskList(action: ReturnType<typeof actions.SaveTaskList.request>) {
  try {
    const isNew = action.payload.id === undefined;
    const taskList = action.payload;
    delete taskList.trainingPredefinedTasks;

    const response = yield call(fetch, `${configConstants.apiUrl}training_predefined_task_lists${isNew ? '' : `/${action.payload.id}`}`, {
      method: isNew ? 'POST' : 'PUT',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    AlertService.setMessage({
      title: 'Takenlijst opgeslagen',
      messageText: 'Uw takenlijst is succesvol opgeslagen.',
      type: 'success'
    });

    if (isNew && response.status >= 200 && response.status < 300) {
      const taskList = yield response.json() as TaskList;

      yield put(push(`/task-lists/${taskList.id}`));
    }

    yield put(actions.SaveTaskList.success());
  } catch (errorMessage) {
    yield put(actions.SaveTaskList.failure({ errorMessage }));
  }
}

export function* savePredefinedTask(action: ReturnType<typeof actions.SavePredefinedTask.request>) {
  try {
    const task = { ...action.payload.task, trainingPredefinedTaskLists: [action.payload.taskList['@id']] };

    yield call(fetch, `${configConstants.apiUrl}training_predefined_tasks`, {
      method: 'POST',
      body: JSON.stringify(task),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    yield put(actions.FetchTaskList.request(action.payload.taskList.id!));

    yield put(actions.SaveTaskList.success());
  } catch (errorMessage) {
    yield put(actions.SaveTaskList.failure({ errorMessage }));
  }
}

export function* fetchTaskList(action: ReturnType<typeof actions.FetchTaskList.request>) {
  const response = yield call(
    fetch,
    `${configConstants.apiUrl}training_predefined_task_lists/${action.payload}?embed[]=trainingpredefinedtask`
  );

  if (response.status >= 200 && response.status < 300) {
    const taskList = yield response.json() as TaskList;

    yield put(actions.FetchTaskList.success(taskList));
  } else {
    throw response;
  }
}

export function* fetchTaskLists(action: ReturnType<typeof actions.FetchTaskLists.request>) {
  try {
    const { sort, order } = action.payload;
    delete action.payload.sort;
    delete action.payload.order;

    const showActive = action.payload.active;
    delete action.payload.active;
    let query = queryString.stringify(action.payload);

    query += sort ? `&order[${sort}]=` + (order || 'asc') : '&order[updatedAt]=desc';

    if (!showActive) {
      query += '&active=1';
    }

    if (showActive === 'false') {
      query += '&active=0';
    }

    const response = yield call(fetch, `${configConstants.apiUrl}training_predefined_task_lists?${query}`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json() as TaskList[];
      yield put(actions.FetchTaskLists.success({ taskLists: data['hydra:member'], totalTaskLists: data['hydra:totalItems'] }));
    } else {
      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.FetchTaskLists.failure({ errorMessage }));
  }
}

function* deleteTaskList(action: ReturnType<typeof actions.DeleteTaskList.request>) {
  try {
    const url = `training_predefined_task_lists/${action.payload.taskListId}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });
    yield put(actions.FetchTaskLists.request(action.payload.queryParams));

    AlertService.setMessage({
      title: 'Takenlijst verwijderd',
      messageText: 'Het takenlijst is succesvol verwijderd',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.DeleteTaskList.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Takenlijst niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
      type: 'error'
    });
  }
}

function* deletePredefinedTask(action: ReturnType<typeof actions.DeletePredefinedTask.request>) {
  try {
    const url = `training_predefined_tasks/${action.payload.task.id}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });

    yield put(actions.FetchTaskList.request(action.payload.taskList.id!));
  } catch (errorMessage) {
    yield put(actions.DeletePredefinedTask.failure({ errorMessage }));
  }
}

export const taskListsSaga = [
  takeLatest(getType(actions.FetchTaskLists.request), fetchTaskLists),
  takeLatest(getType(actions.FetchTaskList.request), fetchTaskList),
  takeLatest(getType(actions.SaveTaskList.request), saveTaskList),
  takeLatest(getType(actions.SavePredefinedTask.request), savePredefinedTask),
  takeLatest(getType(actions.DeleteTaskList.request), deleteTaskList),
  takeLatest(getType(actions.DeletePredefinedTask.request), deletePredefinedTask)
];
