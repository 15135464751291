import SearchTextForm from '../SearchTextForm';
import { configConstants } from '../../../../../../config/constants';
import Contact from '../../../../../interfaces/Contact';
import { EntityReference } from '../../../../../interfaces/Entity';
import TrainingParticipant from '../../../../../interfaces/TrainingParticipant';
import { fetch } from '../../../../../../core/state/fetch';

export async function searchContacts(
  searchText: string,
  page?: number,
  sortField?: string,
  sortDirection?: 'asc' | 'desc',
  filterCondition?: string
) {
  const url = configConstants.apiUrl.replace(/\/$/, '') + '/contacts?search_text=' + searchText;
  const response = await fetch(url).then(r => r.json());
  const entities: Contact[] = response['hydra:member'] || [];
  const amount: number = response['hydra:totalItems'] || 1;
  return { entities, amount };
}

export default class SearchContactForm extends SearchTextForm<Contact> {
  placeholder = 'Zoek contact';
  searchEntities = searchContacts;
}

export class SearchContactForParticipantForm extends SearchTextForm<Contact> {
  placeholder = 'Zoek contact';
  searchEntities = (searchText: string) => {
    return searchContacts(searchText, 1, 'updatedAt', 'asc', undefined);
  };
}
