import React from 'react';
import * as Yup from 'yup';
import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import Course from '../../shared/interfaces/Course';
import Entity from '../../shared/interfaces/Entity';
import SearchCourseForm from '../../shared/components/DetailForm/EntitySelector/SelectForms/SearchCourseForm';
import { TrainerType } from '../../shared/interfaces/Trainer';
import { enumToOption, arrayToOption, languagesToOptions, competenciesToOptions } from '../../shared/helpers/dropDownHelpers';
import Sex from '../../shared/interfaces/Sex';
import { countryCodes } from '../../shared/constants';
import { FormDefinition, FormFieldPosition } from '../../shared/interfaces/FormFieldDefinition';
import Language from '../../shared/interfaces/Language';
import { vatValidation } from '../../shared/helpers/vatValidation';
import iban from 'iban';
import { emailValidation } from '../../shared/helpers/emailValidation';
import { phoneValidation } from '../../shared/helpers/phoneValidation';
import Competency from '../../shared/interfaces/Competency';

export const formDefinition = (languages: Language[], competencies: Competency[]) =>
  [
    {
      propertyName: 'id',
      label: 'Id',
      type: DetailFormFieldTypes.hidden,
      orderNr: 1
    },
    {
      propertyName: 'firstName',
      label: 'Voornaam',
      type: DetailFormFieldTypes.text,
      orderNr: 5,
      disabled: true
    },
    {
      propertyName: 'lastName',
      label: 'Naam',
      type: DetailFormFieldTypes.text,
      orderNr: 10,
      disabled: true
    },
    {
      propertyName: 'department',
      label: 'Afdeling',
      type: DetailFormFieldTypes.text,
      orderNr: 15,
      disabled: true
    },
    {
      propertyName: 'type',
      label: 'Type',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 30,
      dropDownOptions: enumToOption(TrainerType),
      disabled: true
    },
    {
      propertyName: undefined,
      type: DetailFormFieldTypes.header,
      label: 'Adres',
      column: FormFieldPosition.Right,
      orderNr: 49
    },
    {
      propertyName: 'street',
      label: 'Straat',
      type: DetailFormFieldTypes.text,
      orderNr: 50,
      column: FormFieldPosition.Right,
      disabled: true
    },
    {
      propertyName: 'houseNr',
      label: 'Huisnummer',
      type: DetailFormFieldTypes.text,
      orderNr: 60,
      column: FormFieldPosition.Right,
      disabled: true
    },
    {
      propertyName: 'bus',
      label: 'Bus',
      type: DetailFormFieldTypes.text,
      orderNr: 60,
      column: FormFieldPosition.Right,
      disabled: true
    },
    {
      propertyName: 'postalCode',
      label: 'Postcode',
      type: DetailFormFieldTypes.text,
      orderNr: 70,
      column: FormFieldPosition.Right,
      disabled: true
    },
    {
      propertyName: 'city',
      label: 'Plaats',
      type: DetailFormFieldTypes.text,
      orderNr: 80,
      column: FormFieldPosition.Right,
      disabled: true
    },
    {
      propertyName: 'country',
      label: 'Land',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 90,
      dropDownOptions: arrayToOption(countryCodes),
      column: FormFieldPosition.Right,
      disabled: true
    },
    {
      propertyName: 'phoneNr',
      label: 'Telefoonnummer',
      type: DetailFormFieldTypes.telephone,
      orderNr: 110,
      disabled: true
    },
    {
      propertyName: 'mobileNr',
      label: 'GSM-nummer',
      type: DetailFormFieldTypes.telephone,
      orderNr: 115,
      disabled: true
    },
    {
      propertyName: 'email',
      label: 'E-mailadres',
      type: DetailFormFieldTypes.email,
      orderNr: 120,
      disabled: true
    },
    {
      propertyName: 'languages',
      label: 'Talen',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 140,
      validationChecks: Yup.array(),
      multiple: true,
      dropDownOptions: languagesToOptions(languages)
    },
    {
      propertyName: 'competencies',
      label: 'Competenties',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 140,
      validationChecks: Yup.array(),
      multiple: true,
      dropDownOptions: competenciesToOptions(competencies)
    },
    {
      propertyName: 'active',
      label: 'Actief',
      type: DetailFormFieldTypes.checkbox,
      orderNr: 500,
      column: FormFieldPosition.Right
    },
    {
      propertyName: 'remarks',
      label: 'Opmerkingen',
      type: DetailFormFieldTypes.textbox,
      orderNr: 250,
      disabled: true
    }
  ] as FormDefinition;

export const SimpleFormTrainerDefinition = (languages: Language[]) =>
  [
    {
      propertyName: 'id',
      label: 'Id',
      type: DetailFormFieldTypes.hidden,
      orderNr: 1
    },
    {
      propertyName: 'firstName',
      label: 'Voornaam',
      type: DetailFormFieldTypes.text,
      orderNr: 5,
      validationChecks: Yup.string().required('Voornaam moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'lastName',
      label: 'Naam',
      type: DetailFormFieldTypes.text,
      orderNr: 10,
      validationChecks: Yup.string().required('Naam moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'type',
      label: 'Type',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 30,
      dropDownOptions: enumToOption(TrainerType)
    },
    {
      propertyName: 'phoneNr',
      label: 'Telefoonnummer',
      validationChecks: Yup.string()
        .test('phone', 'Dit telefoonnummer is niet geldig', phoneValidation)
        .required('Telefoonnummer moet ingevuld worden.'),
      required: true,
      type: DetailFormFieldTypes.telephone,
      orderNr: 110
    },
    {
      propertyName: 'email',
      label: 'E-mailadres',
      type: DetailFormFieldTypes.email,
      required: true,
      validationChecks: Yup.string()
        .test('email', 'Dit email is niet geldig', emailValidation)
        .required('E-mailadres moet ingevuld worden.'),
      orderNr: 120
    },
    {
      propertyName: 'languages',
      label: 'Talen',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 140,
      validationChecks: Yup.array()
        .of(Yup.string())
        .required('Taal moet ingevuld worden.'),
      required: true,
      multiple: true,
      dropDownOptions: languagesToOptions(languages)
    }
  ] as FormDefinition;
