import React, { useEffect, useState } from 'react';
import Course from '../../interfaces/Course';
import { Segment, Button, Input } from 'semantic-ui-react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { CustomCheckbox } from '../Checkbox';
import InitialLoader from '../InitialLoader';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GlobalState } from '../../../core/state';
import { FetchAllCourses } from '../../../courses/state/actions';

interface SelectorProps {
  originalSelected: string[];
  selected: (keys: string[]) => void;
  response: Course[];
  loadCourses: () => void;
}

const Selector = ({ originalSelected, selected, t, response, loadCourses }: SelectorProps & WithNamespaces) => {
  const [collection, setCollection] = useState<Course[]>([]);
  const [selection, setSelection] = useState<string[]>(originalSelected);

  useEffect(() => {
    loadCourses();
  }, []);

  useEffect(() => {
    setCollection(response);
  }, [response]);

  const isActive = (id: string) => {
    if (selection.indexOf(id) === -1) {
      return false;
    }

    return true;
  };

  const pushEntity = (id: string) => {
    if (selection.indexOf(id) === -1) {
      setSelection([...selection, id]);
    } else {
      setSelection(selection.filter(x => x !== id));
    }
  };

  const filter = (value: string) => {
    setCollection(response.filter(x => x.title.toLowerCase().includes(value)));
  };

  const sliceCount = Math.ceil(collection.length / 2);

  return (
    <>
      <div className="custom-entity-selector-header">
        <Input type="text" icon="search" iconPosition="left" onChange={e => filter(e.target.value)} />
        <Button className="primary" onClick={() => selected(selection)}>
          {t('save')}
        </Button>
      </div>

      {collection.length ? (
        <>
          <div className="row custom-multiple-selector">
            <div className="col-12 col-md-6">
              {collection.slice(0, sliceCount).map((r: Course, key: number) => {
                return (
                  <Segment key={'col-1-' + key} className={isActive(r['@id']) ? 'active' : ''} onClick={() => pushEntity(r['@id'])}>
                    <CustomCheckbox checked={isActive(r['@id'])} />
                    {r['title']}
                  </Segment>
                );
              })}
            </div>
            <div className="col-12 col-md-6">
              {collection.slice(sliceCount, collection.length + 1).map((r: Course, key: number) => {
                return (
                  <Segment key={'col-2-' + key} className={isActive(r['@id']) ? 'active' : ''} onClick={() => pushEntity(r['@id'])}>
                    <CustomCheckbox checked={isActive(r['@id'])} />
                    {r['title']}
                  </Segment>
                );
              })}
            </div>
          </div>
          <div className="custom-entity-selector-footer">
            <Button className="primary mt-4" onClick={() => selected(selection)}>
              {t('save')}
            </Button>
          </div>
        </>
      ) : (
        <InitialLoader />
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    response: state.courses.allCourses
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCourses: () => dispatch(FetchAllCourses.request())
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces(['common', 'resources'], { nsMode: 'fallback' })(Selector));
