import React, { SyntheticEvent } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';
import { setHours, setMinutes } from 'date-fns/esm';

interface Props {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: any) => void;
  value: Date | undefined;
  label: string;
  keyField: string;
  hasTimeField?: boolean;
}

const DatePicker = ({ onChange, value, keyField, hasTimeField }: Props) => {
  
  // data coming from the api is in Europe Brussels, but FE thinks it's utc because of the timezone "+00:00", so dirty hack to keep the same time
  const utcHack = (date?: Date) => {
    if (typeof date === 'undefined') {
      return new Date()
    }

    date.setMinutes(date.getMinutes() + date.getTimezoneOffset())

    return date
  }

  const reverseUtcHack = (date?: Date) => {
    if (typeof date === 'undefined') {
      return new Date()
    }

    date.setMinutes(date.getMinutes() - date.getTimezoneOffset())

    return date
  }
  
  const handleDateChange = (value: Date) => {
    console.log('changing')
    onChange({
      target: {
        name: keyField,
        value: moment(reverseUtcHack(value)).toISOString(true)
      }
    });
  };

  return (
    <Input iconPosition="left" type="text" placeholder={value} className="datePicker">
      <Icon name="calendar alternate outline" />
      {hasTimeField === false ? (
        <ReactDatePicker selected={utcHack(value)} onChange={handleDateChange} timeIntervals={30} dateFormat="dd/MM/yyyy" />
      ) : (
        <ReactDatePicker
          selected={utcHack(value)}
          onChange={handleDateChange}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={30}
          minTime={setHours(setMinutes(new Date(), 0), 7)}
          maxTime={setHours(setMinutes(new Date(), 0), 22)}
          dateFormat="dd/MM/yyyy - H:mm"
        />
      )}
    </Input>
  );
};

export default DatePicker;
