import React, { Component } from 'react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { TreeNode, treeNodeTypes } from '../Tree/CourseTree';
import { Segment, Icon } from 'semantic-ui-react';
import './Tree.scss';

type TreeProps = {
  title?: string;
  data: TreeNode[];
  toggleDetail: (nodeId: number) => void;
};
interface DataTreeState {
  data: TreeNode[];
}

class DataTree extends Component<TreeProps & DataTreeState> {
  state = {
    data: []
  };

  componentWillMount = ({ data } = this.props) => {
    this.setState((prevState: DataTreeState) => ({
      ...prevState,
      data
    }));
  };

  render() {
    return (
      <Segment style={{ height: 3000, width: '100%', border: 0, padding: 0 }}>
        <SortableTree
          treeData={this.state.data}
          canDrag={false}
          onChange={data => {
            this.setState({ data });
          }}
          generateNodeProps={({ node }) => ({
            title: (
              <div
                onClick={() => node['@type'] === treeNodeTypes.Course && this.props.toggleDetail(node.id)}
                style={{ fontSize: '1.1rem', cursor: 'pointer' }}>
                <Icon
                  color="grey"
                  name={node['@type'] === treeNodeTypes.Course ? 'book' : 'folder open'}
                  className={node['@type'] === treeNodeTypes.Course ? 'primary' : 'second'}
                />
                <p className={node['@type'] === treeNodeTypes.Course ? 'course-title' : 'category-title'}> {node.title} </p>
              </div>
            )
          })}
        />
      </Segment>
    );
  }
}
export default DataTree;
