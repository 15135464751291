import { call, put, takeLatest } from 'redux-saga/effects';
import { LocationsActionTypes } from './actions';
import { push, replace } from 'connected-react-router';
import { configConstants } from '../../config/constants';
import * as actions from './actions';
import _ from 'lodash';
import { GQLSessionLocations } from '../../shared/interfaces/GQLSessionLocations';
import { CourseLocation, WithClient } from '../../shared/interfaces/CourseLocation';
import CalendarListItem from '../../shared/interfaces/CalendarLocation';
import { toLocationQuery } from '../helpers/toLocationQuery';
import saveLocation from './api/saveLocation';
import { getType } from 'typesafe-actions';
import { fetch } from '../../core/state/fetch';
import AlertService from '../../core/alert/AlertService';
import TrainingSession, { WithTraining } from '../../shared/interfaces/TrainingSession';

export function* loadEntities(action: ReturnType<typeof actions.FetchLocations.request>) {
  try {
    const { page, order, sort, searchValue } = action.payload;
    let queryString: string[] = [];
    if (page) {
      queryString = [...queryString, `page=${page}`];
    }

    queryString = [...queryString, sort ? `&order[${sort}]=` + (order || 'asc') : '&order[updatedAt]=desc'];

    if (searchValue) {
      queryString = [...queryString, `search_text=${searchValue}`];
    }

    const response = yield call(fetch, `${configConstants.apiUrl}locations?${_.join(queryString, '&')}`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();
      const locations = data['hydra:member'] as CourseLocation[];
      const totalLocations = data['hydra:totalItems'] as number;

      yield put(actions.FetchLocations.success({ locations, totalLocations }));
    }
  } catch (error) {
    yield put(actions.FetchLocations.failure({ errorMessage: error }));
  }
}

export function* loadOneEntity(action: ReturnType<typeof actions.FetchOneLocation.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}locations/${action.payload}?embed[]=customer`);
    if (response.status >= 200 && response.status < 300) {
      const data: CourseLocation<WithClient> = yield response.json();
      yield put(actions.FetchOneLocation.success({ location: data }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put({
      type: LocationsActionTypes.FetchOneLocationFail,
      payload: { errorMessage: error }
    });
  }
}

export function* saveEntity(action: ReturnType<typeof actions.SaveLocation.request>) {
  try {
    const { location, clientId, returnUrl, activeIndex } = action.payload;

    let customer = undefined;

    if (clientId) {
      customer = clientId;
    }

    if (action.payload.location.customer) {
      customer = action.payload.location.customer;
    }

    const response = (yield call(saveLocation, {
      ...action.payload.location,
      customer: customer,
      contactPersonFirstName: action.payload.location.isInternalLocation ? action.payload.location.contactPersonFirstName : '',
      contactPersonLastName: action.payload.location.isInternalLocation ? action.payload.location.contactPersonLastName : '',
      contactPersonEmail: action.payload.location.isInternalLocation ? action.payload.location.contactPersonEmail : '',
      contactPersonPhoneNr: action.payload.location.isInternalLocation ? action.payload.location.contactPersonPhoneNr : ''
    })) as CourseLocation;
    yield put(actions.SaveLocation.success({ location: response }));
    yield put(actions.FetchOneLocation.request(response.id.toString()));
    AlertService.setMessage({
      title: 'Locatie opgeslagen',
      messageText: 'De locatie is succesvol opgeslagen.',
      type: 'success'
    });

    if (!action.payload.noredirect) {
      if (returnUrl) {
        yield put(replace(`${returnUrl}`, { activeIndex }));
      } else if (location.id === 0) {
        yield put(push(`/locations/`));
      } else {
        yield put(push(`/locations/${location.id}`));
      }
    }
  } catch (error) {
    yield put(actions.SaveLocation.failure({ errorMessage: error }));

    AlertService.setMessage({
      title: 'Locatie niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
      type: 'error'
    });
  }
}

export function* loadSessionLocations(action: ReturnType<typeof actions.FetchTrainingSessionLocations.request>) {
  const { start, end, locations } = action.payload;

  const query = `{
    trainingSessions(
      first: 1000
      location_list: ${locations ? JSON.stringify((locations as string).split(',')) : '[]'} 
      startDate: ${start ? `{ after: ${JSON.stringify(start)} }` : '{}'}
      endDate: ${end ? `{ before: ${JSON.stringify(end)} }` : '{}'}
      ) {
      edges {
         node {
            _id
            startDate 
            endDate 
            training { 
              _id 
              course { 
                _id 
                title 
              },
             deletedAt
            } 
            location { 
              _id 
              name 
              displayColor 
            }
          }
        }
      }
    }`;
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);
    if (response.status >= 200 && response.status < 300) {
      const result = yield response.json();
      yield put(
        actions.FetchTrainingSessionLocations.success({
          sessionLocations: result.data as GQLSessionLocations
        })
      );
    }
  } catch (error) {
    yield put(actions.FetchTrainingSessionLocations.failure({ errorMessage: error }));
  }
}

export function* loadCalendarListLocations(action: ReturnType<typeof actions.FetchCalendarListLocations.request>) {
  try {
    let cursor = '';
    let hasNextPage = false;
    let locations: CalendarListItem[] = [];
    do {
      const query = toLocationQuery(cursor);
      const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);
      if (response.status >= 200 && response.status < 300) {
        const result: any = yield response.json();
        cursor = result.data.locations.edges.slice(-1)[0].cursor;
        hasNextPage = result.data.locations.pageInfo.hasNextPage;
        locations = [...locations, ...result.data.locations.edges.map((item: any) => item.node)];
      } else {
        break;
      }
    } while (hasNextPage);

    if (locations.length >= 1) {
      yield put(
        actions.FetchCalendarListLocations.success({
          calendarLocationList: locations
        })
      );
    }
  } catch (error) {
    yield put(actions.FetchCalendarListLocations.failure({ errorMessage: error }));
  }
}

export function* loadSessionsForLocation(action: ReturnType<typeof actions.FetchSessionsForLocation.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}training_sessions?embed[]=training&embed[]=course&location=${action.payload.id}`
    );

    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();
      const sessions = data['hydra:member'] as TrainingSession<WithTraining>[];
      yield put(actions.FetchSessionsForLocation.success({ sessions }));
    } else {
      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.FetchSessionsForLocation.failure({ errorMessage }));
  }
}

function* deleteLocation(action: ReturnType<typeof actions.DeleteLocation.request>) {
  try {
    const url = `locations/${action.payload.locationId}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });
    yield put(actions.FetchLocations.request(action.payload.queryParams));

    AlertService.setMessage({
      title: 'Locatie verwijderd',
      messageText: 'De locatie is succesvol verwijderd.',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.DeleteLocation.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Locatie niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen van de locatie.',
      type: 'error'
    });
  }
}

export const locationsSaga = [
  takeLatest(LocationsActionTypes.FetchLocations, loadEntities),
  takeLatest(LocationsActionTypes.SaveLocation, saveEntity),
  takeLatest(LocationsActionTypes.FetchOneLocation, loadOneEntity),
  takeLatest(LocationsActionTypes.FetchTrainingSessionLocations, loadSessionLocations),
  takeLatest(LocationsActionTypes.FetchCalendarListLocations, loadCalendarListLocations),
  takeLatest(getType(actions.FetchSessionsForLocation.request), loadSessionsForLocation),
  takeLatest(getType(actions.DeleteLocation.request), deleteLocation)
];
