import * as Yup from 'yup';
import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormDefinition, FormFieldPosition } from '../../shared/interfaces/FormFieldDefinition';

export const formDefinition: FormDefinition = [
  {
    propertyName: 'id',
    label: 'Id',
    type: DetailFormFieldTypes.hidden,
    orderNr: 1
  },
  {
    propertyName: 'name',
    label: 'Naam',
    type: DetailFormFieldTypes.text,
    orderNr: 5,
    validationChecks: Yup.string().required('Naam moet ingevuld worden.'),
    required: true,
    column: FormFieldPosition.Left
  },
  {
    propertyName: 'rate',
    label: 'Prijs',
    type: DetailFormFieldTypes.currency,
    orderNr: 10,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required('Prijs moet ingevuld worden.'),
    required: true,
    column: FormFieldPosition.Left
  },
  {
    propertyName: 'displayColor',
    label: 'Kleur',
    type: DetailFormFieldTypes.color,
    orderNr: 20,
    column: FormFieldPosition.Right
  }
];

export const SimpleFormMaterialDefinition = () => formDefinition as FormDefinition;
