import React from 'react';
import * as Yup from 'yup';
import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormDefinition } from '../../shared/interfaces/FormFieldDefinition';
import Entity from '../../shared/interfaces/Entity';
import CourseCategory from '../../shared/interfaces/CourseCategory';
import SearchCategoryForm from '../../shared/components/DetailForm/EntitySelector/SelectForms/SearchCategoryForm';

export const formDefinition: FormDefinition = [
  {
    propertyName: 'id',
    label: 'Id',
    type: DetailFormFieldTypes.hidden,
    orderNr: 1
  },
  {
    propertyName: 'title',
    label: 'Titel',
    type: DetailFormFieldTypes.text,
    orderNr: 2,
    validationChecks: Yup.string().required('Titel moet ingevuld worden.'),
    required: true
  },
  {
    propertyName: 'parent',
    label: 'Categories',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 3,
    validationChecks: Yup.string()
      .nullable(true)
      .notRequired(),
    multiple: false,
    isNewVersion: true,
    dataTableFields: [{ key: 'title' }],
    renderEntity: (courseCategory: Entity) => <span key={courseCategory['@id']}>{(courseCategory as CourseCategory).title}</span>,
    searchEntityForm: SearchCategoryForm
  }
];

export const SimpleFormCategoryDefinition = () =>
  [
    {
      propertyName: 'id',
      label: 'Id',
      type: DetailFormFieldTypes.hidden,
      orderNr: 1
    },
    {
      propertyName: 'title',
      label: 'Titel',
      type: DetailFormFieldTypes.text,
      orderNr: 2,
      validationChecks: Yup.string().required('Titel moet ingevuld worden.'),
      required: true
    },
    {
      propertyName: 'parent',
      label: 'Categories',
      type: DetailFormFieldTypes.entitySelector,
      orderNr: 3,
      validationChecks: Yup.string()
        .nullable(true)
        .notRequired(),
      multiple: false,
      isNewVersion: true,
      dataTableFields: [{ key: 'title' }],
      renderEntity: (courseCategory: Entity) => <span key={courseCategory['@id']}>{(courseCategory as CourseCategory).title}</span>,
      searchEntityForm: SearchCategoryForm
    }
  ] as FormDefinition;
