import React, { useState, useEffect, useContext, ReactNode } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import jwt_decode from 'jwt-decode';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

interface Auth0ContextProps {
  isAuthenticated?: boolean;
  user: any;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup: any;
  handleRedirectCallback: any;
  getIdTokenClaims: any;
  loginWithRedirect: any;
  getTokenSilently: any;
  getTokenWithPopup: any;
  logout: any;
  hasAdminAccess: boolean;
  hasUserAccess: boolean;
  userHasScopes: any;
  children?: any;
}

export const Auth0Context = React.createContext({} as Auth0ContextProps);
export const useAuth0 = () => useContext(Auth0Context);

let _initOptions: Auth0ClientOptions;
let client: Auth0Client | PromiseLike<Auth0Client> | undefined;

const getAuth0Client = (): Promise<Auth0Client> => {
  return new Promise(async (resolve, reject) => {
    if (client) {
      resolve(client);
    } else if (!client && _initOptions) {
      try {
        client = await createAuth0Client(_initOptions);
        resolve(client);
      } catch (e) {
        reject(new Error(`getAuth0Client Error: ${e}`));
      }
    }
  });
};

export const getTokenSilently = async (...p: any[]) => {
  const localClient = await getAuth0Client();

  return await localClient.getTokenSilently(...p);
};

export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [userScopes, setUserScopes] = useState([] as string[]);
  const [hasUserAccess, setHasUserAccess] = useState(false);
  const [hasAdminAccess, setHasAdminAccess] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      _initOptions = initOptions;
      const auth0FromHook = await getAuth0Client();
      setAuth0(auth0FromHook);
      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        const access_token = await auth0FromHook.getTokenSilently();
        const scopes = jwt_decode(access_token);
        const perm = (scopes as any)['permissions'];
        setUserScopes([...perm]);
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHasAdminAccess(userHasScopes(['role:admin']));
    setHasUserAccess(userHasScopes(['role:user']));
  }, [userScopes]);

  const userHasScopes = (routeScopes: string[]) => {
    const scopes = userScopes;
    return scopes.some(scope => routeScopes.includes(scope));
  };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const logout = (...p: any) => {
    auth0Client.logout({
      client_id: initOptions.client_id,
      returnTo: `${window.location.origin}`
    });
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        hasAdminAccess,
        hasUserAccess,
        userHasScopes: (p: string[]) => userHasScopes(p),
        getIdTokenClaims: (...p: any) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p: any) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p: any) => auth0Client.getTokenWithPopup(...p),
        logout
      }}>
      {children}
    </Auth0Context.Provider>
  );
};
