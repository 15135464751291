import React, { SyntheticEvent, Fragment } from 'react';
import { Form, Accordion, Icon, Button } from 'semantic-ui-react';
import { FormFieldDefinition, EntitySelectorFieldDefinition } from '../../interfaces/FormFieldDefinition';
import DetailForm from '../DetailForm/DetailForm';
import { RouteComponentProps } from 'react-router';
import { updateQueryString, queryParams, generateCsvExportUrl } from '../../helpers/queryStringHelpers';
import QueryParams from '../../interfaces/QueryParams';
import { getEnvSettings } from '../../../core/environments/environments';
import { WithNamespaces, translate } from 'react-i18next';
import { fetch } from '../../../core/state/fetch';
import { configConstants } from '../../../config/constants';

type DataTableAdvancedFilterProps = {
  onChange?: (e: SyntheticEvent) => void;
  searchFields: FormFieldDefinition[];
  fieldSet?: string[];
} & RouteComponentProps &
  WithNamespaces;

function defaultValues(formDefinition: FormFieldDefinition[], props: RouteComponentProps) {
  const queryValues = queryParams<QueryParams>(props.location.search);

  return formDefinition
    .filter(definition => !!definition.propertyName)
    .reduce(
      (result, fieldDefinition) => {
        const multiple = (fieldDefinition as EntitySelectorFieldDefinition).multiple;
        const defaultValue = multiple ? [] : '';
        const queryValue = queryValues[fieldDefinition.propertyName!];
        /*
      If a "multiple" query value only has one value selected it will be seen as a single value, and thus will not be an array.
      But in that case we explicitely make it an array so the other code doesn't break.
      */

        const fixedQueryValue = multiple && !Array.isArray(queryValue) ? [queryValue] : queryValue;

        return {
          ...result,
          [fieldDefinition.propertyName || '']: fixedQueryValue || defaultValue
        };
      },
      { '@id': '' }
    );
}

const panels = (props: DataTableAdvancedFilterProps) => {
  const { searchFields, t } = props;

  return [
    {
      key: 'details',
      title: t('advanced-search'),
      content: {
        content: [
          <Fragment key="search">
            <DetailForm
              onFormSubmit={values => updateQueryString(props, values)}
              formDefinition={searchFields}
              selectedEntity={{ '@id': '', ...defaultValues(searchFields, props) }}
              submitText={t('search')}
            />
          </Fragment>
        ]
      }
    }
  ];
};

const DataTableFilterBox = (props: DataTableAdvancedFilterProps) => {
  const { t } = props;
  const envSettings = getEnvSettings(process.env.REACT_APP_ENVIRONMENT);
  const downloadCsvUrl = generateCsvExportUrl(
    envSettings.REACT_APP_API_URL,
    props.location.pathname,
    props.location.search,
    props.fieldSet
  );

  const showFile = (blob: any) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = 'export.csv';
    link.click();
    setTimeout(function() {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  };

  const getDocument = () => {
    const data = {
      method: 'GET',
      responseType: 'blob'
    };
    try {
      fetch(downloadCsvUrl, data)
        .then(r => {
          const reader = r.body!.getReader();
          return new ReadableStream({
            start(controller) {
              // The following function handles each data chunk
              let closed = false;

              function push(): any {
                // "done" is a Boolean and value a "Uint8Array"
                if (closed) {
                  controller.close();
                } else {
                  return reader
                    .read()
                    .then(({ done, value }) => {
                      // Is there no more data to read?
                      if (done) {
                        // Tell the browser that we have finished sending data
                        closed = true;
                        return;
                      }

                      // Get the data and send it to the browser via the controller
                      if (!done) {
                        controller.enqueue(value);
                      }
                    })
                    .then(() => {
                      push();
                    });
                }
              }

              push();
            }
          });
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(showFile)
        .catch(err => console.error(err));
    } catch (error) {}
  };

  return (
    <Fragment>
      <div className="filter-wrap">
        <Accordion as={Form.Field} panels={panels(props)} className="filter-wrapper" defaultActiveIndex={undefined} />
        {/* <Button size="small" className="primary" onClick={getDocument}>
          <Icon name="arrow down" />
          {t('export-to-CSV')}
        </Button> */}
      </div>
    </Fragment>
  );
};

export default translate(['advancedFilter'], { nsMode: 'fallback' })(DataTableFilterBox);
