import React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import Client from '../../../../shared/interfaces/Client';
import Language from '../../../../shared/interfaces/Language';
import { DisplayBox } from '../../../../shared/components/DisplayBox';
import { DisplayGroup } from '../../../../shared/components/DisplayGroup';

type Props = {
  client: Client;
  languages: Language[];
} & WithNamespaces;

export const basicInfoSection = ({ client, languages, t }: Props) => {
  const customerType = (type: number) => {
    if (type === 1) {
      return t('company');
    }
    if (type === 2) {
      return t('private');
    }
    if (type === 3) {
      return t('selfEmployed');
    }

    return '-';
  };

  return (
    <>
      <DisplayBox title={t('contactInformation')}>
        <p>
          {client.street} {client.houseNr} {client.bus ? `bus ${client.bus}` : ''}
          <br />
          {client.postalCode} {client.city}
          <br />
          {client.country && t(client.country)}
          <br />
          {client.email}
          <br />
          {client.phone}
        </p>
      </DisplayBox>
      <hr />

      <div className="row">
        <div className="col-12 col-md-6">
          <DisplayGroup label={t('type')} value={customerType(client.type)} />
          <DisplayGroup label={t('website')} value={client.website} isLink />
          <DisplayGroup
            label={t('language')}
            value={client.languages
              .map(language => {
                const lang = languages.find(l => l['@id'] === language);

                if (lang) {
                  return t(lang.iso);
                }
                return null;
              })
              .join(', ')}
          />
          <DisplayGroup label={t('accountNumber')} value={client.accountNr} />
          <DisplayGroup label={t('vatNr')} value={client.vatNr} />
        </div>
        <div className="col-12 col-md-6">
          <DisplayGroup label={t('code')} value={client.code} />
          <DisplayGroup label={t('remarks')} value={client.remarks} isRaw />
        </div>
      </div>
    </>
  );
};

export default withNamespaces(['clients', 'resources', 'common'], {
  nsMode: 'fallback'
})(basicInfoSection);
