import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import queryString from 'query-string';
import * as actions from './actions';
import { configConstants } from '../../config/constants';
import {
  Training,
  WithCourse,
  WithCourseReference,
  WithCustomer,
  WithCustomerReference,
  WithTrainingSessionsReference
} from '../../shared/interfaces/Training';
import Course from '../../shared/interfaces/Course';
import {
  GQLParticipation,
  GQLTrainingClient,
  GQLTrainingParticipant,
  GQLTrainingSession,
  GQLTrainingTask,
  OverviewTraining,
  TrainingSessionWithMaterials
} from './state';
import { push } from 'connected-react-router';
import CalendarListItem from '../../shared/interfaces/CalendarLocation';
import { toTrainingQuery } from '../helpers/toTrainingQuery';
import { toTrainingSessionQuery } from '../helpers/toTrainingSessionQuery';
import TrainingSession from '../../shared/interfaces/TrainingSession';
import TrainingParticipant from '../../shared/interfaces/TrainingParticipant';
import { Participation } from '../../shared/interfaces/Participation';
import { fetch } from '../../core/state/fetch';
import { getType } from 'typesafe-actions';
import { DbFile } from '../../shared/interfaces/DbFile';
import moment from 'moment';
import { TrainingDocument, TrainingDocumentType, WithTrainingDocument } from '../../shared/interfaces/TrainingDocument';
import { TrainingSessionMaterial, TransportType, WithMaterial } from '../../shared/interfaces/TrainingSessionMaterial';
import AlertService from '../../core/alert/AlertService';
import { Participant, WithContact, WithParticipations } from '../../shared/interfaces/participant';
import Contract from '../../shared/interfaces/Contract';
import { Mail, WithTrainingDocuments } from '../../shared/interfaces/Mail';
import { AnyAction } from 'redux';
import { TrainingTask } from '../../shared/interfaces/TrainingTask';
import Contact from '../../shared/interfaces/Contact';
import { ClientType } from '../../shared/interfaces/Client';

function* loadTrainings(action: ReturnType<typeof actions.FetchTrainings.request>) {
  try {
    const { sort, order } = action.payload;
    delete action.payload.sort;
    delete action.payload.order;
    let query = queryString.stringify(action.payload);

    query += sort ? `&order[${sort}]=` + (order || 'asc') : '&order[updatedAt]=desc';

    const embed = ['trainingsession', 'trainer', 'course', 'customer'];
    const embedString = embed ? '&' + embed.map(x => `embed[]=${x}`).join('&') : '';
    const response = yield call(fetch, `${configConstants.apiUrl}trainings?${query}${embedString}`);

    if (response.status >= 200 && response.status < 300) {
      const rawData = yield response.json();
      const trainings = rawData['hydra:member'] as OverviewTraining[];
      const totalTrainings = rawData['hydra:totalItems'] as number;

      yield put(actions.FetchTrainings.success({ trainings, totalTrainings }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchTrainings.failure({ errorMessage: error }));
  }
}

interface ClientResponse {
  data: { training: { customer: GQLTrainingClient } };
}
function* loadOneTraining(action: ReturnType<typeof actions.FetchOneTraining.request>) {
  const clientQuery = `{
    training(id: "/trainings/${action.payload}") {
      customer {
        companyName,
        email
      }
    }
  }`.replace(/\s/g, '');

  try {
    const [trainingResponse, clientResponse] = yield all([
      call(fetch, `${configConstants.apiUrl}trainings/${action.payload}?embed[]=course`),
      call(fetch, `${configConstants.apiUrl}graphql?query=${clientQuery}`)
    ]);

    if (trainingResponse.status < 200 || trainingResponse.status >= 300) {
      throw trainingResponse;
    }

    const trainingWithCourse = (yield trainingResponse.json()) as Training<WithCourse>;
    const course = trainingWithCourse.course as Course;
    const training = { ...trainingWithCourse, course: course['@id'] } as Training;

    const clientJson = clientResponse.ok ? ((yield clientResponse.json()) as ClientResponse) : undefined;
    const client = clientJson ? clientJson.data.training.customer : undefined;
    yield put(actions.FetchOneTraining.success({ training, course, client }));
  } catch (error) {
    yield put(actions.FetchOneTraining.failure({ errorMessage: error }));
  }
}

interface TrainingSessionsResponse {
  data: {
    trainingSessions: {
      edges: { node: GQLTrainingSession }[];
    };
  };
}
function* loadTrainingSessions(action: ReturnType<typeof actions.FetchTrainingSessions.request>) {
  const { start, end, trainings, id } = action.payload.params;
  const fields = action.payload.fields;
  const subQuery = toTrainingSessionQuery(fields);
  const query = `
    {
      trainingSessions(
        first: 1000
        ${id ? `,training: "/trainings/${id}"` : ''}
        ${trainings ? `,training_List: ${JSON.stringify((trainings as string).split(','))}` : ''}
        ${start ? `,startDate: { after: ${JSON.stringify(start)} }` : ''}
        ${end ? `,endDate: { before: ${JSON.stringify(end)}}` : ''}
        ) {
        edges {
          node {
            _id,
            id,
            startDate,
            endDate,
            deletedAt,
           ${subQuery}
          }
        }
      }
    }
  `.replace(/\s/g, '');
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    const json = (yield response.json()) as TrainingSessionsResponse;
    const sessions = json.data.trainingSessions.edges.map(x => x.node);
    const sessionsWithoutArchived = sessions.filter(session => session.deletedAt === null);
    yield put(actions.FetchTrainingSessions.success(sessionsWithoutArchived));
  } catch (error) {
    yield put(actions.FetchTrainingSessions.failure({ errorMessage: error }));
  }
}

interface TrainingParticipantsResponse {
  data: {
    participants: {
      edges: { node: GQLTrainingParticipant }[];
    };
  };
}
function* loadTrainingParticipants(action: ReturnType<typeof actions.FetchTrainingParticipants.request>) {
  const query = `{
    participants(training: "${action.payload}") {
      edges {
        node {
          _id,
          id,
          firstName,
          lastName,
          email,
          phone,
          cellphone,
          deletedAt,
          customer {
            companyName
          },
          contact {
            id,
            firstName,
            lastName,
            email
          }
        }
      }
    }
  }`.replace(/\s/g, '');

  try {
    const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    const json = (yield response.json()) as TrainingParticipantsResponse;
    const participants = json.data.participants.edges.map(x => x.node);
    yield put(actions.FetchTrainingParticipants.success(participants));
  } catch (error) {
    yield put(actions.FetchTrainingParticipants.failure({ errorMessage: error }));
  }
}

interface TrainingTasksResponse {
  data: {
    trainingTasks: {
      edges: { node: GQLTrainingTask }[];
    };
  };
}
function* loadTrainingTasks(action: ReturnType<typeof actions.FetchTrainingTasks.request>) {
  const query = `{
    trainingTasks (training: "${action.payload}") {
      edges {
        node {
          id,
          executed,
          description,
          dueDate,
         trainingPredefinedTask {
            id,
            description
          }       
        }
      }
    }
  }`.replace(/\s/g, '');

  try {
    const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    const json = (yield response.json()) as TrainingTasksResponse;
    const tasks = json.data.trainingTasks.edges.map(x => x.node);
    yield put(actions.FetchTrainingTasks.success(tasks));
  } catch (error) {
    yield put(actions.FetchTrainingTasks.failure({ errorMessage: error }));
  }
}

export function* saveTrainingTask(action: ReturnType<typeof actions.SaveTrainingTask.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}${action.payload.taskId.replace('/', '')}`, {
      method: 'PUT',
      body: JSON.stringify({ ...action.payload, taskId: undefined }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.ok) {
      yield put(actions.SaveTrainingTask.success(action.payload));

      AlertService.setMessage({
        title: 'Taak opgeslagen',
        messageText: 'De taak is succesvol opgeslagen.',
        type: 'success'
      });
    } else {
      AlertService.setMessage({
        title: 'Taak niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
        type: 'error'
      });

      throw response;
    }
  } catch (error) {
    yield put(actions.SaveTrainingTask.failure({ errorMessage: error }));

    AlertService.setMessage({
      title: 'Taak niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
      type: 'error'
    });
  }
}

export function* saveNewTrainingTask(action: ReturnType<typeof actions.SaveNewTrainingTask.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}training_tasks`, {
      method: 'POST',
      body: JSON.stringify({ ...action.payload }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(actions.SaveNewTrainingTask.success(action.payload));
      yield put(actions.FetchTrainingTasks.request(parseInt(action.payload.training.match(/\d+/g)![0])));

      AlertService.setMessage({
        title: 'Taak aangemaakt',
        messageText: 'De taak is succesvol aangemaakt',
        type: 'success'
      });
    } else {
      AlertService.setMessage({
        title: 'Taak niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
        type: 'error'
      });

      throw response;
    }
  } catch (error) {
    yield put(actions.SaveNewTrainingTask.failure({ errorMessage: error }));

    AlertService.setMessage({
      title: 'Taak niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
      type: 'error'
    });
  }
}

export function* saveTraining(action: ReturnType<typeof actions.SaveTraining.request>) {
  try {
    const training = action.payload.training as Training<WithCourseReference & WithCustomerReference & WithTrainingSessionsReference>;
    delete training.trainingSessions;
    const isNew = training.id === 0;
    const response = yield call(fetch, `${configConstants.apiUrl}trainings${isNew ? '' : `/${training.id}`}`, {
      method: isNew ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...training,
        id: isNew ? undefined : training.id,
        discount: !training.discount ? 0 : training.discount
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const result = (yield response.json()) as Training;

      AlertService.setMessage({
        title: 'Opleiding opgeslagen',
        messageText: 'De opleiding is succesvol opgeslagen.',
        type: 'success'
      });

      yield all([put(actions.SaveTraining.success(result)), put(actions.FetchOneTraining.request(result.id))]);

      if (!action.payload.noredirects) {
        yield put(push(`/trainings${isNew ? '' : `/${training.id}`}`));
      }
    } else {
      AlertService.setMessage({
        title: 'Opleiding niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
        type: 'error'
      });

      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.SaveTraining.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Opleiding niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
      type: 'error'
    });
  }
}

export function* saveTrainingSession(action: ReturnType<typeof actions.SaveTrainingSession.request>) {
  try {
    const session = action.payload;
    const isNew = session.id === 0;
    const response = yield call(fetch, `${configConstants.apiUrl}training_sessions${isNew ? '' : `/${session.id}`}`, {
      method: isNew ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...session,
        courseLink: session.courseLink,
        id: isNew ? undefined : session.id
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const result = (yield response.json()) as TrainingSession;
      yield put(actions.SaveTrainingSession.success(result));
      yield put(actions.FetchOneTrainingSession.request(action.payload.id));
      yield put(push(`${action.payload.training}`));
      AlertService.setMessage({
        title: 'Sessie opgeslagen',
        messageText: 'De sessie is succesvol opgeslagen',
        type: 'success'
      });
    } else {
      AlertService.setMessage({
        title: 'Sessie niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
        type: 'error'
      });

      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.SaveTrainingSession.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Sessie niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen.',
      type: 'error'
    });
  }
}

function* loadOneTrainingSession(action: ReturnType<typeof actions.FetchOneTrainingSession.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}training_sessions/${action.payload}?embed[]=trainingsessionmaterial&embed[]=material`
    );

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    const session = (yield response.json()) as TrainingSessionWithMaterials;
    const materialsWithoutArchived = session.trainingSessionMaterials.filter(
      (sessionMaterial: TrainingSessionMaterial<WithMaterial>) => sessionMaterial.deletedAt === null
    );
    const sessionWithMaterialsWithoutArchived = { ...session, trainingSessionMaterials: materialsWithoutArchived };
    yield put(actions.FetchOneTrainingSession.success(sessionWithMaterialsWithoutArchived));
  } catch (error) {
    yield put(actions.FetchOneTrainingSession.failure({ errorMessage: error }));
  }
}

function* deleteTrainingSession(action: ReturnType<typeof actions.DeleteTrainingSession.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}training_sessions/${action.payload.sessionId}`, { method: 'DELETE' });

    if (response.status < 200 || response.status >= 300) {
      AlertService.setMessage({
        title: 'Sessie niet verwijderd',
        messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
        type: 'error'
      });

      throw response;
    }

    yield put(actions.DeleteTrainingSession.success());

    AlertService.setMessage({
      title: 'Sessie verwijderd',
      messageText: 'De sessie is succesvol verwijderd',
      type: 'success'
    });

    yield put(
      actions.FetchTrainingSessions.request({
        params: { id: action.payload.trainingId },
        fields: ['trainer', 'location']
      })
    );
  } catch (error) {
    yield put(actions.DeleteTrainingSession.failure({ errorMessage: error }));

    AlertService.setMessage({
      title: 'Sessie niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
      type: 'error'
    });
  }
}

interface CalendarListTrainingsResponse {
  data: {
    trainings: {
      edges: {
        cursor: string;
        node: { _id: number; course: { title: string } };
      }[];
      pageInfo: {
        hasNextPage: boolean;
        endCursor: string;
      };
    };
  };
}
function* loadCalendarListTrainings(action: ReturnType<typeof actions.FetchCalendarListTrainings.request>) {
  try {
    let cursor = '';
    let hasNextPage = false;
    let trainings: CalendarListItem[] = [];
    do {
      const query = toTrainingQuery(cursor);
      const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);
      if (response.status >= 200 && response.status < 300) {
        const result: CalendarListTrainingsResponse = yield response.json();

        cursor = result.data.trainings.edges.slice(-1)[0].cursor;
        hasNextPage = result.data.trainings.pageInfo.hasNextPage;
        trainings = [
          ...trainings,
          ...result.data.trainings.edges.map(item => {
            return { _id: item.node._id, name: item.node.course.title };
          })
        ];
      } else {
        break;
      }
    } while (hasNextPage);

    if (trainings.length >= 1) {
      yield put(
        actions.FetchCalendarListTrainings.success({
          calendarListTrainings: trainings
        })
      );
    }
  } catch (error) {
    yield put(actions.FetchCalendarListTrainings.failure({ errorMessage: error }));
  }
}
export function* saveTrainingParticipant(action: ReturnType<typeof actions.SaveTrainingParticipant.request>) {
  try {
    const participant = action.payload;
    const isNew = participant.id === 0;
    const response = yield call(fetch, `${configConstants.apiUrl}participants${isNew ? '' : `/${participant.id}`}`, {
      method: isNew ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...participant,
        id: isNew ? undefined : participant.id
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const result = (yield response.json()) as Participant;
      yield put(actions.SaveTrainingParticipant.success(result));

      AlertService.setMessage({
        title: 'Deelnemer toegevoegd',
        messageText: 'De deelnemer is succesvol toegevoegd aan de opleiding.',
        type: 'success'
      });

      yield put(push(`${action.payload.training}`));
    } else {
      AlertService.setMessage({
        title: 'Deelnemer niet toegevoegd',
        messageText: 'Oeps, er liep iets mis tijdens het toevoegen van de deelnemer.',
        type: 'error'
      });

      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.SaveTrainingParticipant.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Deelnemer niet toegevoegd',
      messageText: 'Oeps, er liep iets mis tijdens het toevoegen van de deelnemer.',
      type: 'error'
    });
  }
}

function* loadOneParticipant(action: ReturnType<typeof actions.FetchOneParticipant.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}participants/${action.payload}?embed[]=contact`);

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    const participant = (yield response.json()) as Participant<WithContact>;
    yield put(actions.FetchOneParticipant.success(participant.contact));
  } catch (error) {
    yield put(actions.FetchOneParticipant.failure({ errorMessage: error }));
  }
}

function* deleteParticipant(action: ReturnType<typeof actions.DeleteParticipant.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}participants/${action.payload.participantId}`, { method: 'DELETE' });

    if (response.status < 200 || response.status >= 300) {
      AlertService.setMessage({
        title: 'Deelnemer niet verwijderd',
        messageText: 'Oeps, er liep iets mis tijdens het verwijderen van de deelnemer.',
        type: 'error'
      });

      throw response;
    }

    yield put(actions.DeleteParticipant.success());

    AlertService.setMessage({
      title: 'Deelnemer verwijderd',
      messageText: 'De deelnemer is succesvol verwijderd van de opleiding.',
      type: 'success'
    });

    yield put(push('/trainings/' + action.payload.trainingId));
    yield put(actions.FetchTrainingParticipants.request(action.payload.trainingId));
    yield put(actions.FetchAllParticipants.request(action.payload.trainingId));
  } catch (error) {
    yield put(actions.DeleteParticipant.failure({ errorMessage: error }));

    AlertService.setMessage({
      title: 'Deelnemer niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen van de deelnemer.',
      type: 'error'
    });
  }
}

interface ParticipationsResponse {
  data: {
    trainingSession: {
      participations: {
        edges: { node: GQLParticipation }[];
      };
    };
  };
}

function* loadParticipations(action: ReturnType<typeof actions.FetchParticipations.request>) {
  const query = `{
    trainingSession(id: "/training_sessions/${action.payload}") {
      participations {
        edges {
          node {
            _id,
            id,
            attended,
            participant {
              id
            },
            trainingSession {
              id
            }
          }
        }
      }
    }
  }`.replace(/\s/g, '');

  try {
    const response = yield call(fetch, `${configConstants.apiUrl}graphql?query=${query}`);

    if (response.status < 200 || response.status >= 300) {
      throw response;
    }

    const json = (yield response.json()) as ParticipationsResponse;
    const participations = json.data.trainingSession.participations.edges.map(x => x.node);
    yield put(actions.FetchParticipations.success(participations));
  } catch (error) {
    yield put(actions.FetchParticipations.failure({ errorMessage: error }));
  }
}

export function* saveParticipation(action: ReturnType<typeof actions.SaveParticipation.request>) {
  try {
    const { participation, sessionId } = action.payload;
    const isNew = participation.id === 0;
    const response = yield call(fetch, `${configConstants.apiUrl}participations${isNew ? '' : `/${participation.id}`}`, {
      method: isNew ? 'POST' : 'PUT',
      body: JSON.stringify({
        ...participation,
        id: isNew ? undefined : participation.id,
        '@id': isNew ? undefined : participation['@id']
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const result = (yield response.json()) as Participation;
      yield put(actions.SaveParticipation.success(result));
      yield put(actions.FetchParticipations.request(sessionId));

      AlertService.setMessage({
        title: 'Deelname opgeslagen',
        messageText: 'De deelname is succesvol opgeslagen.',
        type: 'success'
      });
    } else {
      AlertService.setMessage({
        title: 'Deelname niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opslagen van de deelname.',
        type: 'error'
      });

      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.SaveParticipation.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Deelname niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen van de deelname.',
      type: 'error'
    });
  }
}

function* deleteTraining(action: ReturnType<typeof actions.DeleteTraining.request>) {
  try {
    const url = `trainings/${action.payload.trainingId}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });
    yield put(actions.FetchTrainings.request(action.payload.queryParams));

    AlertService.setMessage({
      title: 'Opleiding gearchiveerd',
      messageText: 'De opleiding is succesvol gearchiveerd.',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.DeleteTraining.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Opleiding niet gearchiveerd',
      messageText: 'Oeps, er liep iets mis tijdens het archiveren.',
      type: 'error'
    });
  }
}

function* deleteTrainingFile(action: ReturnType<typeof actions.DeleteTrainingFile.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}training_documents?trainingDocument=${action.payload.fileId}`);

    const result = yield response.json();
    const trainingDocument = result['hydra:member'];
    const id = trainingDocument[0].id;
    const deleteConnection = yield call(fetch, `${configConstants.apiUrl}training_documents/${id}`, { method: 'DELETE' });
    if (deleteConnection.status === 204) {
      yield call(fetch, `${configConstants.apiUrl}files/${action.payload.fileId}`, { method: 'DELETE' });

      yield put(actions.FetchTrainingFiles.request(action.payload.trainingId));

      AlertService.setMessage({
        title: 'Document verwijderd',
        messageText: 'Het document is succesvol verwijderd.',
        type: 'success'
      });
    } else {
      throw deleteConnection;
    }
  } catch (errorMessage) {
    yield put(actions.DeleteTrainingFile.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Document niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
      type: 'error'
    });
  }
}

async function saveDbFile(file: DbFile) {
  const isNew = file.id === 0;
  const fileResponse = await fetch(`${configConstants.apiUrl}files${isNew ? '' : '/' + file.id}`, {
    method: isNew ? 'POST' : 'PUT',
    body: JSON.stringify({
      ...file,
      '@id': isNew ? undefined : file['@id'],
      id: isNew ? undefined : file.id
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return (await fileResponse.json()) as DbFile;
}

async function saveTrainingDocument(trainingDocument: TrainingDocument) {
  const isNew = trainingDocument.id === 0;
  const fileResponse = await fetch(`${configConstants.apiUrl}training_documents${isNew ? '' : '/' + trainingDocument.id}`, {
    method: isNew ? 'POST' : 'PUT',
    body: JSON.stringify({
      ...trainingDocument,
      '@id': isNew ? undefined : trainingDocument['@id'],
      id: isNew ? undefined : trainingDocument.id
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return (await fileResponse.json()) as TrainingDocument;
}

function* addFileToTraining(action: ReturnType<typeof actions.AddFileToTraining.request>) {
  try {
    const { file } = action.payload;

    const dbFile: DbFile = yield call(saveDbFile, {
      '@id': '',
      id: 0,
      originalFilename: file.name,
      mimeType: file.type,
      fileSize: file.size,
      fileName: file.name,
      filePath: file.name,
      accessUrl: null,
      savePath: null,
      uploadCompletedAt: null,
      uploadUrl: null
    });

    yield call(window.fetch, dbFile.uploadUrl!, {
      method: 'PUT',
      body: file
    });

    yield saveDbFile({
      ...dbFile,
      uploadCompletedAt: moment().toISOString(true)
    });

    yield saveTrainingDocument({
      '@id': '',
      id: 0,
      training: `/trainings/${action.payload.trainingId}`,
      trainingDocument: dbFile['@id'],
      type: TrainingDocumentType.Order
    });

    yield put(actions.FetchTrainingFiles.request(action.payload.trainingId));
    yield put(actions.AddFileToTraining.success());

    AlertService.setMessage({
      title: 'Document toegevoegd',
      messageText: 'Het document is succesvol toegevoegd aan de opleiding.',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.AddFileToTraining.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Document niet toegevoegd',
      messageText: 'Oeps, er liep iets mis tijdens het toevoegen van het document.',
      type: 'error'
    });
  }
}

function* addFileToTrainerContract(action: ReturnType<typeof actions.AddFileToTrainerContract.request>) {
  try {
    const { file } = action.payload;

    const dbFile: DbFile = yield call(saveDbFile, {
      '@id': '',
      id: 0,
      originalFilename: file.name,
      mimeType: file.type,
      fileSize: file.size,
      fileName: file.name,
      filePath: file.name,
      accessUrl: null,
      savePath: null,
      uploadCompletedAt: null,
      uploadUrl: null
    });

    yield call(window.fetch, dbFile.uploadUrl!, {
      method: 'PUT',
      body: file
    });

    yield saveDbFile({
      ...dbFile,
      uploadCompletedAt: moment().toISOString(true)
    });

    const response = yield call(fetch, `${configConstants.apiUrl}trainer_contracts/${action.payload.trainerContractId}`, {
      method: 'PUT',
      body: JSON.stringify({
        signedTrainerContract: dbFile['@id']
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    yield put(actions.FetchTrainerContractsForTraining.request(action.payload.trainingId));
    yield put(actions.AddFileToTrainerContract.success());

    AlertService.setMessage({
      title: 'Document toegevoegd',
      messageText: 'Het document is succesvol toegevoegd aan de trainer.',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.AddFileToTrainerContract.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Document niet toegevoegd',
      messageText: 'Oeps, er liep iets mis tijdens het toevoegen van het contract.',
      type: 'error'
    });
  }
}

function* fetchTrainingFiles(action: ReturnType<typeof actions.FetchTrainingFiles.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}training_documents?training=${action.payload}&embed[]=file`);
    const files = (yield response.json())['hydra:member'] as TrainingDocument<WithTrainingDocument>[];

    yield put(actions.FetchTrainingFiles.success(files));
  } catch (error) {
    yield put(actions.FetchTrainingFiles.failure({ errorMessage: error }));
  }
}

function* fetchTrainingSessionMaterial(action: ReturnType<typeof actions.FetchOneTrainingSessionMaterial.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}training_session_materials/${action.payload}`);

    const material = (yield response.json()) as TrainingSessionMaterial;
    yield put(actions.FetchOneTrainingSessionMaterial.success(material));
  } catch (error) {
    yield put(actions.FetchOneTrainingSessionMaterial.failure({ errorMessage: error }));
  }
}

export function* saveTrainingSessionMaterial(action: ReturnType<typeof actions.SaveTrainingSessionMaterial.request>) {
  try {
    const isNew = action.payload.sessionMaterial.id === 0;
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}training_session_materials${isNew ? '' : `/${action.payload.sessionMaterial.id}`}`,
      {
        method: isNew ? 'POST' : 'PUT',
        body: JSON.stringify({
          ...action.payload.sessionMaterial,
          id: isNew ? undefined : action.payload.sessionMaterial.id,
          '@id': isNew ? undefined : action.payload.sessionMaterial['@id'],
          rate: action.payload.sessionMaterial.rate
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const result = (yield response.json()) as TrainingSessionMaterial;
      yield put(actions.SaveTrainingSessionMaterial.success(result));
      const trainingSessionId = action.payload.sessionMaterial.trainingSession!.match(/\d+/)![0];
      yield put(actions.FetchOneTrainingSession.request(parseInt(trainingSessionId)));

      if (isNew && action.payload.sessionMaterial.transportType === TransportType.Courier) {
        const response = yield call(
          fetch,
          `${configConstants.apiUrl}trainings/${action.payload.session.training!.match(/\d+/)![0]}?embed[]=trainingsession`
        );
        const training = yield response.json();
        const date = new Date(training.trainingSessions[0].startDate);
        const dueDate = new Date(date.setDate(date.getDate() - 7)).toISOString();

        const task = {
          description: `koerierdienst bestellen`,
          training: action.payload.session.training,
          dueDate: dueDate,
          executed: false
        } as TrainingTask;
        yield put(actions.SaveNewTrainingTask.request(task));
      }

      AlertService.setMessage({
        title: 'Materiaal toegevoegd',
        messageText: 'Het materiaal is succesvol toegevoegd aan de opleiding.',
        type: 'success'
      });
    } else {
      AlertService.setMessage({
        title: 'Materiaal niet toegevoegd',
        messageText: 'Oeps, er liep iets mis tijdens het toevoegen van het materiaal.',
        type: 'error'
      });

      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.SaveTrainingSessionMaterial.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Materiaal niet toegevoegd',
      messageText: 'Oeps, er liep iets mis tijdens het toevoegen van het materiaal.',
      type: 'error'
    });
  }
}

function* deleteTrainingSessionMaterial(action: ReturnType<typeof actions.DeleteTrainingSessionMaterial.request>) {
  try {
    const url = `training_session_materials/${action.payload.sessionMaterialId}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });
    yield put(actions.DeleteTrainingSessionMaterial.success());
    yield put(actions.FetchOneTrainingSession.request(action.payload.sessionId));

    AlertService.setMessage({
      title: 'Materiaal verwijderd',
      messageText: 'Het materiaal is succesvol verwijderd van de opleiding.',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.DeleteTrainingSessionMaterial.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Materiaal niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen van het materiaal.',
      type: 'error'
    });
  }
}

function* FetchTrainingParticipantsList(action: ReturnType<typeof actions.FetchTrainingParticipantsList.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}files/training/${action.payload}/generate_participant_list`);
    const file = (yield response.json())['trainingParticipantListDocument'];
    window.open(file.accessUrl);
    yield put(
      actions.FetchTrainingParticipantsList.success({
        fileUrl: file.accessUrl as string
      })
    );
  } catch (error) {
    yield put(actions.FetchTrainingParticipantsList.failure({ errorMessage: error }));
  }
}

function* SendInformationMail(action: ReturnType<typeof actions.SendInformationForm.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}training/${action.payload.trainingId}/customer-form/send`);

    const token = yield response.json();
    const link = `/customer-form?token=${token}`;

    yield put(actions.SendInformationForm.success(link));
  } catch (error) {
    yield put(actions.SendInformationForm.failure({ errorMessage: error }));
  }
}

export function* getParticipants(action: ReturnType<typeof actions.FetchAllParticipants.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}participants?training=${action.payload}&embed[]=participation&embed[]=contact&embed[]=customer`
    );
    if (response.ok) {
      const result = yield response.json();
      const allParticipants = result['hydra:member'] as Participant<WithParticipations & WithContact & WithCustomer>[];
      yield put(actions.FetchAllParticipants.success({ allParticipants }));
    }
  } catch (errorMessage) {
    yield put(actions.FetchAllParticipants.failure({ errorMessage }));
  }
}

export function* getTrainerContractsForTraining(action: ReturnType<typeof actions.FetchTrainerContractsForTraining.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}trainer_contracts?training=${action.payload}&embed[]=trainer&embed[]=file`
    );
    if (response.ok) {
      const result = yield response.json();
      const allTrainerContracts = result['hydra:member'] as Contract[];
      yield put(actions.FetchTrainerContractsForTraining.success({ allTrainerContracts }));
    }
  } catch (errorMessage) {
    yield put(actions.FetchTrainerContractsForTraining.failure({ errorMessage }));
  }
}

function* FetchTrainerContract(action: ReturnType<typeof actions.FetchTrainerContract.request>) {
  try {
    const type: {
      urlKey: string;
      documentKey: 'contract' | 'signedContract';
    } =
      action.payload.type === 'contract'
        ? { urlKey: 'contract', documentKey: 'contract' }
        : { urlKey: 'signedContract', documentKey: 'signedContract' };

    let response;
    action.payload.type === 'contract'
      ? (response = yield call(
          fetch,
          `${configConstants.apiUrl}files/trainer_contract_file_id/${action.payload.id}/generate_${type.urlKey}`
        ))
      : (response = yield call(
          fetch,
          `${configConstants.apiUrl}files/signed_trainer_contract_file_id/${action.payload.id}/generate_${type.urlKey}`
        ));

    const file = (yield response.json())[`${type.documentKey}`];
    yield put(
      actions.FetchTrainerContract.success({
        fileUrl: file.accessUrl as string,
        type: type.documentKey
      })
    );
  } catch (error) {
    yield put(actions.FetchTrainerContract.failure({ errorMessage: error }));
  }
}

function* SendInvitationToParticipant(action: ReturnType<typeof actions.SendInvitationToParticipant.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}mails/participant/${action.payload.id}/send/invitation`);

    if (response.status < 200 || response >= 300) {
      AlertService.setMessage({
        title: 'Invitatie E-mail niet verstuurd',
        messageText: 'Oeps, er liep iets mis tijdens het versturen van de E-mail.',
        type: 'error'
      });

      yield put(actions.SendInvitationToParticipant.success(false));
    }

    yield put(actions.FetchAllParticipants.request(parseInt(action.payload.training!.replace('/trainings/', ''))));

    AlertService.setMessage({
      title: 'Invitatie E-mail verstuurd',
      messageText: '',
      type: 'success'
    });

    yield put(actions.SendInvitationToParticipant.success(true));
  } catch (error) {
    AlertService.setMessage({
      title: 'Invitatie E-mail niet verstuurd',
      messageText: 'Oeps, er liep iets mis tijdens het versturen van de E-mail.',
      type: 'error'
    });

    yield put(actions.SendInvitationToParticipant.failure(error));
  }
}

function* SendCertificateToParticipant(action: ReturnType<typeof actions.SendCertificateToParticipant.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}mails/participant/${action.payload.id}/send/certificate`);

    if (response.status < 200 || response >= 300) {
      AlertService.setMessage({
        title: 'Certificaat E-mail niet verstuurd',
        messageText: 'Oeps, er liep iets mis tijdens het versturen van de E-mail.',
        type: 'error'
      });

      yield put(actions.SendCertificateToParticipant.success(false));
    }

    yield put(actions.FetchAllParticipants.request(parseInt(action.payload.training!.replace('/trainings/', ''))));

    AlertService.setMessage({
      title: 'Certificaat E-mail verstuurd',
      messageText: '',
      type: 'success'
    });

    yield put(actions.SendCertificateToParticipant.success(true));
  } catch (error) {
    AlertService.setMessage({
      title: 'Certificaat E-mail niet verstuurd',
      messageText: 'Oeps, er liep iets mis tijdens het versturen van de E-mail.',
      type: 'error'
    });

    yield put(actions.SendCertificateToParticipant.failure(error));
  }
}

function* SaveTrainingContract(action: ReturnType<typeof actions.SaveTrainingContract.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}trainer_contracts/${action.payload.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        ...action.payload,
        id: action.payload.id,
        '@id': action.payload['@id'],
        travelDistance: action.payload.travelDistance ? action.payload.travelDistance : null
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(actions.FetchTrainerContractsForTraining.request(parseInt(action.payload.training.replace('/trainings/', ''))));

      AlertService.setMessage({
        title: 'Het contract is succesvol opgeslagen',
        messageText: '',
        type: 'success'
      });

      yield put(actions.SaveTrainingContract.success(true));
    } else {
      AlertService.setMessage({
        title: 'Het contract is niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opslagen van het contract.',
        type: 'error'
      });

      yield put(actions.SaveTrainingContract.success(false));
    }
  } catch (error) {
    AlertService.setMessage({
      title: 'Het contract is niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opslagen van het contract.',
      type: 'error'
    });

    yield put(actions.SaveTrainingContract.failure(error));
  }
}

function* loadTrainingMails(action: ReturnType<typeof actions.FetchTrainingMails.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}training_mails?mailEntityId=${action.payload}&embed[]=trainingdocument&embed[]=file&order[createdAt]=desc`
    );

    const mails = (yield response.json())['hydra:member'] as Mail<WithTrainingDocument & WithTrainingDocuments>[];

    yield put(actions.FetchTrainingMails.success(mails));
  } catch (error) {
    yield put(actions.FetchTrainingMails.failure({ errorMessage: error }));
  }
}

function* SendMail(action: AnyAction) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}training_mails`, {
      method: 'POST',
      body: JSON.stringify(action.payload.mail),
      headers: { 'Content-Type': 'application/json' }
    });

    if (response.status >= 200 && response.status < 300) {
      yield put(actions.SaveTrainingContract.success(true));
      yield put(actions.FetchTrainingMails.request(action.payload.trainingId));

      AlertService.setMessage({
        title: 'E-mail verzonden',
        messageText: '',
        type: 'success'
      });
    } else {
      AlertService.setMessage({
        title: 'E-mail niet verzonden',
        messageText: 'Oeps, er liep iets mis tijdens het verzenden van de E-mail.',
        type: 'error'
      });

      yield put(actions.SaveTrainingContract.success(false));
    }
  } catch (error) {
    AlertService.setMessage({
      title: 'E-mail niet verzonden',
      messageText: 'Oeps, er liep iets mis tijdens het verzenden van de E-mail.',
      type: 'error'
    });

    yield put(actions.SaveTrainingContract.failure(error));
  }
}

function* generatePredefinedTasks(action: ReturnType<typeof actions.FetchPredefinedTasks.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}training/${action.payload}/generate_tasks`);
    if (response.ok) {
      yield put(actions.FetchOneTraining.request(action.payload));
    }
  } catch (errorMessage) {
    yield put(actions.FetchPredefinedTasks.failure({ errorMessage }));
  }
}

function* createParticipantWithKnownContact(action: ReturnType<typeof actions.CreateParticipantWithKnownContact.request>) {
  try {
    const contactResponse = yield call(fetch, `${configConstants.apiUrl}${action.payload.contactId.substring(1)}`);
    if (contactResponse.ok) {
      const contact = yield contactResponse.json() as Contact;

      const response = yield call(fetch, `${configConstants.apiUrl}participants`, {
        method: 'POST',
        body: JSON.stringify({
          contact: action.payload.contactId,
          customer: contact.customer,
          training: action.payload.trainingId
        }),
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.ok) {
        yield put(actions.CreateParticipantWithKnownContact.success());
        yield put(push(`${action.payload.trainingId}`));
      }
    }
  } catch (errorMessage) {
    yield put(actions.FetchPredefinedTasks.failure({ errorMessage }));
  }
}

function* createParticipantWithKnownClient(action: ReturnType<typeof actions.CreateParticipantWithKnownClient.request>) {
  try {
    delete action.payload.contactForm['@id'];
    delete action.payload.contactForm.id;
    const contactResponse = yield call(fetch, `${configConstants.apiUrl}contacts`, {
      method: 'POST',
      body: JSON.stringify({ ...action.payload.contactForm, customer: action.payload.clientId, type: '1' }),
      headers: { 'Content-Type': 'application/json' }
    });
    if (contactResponse.ok) {
      const contact = yield contactResponse.json() as Contact;

      const response = yield call(fetch, `${configConstants.apiUrl}participants`, {
        method: 'POST',
        body: JSON.stringify({
          contact: contact['@id'],
          customer: action.payload.clientId,
          training: action.payload.trainingId
        }),
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.ok) {
        yield put(actions.CreateParticipantWithKnownClient.success());
        yield put(push(`${action.payload.trainingId}`));
      }
    }
  } catch (errorMessage) {
    yield put(actions.FetchPredefinedTasks.failure({ errorMessage }));
  }
}

function* createParticipantFromScratch(action: ReturnType<typeof actions.CreateParticipantFromScratch.request>) {
  try {
    if (!action.payload.participantTypeCompany) {
      action.payload.clientForm.companyName = `${action.payload.contactForm.firstName} ${action.payload.contactForm.lastName}`;
      action.payload.clientForm.email = action.payload.contactForm.email;
      action.payload.clientForm.type = ClientType.private;
    }

    delete action.payload.clientForm['@id'];
    delete action.payload.clientForm.id;
    const clientResponse = yield call(fetch, `${configConstants.apiUrl}customers`, {
      method: 'POST',
      body: JSON.stringify(action.payload.clientForm),
      headers: { 'Content-Type': 'application/json' }
    });

    if (clientResponse.ok) {
      const client = yield clientResponse.json();
      delete action.payload.contactForm['@id'];
      delete action.payload.contactForm.id;
      const contactResponse = yield call(fetch, `${configConstants.apiUrl}contacts`, {
        method: 'POST',
        body: JSON.stringify({ ...action.payload.contactForm, customer: client['@id'], type: '1' }),
        headers: { 'Content-Type': 'application/json' }
      });

      if (contactResponse.ok) {
        const contact = yield contactResponse.json() as Contact;

        const response = yield call(fetch, `${configConstants.apiUrl}participants`, {
          method: 'POST',
          body: JSON.stringify({
            contact: contact['@id'],
            customer: client['@id'],
            training: action.payload.trainingId
          }),
          headers: { 'Content-Type': 'application/json' }
        });

        if (response.ok) {
          yield put(actions.CreateParticipantFromScratch.success());
          yield put(push(`${action.payload.trainingId}`));
        }
      }
    }
  } catch (errorMessage) {
    yield put(actions.FetchPredefinedTasks.failure({ errorMessage }));
  }
}

export const trainingsSaga = [
  takeLatest(actions.TrainingsActionTypes.FetchTrainings, loadTrainings),
  takeLatest(actions.TrainingsActionTypes.FetchOneTraining, loadOneTraining),
  takeLatest(actions.TrainingsActionTypes.FetchOneTrainingSession, loadOneTrainingSession),
  takeLatest(actions.TrainingsActionTypes.FetchTrainingSessions, loadTrainingSessions),
  takeLatest(actions.TrainingsActionTypes.FetchTrainingParticipants, loadTrainingParticipants),
  takeLatest(actions.TrainingsActionTypes.FetchTrainingTasks, loadTrainingTasks),
  takeLatest(actions.TrainingsActionTypes.SaveTrainingTask, saveTrainingTask),
  takeLatest(actions.TrainingsActionTypes.SaveNewTrainingTask, saveNewTrainingTask),
  takeLatest(actions.TrainingsActionTypes.SaveTraining, saveTraining),
  takeLatest(actions.TrainingsActionTypes.SaveTrainingSession, saveTrainingSession),
  takeLatest(actions.TrainingsActionTypes.DeleteTrainingSession, deleteTrainingSession),
  takeLatest(actions.TrainingsActionTypes.FetchCalendarListTrainings, loadCalendarListTrainings),
  takeLatest(actions.TrainingsActionTypes.SaveTrainingParticipant, saveTrainingParticipant),
  takeLatest(actions.TrainingsActionTypes.FetchOneParticipant, loadOneParticipant),
  takeLatest(actions.TrainingsActionTypes.DeleteParticipant, deleteParticipant),
  takeLatest(actions.TrainingsActionTypes.FetchParticipations, loadParticipations),
  takeLatest(actions.TrainingsActionTypes.SaveParticipation, saveParticipation),
  takeLatest(getType(actions.DeleteTraining.request), deleteTraining),
  takeLatest(getType(actions.DeleteTrainingFile.request), deleteTrainingFile),
  takeLatest(getType(actions.AddFileToTraining.request), addFileToTraining),
  takeLatest(getType(actions.AddFileToTrainerContract.request), addFileToTrainerContract),
  takeLatest(getType(actions.FetchTrainingFiles.request), fetchTrainingFiles),
  takeLatest(getType(actions.FetchOneTrainingSessionMaterial.request), fetchTrainingSessionMaterial),
  takeLatest(getType(actions.SaveTrainingSessionMaterial.request), saveTrainingSessionMaterial),
  takeLatest(getType(actions.DeleteTrainingSessionMaterial.request), deleteTrainingSessionMaterial),
  takeLatest(actions.SendInformationForm.request, SendInformationMail),
  takeLatest(actions.TrainingsActionTypes.FetchTrainingParticipantsList, FetchTrainingParticipantsList),
  takeLatest(getType(actions.FetchAllParticipants.request), getParticipants),
  takeLatest(getType(actions.FetchTrainerContractsForTraining.request), getTrainerContractsForTraining),
  takeLatest(getType(actions.SendInvitationToParticipant.request), SendInvitationToParticipant),
  takeLatest(getType(actions.SendCertificateToParticipant.request), SendCertificateToParticipant),
  takeLatest(getType(actions.SaveTrainingContract.request), SaveTrainingContract),
  takeEvery(getType(actions.FetchTrainerContract.request), FetchTrainerContract),
  takeEvery(getType(actions.FetchTrainingMails.request), loadTrainingMails),
  takeLatest(getType(actions.SendMail.request), SendMail),
  takeLatest(getType(actions.FetchPredefinedTasks.request), generatePredefinedTasks),
  takeLatest(getType(actions.CreateParticipantWithKnownContact.request), createParticipantWithKnownContact),
  takeLatest(getType(actions.CreateParticipantWithKnownClient.request), createParticipantWithKnownClient),
  takeLatest(getType(actions.CreateParticipantFromScratch.request), createParticipantFromScratch)
];
