import React, { useState } from 'react';
import { Breadcrumb } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Drawer, Button } from 'antd';
import QuickAddForm from './quick-add-form';ThunderboltOutlined
import {ThunderboltOutlined} from '@ant-design/icons';

interface PageHeaderProps {
  title: JSX.Element | string;
  isActive?: boolean;
  breadCrumbParts: string[];
  rightBlock?: JSX.Element;
}

const PageHeader = (props: PageHeaderProps & RouteComponentProps) => {
  const { title, breadCrumbParts, rightBlock } = props;
  const path = props.location.pathname.replace(/^\//, '').split('/');

  let breadcrumbs = breadCrumbParts.map((part, i) => [part || path[i], '/' + path.slice(0, i + 1).join('/')]);
  breadcrumbs = [['Dashboard', '/'], ...breadcrumbs];

  const [quickAddDrawer, setQuickAddDrawer] = useState(false);

  return (
    <div className="page-header">
      <div className="row">
        <div className="col-8">
          <h2>{title}</h2>
          <Breadcrumb>
            {breadcrumbs.map(([label, path], i) => (
              <Breadcrumb.Section key={label} link as="span">
                {i !== 0 ? <Breadcrumb.Divider /> : null}
                <Link to={path}>{label}</Link>
              </Breadcrumb.Section>
            ))}
          </Breadcrumb>
        </div>
        <div className="col-4">
          <div className="right-side-buttons float-right">
            {rightBlock ? rightBlock : null}
            <Button type="dashed" icon={<ThunderboltOutlined />} size="large" onClick={() => setQuickAddDrawer(true)}></Button>
          </div>
        </div>
      </div>
      <Drawer className="quick-add-drawer" onClose={() => setQuickAddDrawer(false)} visible={quickAddDrawer}>
        <QuickAddForm entitySaved={() => setQuickAddDrawer(false)} />
      </Drawer>
      <hr />
    </div>
  );
};

export default PageHeader;
