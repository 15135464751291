import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import MenuBar from './MenuBar';
import { DeepReadonly } from 'utility-types';
import ConfirmationModal from '../ConfirmationModal';

type MenuProps = DeepReadonly<{
  isMenuOpen?: boolean;
}>;

interface MenuState {
  isMenuOpen: boolean;
}

class FixedMenuLayout extends Component<MenuProps, MenuState> {
  state = {
    isMenuOpen: false
  };

  handleMenuToggle = () => {
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen
    }));
  };

  render() {
    const { children } = this.props;
    return (
      <div className={this.state.isMenuOpen ? 'menu-wrapper open' : 'menu-wrapper'}>
        <MenuBar />

        <div className="content-wrapper">
          <div className="container-fluid">
            <Button icon onClick={this.handleMenuToggle} className="menu-toggle float-right">
              <Icon name="align justify" />
            </Button>
          </div>
          <div className="container-fluid">
            <ConfirmationModal />
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default FixedMenuLayout;
