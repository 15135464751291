import * as Yup from 'yup';
import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormDefinition, FormFieldPosition, FormFieldSize } from '../../shared/interfaces/FormFieldDefinition';
import iban from 'iban';
import { ContactType } from './constants';
import { enumToOption, arrayToOption, languageCodesToOptions, languagesToOptions } from '../../shared/helpers/dropDownHelpers';
import Language from '../../shared/interfaces/Language';
import { countryCodes } from '../../shared/constants';
import { ClientType } from '../../shared/interfaces/Client';
import { vatValidation } from '../../shared/helpers/vatValidation';
import { emailValidation } from '../../shared/helpers/emailValidation';
import { phoneValidation } from '../../shared/helpers/phoneValidation';

export const contactFormDefinition: FormDefinition = [
  {
    propertyName: 'code',
    label: 'Code',
    type: DetailFormFieldTypes.text,
    orderNr: 1,
    disabled: true
  },
  {
    propertyName: 'firstName',
    label: 'Voornaam',
    type: DetailFormFieldTypes.text,
    orderNr: 1,
    validationChecks: Yup.string().required(),
    required: true
  },
  {
    propertyName: 'lastName',
    label: 'Achternaam',
    type: DetailFormFieldTypes.text,
    orderNr: 2,
    validationChecks: Yup.string().required(),
    required: true
  },
  {
    propertyName: 'email',
    label: 'E-mailadres',
    type: DetailFormFieldTypes.email,
    validationChecks: Yup.string().email('E-mailadres is niet geldig'),
    orderNr: 3
  },
  {
    propertyName: 'phone',
    label: 'Telefoonnummer',
    type: DetailFormFieldTypes.telephone,
    validationChecks: Yup.string().test('phone', 'Dit telefoonnummer is niet geldig', phoneValidation),
    orderNr: 4
  },
  {
    propertyName: 'cellphone',
    label: 'Gsm-nummer',
    type: DetailFormFieldTypes.telephone,
    orderNr: 5,
    validationChecks: Yup.string().test('phone', 'Dit telefoonnummer is niet geldig', phoneValidation)
  },
  {
    propertyName: 'hasNewsletter',
    label: 'Krijgt nieuwsbrief',
    type: DetailFormFieldTypes.checkbox,
    orderNr: 7,
    validationChecks: Yup.boolean(),
    required: false
  },
  {
    propertyName: 'isLeading',
    label: 'Is verantwoordelijk',
    type: DetailFormFieldTypes.checkbox,
    orderNr: 7,
    validationChecks: Yup.boolean(),
    required: false
  },
  {
    propertyName: 'relation',
    label: 'Relation',
    type: DetailFormFieldTypes.text,
    orderNr: 1
  }
];

export const formDefinition = (languages: Language[]): FormDefinition =>
  [
    {
      propertyName: 'id',
      label: 'Id',
      type: DetailFormFieldTypes.hidden,
      orderNr: 1
    },
    {
      propertyName: 'customerNr',
      label: 'Klantennummer',
      type: DetailFormFieldTypes.hidden,
      orderNr: 1
    },
    {
      label: 'Informatie',
      type: DetailFormFieldTypes.header,
      orderNr: 1,
      column: FormFieldPosition.Left
    },
    {
      propertyName: 'code',
      label: 'Code',
      type: DetailFormFieldTypes.text,
      orderNr: 2,
      column: FormFieldPosition.Left,
      disabled: true
    },
    {
      propertyName: 'companyName',
      label: 'Klantnaam',
      type: DetailFormFieldTypes.text,
      orderNr: 2,
      validationChecks: Yup.string().required('Klantnaam moet ingevuld worden.'),
      column: FormFieldPosition.Left,
      disabled: true
    },
    {
      propertyName: 'type',
      label: 'Type',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 3,
      validationChecks: Yup.string(),
      required: false,
      dropDownOptions: enumToOption(ClientType),
      column: FormFieldPosition.Left,
      disabled: true
    },
    {
      label: 'Adres',
      type: DetailFormFieldTypes.header,
      orderNr: 2,
      column: FormFieldPosition.Right
    },
    {
      propertyName: 'street',
      label: 'Straat',
      type: DetailFormFieldTypes.text,
      orderNr: 3,
      validationChecks: Yup.string(),
      required: false,
      column: FormFieldPosition.Right,
      disabled: true
    },
    {
      propertyName: 'houseNr',
      label: 'Huisnummer',
      type: DetailFormFieldTypes.text,
      orderNr: 4,
      validationChecks: Yup.string(),
      required: false,
      column: FormFieldPosition.Right,
      size: FormFieldSize.Small,
      disabled: true
    },
    {
      propertyName: 'bus',
      label: 'Bus',
      type: DetailFormFieldTypes.text,
      orderNr: 5,
      column: FormFieldPosition.Right,
      size: FormFieldSize.Small,
      disabled: true
    },
    {
      propertyName: 'postalCode',
      label: 'Postcode',
      type: DetailFormFieldTypes.text,
      orderNr: 6,
      validationChecks: Yup.string(),
      required: false,
      column: FormFieldPosition.Right,
      size: FormFieldSize.Small,
      disabled: true
    },
    {
      propertyName: 'city',
      label: 'Plaats',
      type: DetailFormFieldTypes.text,
      orderNr: 7,
      validationChecks: Yup.string(),
      required: false,
      column: FormFieldPosition.Right,
      disabled: true
    },
    {
      propertyName: 'country',
      label: 'Land',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 8,
      validationChecks: Yup.string(),
      required: false,
      dropDownOptions: arrayToOption(countryCodes),
      column: FormFieldPosition.Right,
      disabled: true
    },
    {
      propertyName: 'languages',
      label: 'Talen',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 9,
      validationChecks: Yup.array()
        .of(Yup.string())
        .required('Taal moet ingevuld worden.'),
      required: true,
      multiple: true,
      dropDownOptions: languagesToOptions(languages)
    },
    {
      propertyName: 'email',
      label: 'E-mailadres',
      type: DetailFormFieldTypes.email,
      orderNr: 10,
      validationChecks: Yup.string().test('email', 'Dit email is niet geldig', emailValidation),
      required: false,
      disabled: true
    },
    {
      propertyName: 'phone',
      label: 'Telefoonnummer',
      type: DetailFormFieldTypes.telephone,
      orderNr: 10,
      required: false,
      validationChecks: Yup.string().test('phone', 'Dit telefoonnummer is niet geldig', phoneValidation),
      disabled: true
    },
    {
      propertyName: 'website',
      label: 'Website',
      type: DetailFormFieldTypes.text,
      validationChecks: Yup.string().url('Moet een geldige url zijn'),
      orderNr: 11,
      disabled: true
    },
    {
      propertyName: 'accountNr',
      label: 'Rekeningnummer',
      type: DetailFormFieldTypes.text,
      orderNr: 18,
      validationChecks: Yup.string().test('iban', 'Moet een geldig IBAN nummer zijn', text => (text ? iban.isValid(text) : true)),
      required: false,
      disabled: true
    },
    {
      propertyName: 'vatNr',
      label: 'BTW-nummer',
      type: DetailFormFieldTypes.vat,
      orderNr: 19,
      validationChecks: Yup.string().test('vat', 'Dit BTW-nummer is niet geldig', vatValidation),
      required: false,
      disabled: true
    },
    {
      propertyName: 'remarks',
      label: 'Opmerkingen',
      type: DetailFormFieldTypes.textbox,
      orderNr: 20,
      required: false,
      disabled: true
    }
  ] as FormDefinition;

export const SimpleFormClientDefinition = (languages: Language[]): FormDefinition =>
  [
    {
      propertyName: 'id',
      label: 'Id',
      type: DetailFormFieldTypes.hidden,
      orderNr: 1
    },
    {
      propertyName: 'customerNr',
      label: 'Klantennummer',
      type: DetailFormFieldTypes.hidden,
      orderNr: 1
    },
    {
      propertyName: 'companyName',
      label: 'Klantnaam',
      type: DetailFormFieldTypes.text,
      orderNr: 2,
      validationChecks: Yup.string().required('Klantnaam moet ingevuld worden.'),
      required: true,
      column: FormFieldPosition.Left
    },
    {
      propertyName: 'type',
      label: 'Type',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 3,
      validationChecks: Yup.string(),
      required: false,
      dropDownOptions: enumToOption(ClientType),
      column: FormFieldPosition.Left
    },
    {
      propertyName: 'languages',
      label: 'Talen',
      type: DetailFormFieldTypes.dropdownlist,
      orderNr: 9,
      validationChecks: Yup.array()
        .of(Yup.string())
        .required('Taal moet ingevuld worden.'),
      required: true,
      multiple: true,
      dropDownOptions: languagesToOptions(languages)
    },
    {
      propertyName: 'email',
      label: 'E-mailadres',
      type: DetailFormFieldTypes.email,
      orderNr: 10,
      validationChecks: Yup.string().test('email', 'Dit email is niet geldig', emailValidation),
      required: false
    },
    {
      propertyName: 'phone',
      label: 'Telefoonnummer',
      type: DetailFormFieldTypes.telephone,
      orderNr: 10,
      required: false,
      validationChecks: Yup.string().test('phone', 'Dit telefoonnummer is niet geldig', phoneValidation)
    }
  ] as FormDefinition;
