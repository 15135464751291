import React from 'react';
import * as Yup from 'yup';
import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormDefinition } from '../../shared/interfaces/FormFieldDefinition';
import Entity from '../../shared/interfaces/Entity';
import Course, { priceOptionsInCompany, priceOptionsOpenCalendar, priceOptions } from '../../shared/interfaces/Course';
import Client from '../../shared/interfaces/Client';
import SearchCourseForm from '../../shared/components/DetailForm/EntitySelector/SelectForms/SearchCourseForm';
import SearchClientForm from '../../shared/components/DetailForm/EntitySelector/SelectForms/SearchClientForm';
import SearchTaskListForm from '../../shared/components/DetailForm/EntitySelector/SelectForms/SearchTaskListForm';
import { enumToOption } from '../../shared/helpers/dropDownHelpers';
import { TrainingType } from '../../shared/interfaces/Training';
import TaskList from '../../shared/interfaces/TaskList';

export const formDefinition: FormDefinition = [
  {
    propertyName: 'minParticipants',
    label: 'Minimum aantal deelnemers',
    type: DetailFormFieldTypes.number,
    orderNr: 10,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required(),
    required: true
  },
  {
    propertyName: 'maxParticipants',
    label: 'Maximum aantal deelnemers',
    type: DetailFormFieldTypes.number,
    orderNr: 20,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required(),
    required: true
  },
  {
    propertyName: 'course',
    required: true,
    label: 'Pakket',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 40,
    validationChecks: Yup.string().required('Pakket is verplicht mee te geven'),
    multiple: false,
    renderEntity: (course: Entity) => <span key={course['@id']}>{(course as Course).title}</span>,
    isNewVersion: true,
    dataTableFields: [{ key: 'title', label: 'Pakketnaam'}, { key: 'courseType', label: 'Type'}, { key: 'price', label: 'Prijs'}, { key: 'createdAt', label: 'Aanmaakdatum'}],
    searchEntityForm: SearchCourseForm
  },
  {
    propertyName: 'customer',
    required: true,
    label: 'Klant',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 50,
    validationChecks: Yup.string().required('Klant is verplicht mee te geven'),
    multiple: false,
    renderEntity: (course: Entity) => <span key={course['@id']}>{(course as Client).companyName}</span>,
    isNewVersion: true,
    dataTableFields: [{ key: 'companyName' }],
    searchEntityForm: SearchClientForm
  },
  {
    propertyName: 'evaluationLink',
    label: 'Evaluatie link',
    type: DetailFormFieldTypes.uri,
    orderNr: 60,
  },
  {
    propertyName: 'firstMeasurementLink',
    label: 'Eerste meting link',
    type: DetailFormFieldTypes.uri,
    orderNr: 60,
  },
  {
    propertyName: 'secondMeasurementLink',
    label: 'Tweede meting link',
    type: DetailFormFieldTypes.uri,
    orderNr: 60,
  },
  {
    propertyName: 'trainingPredefinedTaskList',
    label: 'Takenlijst',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 50,
    multiple: false,
    renderEntity: (tasklist: Entity) => <span key={tasklist['@id']}>{(tasklist as TaskList).name}</span>,
    isNewVersion: true,
    dataTableFields: [{ key: 'name' }],
    searchEntityForm: SearchTaskListForm
  }
];

export const formDefinitionWithoutCustomer: FormDefinition = [
  {
    propertyName: 'minParticipants',
    label: 'Minimum aantal deelnemers',
    type: DetailFormFieldTypes.number,
    orderNr: 10,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required(),
    required: true
  },
  {
    propertyName: 'maxParticipants',
    label: 'Maximum aantal deelnemers',
    type: DetailFormFieldTypes.number,
    orderNr: 20,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required(),
    required: true
  },
  {
    propertyName: 'course',
    required: true,
    label: 'Pakket',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 40,
    validationChecks: Yup.string().required(),
    multiple: false,
    renderEntity: (course: Entity) => <span key={course['@id']}>{(course as Course).title}</span>,
    isNewVersion: true,
    dataTableFields: [{ key: 'title', label: 'Pakketnaam'}, { key: 'price', label: 'Prijs'}, { key: 'createdAt', label: 'Aanmaakdatum'}],
    searchEntityForm: SearchCourseForm
  },
  {
    propertyName: 'evaluationLink',
    label: 'Evaluatie link',
    type: DetailFormFieldTypes.uri,
    orderNr: 60,
  },
  {
    propertyName: 'firstMeasurementLink',
    label: 'Eerste meting link',
    type: DetailFormFieldTypes.uri,
    orderNr: 60,
  },
  {
    propertyName: 'secondMeasurementLink',
    label: 'Tweede meting link',
    type: DetailFormFieldTypes.uri,
    orderNr: 60,
  },
  {
    propertyName: 'trainingPredefinedTaskList',
    label: 'Takenlijst',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 50,
    multiple: false,
    renderEntity: (tasklist: Entity) => <span key={tasklist['@id']}>{(tasklist as TaskList).name}</span>,
    isNewVersion: true,
    dataTableFields: [{ key: 'name' }],
    searchEntityForm: SearchTaskListForm
  }
];

export const formDefinitionWithType: FormDefinition = [
  {
    propertyName: 'minParticipants',
    label: 'Minimum aantal deelnemers',
    type: DetailFormFieldTypes.number,
    orderNr: 10,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required(),
    required: true
  },
  {
    propertyName: 'maxParticipants',
    label: 'Maximum aantal deelnemers',
    type: DetailFormFieldTypes.number,
    orderNr: 20,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required(),
    required: true
  },
  {
    propertyName: 'type',
    label: 'Type',
    type: DetailFormFieldTypes.dropdownlist,
    orderNr: 30,
    dropDownOptions: enumToOption(TrainingType)
  },
  {
    propertyName: 'course',
    required: true,
    label: 'Pakket',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 40,
    validationChecks: Yup.string().required(),
    multiple: false,
    renderEntity: (course: Entity) => <span key={course['@id']}>{(course as Course).title}</span>,
    isNewVersion: true,
    dataTableFields: [{ key: 'title', label: 'Pakketnaam'}, { key: 'price', label: 'Prijs'}, { key: 'createdAt', label: 'Aanmaakdatum'}],
    searchEntityForm: SearchCourseForm
  },
  {
    propertyName: 'customer',
    required: true,
    label: 'Klant',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 50,
    validationChecks: Yup.string().required(),
    multiple: false,
    renderEntity: (course: Entity) => <span key={course['@id']}>{(course as Client).companyName}</span>,
    isNewVersion: true,
    dataTableFields: [{ key: 'companyName' }],
    searchEntityForm: SearchClientForm
  },
  {
    propertyName: 'evaluationLink',
    label: 'Evaluatie link',
    type: DetailFormFieldTypes.uri,
    orderNr: 60,
  },
  {
    propertyName: 'firstMeasurementLink',
    label: 'Eerste meting link',
    type: DetailFormFieldTypes.uri,
    orderNr: 60,
  },
  {
    propertyName: 'secondMeasurementLink',
    label: 'Tweede meting link',
    type: DetailFormFieldTypes.uri,
    orderNr: 60,
  },
  {
    propertyName: 'trainingPredefinedTaskList',
    label: 'Takenlijst',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 50,
    multiple: false,
    renderEntity: (tasklist: Entity) => <span key={tasklist['@id']}>{(tasklist as TaskList).name}</span>,
    isNewVersion: true,
    dataTableFields: [{ key: 'name' }],
    searchEntityForm: SearchTaskListForm
  }
];

export const SimpleBedrijfsInternFormDefinition = () => formDefinition as FormDefinition;
export const SimpleOpenCalendarDefinition = () => formDefinitionWithoutCustomer as FormDefinition;
