import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router';
import ListShell from './components/List/ListShell';
import DetailCreate from './components/Detail/DetailCreate';
import DetailShell from './components/Detail/DetailShell';
import CategoriesRoutes from '../categories/Routes';

const Routes = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${match.path}/create`} component={DetailCreate} key={`${match.path}/create`} />
      <Route path={`${match.path}/categories`} component={CategoriesRoutes} />
      <Route path={`${match.path}/:id`} component={DetailShell} />
      <Route component={ListShell} />
    </Switch>
  );
};
export default Routes;
