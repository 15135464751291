import React, { Component } from 'react';
import { GlobalState } from '../../../core/state';
import { getResources } from '../../state/selectors';
import { SaveTrainer } from '../../state/actions';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { baseUrl, newEntity } from '../../helpers/constants';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { FormikValues } from 'formik';
import { formDefinition } from '../../helpers/DetailFormFields';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import { TrainerResources } from './DetailShell';
import { Trainer } from '../../../shared/interfaces/Trainer';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { RouteComponentProps } from 'react-router';
import PageHeader from '../../../shared/components/PageHeader';

type Props = {
  saveInProgress: boolean;
  saveTrainer: (trainer: Trainer) => void;
  navigatePush: (values: string) => void;
  resources: TrainerResources;
} & WithNamespaces &
  RouteComponentProps;

class DetailCreate extends Component<Props> {
  handleSubmit = (formValues: FormikValues) => {
    this.props.saveTrainer(formValues as Trainer);
  };

  render() {
    const { navigatePush, resources, t } = this.props;
    return (
      <>
        <PageHeader title={t('new trainer')} breadCrumbParts={[t('trainers'), t('new')]} {...this.props} />
        <DetailForm
          onFormSubmit={this.handleSubmit}
          savingForm={this.props.saveInProgress}
          selectedEntity={newEntity}
          onCancelBtnClick={() => navigatePush(`/${baseUrl}`)}
          formDefinition={formDefinition(resources.languages, resources.competencies)}
        />
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    saveInProgress: state.trainers.savingTrainerStatus === ProgressStatus.InProgress,
    resources: getResources(state)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  saveTrainer: (trainer: Trainer) => dispatch(SaveTrainer({ trainer })),
  navigatePush: (path: string) => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['trainers', 'resources'], { nsMode: 'fallback' })(DetailCreate));
