import React, { SyntheticEvent, useState } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import TextInput from './TextInput';

interface CurrencyProps {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: SyntheticEvent) => void;
  value: any;
  label: string;
  keyField: string;
  disabled?: boolean;
}

const Currency = ({ onBlur, onChange, value, label, keyField, disabled }: CurrencyProps) => {
  return (
    <Input iconPosition="left" type="text">
      <i style={{ top: '1px' }} aria-hidden="true" className="euro icon" />
      <TextInput disabled={disabled} label={label} type="number" keyField={keyField} onChange={onChange} onBlur={onBlur} value={value} />
    </Input>
  );
};

export default Currency;
