import React from 'react';
import { List, Button } from 'semantic-ui-react';
import Course from '../../../shared/interfaces/Course';
import { RouteComponentProps } from 'react-router';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import PageHeader from '../../../shared/components/PageHeader';
import { Trainer } from '../../../shared/interfaces/Trainer';
import applyArchiveTagCheck from '../../../shared/helpers/archivedFormatter';
import moment from 'moment';
import { DisplayBox } from '../../../shared/components/DisplayBox';
import { DisplayGroup } from '../../../shared/components/DisplayGroup';

type DetailViewProps = {
  selectedCourse: Course;
};

interface TrainerTeaser {
  '@id': string;
  firstName: string;
  lastName: string;
  deletedAt?: string;
}

const DetailView = (props: DetailViewProps & WithNamespaces & RouteComponentProps) => {
  const { selectedCourse, t, history } = props;
  const sortedTrainers = selectedCourse.trainers.sort((a: Trainer, b: Trainer) => {
    const x = a.firstName;
    const y = b.firstName;
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <PageHeader
        {...props}
        breadCrumbParts={[t('courses'), selectedCourse.title]}
        title={
          <span>
            {selectedCourse.title}
            {selectedCourse.active ? null : (
              <>
                &nbsp;-&nbsp;<span className="deactivated">{t('deactived')}</span>
              </>
            )}
            {selectedCourse.deletedAt ? (
              <span className="archived"> (Gearchiveerd op {moment(selectedCourse.deletedAt).format('DD/MM/YY')})</span>
            ) : null}
          </span>
        }
        rightBlock={
          <Button className="primary float-right" onClick={() => history.push(`/courses/${selectedCourse.id}/edit`)}>
            {t('edit')}
          </Button>
        }
      />
      <div className="row">
        <div className="col-12 col-md-8">
          <DisplayBox>
            <>
              <DisplayGroup label={t('code')} value={selectedCourse.code} />
              <DisplayGroup label={t('description')} value={selectedCourse.description} isRaw />
              <DisplayGroup label={t('price')} value={selectedCourse.price} isCurrency />
              <DisplayGroup label={t('unit')} value={selectedCourse.unit} />
              <DisplayGroup label={t('type')} value={selectedCourse.courseType} />
            </>
          </DisplayBox>
        </div>
        <div className="col-12 col-md-4">
          <DisplayBox title={t('trainers')}>
            <>
              <List>
                {selectedCourse.trainers.length ? (
                  sortedTrainers.map((trainer: TrainerTeaser, key) => (
                    <List.Item className="trainers-list" onClick={() => history.push(trainer['@id'])} key={key} as="a">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: applyArchiveTagCheck(trainer.firstName + ' ' + trainer.lastName, trainer.deletedAt, true, true)
                        }}></span>
                    </List.Item>
                  ))
                ) : (
                  <List.Item>{t('courses.course-no-trainers')}</List.Item>
                )}
              </List>
            </>
          </DisplayBox>
        </div>
      </div>
    </>
  );
};

export default withNamespaces(['courses', 'menu', 'resources', 'trees'], {
  nsMode: 'fallback'
})(DetailView);
