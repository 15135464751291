import { call, put, takeLatest } from 'redux-saga/effects';
import { configConstants } from '../../config/constants';
import * as actions from './actions';
import { getType } from 'typesafe-actions';
import { fetch } from '../../core/state/fetch';
import { Task } from '../../shared/interfaces/Task';

export function* loadTasks(action: ReturnType<typeof actions.FetchTasks.request>) {
  try {
    const response = yield call(
      fetch,
      `${configConstants.apiUrl}training_tasks?dueDate[exists]=true&executed=false&embed[]=training&embed[]=course&order[dueDate]=ASC`
    );
    if (response.ok) {
      const data = yield response.json();
      const tasks = data['hydra:member'];
      const tasksWithoutArchivedTrainings = tasks.filter((task: Task) => {
        return task.training.deletedAt === null;
      }) as Task[];
      yield put(actions.FetchTasks.success({ tasks: tasksWithoutArchivedTrainings }));
    } else {
      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.FetchTasks.failure({ errorMessage }));
  }
}

export const tasksSaga = [takeLatest(getType(actions.FetchTasks.request), loadTasks)];
