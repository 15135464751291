import React, { Fragment } from 'react';
import { Segment, Icon, Button } from 'semantic-ui-react';
import { GQLTrainingSession, TrainingsAction, TrainingSessionWithMaterials } from '../../../../../state/state';
import ProgressStatus from '../../../../../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../../../../../shared/components/InitialLoader';
import { RouteComponentProps } from 'react-router';
import Course from '../../../../../../shared/interfaces/Course';
import { Training } from '../../../../../../shared/interfaces/Training';
import moment from 'moment-timezone';
import TrainerTeaserView from '../../../../../../trainers/components/Teaser/TrainerTeaserView';

import './SessionView.scss';
import { translate, WithNamespaces } from 'react-i18next';
import { Empty, Tooltip } from 'antd';
import applyArchiveTagCheck from '../../../../../../shared/helpers/archivedFormatter';
import { GlobalState } from '../../../../../../core/state';
import { Dispatch } from 'redux';
import { AppAction } from '../../../../../../core/state/app.state';
import * as actions from '../../../../../state/actions';
import TrainingSession from '../../../../../../shared/interfaces/TrainingSession';
import { ConfirmAction } from '../../../../../../core/state/actions';
import { connect } from 'react-redux';

type Props = {
  sessions: GQLTrainingSession[] | undefined;
  sessionsStatus: ProgressStatus;
  training: Training;
  course: Course;
  deleteSession: (sessionId: number, trainingId: number) => void;
  hasMaterialModule: boolean;
} & RouteComponentProps &
  WithNamespaces;

class SessionView extends React.Component<Props> {
  renderSessionRow(session: GQLTrainingSession, index: number) {
    const { course, training, history, match, t } = this.props;
    const sessionPath = session.id.replace('training_', '');

    return (
      <Segment className="session-teaser" key={session.id}>
        <h3>
          <span
            dangerouslySetInnerHTML={{
              __html: applyArchiveTagCheck(course.title, course.deletedAt, true, true)
            }}></span>
          (dag {index + 1})
        </h3>

        {session.location ? (
          <div className="adress">
            <span
              dangerouslySetInnerHTML={{
                __html: applyArchiveTagCheck(session.location!.name, session.location.deletedAt, true, true)
              }}></span>
          </div>
        ) : null}
        <br />
        <TrainerTeaserView
          name={session.trainer ? session.trainer.firstName + ' ' + session.trainer.lastName : '-'}
          deletedAt={session.trainer ? session.trainer.deletedAt : ''}
        />

        <div className="date">
          <span>{moment(session.startDate).format('DD/MM/YYYY')}</span>
          <span>
            {moment(session.startDate).format('LT')}-{moment(session.endDate).format('LT')}
          </span>
        </div>
        <div className="action-button">
          {this.props.hasMaterialModule && (
            <Button icon onClick={() => history.push(match.url + `${sessionPath}/materials`)} title={t('materials')}>
              <Icon name={'boxes'} />
            </Button>
          )}
          <Button icon onClick={() => history.push(match.url + `${sessionPath}/participants`)} title={t('participants')}>
            <Icon name={'clipboard list'} />
          </Button>
          <Button icon onClick={() => history.push(match.url + `${sessionPath}/edit`)}>
            <Icon name={'pencil'} />
          </Button>
          <Button icon onClick={() => this.props.deleteSession(session._id, training.id)}>
            <Icon name={'trash alternate outline'} />
          </Button>
        </div>
      </Segment>
    );
  }
  downloadParticipantList = () => {};
  renderSessions() {
    const { sessions, sessionsStatus, t } = this.props;

    if (sessionsStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    return (
      <Fragment>
        {sessions && sessions.length > 0 ? (
          sessions.map(this.renderSessionRow.bind(this))
        ) : (
          <Empty description={<span>{t('noSessions')}</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Fragment>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className="detail-group">
        <div className="row header">
          <div className="col-6">
            <h3 className="text-uppercase">{t('sessions')}</h3>
          </div>
          <div className="col-6">
            {this.props.course.deletedAt ? (
              <Tooltip placement="top" title={t('courseIsArchived')}>
                <Button className="float-right primary inactive">
                  {t('addSession')}
                </Button>
              </Tooltip>
            ) : (
              <Button
                className="float-right primary"
                onClick={() => this.props.history.push(`/trainings/${this.props.training.id}/sessions/create`)}>
                {t('addSession')}
              </Button>
            )}
          </div>
        </div>
        {this.renderSessions()}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  hasMaterialModule: state.app.activeModules.materialModule
});

export default connect(mapStateToProps, null)(SessionView);
