import moment from 'moment';

export default function applyArchiveTagCheck(value: string, deletedAt?: string, strike = false, date = false) {
  value = value.replace('>', '&gt;');
  value = value.replace('<', '&gt;');

  if (!deletedAt) {
    return value;
  }

  if (strike) {
    value = `<s>${value}</s>`;
  }

  if (date) {
    value += ` (Gearchiveerd op ${moment(deletedAt).format('DD/MM/YY')})`;
  }

  return value;
}
