// CORE
import React, { useEffect } from 'react';

// COMPONENTS
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

// SERVICES
import { withNamespaces, WithNamespaces } from 'react-i18next';
import i18next from 'i18next';
import { useAuth0 } from '../../auth/AuthWrapper';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import * as actions from '../../state/actions';
import { AppAction } from '../../state/app.state';
import { GlobalState } from '../../state';
import Modules from '../../../shared/interfaces/Modules';

export const sidebarWidth = 250;

const menuData = (t: i18next.WithT['t'], activeModule: Modules) => {
  let defaultMenuItems = [
    { to: '/', value: t('dashboard'), delta: 0 },
    { to: '/clients', value: t('clients'), delta: 20 },
    { to: '/trainers', value: t('trainers'), delta: 30 },
    { to: '/courses', value: t('courses'), delta: 40 },
    { to: '/trainings', value: t('trainings'), delta: 50 },
    { to: '/tasks', value: t('tasks'), delta: 60 },
    { to: '/locations', value: t('locations'), delta: 70 },
    { to: '/task-lists', value: t('task-lists'), delta: 65 }
  ];

  const moduleMenuItems = [];

  if (activeModule.requestModule) {
    moduleMenuItems.push({ to: '/requests', value: t('requests'), delta: 10 });
  }

  if (activeModule.materialModule) {
    moduleMenuItems.push({ to: '/materials', value: t('materials'), delta: 80 });
  }

  if (moduleMenuItems.length) {
    defaultMenuItems = defaultMenuItems.concat(moduleMenuItems);
  }

  return defaultMenuItems.sort((a, b) => (a.delta > b.delta ? 1 : -1));
};

type MenuBarProps = {
  loadResources: () => void;
  activeModule: Modules;
} & WithNamespaces;

const renderMenuItem = (value: string, to: string) => {
  const pathname = window.location.pathname;
  const isActive = to === '/' ? pathname === '/' : pathname.match(new RegExp(`^${to}`)) !== null;

  return (
    <Menu.Item key={to} as={Link} to={to} active={isActive}>
      {value}
    </Menu.Item>
  );
};

const MenuBar = ({ t, activeModule, ...props }: MenuBarProps) => {
  const { isAuthenticated, loading, logout, hasAdminAccess, hasUserAccess, userHasScopes } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      props.loadResources();
    }
  }, [isAuthenticated]);
  return (
    <>
      {isAuthenticated && !loading ? (
        <div className={'menu-sidebar'}>
          <div style={{ flex: 1 }}>
            <Menu fluid inverted vertical borderless compact>
              <Menu.Item className="withHeader">
                <Menu.Header>Lester.</Menu.Header>
              </Menu.Item>
              <>
                {menuData(t, activeModule).map(link => renderMenuItem(link.value, link.to))}
                <Menu.Item onClick={() => logout()}>{t('logout')}</Menu.Item>
              </>
            </Menu>
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    activeModule: state.app.activeModules
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AppAction>) => ({
  loadResources: () => dispatch(actions.LoadResources.request())
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('menu')(MenuBar));
