import React from 'react';
import { Loader } from 'semantic-ui-react';

const CalendarLoader = () => {
  return (
    <div>
      <Loader active inline />
    </div>
  );
};

export default CalendarLoader;
