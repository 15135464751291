import Course from '../../shared/interfaces/Course';

export enum courseActiveStates {
  Actief = '',
  Beide = 'both',
  Inactief = 'false'
}

export default {
  baseUrl: 'courses',
  defaultCourseData: {
    '@id': '',
    id: 0,
    title: '',
    description: '',
    trainers: []
  } as Course
};

export const newCourseData: Course = {
  '@id': '',
  id: 0,
  title: '',
  description: '',
  trainers: [],
  code: '',
  price: 0,
  type: '',
  unit: ''
};
