import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import { configConstants } from '../../config/constants';
import { push } from 'connected-react-router';
import CourseCategory, { WithParent } from '../../shared/interfaces/CourseCategory';
import { AnyAction } from 'redux';
import { getType } from 'typesafe-actions';
import { takeLatest } from 'redux-saga';
import AlertService from '../../core/alert/AlertService';
import { fetch } from '../../core/state/fetch';

export function* saveCategory(action: ReturnType<typeof actions.SaveCategory.request>) {
  try {
    const category = action.payload.courseCategory;
    delete category.courses;
    delete category.children;
    const isNew = category.id === 0;
    const response = yield call(
      fetch,
      isNew ? `${configConstants.apiUrl}course_categories` : `${configConstants.apiUrl}course_categories/${category.id}`,
      {
        method: isNew ? 'POST' : 'PUT',
        body: JSON.stringify(
          isNew
            ? { ...category, id: undefined, parent: category.parent ? category.parent : null }
            : { ...category, parent: category.parent ? category.parent : null }
        ),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (response.status >= 200 && response.status < 300) {
      const result = yield response.json() as CourseCategory<WithParent>;
      yield put(actions.SaveCategory.success(result));

      AlertService.setMessage({
        title: 'Catergorie opgeslagen',
        messageText: 'De categegorie is succesvol opgeslagen.',
        type: 'success'
      });

      if (!action.payload.noredirect) {
        yield put(isNew ? push(`/courses`) : push(`/courses/categories/edit`));
      }
    } else {
      AlertService.setMessage({
        title: 'Categorie niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opgeslagen.',
        type: 'error'
      });

      throw response;
    }
  } catch (errorMessage) {
    yield put(actions.SaveCategory.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Categorie niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opgeslagen.',
      type: 'error'
    });
  }
}

export function* loadCategories(action: AnyAction) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}course_categories?pagination=false`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();
      const categories = data['hydra:member'] as CourseCategory[];
      yield put(actions.FetchCategories.success({ categories }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchCategories.failure({ errorMessage: error }));
  }
}

export function* loadOneCategory(action: AnyAction) {
  try {
    const { id } = action.payload;
    const response = yield call(fetch, `${configConstants.apiUrl}course_categories/${id}`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json() as CourseCategory<WithParent>;
      yield put(actions.FetchOneCategory.success({ category: data }));
    }
  } catch (error) {
    yield put(actions.FetchOneCategory.failure({ errorMessage: error }));
  }
}

function* deleteCategory(action: ReturnType<typeof actions.DeleteCategory.request>) {
  try {
    yield call(fetch, `${configConstants.apiUrl}course_categories/${action.payload.categoryId}`, { method: 'DELETE' });
    yield put(actions.FetchCategories.request(action.payload.queryParams));

    AlertService.setMessage({
      title: 'Categorie verwijderd',
      messageText: 'De categorie is succesvol verwijderd.',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.DeleteCategory.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Categorie niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
      type: 'error'
    });
  }
}

export const categoriesSaga = [
  takeEvery(actions.SaveCategory.request, saveCategory),
  takeLatest(getType(actions.FetchCategories.request), loadCategories),
  takeLatest(getType(actions.FetchOneCategory.request), loadOneCategory),
  takeEvery(getType(actions.DeleteCategory.request), deleteCategory)
];
