import { createSelector } from 'reselect';
import { AppState } from './app.state';
import { RouterState } from 'connected-react-router';

export const getLoading = createSelector(
  (state: AppState) => state,
  state => state.loading
);
export const getPathName = createSelector(
  (state: RouterState) => state,
  state => state.location.pathname
);

export const getMaterialModuleStatus = createSelector(
  (state: AppState) => state,
  state => state.activeModules.materialModule
);

export const getRequestModuleStatus = createSelector(
  (state: AppState) => state,
  state => state.activeModules.requestModule
);
