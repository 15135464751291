import React from 'react';
import { Segment, Header, Button } from 'semantic-ui-react';
import { CourseLocation } from '../../../../shared/interfaces/CourseLocation';
import { Link } from 'react-router-dom';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import Client from '../../../../shared/interfaces/Client';

type Props = {
  locations: CourseLocation[];
  client: Client;
  unlinkLocation: (id: number) => void;
} & WithNamespaces;

export const locationsSection = ({ locations, t, unlinkLocation, client }: Props) => {
  return (
    <div className="location-teaser-list">
      <Header as="h4" content={t('locations')} />
      <div className="row">
        {locations.map(location => {
          return (
            <div className="col-12 col-md-6 col-lg-4 margin-bot-5" key={location['@id']}>
              <Segment>
                <div className="row  margin-bot-5">
                  <div className="col-9">
                    <b>{location.name}</b>
                  </div>
                  <div className="col-3">
                    <Link
                      to={{
                        pathname: `/locations/${location.id}/edit`,
                        state: {
                          clientId: client.id,
                          returnUrl: `/clients/${client.id}`,
                          tabActiveIndex: 2
                        }
                      }}>
                      <Button icon="pencil" />
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className=" col-9">{location.street + ' ' + location.houseNr}</div>
                  <div className="col-3">
                    <Button icon="trash alternate outline" type="button" onClick={() => unlinkLocation(location.id as number)} />
                  </div>
                </div>
                <div className="row col-12">{location.postalCode + ' ' + location.city}</div>
                <div className="row col-12">{t(location.country ? location.country : 'unknown')}</div>
              </Segment>
            </div>
          );
        })}
      </div>
      <Link
        to={{
          pathname: `/locations/create`,
          state: {
            clientId: client.id,
            returnUrl: `/clients/${client.id}`,
            tabActiveIndex: 2
          }
        }}>
        <Button positive className="primary" content={t('createRequest')} />
      </Link>
    </div>
  );
};

export default withNamespaces(['clients', 'resources', 'common'], {
  nsMode: 'fallback'
})(locationsSection);
