import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Dispatch } from 'redux';
import { GlobalState } from '../../../core/state';
import { Material } from '../../../shared/interfaces/Material';
import * as actions from '../../state/actions';
import { RouteComponentProps, Route, Switch, Redirect } from 'react-router';
import InitialLoader from '../../../shared/components/InitialLoader';
import { FormikValues } from 'formik';
import NoDataFound from '../../../shared/components/NoDataFound';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import Language from '../../../shared/interfaces/Language';
import { formDefinition } from '../../helpers/DetailFormFields';
import DetailView from './DetailView';
import PageHeader from '../../../shared/components/PageHeader';

type DetailShellProps = {
  loadOneMaterial: (id: number | string) => void;
  saveMaterial: (material: Material) => void;
  selectedMaterial: Material;
  loadingMaterial: boolean;
  materialLoaded: boolean;
  languages: Language[];
};

type RouteInfo = {
  id: string;
};

class DetailShell extends Component<DetailShellProps & WithNamespaces & RouteComponentProps<RouteInfo>> {
  componentDidMount({ loadOneMaterial, match } = this.props) {
    loadOneMaterial(match.params.id);
  }
  handleSubmit = (formValues: FormikValues, { saveMaterial, selectedMaterial } = this.props) => {
    saveMaterial({
      ...(selectedMaterial as Material),
      ...formValues
    });
  };
  render() {
    const { selectedMaterial, match, loadingMaterial, materialLoaded, t } = this.props;
    if (!selectedMaterial && loadingMaterial) {
      return <InitialLoader />;
    }
    if (selectedMaterial && materialLoaded) {
      return (
        <Switch>
          <Route
            exact
            path={`${match.path}/edit`}
            render={props => (
              <>
                <PageHeader {...props} title={t('materials')} breadCrumbParts={[t('materials'), selectedMaterial.name, t('edit')]} />
                <DetailForm
                  selectedEntity={selectedMaterial}
                  onFormSubmit={this.handleSubmit}
                  onCancelBtnClick={() => this.props.history.push(`/materials`)}
                  formDefinition={formDefinition}
                />
              </>
            )}
          />
          <Route
            exact
            path={`${match.path}`}
            render={props => <DetailView {...props} key={selectedMaterial.id} material={selectedMaterial} />}
            key={`${match.path}/:id`}
          />
          <Route
            render={() => (
              <Redirect
                to={{
                  pathname: `/${match.url.split('/')[1]}`
                }}
              />
            )}
          />
        </Switch>
      );
    } else {
      return <NoDataFound />;
    }
  }
}
const mapStateToProps = (state: GlobalState) => {
  return {
    selectedMaterial: state.materials.selectedMaterial as Material,
    loadingMaterial: state.materials.loadingMaterial as boolean,
    materialLoaded: state.materials.materialLoaded as boolean,
    languages: state.app.languages as Language[]
  };
};

const mapDispatchToProps = (dispatch: Dispatch, getState: () => GlobalState) => ({
  loadOneMaterial: (id: number | string) => dispatch(actions.FetchOneMaterial.request({ materialId: id as number })),
  saveMaterial: (material: Material) => dispatch(actions.SaveMaterial.request({ material }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['clients', 'resources', 'materials', 'menu'], { nsMode: 'fallback' })(DetailShell));
