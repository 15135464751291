import React, { Component } from 'react';
import { GlobalState } from '../../../core/state';
import { getSavingCourse } from '../../state/selectors';
import { SaveCourse } from '../../state/actions';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import moduleConstants from '../../helpers/constants';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { FormikValues } from 'formik';
import { formDefinition } from '../../helpers/DetailFormFields';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import Course from '../../../shared/interfaces/Course';
import PageHeader from '../../../shared/components/PageHeader';
import { RouteComponentProps } from 'react-router';

type DetailCreateProps = {
  savingCourse: boolean;
  saveCourse: (values: Course) => void;
  navigatePush: (values: string) => void;
} & WithNamespaces &
  RouteComponentProps;

const { baseUrl, defaultCourseData } = moduleConstants;

class DetailCreate extends Component<DetailCreateProps> {
  handleSubmit = (formValues: any) => {
    const newCourse = { ...defaultCourseData, ...formValues } as Course;
    this.props.saveCourse(newCourse);
  };

  render() {
    const { savingCourse, navigatePush, t } = this.props;
    return (
      <>
        <PageHeader title={t('new course')} breadCrumbParts={[t('courses'), t('new')]} {...this.props} />
        <DetailForm
          onFormSubmit={this.handleSubmit}
          savingForm={savingCourse}
          selectedEntity={defaultCourseData}
          onCancelBtnClick={() => navigatePush(`/${baseUrl}`)}
          formDefinition={formDefinition}
        />
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    savingCourse: getSavingCourse(state.courses)
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  saveCourse: (course: Course) => dispatch(SaveCourse({ course })),
  navigatePush: (path: string) => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['courses', 'menu', 'resources'], { nsMode: 'fallback' })(DetailCreate));
