import React, { SyntheticEvent } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import TextInput from './TextInput';

interface DistanceProps {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: SyntheticEvent) => void;
  value: any;
  label: string;
  keyField: string;
}

const Distance = ({ onBlur, onChange, value, label, keyField }: DistanceProps) => {
  return (
    <Input iconPosition="left" type="text">
      <i
        aria-hidden="true"
        className="icon"
        style={{ left: '0', position: 'absolute', textAlign: 'center', top: '50%', marginTop: ' -.5em' }}>
        <b className="ui" style={{ fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif" }}>
          km
        </b>
      </i>
      <TextInput label={label} type="number" keyField={keyField} onChange={onChange} onBlur={onBlur} value={value} />
    </Input>
  );
};

export default Distance;
