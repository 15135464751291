import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface EditorProps {
  onChange: (e: any) => void;
  value: string;
  label: string;
  keyField: string;
  toolbar?: any[];
  hasToolbar?: boolean;
  disabled?: boolean;
}

function EditorInput({ onChange, value, keyField, toolbar, hasToolbar, disabled }: EditorProps) {
  const [stateValue, setStateValue] = useState(value);

  useEffect(() => {
    setStateValue(value);
  }, [value]);

  const handleChange = (content: string) => {
    setStateValue(content);
    onChange({
      target: {
        name: keyField,
        value: content
      }
    });
  };

  const modules = {
    toolbar:
      hasToolbar === false
        ? null
        : toolbar
        ? toolbar
        : ['bold', 'italic', 'underline', 'link', { list: 'ordered' }, { list: 'bullet' }, 'clean']
  };

  return (
    <ReactQuill
      readOnly={disabled}
      key="keyField"
      defaultValue={value}
      value={stateValue || ''}
      modules={modules}
      onChange={handleChange}
    />
  );
}

export default EditorInput;
