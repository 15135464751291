import React from 'react';
import { Table } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { DisplayBox } from '../../../shared/components/DisplayBox';

import moment from 'moment';
import { WithNamespaces } from 'react-i18next';
import TrainingSession, { WithTraining } from '../../../shared/interfaces/TrainingSession';
import applyArchiveTagCheck from '../../../shared/helpers/archivedFormatter';

interface Props {
  sessions: TrainingSession<WithTraining>[];
}
export const TrainingHistory = ({ sessions, history, t }: Props & RouteComponentProps & WithNamespaces) => {
  return (
    <DisplayBox title={t('trainingshistory')}>
      {sessions && sessions.length > 0 ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('startDate')}</Table.HeaderCell>
              <Table.HeaderCell>{t('training')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sessions.map((session, key) => (
              <Table.Row key={key}>
                <Table.Cell>{moment(session.startDate).format('DD/MM/YYYY')}</Table.Cell>
                <Table.Cell>
                  {session.training.course ? (
                    <a href={`trainings/${session.training.id}`}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: applyArchiveTagCheck(session.training.course.title, session.training.deletedAt, true, true)
                        }}></span>
                    </a>
                  ) : (
                    '-'
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        t('no-trainings-history')
      )}
    </DisplayBox>
  );
};
