import React, { Component } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { Button, Icon } from 'semantic-ui-react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

class CalendarToolbar extends Component<ToolbarProps & WithNamespaces> {
  render() {
    const { t, view } = this.props;

    return (
      <div className="calendar-toolbar flew-row-sm">
        <div className="calendar-toolbar-controls">
          <Button onClick={() => this.props.onNavigate('TODAY')}>{t('today')}</Button>
          <Button icon onClick={() => this.props.onNavigate('PREV')}>
            <Icon name="arrow left" />
          </Button>
          <Button icon onClick={() => this.props.onNavigate('NEXT')}>
            <Icon name="arrow right" />
          </Button>
        </div>
        <div className="calendar-toolbar-label">{this.props.label}</div>
        <div className="calendar-toolbar-view">
          <Button className={view === 'month' ? 'primary' : undefined} onClick={this.props.onView.bind(null, 'month')}>
            {t('month')}
          </Button>
          <Button className={view === 'week' ? 'primary' : undefined} onClick={this.props.onView.bind(null, 'week')}>
            {t('week')}
          </Button>
          <Button className={view === 'day' ? 'primary' : undefined} onClick={this.props.onView.bind(null, 'day')}>
            {t('day')}
          </Button>
          <Button className={view === 'agenda' ? 'primary' : undefined} onClick={this.props.onView.bind(null, 'agenda')}>
            {t('agenda')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withNamespaces(['calendar', 'resources', 'common'], {
  nsMode: 'fallback'
})(CalendarToolbar);
