import React, { Component } from 'react';
import { GlobalState } from '../../../core/state';
import { Dispatch } from 'redux';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import QueryParams from '../../../shared/interfaces/QueryParams';
import * as actions from '../../state/actions';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { queryParams } from '../../../shared/helpers/queryStringHelpers';
import CalendarListItem from '../../../shared/interfaces/CalendarLocation';
import InitialLoader from '../../../shared/components/InitialLoader';
import CalendarView from '../../../shared/components/Calendar/CalendarView';
import NoDataFound from '../../../shared/components/NoDataFound';
import { toTrainingEvents } from '../../helpers/toTrainingEvents';
import isEqual from 'lodash/isEqual';
import { GQLTrainingSession } from '../../state/state';

type CalendarShellProps = {
  trainingSessions: GQLTrainingSession[] | null;
  trainingSessionsStatus: ProgressStatus;
  calendarListTrainings: CalendarListItem[] | null;
  calendarListTrainingsStatus: ProgressStatus;
  loadTrainingSessions: (queryParams: QueryParams) => void;
  loadCalendarListTrainings: () => void;
  drawerOpen: boolean;
  closeDrawer: () => void;
} & WithNamespaces &
  RouteComponentProps;

class CalendarShell extends Component<CalendarShellProps> {
  queryParams(props: CalendarShellProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  componentDidMount = () => {
    this.props.loadCalendarListTrainings();
    this.props.loadTrainingSessions(this.queryParams());
  };

  componentDidUpdate(prevProps: CalendarShellProps) {
    const { loadTrainingSessions } = this.props;
    const prevTrainings = this.queryParams(prevProps).trainings;
    const locationsChanged = !isEqual(this.queryParams().trainings, prevTrainings);
    const trainingsInitialized = prevProps.calendarListTrainings === null && this.props.calendarListTrainings !== null;
    const endDateChanged = !isEqual(this.queryParams(prevProps).end, this.queryParams().end);
    const startDateChanged = !isEqual(this.queryParams(prevProps).start, this.queryParams().start);
    const dateChanged = endDateChanged || startDateChanged;
    const viewChangedWithNewDates = !isEqual(this.queryParams(prevProps).view, this.queryParams().view) && dateChanged;
    if (locationsChanged || trainingsInitialized || viewChangedWithNewDates || dateChanged) {
      loadTrainingSessions(this.queryParams());
    }
  }

  render() {
    const {
      trainingSessions,
      trainingSessionsStatus,
      calendarListTrainings,
      calendarListTrainingsStatus,
      loadTrainingSessions
    } = this.props;
    switch (calendarListTrainingsStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        const events = toTrainingEvents(trainingSessions || []);
        return (
          <CalendarView
            {...this.props}
            events={events}
            items={calendarListTrainings!}
            status={trainingSessionsStatus}
            name={'trainings'}
            action={loadTrainingSessions}
            drawerOpen={this.props.drawerOpen}
            closeDrawer={this.props.closeDrawer}
            entityPath={'/trainings/{id}'}
          />
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    trainingSessions: state.trainings.selectedTrainingSessions as GQLTrainingSession[] | null,
    trainingSessionsStatus: state.trainings.loadingTrainingSessionsStatus,
    calendarListTrainings: state.trainings.calendarListTrainings as CalendarListItem[] | null,
    calendarListTrainingsStatus: state.trainings.calendarListTrainingsStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    loadTrainingSessions: (queryParams: QueryParams) =>
      dispatch(actions.FetchTrainingSessions.request({ params: queryParams, fields: ['training', 'location'] })),
    loadCalendarListTrainings: () => dispatch(actions.FetchCalendarListTrainings.request({}))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces([], { nsMode: 'fallback' })(CalendarShell));
