import React, { Fragment, SyntheticEvent } from 'react';
import { Formik, Field, FieldProps } from 'formik';
import { Modal, Button, Table, Pagination, PaginationProps } from 'semantic-ui-react';
import Entity, { EntityReference } from '../../../../interfaces/Entity';
import SearchEntityForm from '../SearchEntityForm';
import { WithNamespaces, translate } from 'react-i18next';
import { configConstants } from '../../../../../config/constants';

export type EntityReferenceArity = (EntityReference | undefined) | EntityReference[];

type Props<T> = WithNamespaces & {
  parentEntity: Entity;
  renderReference: (entity: Entity) => JSX.Element;
  cancel: () => void;
  afterSelect: (reference: T) => void;
  isNewVersion?: boolean;
  dataTableFields?: { key: string; label?: string }[];
  dataTableSortable?: boolean;
  filterCondition?: string;
  searchEntityForm: typeof SearchEntityForm;
};

type State<T extends EntityReferenceArity> = {
  entities: Entity[];
  selectedEntity: T;
  amount: number;
  sortField?: string;
  sortDirection?: 'ascending' | 'descending';
  page?: number | string;
};

abstract class SelectEntityModal<P extends EntityReference | EntityReference[], S extends EntityReferenceArity> extends React.Component<
  Props<P>,
  State<S>
> {
  abstract get canSubmit(): boolean;
  abstract renderEntitySelection(entity: Entity, field: FieldProps['field']): JSX.Element;
  abstract toggleSelection(reference: EntityReference): void;
  abstract afterSelect(): void;

  setEntities(result: { entities: Entity[]; amount: number }) {
    this.setState({ entities: result.entities, amount: result.amount });
  }
  handlePaginationChange = (e: SyntheticEvent, { activePage }: PaginationProps) => {
    this.setState(() => ({ page: activePage }));
  };
  handleSorting = (key: string) => {
    this.setState(() => ({
      sortField: key,
      sortDirection: this.state.sortField !== key ? 'ascending' : this.state.sortDirection === 'ascending' ? 'descending' : 'ascending'
    }));
  };

  renderEntities() {
    if (this.state.entities.length === 0) {
      return <div className="col-12 col-md-6">{this.props.t('noEntitiesFound')}</div>;
    }

    if (this.props.isNewVersion && this.props.dataTableFields) {
      return (
        <div className="col-12 ">
          <Table compact sortable={this.props.dataTableSortable ? true : false}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell disabled />
                {this.props.dataTableFields.map(header => {
                  return (
                    <Table.HeaderCell
                      key={header.key}
                      onClick={() => this.handleSorting(header.key)}
                      sorted={this.state.sortField === header.key ? this.state.sortDirection : undefined}>
                      {header.label ? header.label : translate(header.key)}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.entities.map(entity => (
                <Field
                  key={entity['@id']}
                  {...this.props}
                  render={({ field }: FieldProps) => (
                    <Table.Row key={entity['@id']}>
                      <Table.Cell collapsing>{this.renderEntitySelection(entity, field)}</Table.Cell>
                      {this.props.dataTableFields ? (
                        this.props.dataTableFields.map(field => <Table.Cell key={field.key}>{(entity as any)[field.key]}</Table.Cell>)
                      ) : (
                        <Fragment />
                      )}
                    </Table.Row>
                  )}
                />
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={this.props.dataTableFields.length + 1}>
                  <Pagination
                    defaultActivePage={1}
                    totalPages={Math.ceil(this.state.amount / configConstants.pageSize)}
                    onPageChange={this.handlePaginationChange}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      );
    } else {
      return this.state.entities.map(entity => (
        <div className="col-12 col-md-6" key={entity['@id']}>
          <Field
            {...this.props}
            render={({ field }: FieldProps) => (
              <label>
                {this.renderEntitySelection(entity, field)}
                {this.props.renderReference(entity)}
              </label>
            )}
          />
        </div>
      ));
    }
  }

  render() {
    const SearchEntityForm = this.props.searchEntityForm;
    return (
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          this.afterSelect();
        }}
        validate={() => (this.canSubmit ? undefined : {})}
        render={({ submitForm }) => (
          <React.Fragment>
            <Modal.Header>{this.props.t('select')}</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <div style={{ marginBottom: '1em' }}>
                  <SearchEntityForm
                    onResult={this.setEntities.bind(this)}
                    parentEntity={this.props.parentEntity}
                    isNewVersion={this.props.isNewVersion}
                    sortField={this.state.sortField}
                    sortDirection={this.state.sortDirection}
                    filterCondition={this.props.filterCondition}
                    page={this.state.page as number}
                  />
                </div>
                <div className="row">{this.renderEntities()}</div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button type="button" onClick={this.props.cancel}>
                {this.props.t('cancel')}
              </Button>
              <Button positive content={this.props.t('select')} type="submit" onClick={submitForm} disabled={!this.canSubmit} />
            </Modal.Actions>
          </React.Fragment>
        )}
      />
    );
  }
}

class _SelectMultipleEntityModal extends SelectEntityModal<EntityReference[], EntityReference[]> {
  state: State<EntityReference[]> = {
    entities: [],
    selectedEntity: [],
    amount: 0
  };

  get canSubmit() {
    return this.state.selectedEntity.length > 0;
  }

  toggleSelection(reference: EntityReference) {
    const { selectedEntity } = this.state;
    const selected = selectedEntity.includes(reference);

    if (selected) {
      this.setState({ selectedEntity: selectedEntity.filter(x => x !== reference) });
    } else {
      this.setState({ selectedEntity: [...selectedEntity, reference] });
    }
  }

  renderEntitySelection(entity: Entity, field: FieldProps['field']) {
    return (
      <input
        {...field}
        type="checkbox"
        checked={this.state.selectedEntity.includes(entity['@id'])}
        onChange={() => this.toggleSelection(entity['@id'])}
        style={{ marginRight: '0.5em' }}
      />
    );
  }

  afterSelect() {
    this.props.afterSelect(this.state.selectedEntity);
  }
}

class _SelectSingleEntityModal extends SelectEntityModal<EntityReference, EntityReference | undefined> {
  state: State<EntityReference | undefined> = {
    entities: [],
    selectedEntity: undefined,
    amount: 0,
    sortField: 'updatedAt',
    sortDirection: 'ascending',
    page: 1
  };

  get canSubmit() {
    return this.state.selectedEntity !== undefined;
  }

  toggleSelection(reference: EntityReference) {
    const { selectedEntity } = this.state;
    const selected = selectedEntity && selectedEntity === reference;
    this.setState({ selectedEntity: selected ? undefined : reference });
  }

  renderEntitySelection(entity: Entity, field: FieldProps['field']) {
    return (
      <input
        {...field}
        type="radio"
        checked={this.state.selectedEntity === entity['@id']}
        onChange={() => this.toggleSelection(entity['@id'])}
        style={{ marginRight: '0.5em' }}
      />
    );
  }

  afterSelect() {
    this.props.afterSelect(this.state.selectedEntity!);
  }
}

const t = translate(['entitySelector', 'common'], { nsMode: 'fallback' });
export const SelectSingleEntityModal = t(_SelectSingleEntityModal);
export const SelectMultipleEntityModal = t(_SelectMultipleEntityModal);
