import { DeepReadonly } from 'utility-types';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import * as actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import TaskList from '../../shared/interfaces/TaskList';
import { configConstants } from '../../config/constants';
const { pageSize } = configConstants;

export type TaskListsAction = ActionType<typeof actions>;

export type TaskListsState = DeepReadonly<{
  loadingTaskListsStatus: ProgressStatus;
  loadingTaskListStatus: ProgressStatus;
  selectedTaskListId: number | undefined;
  selectedTaskList: TaskList | null;
  taskLists: TaskList[];
  savingTaskList: ProgressStatus;
  savingPredefinedTask: ProgressStatus;
  pageSize: number;
  totalTaskLists: number;
  errorMessage: string | undefined;
}>;

const initialState: TaskListsState = {
  loadingTaskListsStatus: ProgressStatus.Uninitialized,
  loadingTaskListStatus: ProgressStatus.Uninitialized,
  selectedTaskListId: undefined,
  selectedTaskList: null,
  taskLists: [],
  savingTaskList: ProgressStatus.Uninitialized,
  savingPredefinedTask: ProgressStatus.Uninitialized,
  pageSize: pageSize,
  totalTaskLists: 0,
  errorMessage: undefined
};

export function taskLists(state: TaskListsState = initialState, action: TaskListsAction): TaskListsState {
  switch (action.type) {
    case getType(actions.FetchTaskLists.request):
      return {
        ...state,
        loadingTaskListsStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchTaskLists.success):
      return {
        ...state,
        loadingTaskListsStatus: ProgressStatus.Done,
        taskLists: action.payload.taskLists,
        totalTaskLists: action.payload.totalTaskLists
      };
    case getType(actions.FetchTaskLists.failure):
      return {
        ...state,
        loadingTaskListsStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTaskList.request):
      return {
        ...state,
        loadingTaskListStatus: state.loadingTaskListStatus === ProgressStatus.Done ? ProgressStatus.Done : ProgressStatus.InProgress
      };
    case getType(actions.FetchTaskList.success):
      return {
        ...state,
        loadingTaskListStatus: ProgressStatus.Done,
        selectedTaskList: action.payload
      };
    case getType(actions.FetchTaskList.failure):
      return {
        ...state,
        loadingTaskListStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.SaveTaskList.request):
      return {
        ...state,
        savingTaskList: ProgressStatus.InProgress
      };
    case getType(actions.SaveTaskList.success):
      return {
        ...state,
        savingTaskList: ProgressStatus.Uninitialized
      };
    case getType(actions.SaveTaskList.failure):
      return {
        ...state,
        savingTaskList: ProgressStatus.Uninitialized
      };
    case getType(actions.SavePredefinedTask.request):
      return {
        ...state,
        savingPredefinedTask: ProgressStatus.InProgress
      };
    case getType(actions.SavePredefinedTask.success):
      return {
        ...state,
        savingPredefinedTask: ProgressStatus.Uninitialized
      };
    case getType(actions.SavePredefinedTask.failure):
      return {
        ...state,
        savingPredefinedTask: ProgressStatus.Uninitialized
      };
    default:
      return state;
  }
}
