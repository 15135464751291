import React from 'react';
import { connect } from 'react-redux';
import TrainingParticipant from '../../../../../shared/interfaces/TrainingParticipant';
import ProgressStatus from '../../../../../shared/interfaces/ProgressStatus';
import { RouteComponentProps, Route, Switch } from 'react-router';
import InitialLoader from '../../../../../shared/components/InitialLoader';
import NoDataFound from '../../../../../shared/components/NoDataFound';
import { GlobalState } from '../../../../../core/state';
import { Dispatch } from 'redux';
import { TrainingsAction } from '../../../../state/state';
import * as actions from '../../../../state/actions';
import { newParticipant } from '../../../../helpers/constants';
import { Training, TrainingType } from '../../../../../shared/interfaces/Training';
import { TrainingRouteInfo } from '../../DetailShell';
import Course from '../../../../../shared/interfaces/Course';
import { Participant } from '../../../../../shared/interfaces/participant';
import ParticipantForm from './ParticipantForm';
import EditForm from './EditForm';
import Contact from '../../../../../shared/interfaces/Contact';

type ParticipantRouteInfo = {
  participantId: string;
} & TrainingRouteInfo;

type Props = {
  loadingParticipantStatus: ProgressStatus;
  selectedParticipant: Contact;
  training: Training;
  course: Course;
  saveParticipant: (participant: Participant) => void;
  loadParticipant: (participantId: number) => void;
} & RouteComponentProps<ParticipantRouteInfo>;

class ParticipantShell extends React.Component<Props> {
  componentDidMount() {
    const participantId = parseInt(this.props.match.params.participantId);
    if (participantId) {
      this.props.loadParticipant(participantId);
    }
  }

  render() {
    const { match, training, course, selectedParticipant} = this.props;

    let freshParticipant = {
      ...newParticipant,
      training: training!['@id']
    } as Participant;

    if (training.type === TrainingType.BedrijfsIntern) {
      freshParticipant = {
        ...newParticipant,
        training: training!['@id'],
        customer: training.customer
      } as Participant;
    }

    return (
      <Switch>
        <Route exact path={`${match.path}`} render={() => <ParticipantForm {...this.props} training={training} course={course} />} />
        {selectedParticipant ? (
          <Route exact path={`${match.path}/edit`} render={() => <EditForm {...this.props} contact={selectedParticipant} training={training} course={course}></EditForm>} />
        ) : (
          <InitialLoader />
        )}
      </Switch>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  loadingParticipantStatus: state.trainings.loadingParticipantStatus,
  selectedParticipant: state.trainings.selectedParticipant as Contact,
  training: state.trainings.selectedTraining as Training,
  course: state.trainings.selectedTrainingCourse as Course
});

const mapDispatchToProps = (dispatch: Dispatch<TrainingsAction>) => ({
  saveParticipant: (participant: Participant) => dispatch(actions.SaveTrainingParticipant.request(participant)),
  loadParticipant: (participantId: number) => dispatch(actions.FetchOneParticipant.request(participantId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantShell);
