import * as actions from './actions';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { fetch } from '../../core/state/fetch';
import { TrainingRequestStatus } from '../../shared/interfaces/TrainingRequest';
import { configConstants } from '../../config/constants';
import { Training, WithCourse, WithTrainingSessions } from '../../shared/interfaces/Training';
import { DashboardRequest } from './state';

function fetchTrainingRequests(status: TrainingRequestStatus) {
  const trainingRequestsUrl = `${configConstants.apiUrl}training_requests?embed[]=course&embed[]=training`;
  return call(fetch, `${trainingRequestsUrl}&status=${status}`);
}

function* loadDashboardData(_: ReturnType<typeof actions.LoadDashboard.request>) {
  try {
    const [newRequestsResponse, wonRequestsResponse, lostRequestsResponse, nextTrainingsResponse] = yield all([
      fetchTrainingRequests(TrainingRequestStatus.NewRequest),
      fetchTrainingRequests(TrainingRequestStatus.WonRequest),
      fetchTrainingRequests(TrainingRequestStatus.LostRequest),
      call(fetch, `${configConstants.apiUrl}trainings?embed[]=course&embed[]=trainingSession`)
    ]);

    const latestNewRequests: DashboardRequest[] = (yield newRequestsResponse.json())['hydra:member'];
    const latestWonRequests: DashboardRequest[] = (yield wonRequestsResponse.json())['hydra:member'];
    const latestLostRequests: DashboardRequest[] = (yield lostRequestsResponse.json())['hydra:member'];
    const upcomingTrainings: Training<WithCourse & WithTrainingSessions>[] = (yield nextTrainingsResponse.json())['hydra:member'];

    yield put(
      actions.LoadDashboard.success({
        latestNewRequests,
        latestWonRequests,
        latestLostRequests,
        upcomingTrainings
      })
    );
  } catch (e) {
    yield put(actions.LoadDashboard.failure({ errorMessage: e }));
  }
}

function filterOutArchivedRequests(requests: Request[]) {
  return requests.filter((request: Request) => {});
}

export const dashboardSaga = [takeLatest(getType(actions.LoadDashboard.request), loadDashboardData)];
