import { createAsyncAction, createAction } from 'typesafe-actions';
import QueryParams from '../../shared/interfaces/QueryParams';
import { Training, WithCustomer } from '../../shared/interfaces/Training';
import Course from '../../shared/interfaces/Course';
import {
  GQLTrainingSession,
  GQLTrainingClient,
  GQLTrainingParticipant,
  GQLTrainingTask,
  GQLParticipation,
  OverviewTraining,
  TrainingSessionWithMaterials
} from './state';
import { EntityReference } from '../../shared/interfaces/Entity';
import CalendarListItem from '../../shared/interfaces/CalendarLocation';
import TrainingSession from '../../shared/interfaces/TrainingSession';
import TrainingParticipant from '../../shared/interfaces/TrainingParticipant';
import { Participation } from '../../shared/interfaces/Participation';
import { TrainingTask } from '../../shared/interfaces/TrainingTask';
import { TrainingDocument, WithTrainingDocument } from '../../shared/interfaces/TrainingDocument';
import { TrainingSessionMaterial } from '../../shared/interfaces/TrainingSessionMaterial';
import { Participant, WithParticipations, WithContact } from '../../shared/interfaces/participant';
import Contract from '../../shared/interfaces/Contract';
import { Mail, WithTrainingDocuments } from '../../shared/interfaces/Mail';
import Contact from '../../shared/interfaces/Contact';
import Client from '../../shared/interfaces/Client';

export enum TrainingsActionTypes {
  FetchTrainings = '[Trainings] Fetch trainings...',
  FetchTrainingsSuccess = '[Trainings] Fetch trainings Success',
  FetchTrainingsFail = '[Trainings] Fetch trainings Fail',
  FetchOneTraining = '[Trainings] Fetch one training...',
  FetchOneTrainingSuccess = '[Trainings] Fetch one training Success',
  FetchOneTrainingFail = '[Trainings] Fetch one training Fail',
  FetchOneTrainingSession = '[Trainings] Fetch one training session...',
  FetchOneTrainingSessionSuccess = '[Trainings] Fetch one training session Success',
  FetchOneTrainingSessionFail = '[Trainings] Fetch one training session Fail',
  FetchTrainingSessions = '[Trainings] Fetch training sessions...',
  FetchTrainingSessionsSuccess = '[Trainings] Fetch training sessions Success',
  FetchTrainingSessionsFail = '[Trainings] Fetch training sessions Fail',
  FetchTrainingParticipants = '[Trainings] Fetch training participants...',
  FetchTrainingParticipantsSuccess = '[Trainings] Fetch training participants Success',
  FetchTrainingParticipantsFail = '[Trainings] Fetch training participants Fail',
  FetchTrainingTasks = '[Trainings] Fetch training tasks...',
  FetchTrainingTasksSuccess = '[Trainings] Fetch training tasks Success',
  FetchTrainingTasksFail = '[Trainings] Fetch training tasks Fail',
  SaveNewTrainingTask = '[Trainings] Save new training task...',
  SaveNewTrainingTaskSuccess = '[Trainings] Save new training task Success',
  SaveNewTrainingTaskFail = '[Trainings] Save new training task Fail',
  SaveTrainingTask = '[Trainings] Save training task...',
  SaveTrainingTaskSuccess = '[Trainings] Save training task Success',
  SaveTrainingTaskFail = '[Trainings] Save training task Fail',
  SaveTraining = '[Trainings] Saving training...',
  SaveTrainingSuccess = '[Trainings] Saving training Success',
  SaveTrainingFail = '[Trainings] Saving training Fail',
  SaveTrainingSession = '[Trainings] Saving training session...',
  SaveTrainingSessionSuccess = '[Trainings] Saving training session Success',
  SaveTrainingSessionFail = '[Trainings] Saving training session Fail',
  DeleteTrainingSession = '[Trainings] Deleting training session...',
  DeleteTrainingSessionSuccess = '[Trainings] Deleting training session Success',
  DeleteTrainingSessionFail = '[Trainings] Deleting training session Fail',
  FetchCalendarListTrainings = '[Trainings] Fetch Calendar Trainings...',
  FetchCalendarListTrainingsSuccess = '[Trainings] Fetch Calendar Trainings Success',
  FetchCalendarListTrainingsFail = '[Trainings] Fetch Calendar Trainings Fail',
  SaveTrainingParticipant = '[Trainings] Saving training participant...',
  SaveTrainingParticipantSuccess = '[Trainings] Saving training participant Success',
  SaveTrainingParticipantFail = '[Trainings] Saving training participant Fail',
  FetchOneParticipant = '[Trainings] Fetch one participant...',
  FetchOneParticipantSuccess = '[Trainings] Fetch one participant Success',
  FetchOneParticipantFail = '[Trainings] Fetch one participant Fail',
  DeleteParticipant = '[Trainings] Deleting participant...',
  DeleteParticipantSuccess = '[Trainings] Deleting participant Success',
  DeleteParticipantFail = '[Trainings] Deleting Participant Fail',
  FetchParticipations = '[Trainings] Fetch participations...',
  FetchParticipationsSuccess = '[Trainings] Fetch participations Success',
  FetchParticipationsFail = '[Trainings] Fetch participations Fail',
  SaveParticipation = '[Trainings] Save participation...',
  SaveParticipationSuccess = '[Trainings] Save participation Success',
  SaveParticipationFail = '[Trainings] Save participation Fail',
  FetchTrainingParticipantsList = '[Trainings] Fetch Participants List...',
  FetchTrainingParticipantsListSuccess = '[Trainings] Fetch Participants List Success',
  FetchTrainingParticipantsListFail = '[Trainings] Fetch Participants List Fail'
}

export const FetchCalendarListTrainings = createAsyncAction(
  TrainingsActionTypes.FetchCalendarListTrainings,
  TrainingsActionTypes.FetchCalendarListTrainingsSuccess,
  TrainingsActionTypes.FetchCalendarListTrainingsFail
)<{}, { calendarListTrainings: CalendarListItem[] }, { errorMessage: string }>();

export const FetchTrainings = createAsyncAction(
  TrainingsActionTypes.FetchTrainings,
  TrainingsActionTypes.FetchTrainingsSuccess,
  TrainingsActionTypes.FetchTrainingsFail
)<
  QueryParams,
  {
    trainings: OverviewTraining[];
    totalTrainings: number;
  },
  { errorMessage: string }
>();

export const FetchOneTraining = createAsyncAction(
  TrainingsActionTypes.FetchOneTraining,
  TrainingsActionTypes.FetchOneTrainingSuccess,
  TrainingsActionTypes.FetchOneTrainingFail
)<
  number,
  {
    training: Training;
    course: Course;
    client: GQLTrainingClient | undefined;
  },
  { errorMessage: string }
>();

export const FetchOneTrainingSession = createAsyncAction(
  TrainingsActionTypes.FetchOneTrainingSession,
  TrainingsActionTypes.FetchOneTrainingSessionSuccess,
  TrainingsActionTypes.FetchOneTrainingSessionFail
)<number, TrainingSessionWithMaterials, { errorMessage: string }>();

export const FetchTrainingSessions = createAsyncAction(
  TrainingsActionTypes.FetchTrainingSessions,
  TrainingsActionTypes.FetchTrainingSessionsSuccess,
  TrainingsActionTypes.FetchTrainingSessionsFail
)<{ params: QueryParams; fields: string[] }, GQLTrainingSession[], { errorMessage: string }>();

export const FetchTrainingParticipants = createAsyncAction(
  TrainingsActionTypes.FetchTrainingParticipants,
  TrainingsActionTypes.FetchTrainingParticipantsSuccess,
  TrainingsActionTypes.FetchTrainingParticipantsFail
)<number, GQLTrainingParticipant[], { errorMessage: string }>();

export const FetchTrainingTasks = createAsyncAction(
  TrainingsActionTypes.FetchTrainingTasks,
  TrainingsActionTypes.FetchTrainingTasksSuccess,
  TrainingsActionTypes.FetchTrainingTasksFail
)<number, GQLTrainingTask[], { errorMessage: string }>();

export const SaveTrainingTask = createAsyncAction(
  TrainingsActionTypes.SaveTrainingTask,
  TrainingsActionTypes.SaveTrainingTaskSuccess,
  TrainingsActionTypes.SaveTrainingTaskFail
)<
  { taskId: EntityReference; executed?: boolean; dueDate?: string },
  { taskId: EntityReference; executed?: boolean; dueDate?: string },
  { errorMessage: string }
>();

export const SaveNewTrainingTask = createAsyncAction(
  TrainingsActionTypes.SaveNewTrainingTask,
  TrainingsActionTypes.SaveNewTrainingTaskSuccess,
  TrainingsActionTypes.SaveNewTrainingTaskFail
)<TrainingTask, TrainingTask, { errorMessage: string }>();

export const SaveTraining = createAsyncAction(
  TrainingsActionTypes.SaveTraining,
  TrainingsActionTypes.SaveTrainingSuccess,
  TrainingsActionTypes.SaveTrainingFail
)<{ training: Training; noredirects?: boolean }, Training, { errorMessage: string }>();

export const SaveTrainingSession = createAsyncAction(
  TrainingsActionTypes.SaveTrainingSession,
  TrainingsActionTypes.SaveTrainingSessionSuccess,
  TrainingsActionTypes.SaveTrainingSessionFail
)<TrainingSession, TrainingSession, { errorMessage: string }>();

export const DeleteTrainingSession = createAsyncAction(
  TrainingsActionTypes.DeleteTrainingSession,
  TrainingsActionTypes.DeleteTrainingSessionSuccess,
  TrainingsActionTypes.DeleteTrainingSessionFail
)<{ sessionId: number; trainingId: number }, void, { errorMessage: string }>();

export const SaveTrainingParticipant = createAsyncAction(
  TrainingsActionTypes.SaveTrainingParticipant,
  TrainingsActionTypes.SaveTrainingParticipantSuccess,
  TrainingsActionTypes.SaveTrainingParticipantFail
)<Participant, Participant, { errorMessage: string }>();

export const FetchOneParticipant = createAsyncAction(
  TrainingsActionTypes.FetchOneParticipant,
  TrainingsActionTypes.FetchOneParticipantSuccess,
  TrainingsActionTypes.FetchOneParticipantFail
)<number, Contact, { errorMessage: string }>();

export const DeleteParticipant = createAsyncAction(
  TrainingsActionTypes.DeleteParticipant,
  TrainingsActionTypes.DeleteParticipantSuccess,
  TrainingsActionTypes.DeleteParticipantFail
)<{ participantId: number; trainingId: number }, void, { errorMessage: string }>();

export const FetchParticipations = createAsyncAction(
  TrainingsActionTypes.FetchParticipations,
  TrainingsActionTypes.FetchParticipationsSuccess,
  TrainingsActionTypes.FetchParticipationsFail
)<number, GQLParticipation[], { errorMessage: string }>();

export const SaveParticipation = createAsyncAction(
  TrainingsActionTypes.SaveParticipation,
  TrainingsActionTypes.SaveParticipationSuccess,
  TrainingsActionTypes.SaveParticipationFail
)<{ participation: Participation; sessionId: number }, Participation, { errorMessage: string }>();

export const DeleteTraining = createAsyncAction(
  '[Trainings] Delete training...',
  '[Trainings] Delete training success',
  '[Trainings] Delete training failure'
)<{ trainingId: number; queryParams: QueryParams }, void, { errorMessage: string }>();

export const DeleteTrainingFile = createAsyncAction(
  '[Trainings] Delete training file...',
  '[Trainings] Delete training file success',
  '[Trainings] Delete training file failure'
)<{ fileId: number; trainingId: number }, void, { errorMessage: string }>();

export const AddFileToTraining = createAsyncAction(
  '[Trainings] Add file to training...',
  '[Trainings] Add file to training success',
  '[Trainings] Add file to training failure'
)<{ trainingId: number; file: File }, void, { errorMessage: string }>();

export const AddFileToTrainerContract = createAsyncAction(
  '[Trainings] Add file to trainer contract...',
  '[Trainings] Add file to trainer contract success',
  '[Trainings] Add file to trainer contract failure'
)<{ trainerContractId: number; trainingId: number; file: File }, void, { errorMessage: string }>();

export const FetchTrainingFiles = createAsyncAction(
  '[Trainings] Fetch training files...',
  '[Trainings] Fetch training files success',
  '[Trainings] Fetch training files failure'
)<number, TrainingDocument<WithTrainingDocument>[], { errorMessage: string }>();
export const FetchOneTrainingSessionMaterial = createAsyncAction(
  '[Trainings] Fetch training session material...',
  '[Trainings] Fetch training session material success',
  '[Trainings] Fetch training session material failure'
)<number, TrainingSessionMaterial, { errorMessage: string }>();

export const SaveTrainingSessionMaterial = createAsyncAction(
  '[Trainings] Save training session material...',
  '[Trainings] Save training session material success',
  '[Trainings] Save training session material failure'
)<{ sessionMaterial: TrainingSessionMaterial; session: TrainingSessionWithMaterials }, TrainingSessionMaterial, { errorMessage: string }>();

export const DeleteTrainingSessionMaterial = createAsyncAction(
  '[Trainings] Delete training session material...',
  '[Trainings] Delete training session material success',
  '[Trainings] Delete training session material failure'
)<{ sessionMaterialId: number; sessionId: number }, void, { errorMessage: string }>();

export const SendInformationForm = createAsyncAction(
  '[Trainings] Send information form...',
  '[Trainings] Send information form success',
  '[Trainings] Send information form failure'
)<{ trainingId: number }, string, { errorMessage: string }>();

export const FetchTrainingParticipantsList = createAsyncAction(
  TrainingsActionTypes.FetchTrainingParticipantsList,
  TrainingsActionTypes.FetchTrainingParticipantsListSuccess,
  TrainingsActionTypes.FetchTrainingParticipantsListFail
)<number, { fileUrl: string }, { errorMessage: string }>();

export const FetchAllParticipants = createAsyncAction(
  '[Trainings] Fetch All Participants...',
  '[Trainings] Fetch All Participants Success',
  '[Trainings] Fetch All Participants Fail'
)<number, { allParticipants: Participant<WithParticipations & WithContact & WithCustomer>[] }, { errorMessage: string }>();

export const FetchTrainerContractsForTraining = createAsyncAction(
  '[Trainings] Fetch All Trainer Contracts...',
  '[Trainings] Fetch All Trainer Contracts Success',
  '[Trainings] Fetch All Trainer Contracts Fail'
)<number, { allTrainerContracts: Contract[] }, { errorMessage: string }>();

export const FetchTrainerContract = createAsyncAction(
  '[Trainings] Fetch Trainer Contract...',
  '[Trainings] Fetch Trainer Contract Success',
  '[Trainings] Fetch Trainer Contract Fail'
)<{ id: number; type: string }, { fileUrl: string; type: 'contract' | 'signedContract' }, { errorMessage: string }>();

export const SendInvitationToParticipant = createAsyncAction(
  '[Trainings] Send participant invitation...',
  '[Trainings] Send participant invitation Success',
  '[Trainings] Send participant invitation Fail'
)<Participant, boolean, { errorMessage: string }>();

export const SendCertificateToParticipant = createAsyncAction(
  '[Trainings] Send participant certificate...',
  '[Trainings] Send participant certificate Success',
  '[Trainings] Send participant certificate Fail'
)<Participant, boolean, { errorMessage: string }>();

export const SaveTrainingContract = createAsyncAction(
  '[Trainings] Saving training contract...',
  '[Trainings] Saving training contract Success',
  '[Trainings] Saving training contract Fail'
)<Contract, boolean, { errorMessage: string }>();

export const FetchTrainingMails = createAsyncAction(
  '[Trainings] Fetch Mails...',
  '[Trainings] Fetch Mails Success',
  '[Trainings] Fetch Mails Fail'
)<number, Mail<WithTrainingDocument & WithTrainingDocuments>[], { errorMessage: string }>();

export const SendMail = createAsyncAction(
  '[Trainings] Sending mail...',
  '[Trainings] Sending mail Success',
  '[Trainings] Sending mail Fail'
)<{ mail: Mail; trainingId: number }, boolean, { errorMessage: string }>();

export const FetchPredefinedTasks = createAsyncAction(
  '[Trainings] fetching predefined tasks ...',
  '[Trainings] fetching predefined tasks  Success',
  '[Trainings] fetching predefined tasks  Fail'
)<number, void, { errorMessage: string }>();

export const SetTrainingTabIndex = createAction('[Trainings] Set training tab index', resolve => {
  return (value: { index: number; training: Training }) => resolve(value);
});

export const ClearTrainingSessions = createAction('[Trainings] Clear training sessions');

export const CreateParticipantWithKnownContact = createAsyncAction(
  '[Participants] Creating participant with known contact ...',
  '[Participants] Creating participant with known contact  success',
  '[Participants] Creating participant with known contact  failure'
)<{ trainingId: string; contactId: string }, void, { errorMessage: string }>();

export const CreateParticipantWithKnownClient = createAsyncAction(
  '[Participants] Creating participant with known client ...',
  '[Participants] Creating participant with known client  success',
  '[Participants] Creating participant with known client  failure'
)<{ trainingId: string; clientId: string; contactForm: Contact }, void, { errorMessage: string }>();

export const CreateParticipantFromScratch = createAsyncAction(
  '[Participants] Creating participant...',
  '[Participants] Creating participant success',
  '[Participants] Creating participant failure'
)<{ trainingId: string; clientForm: Client; contactForm: Contact; participantTypeCompany: boolean }, void, { errorMessage: string }>();
