import React from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';

interface DataTableActionButtonsProps {
  id: number;
  activeId?: number;
  onSelect: (key: number) => void;
  onArchive: (key: number) => void;
  onEdit?: (key: number) => void;
}

const DataTableActionButtons = ({ id, onSelect, activeId, onArchive, onEdit }: DataTableActionButtonsProps) => (
  <Table.Cell key={id}>
    <Button color="facebook" icon onClick={() => (activeId !== id ? onSelect(id) : null)} title="TONEN">
      <Icon name="zoom" />
    </Button>
    <Button color="google plus" icon onClick={() => onArchive(id)} title="ARCHIVEREN">
      <Icon name="archive" />
    </Button>
    {onEdit ? (
      <Button color="twitter" icon onClick={() => onEdit(id)} title="EDITEREN">
        <Icon name="pencil" />
      </Button>
    ) : null}
  </Table.Cell>
);

export default DataTableActionButtons;
