import React, { Component, SyntheticEvent } from 'react';
import { PaginationProps, Button, Icon } from 'semantic-ui-react';
import { GlobalState } from '../../../core/state';
import { Dispatch } from 'redux';
import QueryParams from '../../../shared/interfaces/QueryParams';
import * as actions from '../../state/actions';
import { queryParams, updateQueryString } from '../../../shared/helpers/queryStringHelpers';
import { RouteComponentProps } from 'react-router';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import InitialLoader from '../../../shared/components/InitialLoader';
import NoDataFound from '../../../shared/components/NoDataFound';
import DataTableWrapper from '../../../shared/components/DataTable/DataTableWrapper';
import { dataTableModel } from '../../helpers/DataTableFields';
import isEqual from 'lodash/isEqual';
import DataTree from '../../../shared/components/Tree/DataTree';
import { TreeNode } from '../../../shared/components/Tree/CourseTree';
import Course from '../../../shared/interfaces/Course';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import PageHeader from '../../../shared/components/PageHeader';
import { ConfirmAction } from '../../../core/state/actions';
import DataTableAdvancedFilter from '../../../shared/components/DataTable/DataTableAdvancedFilter';
import { ShowInActive } from '../../helpers/DataSearchFields';
import SiteSettings from '../../../shared/interfaces/SiteSettings';

type ListShellProps = {
  siteSettings: SiteSettings;
  courseCategories: TreeNode[];
  courses: Course[];
  totalPages: number;
  courseStatus: ProgressStatus;
  selectedCourseId: number;
  selectedCourse: Course | undefined;
  coursesStatus: ProgressStatus;
  loadCourseCategories: (queryParams: QueryParams) => void;
  loadCourses: (queryParams: QueryParams) => void;
  loadOneCourse: (courseId: number) => void;
  deleteCourse: (courseId: number, queryParams: QueryParams) => void;
} & RouteComponentProps;

interface ListShellState {
  checked: boolean;
  open: boolean;
  courseId: number;
}

class ListShell extends Component<ListShellProps & WithNamespaces, ListShellState> {
  state = {
    checked: false,
    open: false,
    courseId: 0
  };

  queryParams(props: ListShellProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  get page() {
    return this.queryParams().page || 1;
  }

  handlePaginationChange = (e: SyntheticEvent, { activePage }: PaginationProps) => {
    updateQueryString(this.props, { page: activePage });
  };

  filterResults = (value: string | number) => {
    updateQueryString(this.props, {
      searchValue: value,
      page: 1,
      sort: undefined,
      order: undefined
    });
  };

  componentDidMount = () => {
    const { loadCourseCategories, loadCourses } = this.props;
    loadCourses(this.queryParams());
    loadCourseCategories(this.queryParams());
  };

  componentWillUpdate(nextProps: ListShellProps) {
    const nextQueryparams = this.queryParams(nextProps);
    const queryChanged = !isEqual(this.queryParams(), nextQueryparams);
    if (queryChanged) {
      this.props.loadCourses(nextQueryparams);
      this.props.loadCourseCategories(nextQueryparams);
    }
  }

  handleChange = () => {
    this.setState((prevState: ListShellState) => ({
      ...prevState,
      checked: !prevState.checked,
      open: prevState.open && false
    }));
  };

  toggleToList = () => {
    this.setState((prevState: ListShellState) => ({
      ...prevState,
      checked: false,
      open: false
    }));
  };

  toggleToTree = () => {
    this.setState((prevState: ListShellState) => ({
      ...prevState,
      checked: true,
      open: false
    }));
  };

  handleDetailOpen = (courseId: number) => {
    this.setState(
      (prevState: ListShellState) => ({
        ...prevState,
        courseId: courseId,
        open: true
      }),
      () => {
        this.props.loadOneCourse(courseId);
      }
    );
  };

  handleDetailClose = () => {
    this.setState((prevState: ListShellState) => ({
      ...prevState,
      open: false
    }));
  };

  toggleTreeDetail = (courseId: number) => {
    this.setState(
      (prevState: ListShellState) => ({
        ...prevState,
        open: true,
        courseId: courseId
      }),
      () => {
        this.props.loadOneCourse(courseId);
      }
    );
  };

  renderCoursesListView = () => {
    const { coursesStatus, deleteCourse, history, match } = this.props;
    switch (coursesStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        return (
          <DataTableWrapper
            {...this.props}
            onDialogCancel={() => {}}
            onDialogClose={() => {}}
            onDialogConfirm={() => {}}
            onPageChange={this.handlePaginationChange}
            onSubmit={this.filterResults}
            dialogOpen={false}
            defaultActivePage={this.page}
            dialogContent={this.props.t('confirmArchiveBody')}
            dialogHeader={this.props.t('confirmArchiveHeader')}
            tableData={this.props.courses}
            totalPages={this.props.totalPages}
            withActionButtons={true}
            activeId={this.props.selectedCourseId}
            activeSortHeader={this.queryParams().sort}
            fieldSet={dataTableModel.map(header => ({
              header: this.props.t(header),
              key: header
            }))}
            onSelect={(id: number) => history.push(`${match.path}/${id}`)}
            onArchive={(courseId: number) => deleteCourse(courseId, this.queryParams())}>
            <DataTableAdvancedFilter {...this.props} fieldSet={[]} searchFields={ShowInActive()} />
          </DataTableWrapper>
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  };

  renderCoursesTreeView = () => {
    const { history, match, t } = this.props;
    return (
      <DataTree
        title={t('courseTitle')}
        toggleDetail={(id: number) => history.push(`${match.path}/${id}`)}
        data={this.props.courseCategories}
      />
    );
  };

  renderCourses = () => {
    return (
      <div style={{ position: 'relative' }}>
        <div className="row">
          <div className="col-12">{this.state.checked ? this.renderCoursesTreeView() : this.renderCoursesListView()}</div>
        </div>
      </div>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <PageHeader breadCrumbParts={[t('courses')]} title={'Pakket overzicht'} {...this.props} />
        {this.props.siteSettings.CAN_ACCESS_COURSE_CATEGORIES_CRUD && (
          <Button.Group className="button-switch m-r-sm">
            <Button icon onClick={() => this.toggleToList()} disabled={!this.state.checked}>
              <Icon name="list" />
            </Button>
            <Button icon onClick={() => this.toggleToTree()} disabled={this.state.checked}>
              <Icon name="sitemap" />
            </Button>
          </Button.Group>
        )}
        {this.props.siteSettings.CAN_ACCESS_COURSE_CRUD && (
          <Button
            positive
            className="primary"
            content="Pakket toevoegen"
            onClick={() => this.props.history.push(this.props.match.path + '/create')}
          />
        )}
        {this.props.siteSettings.CAN_ACCESS_COURSE_CATEGORIES_CRUD && (
          <>
            <Button
              positive
              className="primary"
              content="Categorie toevoegen"
              onClick={() => this.props.history.push('courses/categories/create')}
            />
            <Button
              positive
              className="primary"
              content="Categorie bewerken"
              onClick={() => this.props.history.push('courses/categories/edit')}
            />
          </>
        )}
        <br />
        <br />
        {this.renderCourses()}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: GlobalState) => {
  return {
    siteSettings: state.app.siteSettings,
    courseCategories: state.courses.courseCategories,
    courses: state.courses.courses,
    coursesStatus: state.courses.coursesStatus,
    courseStatus: state.courses.courseStatus,
    totalPages: Math.ceil(state.courses.totalCourses / state.courses.pageSize),
    selectedCourseId: state.courses.selectedCourseId,
    selectedCourse: state.courses.selectedCourse
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCourseCategories: (queryParams: QueryParams) => dispatch(actions.FetchCourseCategories(queryParams)),
  loadOneCourse: (courseId: number) => dispatch(actions.FetchOneCourse(courseId)),
  loadCourses: (queryParams: QueryParams) => dispatch(actions.FetchCourses(queryParams)),
  deleteCourse: (courseId: number, queryParams: QueryParams) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteCourse',
        content: 'confirmDeleteCourse',
        action: () => dispatch(actions.DeleteCourse.request({ courseId, queryParams }))
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['courses', 'resources', 'trees', 'menu'], { nsMode: 'fallback' })(ListShell));
