import { Material } from '../../shared/interfaces/Material';

export default {
  baseUrl: 'materials',
  newMaterial: {
    '@id': '',
    id: 0,
    name: '',
    rate: '',
    displayColor: ''
  } as Material
};

export const newMaterialData: Material = {
  '@id': '',
  id: 0,
  name: '',
  rate: '',
  displayColor: ''
};
