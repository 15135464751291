import React from 'react';
import { Rating } from 'semantic-ui-react';

interface GradingProps {
  onChange: (e: any) => void;
  value: any;
  keyField: string;
}

const Grading = ({ onChange, value, keyField }: GradingProps) => {
  const handleChange = (e: any, { rating, name }: any) => {
    onChange({
      target: {
        name: name,
        value: rating
      }
    });
  };
  return <Rating icon="star" rating={value} onRate={handleChange} name={keyField} maxRating={5} />;
};

export default Grading;
