import React from 'react';
import { Icon, Button, Table } from 'semantic-ui-react';
import { GQLTrainingSession } from '../../../../state/state';
import ProgressStatus from '../../../../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../../../../shared/components/InitialLoader';
import { RouteComponentProps } from 'react-router';
import Course from '../../../../../shared/interfaces/Course';
import { Training, WithCustomer } from '../../../../../shared/interfaces/Training';
import { WithNamespaces } from 'react-i18next';
import DetailGroup from '../../../../../shared/components/DetailGroup/DetailGroup';
import { Participant, WithParticipations, WithContact } from '../../../../../shared/interfaces/participant';
import { GlobalState } from '../../../../../core/state';
import { Dispatch } from 'redux';
import * as actions from '../../../../state/actions';
import { connect } from 'react-redux';
import moment from 'moment';
import { Badge, Dropdown, Menu, Empty, Tooltip } from 'antd';
import { Participation } from '../../../../../shared/interfaces/Participation';
import applyArchiveTagCheck from '../../../../../shared/helpers/archivedFormatter';

type Props = {
  participantsStatus: ProgressStatus;
  training: Training;
  course: Course;
  sessions: GQLTrainingSession[] | undefined;
  deleteParticipant: (participantId: number, trainingId: number) => void;
  participantsListFileUrl: string | undefined;
  loadParticipantsList: (trainingId: number) => void;
  allParticipants: Participant<WithParticipations & WithContact & WithCustomer>[];
  getParticipants: (trainingId: number) => void;
  sendInvitation: (participant: Participant) => void;
  sendCertificate: (participant: Participant) => void;
} & RouteComponentProps &
  WithNamespaces;

class ParticipantsView extends React.Component<Props> {
  componentDidMount() {
    const { getParticipants, training } = this.props;
    getParticipants(training.id);
  }

  disableCertificateButton = (id: string) => {
    const { sessions, allParticipants } = this.props;
    if (sessions) {
      const numberId = parseInt(id.substring(14));
      const foundParticipant = allParticipants.filter(participant => participant.id === numberId);
      const participations = foundParticipant[0] && foundParticipant[0].participations.filter(participation => participation !== null);
      if (participations && participations.length === sessions.length) {
        return false;
      }
    }
    return true;
  };

  renderAttendancyList = (participant: any) => {
    if (participant.certificateEmailSentAt) {
      return (
        <i>
          {this.props.t('training.participant.certificate-at.label') +
            ' ' +
            moment(participant.certificateEmailSentAt).format('DD/MM/Y hh:ss')}
        </i>
      );
    }

    let completed = true;
    let sessionsWithoutDeleted: GQLTrainingSession[] = [];
    if (this.props.sessions) {
      sessionsWithoutDeleted = this.props.sessions.filter(session => {
        return session.deletedAt === null;
      });
    }

    if (sessionsWithoutDeleted.length === 0) {
      completed = false;
    }

    if (sessionsWithoutDeleted && sessionsWithoutDeleted.length !== 0) {
      sessionsWithoutDeleted.forEach(session => {
        const sessionParticipant = participant.participations.find((participation: Participation) => {
          return participation.trainingSession === session['id'];
        }) as Participation;

        if (!sessionParticipant) {
          completed = false;
          return;
        }

        if (!sessionParticipant.attended) {
          completed = false;
        }
      });
    }

    if (completed) {
      return (
        <Button className="upload-button" onClick={() => this.props.sendCertificate(participant)}>
          {this.props.t('send')}
        </Button>
      );
    }

    if (this.props.sessions && this.props.sessions.length !== 0) {
      return this.props.sessions.map((session: GQLTrainingSession, i) => {
        const participation = participant.participations.find(
          (participation: Participation) => session['id'] === participation.trainingSession
        );

        if (participation && participation.attended) {
          return (
            <>
              <Badge
                status="success"
                text={applyArchiveTagCheck(moment(session.startDate).format('DD/MM/Y HH:mm'), session.deletedAt, false, true)}
              />
              <br />
            </>
          );
        }
        if (participation && !participation.attended) {
          return (
            <>
              <Badge
                status="default"
                text={applyArchiveTagCheck(moment(session.startDate).format('DD/MM/Y HH:mm'), session.deletedAt, false, true)}
              />
              <br />
            </>
          );
        }

        return (
          <>
            <Badge
              status="default"
              text={applyArchiveTagCheck(moment(session.startDate).format('DD/MM/Y HH:mm'), session.deletedAt, false, true)}
            />
            <br />
          </>
        );
      });
    } else {
      return this.props.t('training.participants.no-sessions.string');
    }
  };

  renderParticipants() {
    const { participantsStatus, allParticipants, t } = this.props;

    if (participantsStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    const menu = (participant: Participant) => {
      return (
        <Menu>
          {participant.firstName ? (
            <Menu.Item key="0" onClick={() => this.props.history.push(this.props.match.url + `/participants/${participant.id}/pre/edit`)}>
              {this.props.t('edit')}
            </Menu.Item>
          ) : (
            <Menu.Item key="0" onClick={() => this.props.history.push(this.props.match.url + `/participants/${participant.id}/edit`)}>
              {this.props.t('edit')}
            </Menu.Item>
          )}

          <Menu.Item
            key="1"
            onClick={() => {
              this.props.deleteParticipant(participant.id!, this.props.training.id);
            }}>
            {this.props.t('delete')}
          </Menu.Item>

          {participant.certificateEmailCanBeResent ? (
            <Menu.Item
              key="2"
              onClick={() => this.props.sendCertificate(participant)}>
            {this.props.t('resend-certificate')}
          </Menu.Item>
          ): null}
        </Menu>
      );
    };

    return (
      <DetailGroup>
        {allParticipants && allParticipants.length > 0 ? (
          <Table>
            <Table.Body>
              {allParticipants.map((participant: any) => (
                <Table.Row key={participant.id} className={participant.firstName ? 'pre' : ''}>
                  <Table.Cell>
                    <div className="row">
                      <div className="font-weight-light col-4">deelnemer:</div>
                      <div className="col-8"
                        dangerouslySetInnerHTML={{
                          __html: applyArchiveTagCheck(
                            participant.contact
                              ? `${participant.contact.firstName} ${participant.contact.lastName}`
                              : participant.firstName || participant.lastName
                              ? `${participant.firstName} ${participant.lastName}`
                              : this.props.t('unknownPartcipant'),
                            participant.contact ? participant.contact.deletedAt : '',
                            true,
                            true
                          )
                        }}>
                      </div>

                      <div className="font-weight-light col-4">klant:</div>
                      {participant.customer ? (
                        <div className="col-8"
                          dangerouslySetInnerHTML={{
                            __html: applyArchiveTagCheck(participant.customer.companyName, participant.customer.deletedAt, true, true)
                          }}></div>
                      ) : null}
                    </div>
                    <hr />
                    <div>
                      <div className="font-weight-light">
                        {participant.hasPaid ? <Icon name="check" className="green" /> : <Icon name="circle" className="red"/>} Betaald
                      </div>
                      <div className="font-weight-light">{participant.paymentMethod ? participant.paymentMethod.replace('_', ' ') : ''}</div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="progress-list">
                      {participant.firstName ? null : (
                        <>
                          <div className="action-box">
                            <div className="status">
                              {participant.inviteEmailSentAt ? <Icon name="check" className="active" /> : <Icon name="circle" />}
                            </div>
                            <div className="action">
                              <h5>{t('training.participants.invitation-at.label')}</h5>
                              {participant.inviteEmailSentAt ? (
                                <i>
                                  {t('training.participant.invitation-at.label') +
                                    ' ' +
                                    moment(participant.inviteEmailSentAt).format('DD/MM/Y HH:mm')}
                                </i>
                              ) : (
                                this.props.sessions && this.props.sessions.length ?
                                  (
                                    <Button className="upload-button" onClick={() => this.props.sendInvitation(participant)}>
                                      {t('send')}
                                    </Button>
                                  ) :
                                  (<span className="deactivated">Er zijn geen sessies gevonden</span>)
                              )}
                            </div>
                          </div>
                          <div className="action-box mt-3">
                            <div className="status">
                              {participant.certificateEmailSentAt ? <Icon name="check" className="active" /> : <Icon name="circle" />}
                            </div>
                            <div className="action">
                              <h5>{t('training.participants.certificate.label')}</h5>
                              {this.renderAttendancyList(participant)}
                            </div>
                          </div>
                        </>
                      )}

                      <Dropdown className="options" overlay={menu(participant)} trigger={['click']}>
                        <Icon name="ellipsis vertical" />
                      </Dropdown>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Empty description={<span>{this.props.t('no-participants')}</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </DetailGroup>
    );
  }

  render() {
    const { t, participantsListFileUrl } = this.props;
    return (
      <div className="detail-group">
        <div className="row header">
          <div className="col-6">
            <h3 className="uppercase">{this.props.t('participants')}</h3>
          </div>
          <div className="col-6 d-flex justify-content-end">
           
            {this.props.course.deletedAt ? (
              <Tooltip placement="top" title={t('courseIsArchived')}>
                 <Button className="primary inactive mr-2">
                  {t('add-participant')}
                </Button>
              </Tooltip>
            ) : (
              <Button
                className="primary mr-2"
                onClick={() => this.props.history.push(`/trainings/${this.props.training.id}/participants/create`)}>
                {t('add-participant')}
              </Button>
            )}

            <Button className="float-right primary" onClick={() => this.props.loadParticipantsList(this.props.training.id)}>
              <Icon name="download" style={{ marginRight: 0 }} />
            </Button>
          </div>
        </div>
        {this.renderParticipants()}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => ({
  allParticipants: state.trainings.allParticipants as Participant<WithParticipations & WithContact & WithCustomer>[],
  participantsListFileUrl: state.trainings.participantsListFileUrl
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendInvitation: (participant: Participant) => dispatch(actions.SendInvitationToParticipant.request(participant)),
  sendCertificate: (participant: Participant) => dispatch(actions.SendCertificateToParticipant.request(participant)),
  getParticipants: (trainingId: number) => dispatch(actions.FetchAllParticipants.request(trainingId)),
  loadParticipantsList: (trainingId: number) => dispatch(actions.FetchTrainingParticipantsList.request(trainingId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsView);
