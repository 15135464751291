import Noty, { Layout, Type } from 'noty';

interface AlertMessage {
  title: string;
  messageText: string;
  overrideTimeOut?: number;
  type: Type;
  overrideLayout?: Layout;
}

export default class AlertService {
  /**
   *Creates an instance of AlertService.
   * @memberof AlertService
   */
  static defaultLayout: Layout = 'topRight';
  static defaultTimeOut = 2000;

  static setMessage = (message: AlertMessage) => {
    Noty.setMaxVisible(10);
    // if (process.env.NODE_ENV === 'development' && message.type === 'error') {
    //   throw new Error(message.messageText);
    // } else {
    new Noty({
      text: `<b>${message.title}</b><br/><p>${message.messageText}</p>`,
      type: message.type,
      theme: 'relax',
      timeout: message.overrideTimeOut || AlertService.defaultTimeOut,
      layout: message.overrideLayout || AlertService.defaultLayout,
      animation: {
        open: 'animated slideInRight faster',
        close: 'animated slideOutRight faster'
      }
    }).show();
    // }
  };
}
