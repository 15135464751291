import { DeepReadonly } from 'utility-types';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import * as actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { Task } from '../../shared/interfaces/Task';

export type TasksAction = ActionType<typeof actions>;

export type TasksState = DeepReadonly<{
  loadingTasksStatus: ProgressStatus;
  tasks: Task[] | [];
  errorMessage: string | undefined;
}>;

const initialState: TasksState = {
  loadingTasksStatus: ProgressStatus.Uninitialized,
  tasks: [],
  errorMessage: undefined
};

export function tasks(state: TasksState = initialState, action: TasksAction): TasksState {
  switch (action.type) {
    case getType(actions.FetchTasks.request):
      return { ...state, loadingTasksStatus: ProgressStatus.InProgress };
    case getType(actions.FetchTasks.success):
      return {
        ...state,
        loadingTasksStatus: ProgressStatus.Done,
        tasks: action.payload.tasks
      };
    case getType(actions.FetchTasks.failure):
      return {
        ...state,
        loadingTasksStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
}
