import React from 'react';
import { getEnvSettings } from '../core/environments/environments';

const envSet = getEnvSettings(process.env.REACT_APP_ENVIRONMENT);

const Reports = () => {
  return (
    <div>
      Reports <br /> {JSON.stringify(envSet)}
      <br /> replace this content
    </div>
  );
};

export default Reports;
