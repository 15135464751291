import React, { useState, useEffect } from 'react';
import { Icon, Table } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { Training } from '../../../../shared/interfaces/Training';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import DetailGroup from '../../../../shared/components/DetailGroup/DetailGroup';
import { GlobalState } from '../../../../core/state';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../../state/actions';
import Contract from '../../../../shared/interfaces/Contract';
import { MultiFilePicker } from '../../../../shared/components/DetailForm/FilePicker';
import Axios from 'axios';
import { Button, Drawer, Empty } from 'antd';
import DetailForm from '../../../../shared/components/DetailForm/DetailForm';
import { formDefinition } from '../../../helpers/TrainerContractFields';
import { FormikValues } from 'formik';
import ProgressStatus from '../../../../shared/interfaces/ProgressStatus';
import { configConstants } from '../../../../config/constants';
import { fetch } from '../../../../core/state/fetch';
import { Link } from 'react-router-dom';
import applyArchiveTagCheck from '../../../../shared/helpers/archivedFormatter';
import SiteSettings from '../../../../shared/interfaces/SiteSettings';

interface Props extends WithNamespaces, RouteComponentProps {
  contracts: Contract[];
  contract: string | undefined;
  signedContract: string | undefined;
  fetchTrainerContract: (id: number) => void;
  fetchTrainerSignedContract: (id: number) => void;
  selectedTraining: Training | undefined;
  addFileToTrainerContract: (contractId: number, trainingId: number, file: File) => void;
  saveTrainerContract: (contract: Contract) => void;
  savingTrainerContract: ProgressStatus;
  siteSettings: SiteSettings;
}

const generateContract = (contractId: number) => {
  const fetchData = async () => {
    const result = await fetch(`${configConstants.apiUrl}files/trainer_contracts/${contractId}/generate_contract`);
    const data = await result.json();

    window.open(data.trainerContract.accessUrl);
  };

  fetchData();
};

const TrainerView = (props: Props) => {
  const [trainerContractEdit, setTrainerContractEdit] = useState<Contract | undefined>(undefined);
  const { contracts, t, addFileToTrainerContract, selectedTraining, saveTrainerContract, savingTrainerContract } = props;

  useEffect(() => {
    if (savingTrainerContract === ProgressStatus.Done) {
      setTrainerContractEdit(undefined);
    }
  }, [savingTrainerContract]);

  return (
    <>
      <DetailGroup title={t('trainers')}>
        {contracts && contracts.length > 0 ? (
          <Table>
            <Table.Body className="trainer-contracts-table">
              {contracts.map((contract: Contract) => (
                <Table.Row key={contract.id}>
                  <Table.Cell className="d-flex">
                    <Link to={`/trainers/${contract.trainer.id}`}>
                      {}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: applyArchiveTagCheck(
                            `${contract.trainer.firstName} ${contract.trainer.lastName}`,
                            contract.trainer.deletedAt,
                            true,
                            true
                          )
                        }}></span>
                    </Link>
                  </Table.Cell>
                  {props.siteSettings.CAN_ACCESS_TRAINER_CONTRACTS && (
                    <Table.Cell>
                      <div className="progress-list">
                        <div className="action-box">
                          <div className="status">
                            <Icon name="check" className="active" />
                          </div>
                          <div className="action">
                            <h5>{t('training.contracts.unsigned-contract.title.label')}</h5>

                            <a onClick={() => generateContract(contract.id)}>
                              <Icon name="download" />
                              {t('download')}
                            </a>
                          </div>
                        </div>
                        <div className="action-box mt-3">
                          <div className="status">
                            {contract.signedTrainerContract && contract.signedTrainerContract.accessUrl ? (
                              <Icon name="check" className="active" />
                            ) : (
                              <Icon name="circle" />
                            )}
                          </div>
                          <div className="action">
                            <h5>{t('training.contracts.signed-contract.title.label')}</h5>
                            {contract.signedTrainerContract && contract.signedTrainerContract.accessUrl ? (
                              <>
                                <a href={contract.signedTrainerContract.accessUrl} target="_blank" rel="noopener noreferrer">
                                  <Icon name="download" />
                                  {t('download')}
                                </a>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                              </>
                            ) : null}
                            <MultiFilePicker
                              value={[]}
                              onFileDrop={files => {
                                addFileToTrainerContract(contract.id, selectedTraining!.id, files[0]);
                              }}
                              type="button"
                            />
                          </div>
                        </div>
                        <Button type="link" className="options" onClick={() => setTrainerContractEdit(contract)}>
                          <Icon name="pencil" />
                        </Button>
                      </div>
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Empty description={<span>{props.t('no-trainers')}</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </DetailGroup>
      <Drawer
        title={
          t('training.trainer-contract.edit.string') +
          ' ' +
          (trainerContractEdit ? trainerContractEdit.trainer.firstName : '') +
          ' ' +
          (trainerContractEdit ? trainerContractEdit.trainer.lastName : '')
        }
        width={720}
        onClose={() => setTrainerContractEdit(undefined)}
        visible={trainerContractEdit ? true : false}>
        {trainerContractEdit ? (
          <DetailForm
            formDefinition={formDefinition}
            savingForm={savingTrainerContract === ProgressStatus.InProgress}
            selectedEntity={trainerContractEdit}
            onCancelBtnClick={() => setTrainerContractEdit(undefined)}
            onFormSubmit={(values: FormikValues) => {
              const newTrainerContract = { ...trainerContractEdit, ...values };
              delete newTrainerContract.trainer;
              delete newTrainerContract.trainerContract;
              delete newTrainerContract.signedTrainerContract;

              saveTrainerContract(newTrainerContract);
            }}
          />
        ) : null}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => {
  return {
    siteSettings: state.app.siteSettings,
    contract: state.trainings.contract,
    signedContract: state.trainings.signedContract,
    selectedTraining: state.trainings.selectedTraining as Training,
    savingTrainerContract: state.trainings.savingTrainerContract
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchTrainerContract: (id: number) =>
      dispatch(
        actions.FetchTrainerContract.request({
          id: id,
          type: 'contract'
        })
      ),
    fetchTrainerSignedContract: (id: number) =>
      dispatch(
        actions.FetchTrainerContract.request({
          id: id,
          type: 'signedContract'
        })
      ),
    addFileToTrainerContract: (trainerContractId: number, trainingId: number, file: File) =>
      dispatch(actions.AddFileToTrainerContract.request({ trainerContractId, trainingId, file })),
    saveTrainerContract: (contract: Contract) => dispatch(actions.SaveTrainingContract.request(contract))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['trainings', 'trainers', 'resources'], { nsMode: 'fallback' })(TrainerView));
