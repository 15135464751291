import moment from 'moment-timezone';
import { CalendarEvent } from '../../shared/interfaces/CalendarEvent';
import { GQLTrainingSession } from '../state/state';

export const toTrainingEvents = (sessionTrainings: GQLTrainingSession[]): CalendarEvent[] => {
  const events: CalendarEvent[] = sessionTrainings.map(trainingSession => {
    return {
      ...events,
      '@id': `/trainingSessions/${trainingSession._id}`,
      id: trainingSession.training!._id,
      entityId: trainingSession.training!._id,
      title: trainingSession.training!.course.title,
      location: trainingSession.location,
      start: moment(trainingSession.startDate, 'YYYY/MM/DD HH:mm').toDate(),
      color: trainingSession.location ? trainingSession.location.displayColor : '#438EF7',
      end: moment(trainingSession.endDate, 'YYYY/MM/DD HH:mm').toDate()
    };
  });
  return events;
};
