import React from 'react';
import { MultiFilePicker } from '../../../../shared/components/DetailForm/FilePicker';
import { Training } from '../../../../shared/interfaces/Training';
import ProgressStatus from '../../../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../../../shared/components/InitialLoader';
import { TrainingDocument, WithTrainingDocument } from '../../../../shared/interfaces/TrainingDocument';
import FileList from '../../../../shared/components/FileList';
import QueryParams from '../../../../shared/interfaces/QueryParams';
import { Skeleton } from 'antd';

type Props = {
  training: Training;
  addFileStatus: ProgressStatus;
  filesStatus: ProgressStatus;
  files: TrainingDocument<WithTrainingDocument>[] | undefined;
  addFileToTraining: (trainingId: number, file: File) => void;
  deleteTrainingFile: (fileId: number, trainingId: number) => void;
};

export default ({ addFileToTraining, deleteTrainingFile, training, addFileStatus, filesStatus, files }: Props) => {
  return (
    <div className="detail-group">
      <div className="row header" style={{ marginBottom: '1em' }}>
        <div className="col-12">
          <h4>BESTANDEN</h4>
        </div>
      </div>

      <MultiFilePicker
        value={[]}
        onFileDrop={files => {
          addFileToTraining(training.id, files[0]);
        }}
        uploadStatus={addFileStatus}
      />
      <div className="row m-t-lg">
        {filesStatus === ProgressStatus.InProgress ? (
          <div className="col-12">
            <Skeleton />
          </div>
        ) : (
          <FileList
            blockViewOn={false}
            files={(files || []).map(f => f.trainingDocument)}
            deleteFile={deleteTrainingFile}
            componentId={training.id}
          />
        )}
      </div>
    </div>
  );
};
