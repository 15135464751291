import React, { SyntheticEvent, useState } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import TextInput from './TextInput';

interface DiscountProps {
  onBlur: (e: SyntheticEvent) => void;
  onChange: (e: SyntheticEvent) => void;
  value: any;
  label: string;
  keyField: string;
}

const Discount = ({ onBlur, onChange, value, label, keyField }: DiscountProps) => {
  return (
    <div className="ui icon input">
      <TextInput label={label} type="number" keyField={keyField} onChange={onChange} onBlur={onBlur} value={value} />
      <i className="percent icon" />
    </div>
  );
};

export default Discount;
