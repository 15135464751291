import React, { Component, Fragment } from 'react';
import { Material } from '../../../shared/interfaces/Material';
import { connect } from 'react-redux';
import * as actions from '../../state/actions';
import constants from '../../helpers/constants';
import { RouteComponentProps } from 'react-router';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { formDefinition } from '../../helpers/DetailFormFields';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import PageHeader from '../../../shared/components/PageHeader';

const { baseUrl, newMaterial } = constants;

interface Props {
  saveMaterial: (material: Material) => void;
}
class DetailCreate extends Component<Props & RouteComponentProps & WithNamespaces> {
  render() {
    const { history, saveMaterial, t } = this.props;
    return (
      <Fragment>
        <PageHeader {...this.props} title={t('materials')} breadCrumbParts={[t('materials'), t('new material')]} />
        <DetailForm
          onFormSubmit={data => saveMaterial(data as Material)}
          selectedEntity={newMaterial}
          onCancelBtnClick={() => history.push(`/${baseUrl}`)}
          formDefinition={formDefinition}
        />
      </Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  saveMaterial: (material: Material) => dispatch(actions.SaveMaterial.request({ material }))
});

export default connect(null, mapDispatchToProps)(withNamespaces(['materials', 'menu'], { nsMode: 'fallback' })(DetailCreate));
