import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from '../../../core/state';
import { TrainingRequest } from '../../../shared/interfaces/TrainingRequest';
import { Dispatch } from 'redux';
import * as actions from '../../state/actions';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { RouteComponentProps, Switch, Route } from 'react-router';
import DetailView from './DetailView';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import { FormikValues } from 'formik';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import InitialLoader from '../../../shared/components/InitialLoader';
import NoDataFound from '../../../shared/components/NoDataFound';
import PageHeader from '../../../shared/components/PageHeader';
import { translate, WithNamespaces } from 'react-i18next';
import { TrainingRequestsAction } from '../../state/state';
import { formDefinition } from '../../helpers/formDefinitions';
import { AppAction } from '../../../core/state/app.state';
import { ConfirmAction, ConfirmActionParameters } from '../../../core/state/actions';
import Client from '../../../shared/interfaces/Client';
import Course from '../../../shared/interfaces/Course';
import { RequestSessionTeaser } from '../../../shared/interfaces/RequestSessionTeaser';
import { Training } from '../../../shared/interfaces/Training';

type DetailShellProps = {
  selectedTrainingRequest: TrainingRequest;
  selectedCustomer: Client;
  selectedCourse: Course;
  selectedSessions: RequestSessionTeaser[];
  selectedTraining: Training;
  selectedTrainingRequestStatus: ProgressStatus;
  trainingRequestOrderDocument: string | undefined;
  trainingRequestOfferDocument: string | undefined;
  loadOneTrainingRequest: (id: number) => void;
  navigatePush: (path: string) => void;
  saveTrainingRequest: (trainingRequest: TrainingRequest, redirectAfterSave: boolean) => void;
  saveTrainingRequestStatus: ProgressStatus;
  askConfirmation: (params: ConfirmActionParameters) => void;
  setRequestToWon: (request: TrainingRequest) => void;
  setRequestToLost: (request: TrainingRequest) => void;
  fetchTrainingRequestOfferDocument: (id: number) => void;
  fetchTrainingRequestOrderDocument: (id: number) => void;
} & RouteComponentProps<RouteInfo> &
  WithNamespaces;

interface RouteInfo {
  id: string;
}

class DetailShell extends Component<DetailShellProps> {
  componentDidMount = () => {
    const { loadOneTrainingRequest, match, fetchTrainingRequestOfferDocument, fetchTrainingRequestOrderDocument } = this.props;
    loadOneTrainingRequest(parseInt(match.params.id));
    fetchTrainingRequestOfferDocument(parseInt(match.params.id, 10));
    fetchTrainingRequestOrderDocument(parseInt(match.params.id, 10));
  };
  handleSubmit = (formValues: FormikValues) => {
    const updatedTrainingRequest = {
      ...this.props.selectedTrainingRequest,
      ...(formValues as TrainingRequest)
    };
    this.props.saveTrainingRequest(updatedTrainingRequest, true);
  };

  render() {
    const {
      selectedTrainingRequest,
      selectedCustomer,
      selectedCourse,
      selectedSessions,
      selectedTraining,
      selectedTrainingRequestStatus,
      saveTrainingRequestStatus,
      match,
      navigatePush,
      t,
      setRequestToWon,
      setRequestToLost,
      trainingRequestOfferDocument,
      trainingRequestOrderDocument
    } = this.props;
    switch (selectedTrainingRequestStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        return (
          <Switch>
            <Route
              path={`${match.path}/edit`}
              render={() => (
                <>
                  <PageHeader
                    {...this.props}
                    breadCrumbParts={[t('requests'), selectedTrainingRequest.firstName + ' ' + selectedTrainingRequest.lastName, t('edit')]}
                    title={t('moduleTitle')}
                  />
                  <DetailForm
                    onFormSubmit={this.handleSubmit}
                    savingForm={ProgressStatus.InProgress === saveTrainingRequestStatus}
                    selectedEntity={selectedTrainingRequest}
                    onCancelBtnClick={() => navigatePush(`${match.url}`)}
                    formDefinition={formDefinition}
                  />
                </>
              )}
            />
            <Route
              path={match.path}
              render={() => (
                <DetailView
                  key={selectedTrainingRequest.id}
                  {...this.props}
                  trainingRequestOrderDocument={trainingRequestOrderDocument}
                  trainingRequestOfferDocument={trainingRequestOfferDocument}
                  trainingRequest={selectedTrainingRequest}
                  sessions={selectedSessions}
                  training={selectedTraining}
                  trainingRequestStatus={selectedTrainingRequestStatus}
                  saveRequest={this.props.saveTrainingRequest}
                  reloadRequest={() => {
                    const { loadOneTrainingRequest, match } = this.props;
                    loadOneTrainingRequest(parseInt(match.params.id));
                  }}
                  setRequestToWon={() => setRequestToWon(selectedTrainingRequest)}
                  setRequestToLost={(TrainingRequest: TrainingRequest) => setRequestToLost(TrainingRequest)}
                />
              )}
            />
          </Switch>
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    selectedTrainingRequest: state.requests.selectedTrainingRequest as TrainingRequest,
    selectedCustomer: state.clients.selectedClient as Client,
    selectedCourse: state.courses.selectedCourse as Course,
    selectedSessions: state.requests.selectedRequestSessions as RequestSessionTeaser[],
    selectedTraining: state.requests.selectedTrainingRequestTraining as Training,
    selectedTrainingRequestStatus: state.requests.selectedTrainingRequestStatus,
    saveTrainingRequestStatus: state.requests.savingTrainingRequestStatus,
    trainingRequestOrderDocument: state.requests.orderDocument,
    trainingRequestOfferDocument: state.requests.offerDocument
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TrainingRequestsAction | CallHistoryMethodAction | AppAction>) => {
  return {
    loadOneTrainingRequest: (id: number) => dispatch(actions.FetchOneTrainingRequest.request({ id })),
    saveTrainingRequest: (trainingRequest: TrainingRequest, redirectAfterSave: boolean) =>
      dispatch(
        actions.SaveTrainingRequest.request({
          trainingRequest,
          redirectAfterSave
        })
      ),
    navigatePush: (path: string) => dispatch(push(path)),
    askConfirmation: (params: ConfirmActionParameters) => dispatch(ConfirmAction.request(params)),
    setRequestToWon: (request: TrainingRequest) =>
      dispatch(
        ConfirmAction.request({
          title: 'setRequestToWon',
          content: 'confirmSetRequestToWon',
          action: () => dispatch(actions.SetRequestToWon.request(request))
        })
      ),
    setRequestToLost: (request: TrainingRequest) => dispatch(actions.SetRequestToLost.request(request)),
    fetchTrainingRequestOfferDocument: (id: number) =>
      dispatch(
        actions.FetchTrainingRequestDocument.request({
          id: id,
          type: 'offerDocument'
        })
      ),
    fetchTrainingRequestOrderDocument: (id: number) =>
      dispatch(
        actions.FetchTrainingRequestDocument.request({
          id: id,
          type: 'orderDocument'
        })
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate(['requests', 'common'], { nsMode: 'fallback' })(DetailShell));
