import React, { Component } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';

type Props = {
  value: string;
};

class Label extends Component<Props & WithNamespaces> {
  render() {
    const { t } = this.props;

    return <label>{t(this.props.value)}</label>;
  }
}

export default withNamespaces(['common', 'resources', 'clients', 'courses', 'requests', 'trainers', 'trainings'], { nsMode: 'fallback' })(
  Label
);
