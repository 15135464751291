import moment from 'moment';
import { GQLSessionMaterials } from '../../shared/interfaces/GQLSessionMaterials';
import { CalendarEvent } from '../../shared/interfaces/CalendarEvent';

export const toCalendarEvents = (materials: GQLSessionMaterials): CalendarEvent[] => {
  const events: CalendarEvent[] = materials.trainingSessionMaterials.edges.map((material, key: number) => {
    return {
      ...events,
      '@id': `/trainingSessions/${material.node.trainingSession._id}`,
      id: material.node._id,
      title: material.node.material.name,
      entityId: material.node.material._id,
      subTitle: material.node.trainingSession.training.course.title,
      start: moment(material.node.trainingSession.startDate, 'YYYY/MM/DD HH:mm').toDate(),
      end: moment(material.node.trainingSession.endDate, 'YYYY/MM/DD HH:mm').toDate(),
      color: material.node.material.displayColor,
      trainingId: material.node.trainingSession.training._id,
      trainingDeleted: material.node.trainingSession.training.deletedAt
    };
  });
  return events;
};
