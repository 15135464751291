import React, { Component, Fragment } from 'react';
import { GlobalState } from '../../../core/state';
import { getSavingLocations, getResources } from '../../state/selectors';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { newEntity, baseUrl } from '../../helpers/constants';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { CourseLocation } from '../../../shared/interfaces/CourseLocation';
import { Dispatch } from 'redux';
import { convertToDetailFormFields, convertToDetailFormValidation } from '../../../shared/components/DetailForm/DetailForm';
import { formDefinition } from '../../helpers/DetailFormFields';
import { LocationsResources } from './DetailShell';
import * as actions from '../../state/actions';
import PageHeader from '../../../shared/components/PageHeader';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { Button, Container } from 'semantic-ui-react';
import { Form, Formik } from 'formik';
import DetailFormField, { DetailFormFieldTypes } from '../../../shared/components/DetailForm/DetailFormField';
import { arrayToOption } from '../../../shared/helpers/dropDownHelpers';
import { countryCodes } from '../../../shared/constants';
import Entity from '../../../shared/interfaces/Entity';
import Client from '../../../shared/interfaces/Client';
import SearchClientForm from '../../../shared/components/DetailForm/EntitySelector/SelectForms/SearchClientForm';
import { emailValidation } from '../../../shared/helpers/emailValidation';
import { phoneValidation } from '../../../shared/helpers/phoneValidation';

type DetailCreateProps = {
  savingLocation: boolean;
  saveLocation: (values: CourseLocation, clientId?: string, returnUrl?: string, activeIndex?: string) => any;
  navigatePush: (values: string) => void;
  resources: LocationsResources;
} & RouteComponentProps &
  WithNamespaces;

class DetailCreate extends Component<DetailCreateProps> {
  render() {
    const { savingLocation, navigatePush, saveLocation, t, location } = this.props;
    const { clientId = undefined, returnUrl = undefined, tabActiveIndex = undefined } = { ...location.state };

    return (
      <Fragment>
        <PageHeader breadCrumbParts={[t('locations'), t('new')]} title={t('locations')} {...this.props} />
        <Formik
          initialValues={convertToDetailFormFields(newEntity, formDefinition)}
          validationSchema={Yup.object().shape(convertToDetailFormValidation(formDefinition))}
          onSubmit={values => saveLocation(values as CourseLocation, clientId, returnUrl, tabActiveIndex)}>
          {formProps => (
            <Container>
              <Form className="ui form" onSubmitCapture={undefined}>
                <div className="row">
                  <div className="col-12 col-lg-8">
                    <DetailFormField
                      key={'name'}
                      touched={(formProps.touched as any)['name' || '']}
                      error={(formProps.errors as any)['name' || '']}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      value={formProps.values['name' || '']}
                      selectedEntity={newEntity}
                      label={'Naam'}
                      type={DetailFormFieldTypes.text}
                      orderNr={10}
                      propertyName={'name'}
                      validationChecks={Yup.string().required('Naam moet ingevuld worden.')}
                      required={true}
                    />
                    <div className="row mb-3">
                      <div className="col-8">
                        <DetailFormField
                          key={'street'}
                          touched={(formProps.touched as any)['street' || '']}
                          error={(formProps.errors as any)['street' || '']}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          value={formProps.values['street' || '']}
                          selectedEntity={newEntity}
                          label={'Straat'}
                          type={DetailFormFieldTypes.text}
                          orderNr={20}
                          propertyName={'street'}
                          validationChecks={Yup.string().required('Straat moet ingevuld worden.')}
                          required={true}
                        />
                      </div>
                      <div className="col-4">
                        <DetailFormField
                          key={'houseNr'}
                          touched={(formProps.touched as any)['houseNr' || '']}
                          error={(formProps.errors as any)['houseNr' || '']}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          value={formProps.values['houseNr' || '']}
                          selectedEntity={newEntity}
                          label={'Huisnummer'}
                          type={DetailFormFieldTypes.text}
                          orderNr={20}
                          propertyName={'houseNr'}
                          validationChecks={Yup.string().required('Huisnummer moet ingevuld worden.')}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <DetailFormField
                          key={'bus'}
                          touched={(formProps.touched as any)['bus' || '']}
                          error={(formProps.errors as any)['bus' || '']}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          value={formProps.values['bus' || '']}
                          selectedEntity={newEntity}
                          label={'Bus'}
                          type={DetailFormFieldTypes.text}
                          orderNr={10}
                          propertyName={'bus'}
                        />
                      </div>
                      <div className="col-6">
                        <DetailFormField
                          key={'postalCode'}
                          touched={(formProps.touched as any)['postalCode' || '']}
                          error={(formProps.errors as any)['postalCode' || '']}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          value={formProps.values['postalCode' || '']}
                          selectedEntity={newEntity}
                          label={'Postcode'}
                          type={DetailFormFieldTypes.text}
                          orderNr={10}
                          propertyName={'postalCode'}
                          validationChecks={Yup.string().required('Postcode moet ingevuld worden.')}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-6">
                        <DetailFormField
                          key={'city'}
                          touched={(formProps.touched as any)['city' || '']}
                          error={(formProps.errors as any)['city' || '']}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          value={formProps.values['city' || '']}
                          selectedEntity={newEntity}
                          label={'Plaats'}
                          type={DetailFormFieldTypes.text}
                          orderNr={10}
                          propertyName={'city'}
                          validationChecks={Yup.string().required('Plaats moet ingevuld worden.')}
                          required={true}
                        />
                      </div>
                      <div className="col-6">
                        <DetailFormField
                          key={'country'}
                          touched={(formProps.touched as any)['country' || '']}
                          error={(formProps.errors as any)['country' || '']}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          value={formProps.values['country' || '']}
                          selectedEntity={newEntity}
                          label={'Land'}
                          type={DetailFormFieldTypes.dropdownlist}
                          dropDownOptions={arrayToOption(countryCodes)}
                          orderNr={10}
                          propertyName={'country'}
                          validationChecks={Yup.string().required('Land moet ingevuld worden.')}
                          required={true}
                        />
                      </div>
                    </div>
                    <DetailFormField
                      key={'customer'}
                      touched={(formProps.touched as any)['customer' || '']}
                      error={(formProps.errors as any)['customer' || '']}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      value={formProps.values['customer' || '']}
                      selectedEntity={newEntity}
                      label={'Klant'}
                      type={DetailFormFieldTypes.entitySelector}
                      orderNr={10}
                      propertyName={'customer'}
                      multiple={false}
                      renderEntity={(course: Entity) => <span key={course['@id']}>{(course as Client).companyName}</span>}
                      isNewVersion={true}
                      dataTableFields={[{ key: 'companyName' }]}
                      searchEntityForm={SearchClientForm}
                    />
                    <DetailFormField
                      key={'rate'}
                      touched={(formProps.touched as any)['rate' || '']}
                      error={(formProps.errors as any)['rate' || '']}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      value={formProps.values['rate' || '']}
                      selectedEntity={newEntity}
                      label={'Tarief'}
                      type={DetailFormFieldTypes.textbox}
                      orderNr={10}
                      propertyName={'rate'}
                    />
                    <DetailFormField
                      key={'travelDirections'}
                      touched={(formProps.touched as any)['travelDirections' || '']}
                      error={(formProps.errors as any)['travelDirections' || '']}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      value={formProps.values['travelDirections' || '']}
                      selectedEntity={newEntity}
                      label={'Routebeschrijving'}
                      type={DetailFormFieldTypes.textbox}
                      orderNr={10}
                      propertyName={'travelDirections'}
                    />
                    <DetailFormField
                      key={'remarks'}
                      touched={(formProps.touched as any)['remarks' || '']}
                      error={(formProps.errors as any)['remarks' || '']}
                      onChange={formProps.handleChange}
                      onBlur={formProps.handleBlur}
                      value={formProps.values['remarks' || '']}
                      selectedEntity={newEntity}
                      label={'Opmerkingen'}
                      type={DetailFormFieldTypes.textbox}
                      orderNr={10}
                      propertyName={'remarks'}
                    />
                    {!formProps.values.isInternalLocation && (
                      <>
                        <div className="row mb-3">
                          <div className="col-12 col-lg-6">
                            <DetailFormField
                              key={'contactPersonFirstName'}
                              touched={(formProps.touched as any)['contactPersonFirstName' || '']}
                              error={(formProps.errors as any)['contactPersonFirstName' || '']}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values['contactPersonFirstName' || '']}
                              selectedEntity={newEntity}
                              label={'Voornaam van contactpersoon'}
                              type={DetailFormFieldTypes.text}
                              orderNr={10}
                              propertyName={'contactPersonFirstName'}
                            />
                          </div>
                          <div className="col-12 col-lg-6">
                            <DetailFormField
                              key={'contactPersonLastName'}
                              touched={(formProps.touched as any)['contactPersonLastName' || '']}
                              error={(formProps.errors as any)['contactPersonLastName' || '']}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values['contactPersonLastName' || '']}
                              selectedEntity={newEntity}
                              label={'Naam van contactpersoon'}
                              type={DetailFormFieldTypes.text}
                              orderNr={10}
                              propertyName={'contactPersonLastName'}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-lg-6">
                            <DetailFormField
                              key={'contactPersonEmail'}
                              touched={(formProps.touched as any)['contactPersonEmail' || '']}
                              error={(formProps.errors as any)['contactPersonEmail' || '']}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values['contactPersonEmail' || '']}
                              selectedEntity={newEntity}
                              label={'E-mailadres van contactpersoon'}
                              type={DetailFormFieldTypes.email}
                              orderNr={10}
                              validationChecks={Yup.string().test('email', 'Dit email is niet geldig', emailValidation)}
                              propertyName={'contactPersonEmail'}
                            />
                          </div>
                          <div className="col-12 col-lg-6">
                            <DetailFormField
                              key={'contactPersonPhoneNr'}
                              touched={(formProps.touched as any)['contactPersonPhoneNr' || '']}
                              error={(formProps.errors as any)['contactPersonPhoneNr' || '']}
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              value={formProps.values['contactPersonPhoneNr' || '']}
                              selectedEntity={newEntity}
                              label={'Telefoonnummer van contactpersoon'}
                              type={DetailFormFieldTypes.telephone}
                              orderNr={10}
                              validationChecks={Yup.string().test('phone', 'Dit telefoonnummer is niet geldig', phoneValidation)}
                              propertyName={'contactPersonPhoneNr'}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="column-devider">
                      <DetailFormField
                        key={'displayColor'}
                        touched={(formProps.touched as any)['displayColor' || '']}
                        error={(formProps.errors as any)['displayColor' || '']}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        value={formProps.values['displayColor' || '']}
                        selectedEntity={newEntity}
                        label={'Kleur'}
                        type={DetailFormFieldTypes.color}
                        orderNr={10}
                        propertyName={'displayColor'}
                      />
                      <DetailFormField
                        key={'isInternalLocation'}
                        touched={(formProps.touched as any)['isInternalLocation' || '']}
                        error={(formProps.errors as any)['isInternalLocation' || '']}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        value={formProps.values['isInternalLocation' || '']}
                        selectedEntity={newEntity}
                        label={'Interne Locatie'}
                        type={DetailFormFieldTypes.checkbox}
                        orderNr={10}
                        propertyName={'isInternalLocation'}
                      />
                      <DetailFormField
                        key={'hasInternet'}
                        touched={(formProps.touched as any)['hasInternet' || '']}
                        error={(formProps.errors as any)['hasInternet' || '']}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        value={formProps.values['hasInternet' || '']}
                        selectedEntity={newEntity}
                        label={'Internet op locatie'}
                        type={DetailFormFieldTypes.checkbox}
                        orderNr={10}
                        propertyName={'hasInternet'}
                      />
                      <DetailFormField
                        key={'hasBeamer'}
                        touched={(formProps.touched as any)['hasBeamer' || '']}
                        error={(formProps.errors as any)['hasBeamer' || '']}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        value={formProps.values['hasBeamer' || '']}
                        selectedEntity={newEntity}
                        label={'Beamer op locatie'}
                        type={DetailFormFieldTypes.checkbox}
                        orderNr={10}
                        propertyName={'hasBeamer'}
                      />
                      <DetailFormField
                        key={'hasDrinks'}
                        touched={(formProps.touched as any)['hasDrinks' || '']}
                        error={(formProps.errors as any)['hasDrinks' || '']}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        value={formProps.values['hasDrinks' || '']}
                        selectedEntity={newEntity}
                        label={'Drank op locatie'}
                        type={DetailFormFieldTypes.checkbox}
                        orderNr={10}
                        propertyName={'hasDrinks'}
                      />
                      <DetailFormField
                        key={'numberOfParticipants'}
                        touched={(formProps.touched as any)['numberOfParticipants' || '']}
                        error={(formProps.errors as any)['numberOfParticipants' || '']}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        value={formProps.values['numberOfParticipants' || '']}
                        selectedEntity={newEntity}
                        label={'Aantal deelnemers'}
                        type={DetailFormFieldTypes.number}
                        orderNr={10}
                        propertyName={'numberOfParticipants'}
                      />
                    </div>
                  </div>
                </div>
                <DetailFormField
                  key={'id'}
                  touched={(formProps.touched as any)['id' || '']}
                  error={(formProps.errors as any)['id' || '']}
                  onChange={formProps.handleChange}
                  onBlur={formProps.handleBlur}
                  value={formProps.values['id' || '']}
                  selectedEntity={newEntity}
                  label={'id'}
                  type={DetailFormFieldTypes.hidden}
                  orderNr={10}
                  propertyName={'id'}
                />
                <div className="form-buttons">
                  <div>
                    <Button type="button" onClick={() => navigatePush(`/${baseUrl}`)}>
                      Annuleren
                    </Button>
                  </div>
                  <Button loading={savingLocation} disabled={savingLocation} type="submit" className="primary">
                    Opslaan
                  </Button>
                </div>
              </Form>
            </Container>
          )}
        </Formik>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    savingLocation: getSavingLocations(state.locations),
    resources: getResources(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveLocation: (location: CourseLocation, clientId?: string, returnUrl?: string, activeIndex?: string) =>
    dispatch(
      actions.SaveLocation.request({
        location,
        clientId,
        returnUrl,
        activeIndex
      })
    ),
  navigatePush: (path: string) => dispatch(push(path))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['locations', 'resources', 'menu'], { nsMode: 'fallback' })(DetailCreate));
