import React, { SyntheticEvent } from 'react';
import { GlobalState } from '../../../core/state';
import { Dispatch } from 'redux';
import { TrainingsAction, OverviewTraining } from '../../state/state';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import NoDataFound from '../../../shared/components/NoDataFound';
import DataTableWrapper from '../../../shared/components/DataTable/DataTableWrapper';
import { updateQueryString } from '../../../shared/helpers/queryStringHelpers';
import QueryParams from '../../../shared/interfaces/QueryParams';
import { PaginationProps, Icon } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import { configConstants } from '../../../config/constants';
import DataTableFilterBox from '../../../shared/components/DataTable/DataTableAdvancedFilter';
import { searchColumnFields } from '../../helpers/DataSearchFields';
import * as actions from '../../state/actions';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import { queryParams } from '../../../shared/helpers/queryStringHelpers';
import isEqual from 'lodash/isEqual';
import InitialLoader from '../../../shared/components/InitialLoader';
import getTotalPeriod from '../../helpers/getTotalPeriod';
import { Trainer } from '../../../shared/interfaces/Trainer';
import { AppAction } from '../../../core/state/app.state';
import { ConfirmAction } from '../../../core/state/actions';
import { $enum } from 'ts-enum-util';
import { TrainingType, TrainingStatus } from '../../../shared/interfaces/Training';
import { Auth0Context } from '../../../core/auth/AuthWrapper';
import applyArchiveTagCheck from '../../../shared/helpers/archivedFormatter';

type Props = {
  queryParams: QueryParams;
  trainings: OverviewTraining[];
  totalTrainings: number;
  loadTrainings: (query: QueryParams) => void;
  loadingTrainingsStatus: ProgressStatus;
  deleteTraining: (trainingId: number, queryParams: QueryParams) => void;
} & WithNamespaces &
  RouteComponentProps;

class ListView extends React.Component<Props> {
  queryParams(props: Props = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  componentDidMount = () => {
    this.props.loadTrainings(this.queryParams());
  };

  componentWillUpdate(nextProps: Props) {
    const nextQueryparams = this.queryParams(nextProps);
    const queryChanged = !isEqual(this.queryParams(), nextQueryparams);
    if (queryChanged) {
      this.props.loadTrainings(nextQueryparams);
    }
  }

  handlePaginationChange = (e: SyntheticEvent, { activePage }: PaginationProps) => {
    updateQueryString(this.props, { page: activePage });
  };

  filterResults = (value: string | number) => {
    updateQueryString(this.props, {
      searchValue: value,
      page: 1,
      sort: undefined,
      order: undefined
    });
  };

  trainingToTableData(training: OverviewTraining) {
    const period = getTotalPeriod(training.trainingSessions);
    const trainers = training.trainingSessions.map(session => session.trainer).filter(x => x !== null);
    const uniqueTrainers = trainers.reduce((results, trainer) => {
      const trainerExists = results.find(x => x.id === trainer.id);
      return trainerExists ? results : [...results, trainer];
    }, [] as Trainer[]);
    const trainingType = $enum(TrainingType).getKeyOrThrow(training.type);
    const status = $enum(TrainingStatus).getKeyOrThrow(training.status);

    return {
      ...training,
      course: training.course ? (
        <span
          dangerouslySetInnerHTML={{
            __html: applyArchiveTagCheck(training.course.title, training.course.deletedAt, true, false)
          }}></span>
      ) : null,
      period: period ? `${period.start.format('DD/MM/YYYY')} - ${period.end.format('DD/MM/YYYY')}` : '',
      trainers: (
        <span
          dangerouslySetInnerHTML={{
            __html: uniqueTrainers.map(x => applyArchiveTagCheck(`${x.firstName} ${x.lastName}`, x.deletedAt, true, false)).join(', ')
          }}></span>
      ),
      totalParticipants: `${training.participants.length}/${training.maxParticipants}`,
      customer: training.customer ? (
        <span
          dangerouslySetInnerHTML={{
            __html: applyArchiveTagCheck(training.customer.companyName, training.customer.deletedAt, true, false)
          }}></span>
      ) : (
        ''
      ),
      trainingType: this.props.t(trainingType),
      status: this.props.t(status)
    };
  }

  render() {
    const userColumns = [
      { name: 'course', order: 1 },
      { name: 'customer', order: 2 },
      { name: 'trainingType', order: 3 },
      { name: 'period', order: 4 },
      { name: 'trainers', order: 6 },
      { name: 'totalParticipants', order: 7 },
      { name: 'status', order: 8 }
    ];
    const generateColumns = () =>
      [...userColumns]
        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
        .map(header => ({
          header: t(header.name),
          key: header.name
        }));

    const { trainings, t, history, match, loadingTrainingsStatus } = this.props;

    switch (loadingTrainingsStatus) {
      case ProgressStatus.InProgress:
        return <InitialLoader />;
      case ProgressStatus.Done:
        return (
          <Auth0Context.Consumer>
            {props => (
              <DataTableWrapper
                {...this.props}
                onDialogCancel={() => {}}
                onDialogClose={() => {}}
                onDialogConfirm={() => {}}
                onPageChange={this.handlePaginationChange}
                onSubmit={this.filterResults}
                dialogOpen={false}
                defaultActivePage={this.queryParams().page || 1}
                dialogContent={t('confirmArchiveBody')}
                dialogHeader={t('confirmArchiveHeader')}
                tableData={trainings.map(this.trainingToTableData.bind(this))}
                totalPages={Math.ceil(this.props.totalTrainings / configConstants.pageSize)}
                withActionButtons={true}
                activeId={0}
                activeSortHeader={this.queryParams().sort}
                fieldSet={generateColumns()}
                enabledSortingHeaders={[]}
                onSelect={(id: number | string) => history.push(`${match.path}/${id}`)}
                onArchive={(trainingId: number) => {
                  this.props.deleteTraining(trainingId, this.queryParams());
                }}>
                <DataTableFilterBox
                  {...this.props}
                  fieldSet={[
                    'id',
                    'course.title',
                    'trainingType',
                    'trainingSession.trainingPeriod',
                    'trainer.fullName',
                    'training.participantCount',
                    'customer.companyName',
                    'status'
                  ]}
                  searchFields={searchColumnFields}
                />
              </DataTableWrapper>
            )}
          </Auth0Context.Consumer>
        );
      case ProgressStatus.Error:
        return <NoDataFound />;
      case ProgressStatus.Uninitialized:
        return null;
    }
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    loadingTrainingsStatus: state.trainings.loadingTrainingsStatus,
    trainings: state.trainings.trainings as OverviewTraining[],
    totalTrainings: state.trainings.totalTrainings
  };
};

const mapDispatchToProps = (dispatch: Dispatch<TrainingsAction | AppAction>) => ({
  loadTrainings: (query: QueryParams) => dispatch(actions.FetchTrainings.request(query)),
  deleteTraining: (trainingId: number, queryParams: QueryParams) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteTraining',
        content: 'confirmDeleteTraining',
        action: () => dispatch(actions.DeleteTraining.request({ trainingId, queryParams }))
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['trainings', 'menu', 'common', 'resources'], { nsMode: 'fallback' })(ListView));
