import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router';
import * as actions from './actions';
import Course from '../../shared/interfaces/Course';
import { configConstants } from '../../config/constants';
import { FlatNode } from '../../shared/components/Tree/CourseTree';
import { addChildren } from '../helpers/addChildren';
import queryString from 'query-string';
import { fetch } from '../../core/state/fetch';
import { getType } from 'typesafe-actions';
import AlertService from '../../core/alert/AlertService';

export function* loadCourseCategories(action: AnyAction) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}course_categories/tree`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json();
      const categories = data['hydra:member'] as FlatNode[];
      const result = categories.map(category => addChildren(category));
      yield put(actions.FetchCourseCategoriesSuccess({ courseCategories: result }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchCourseCategoriesFail({ errorMessage: error }));
  }
}

export function* loadCourses(action: AnyAction) {
  try {
    const { sort, order } = action.payload;
    delete action.payload.sort;
    delete action.payload.order;

    const showActive = action.payload.active;
    delete action.payload.active;
    let query = queryString.stringify(action.payload);

    query += sort ? `&order[${sort}]=` + (order || 'asc') : '&order[updatedAt]=desc';

    if (!showActive) {
      query += '&active=1';
    }

    if (showActive === 'false') {
      query += '&active=0';
    }

    const response = yield call(fetch, `${configConstants.apiUrl}courses?${query}`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json() as Course[];

      yield put(actions.FetchCoursesSuccess({ courses: data['hydra:member'], totalCourses: data['hydra:totalItems'] }));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchCoursesFail({ errorMessage: error }));
  }
}

export function* loadOneCourse(action: ReturnType<typeof actions.FetchOneCourse>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}courses/${action.payload.courseId}?embed[]=trainer`);
    if (response.status >= 200 && response.status < 300) {
      const courseData = (yield response.json()) as Course;

      yield put(
        actions.FetchOneCourseSuccess({
          course: courseData
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(
      actions.FetchOneCourseFail({
        errorMessage: error
      })
    );
  }
}

export function* saveCourse(action: AnyAction) {
  try {
    const { course } = action.payload as { course: Course; noredirect?: boolean };
    const isNew = course.id === 0;

    delete course.trainers;

    const response = yield call(fetch, isNew ? `${configConstants.apiUrl}courses` : `${configConstants.apiUrl}courses/${course.id}`, {
      method: isNew ? 'POST' : 'PUT',
      body: JSON.stringify(isNew ? { ...course, id: undefined } : course),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.status >= 200 && response.status < 300) {
      const result = yield response.json();
      yield put(
        actions.SaveCourseSuccess({
          course: result
        })
      );

      AlertService.setMessage({
        title: 'Pakket opgeslagen',
        messageText: 'Het pakket is succesvol opgeslagen.',
        type: 'success'
      });

      if (!action.payload.noredirect) {
        yield put(push(`/courses`));
      }
    } else {
      AlertService.setMessage({
        title: 'Pakket niet opgeslagen',
        messageText: 'Oeps, er liep iets mis tijdens het opgeslagen.',
        type: 'error'
      });

      throw response;
    }
  } catch (error) {
    yield put(
      actions.SaveCourseFail({
        errorMessage: error
      })
    );

    AlertService.setMessage({
      title: 'Pakket niet opgeslagen',
      messageText: 'Oeps, er liep iets mis tijdens het opgeslagen.',
      type: 'error'
    });
  }
}

function* deleteCourse(action: ReturnType<typeof actions.DeleteCourse.request>) {
  try {
    const url = `courses/${action.payload.courseId}`;
    yield call(fetch, configConstants.apiUrl + url, { method: 'DELETE' });
    yield put(actions.FetchCourses(action.payload.queryParams));

    AlertService.setMessage({
      title: 'Pakket verwijderd',
      messageText: 'Het pakket is succesvol verwijderd',
      type: 'success'
    });
  } catch (errorMessage) {
    yield put(actions.DeleteCourse.failure({ errorMessage }));

    AlertService.setMessage({
      title: 'Pakket niet verwijderd',
      messageText: 'Oeps, er liep iets mis tijdens het verwijderen.',
      type: 'error'
    });
  }
}

export function* fetchAllCourses(action: ReturnType<typeof actions.FetchAllCourses.request>) {
  try {
    const response = yield call(fetch, `${configConstants.apiUrl}courses?pagination=false&order[title]=asc&active=1`);
    if (response.status >= 200 && response.status < 300) {
      const data = yield response.json() as Course[];
      yield put(actions.FetchAllCourses.success(data['hydra:member']));
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.FetchAllCourses.failure({ errorMessage: error }));
  }
}

export const coursesSaga = [
  takeEvery(getType(actions.SaveCourse), saveCourse),
  takeLatest(getType(actions.FetchCourseCategories), loadCourseCategories),
  takeLatest(getType(actions.FetchCourses), loadCourses),
  takeLatest(getType(actions.FetchOneCourse), loadOneCourse),
  takeLatest(getType(actions.FetchAllCourses.request), fetchAllCourses),
  takeLatest(getType(actions.DeleteCourse.request), deleteCourse)
];
