import { createAction, createAsyncAction } from 'typesafe-actions';
import { Trainer, WithCompetencies, WithLanguages } from '../../shared/interfaces/Trainer';
import QueryParams from '../../shared/interfaces/QueryParams';
import Course from '../../shared/interfaces/Course';
import { TrainerTraining } from '../../shared/interfaces/TrainerTraining';

export enum TrainersActionTypes {
  FetchTrainers = '[Trainers] Fetch trainers...',
  FetchTrainersSuccess = '[Trainers] Fetch trainers Success',
  FetchTrainersFail = '[Trainers] Fetch trainers Fail',
  SetTrainer = '[Trainers] Set Trainer',
  ClearTrainers = '[Trainers] Clearing Trainers',
  FetchOneTrainer = '[Trainers] Fetch One trainer...',
  FetchOneTrainerSuccess = '[Trainers] Fetch One trainer Success',
  FetchOneTrainerFail = '[Trainers] Fetch One trainer Fail',
  FetchTrainerCourses = '[Trainers] Fetch trainer courses',
  FetchTrainerCoursesSuccess = '[Trainers] Fetch trainer courses Success',
  FetchTrainerCoursesFail = '[Trainers] Fetch trainer courses Fail',
  FetchTrainerTrainings = '[Trainers] Fetch trainer trainings',
  FetchTrainerTrainingsSuccess = '[Trainers] Fetch trainer trainings Success',
  FetchTrainerTrainingsFail = '[Trainers] Fetch trainer trainings Fail',

  FetchCoursesByTrainingIds = '[Trainers] Fetch training courses',
  FetchCoursesByTrainingIdsSuccess = '[Trainers] Fetch training courses Success',
  FetchCoursesByTrainingIdsFail = '[Trainers] Fetch training courses Fail',

  DeleteTrainer = '[Trainers] Deleting Trainer...',
  DeleteTrainerSuccess = '[Trainers] Deleting Trainer Success',
  DeleteTrainerFail = '[Trainers] Deleting Trainer Fail',
  SaveTrainer = '[Trainers] Saving Trainer...',
  SaveTrainerSuccess = '[Trainers] Saving Trainer Success',
  SaveTrainerFail = '[Trainers] Saving Trainer Fail'
}

export const FetchTrainers = createAction(TrainersActionTypes.FetchTrainers, resolve => {
  return (value: QueryParams) => resolve(value);
});

export const FetchTrainersSuccess = createAction(TrainersActionTypes.FetchTrainersSuccess, resolve => {
  return (value: { trainers: Trainer<WithCompetencies>[]; totalTrainers: number; courses: Course[] }) => resolve(value);
});

export const FetchTrainersFail = createAction(TrainersActionTypes.FetchTrainersFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const FetchOneTrainer = createAction(TrainersActionTypes.FetchOneTrainer, resolve => {
  return (value: { trainerId: number }) => resolve(value);
});

export const FetchOneTrainerSuccess = createAction(TrainersActionTypes.FetchOneTrainerSuccess, resolve => {
  return (value: { trainer: Trainer<WithLanguages> }) => resolve(value);
});

export const FetchTrainerCourses = createAction(TrainersActionTypes.FetchTrainerCourses, resolve => {
  return (value: { trainerId: number }) => resolve(value);
});

export const FetchTrainerCoursesSuccess = createAction(TrainersActionTypes.FetchTrainerCoursesSuccess, resolve => {
  return (value: { courses: Course[] }) => resolve(value);
});

export const FetchTrainerCoursesFail = createAction(TrainersActionTypes.FetchTrainerCoursesFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const FetchTrainerTrainings = createAction(TrainersActionTypes.FetchTrainerTrainings, resolve => {
  return (value: { trainerId: number }) => resolve(value);
});

export const FetchTrainerTrainingsSuccess = createAction(TrainersActionTypes.FetchTrainerTrainingsSuccess, resolve => {
  return (value: { trainings: TrainerTraining[] }) => resolve(value);
});

export const FetchTrainerTrainingsFail = createAction(TrainersActionTypes.FetchTrainerTrainingsFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const FetchOneTrainerFail = createAction(TrainersActionTypes.FetchOneTrainerFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const SetTrainer = createAction(TrainersActionTypes.SetTrainer, resolve => {
  return (value: { trainerId: number }) => resolve(value);
});
export const ClearTrainers = createAction(TrainersActionTypes.ClearTrainers);

export const DeleteTrainerSuccess = createAction(TrainersActionTypes.DeleteTrainerSuccess, resolve => {
  return (value: { trainerId: number }) => resolve(value);
});

export const DeleteTrainerFail = createAction(TrainersActionTypes.DeleteTrainerFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const SaveTrainer = createAction(TrainersActionTypes.SaveTrainer, resolve => {
  return (value: { trainer: Trainer; noredirect?: boolean }) => resolve(value);
});

export const SaveTrainerSuccess = createAction(TrainersActionTypes.SaveTrainerSuccess);

export const SaveTrainerFail = createAction(TrainersActionTypes.SaveTrainerFail, resolve => {
  return (value: { errorMessage: string }) => resolve(value);
});

export const DeleteTrainer = createAsyncAction(
  '[Trainers] Delete trainer...',
  '[Trainers] Delete trainer success',
  '[Trainers] Delete trainer failure'
)<{ trainerId: number; queryParams: QueryParams }, void, { errorMessage: string }>();

export const SetTrainerTabIndex = createAction('[Trainers] Set trainer tab index', resolve => {
  return (value: { index: number; trainer: Trainer }) => resolve(value);
});
