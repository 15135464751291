import Entity, { EntityReference } from './Entity';
import { DbFile } from './DbFile';
import { Training } from './Training';

export enum TrainingDocumentType {
  Order = 'order',
  Offerte = 'offerte'
}

interface WithTrainingReference {
  training: EntityReference;
}

interface WithTrainingDocumentReference {
  trainingDocument: EntityReference;
}

export interface WithTraining {
  training: Training;
}

export interface WithTrainingDocument {
  trainingDocument: DbFile;
}

export type TrainingDocument<T = WithTrainingReference & WithTrainingDocumentReference> = T &
  Entity & {
    id: number;
    type: TrainingDocumentType;
  };
