import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

export function queryParams<T>(search: string) {
  return (queryString.parse(search) as unknown) as T;
}

export function updateQueryString<T>(props: RouteComponentProps, newValues: T) {
  const currentQuery = queryParams<T>(props.location.search);
  const newProps = Object.assign(currentQuery, newValues);
  props.history.push({
    pathname: props.location.pathname,
    search: queryString.stringify(filterSearchResults(newProps), { encode: false })
  });
}
function filterSearchResults(values: any) {
  const obj: any = {};
  Object.keys(values).forEach(key => values[key] && (obj[key] = values[key]));
  return obj;
}

export function generateCsvExportUrl<T>(baseUrl: string, pathName: string, search?: string, fieldSet?: string[]) {
  const path = convertPathNameToApiRoute(pathName);

  let fields = '';
  if (fieldSet) {
    // Check length
    const fieldsLength = fieldSet.length;
    // Start the fields query string
    fields = '&fields=';

    fieldSet.map((field, i) => {
      fields = fields.concat(field);

      // if not last element in fields array, add a comma.
      if (fieldsLength !== i + 1) {
        fields = fields.concat(',');
      }
    });
  }

  return `${baseUrl.replace(/\/$/, '')}${path}.csv${search ? search + '&pagination=false' : '?pagination=false'}${fields ? fields : ''}`;
}
function convertPathNameToApiRoute(path: string) {
  switch (path) {
    case '/requests':
      return '/training_requests';
    case '/clients':
      return '/customers';
  }

  return path;
}
