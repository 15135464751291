import { TrainingType, WithCourseReference, Training, WithTrainingSessions } from './Training';
import { ClientReference } from './Client';
import Entity, { EntityReference } from './Entity';
import Course from './Course';
import TrainingSession from './TrainingSession';

export enum TrainingRequestStatus {
  NewRequest = 1,
  InAnticipation = 2,
  WonRequest = 3,
  LostRequest = 4
}

export enum TrainingRequestChannel {
  Website = 1,
  Telephone = 2,
  Email = 3,
  Others = 4
}

export enum RateUnit {
  PerDay = 1,
  PerSession = 2,
  PerParticipantDay = 3,
  PerParticipantEvening = 4
}

export type WithTrainingReference = {
  training: EntityReference | undefined;
};

export type WithTraining<T = WithCourseReference & WithCourse & WithTrainingSessions> = {
  training?: Training<T>;
};

export type WithTrainingRequestSessionsReference = {
  trainingRequestSessions: EntityReference[];
};

export type WithTrainingRequestSessions = {
  trainingRequestSessions: [
    {
      id: number;
      startDate: string;
    }
  ];
};

export type WithTrainingSessions = {
  trainingSessions?: [
    {
      id: number;
      trainingSession: TrainingSession;
    }
  ];
};

export type WithCourse = {
  course?: Course;
};

export type TrainingRequest<T = WithCourseReference & WithTrainingReference & WithTrainingRequestSessionsReference> = Entity &
  T & {
    id: number;
    orderDocument: undefined;
    type: number | undefined;
    firstName: string;
    lastName: string;
    email: string;
    phoneNr: string;
    companyName: string;
    vatNr: string;
    hasKmoWallet: boolean;
    remarks: string;
    channel: TrainingRequestChannel;
    status: TrainingRequestStatus;
    totalParticipants: number;
    trainingType: TrainingType | undefined;
    requestIsCancelled: boolean;
    cancellationReason: string;
    customer: ClientReference | undefined;
    rate: number | undefined;
    rateUnit: RateUnit;
    createdAt?: string;
    deletedAt?: string;
    followUpDate?: string;
    designatedTraining?: EntityReference;
    discount?: number;
  };
