import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { DeepReadonly } from 'utility-types/dist/mapped-types';
import { Material } from '../../shared/interfaces/Material';
import { GQLSessionMaterials } from '../../shared/interfaces/GQLSessionMaterials';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import CalendarListItem from '../../shared/interfaces/CalendarLocation';
export type MaterialssAction = ActionType<typeof actions>;

export type MaterialsState = DeepReadonly<{
  savingMaterial: boolean;
  materialSaved: boolean;
  selectedMaterial: Material | null;
  loadingMaterial: boolean;
  materialLoaded: boolean;
  materialStatus: ProgressStatus;
  errorMessage: string | null;
  sessionMaterials: GQLSessionMaterials | null;
  calendarMaterialList: CalendarListItem[] | null;
  calendarMaterialStatus: ProgressStatus;
  fetchingMaterialsStatus: ProgressStatus;
  materials: Material[];
}>;

const initialState: MaterialsState = {
  savingMaterial: false,
  materialSaved: false,
  loadingMaterial: false,
  materialLoaded: false,
  selectedMaterial: null,
  materialStatus: ProgressStatus.Uninitialized,
  calendarMaterialStatus: ProgressStatus.Uninitialized,
  calendarMaterialList: null,
  errorMessage: null,
  sessionMaterials: null,
  fetchingMaterialsStatus: ProgressStatus.Uninitialized,
  materials: []
};
export function materials(state: MaterialsState = initialState, action: MaterialssAction): MaterialsState {
  switch (action.type) {
    case getType(actions.SaveMaterial.request):
      return {
        ...state,
        savingMaterial: true,
        materialSaved: false
      };
    case getType(actions.SaveMaterial.success):
      return {
        ...state,
        savingMaterial: false,
        materialSaved: true,
        selectedMaterial: action.payload.material
      };
    case getType(actions.SaveMaterial.failure):
      return {
        ...state,
        savingMaterial: false,
        materialSaved: true,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchOneMaterial.request):
      return {
        ...state,
        loadingMaterial: true,
        materialLoaded: false
      };
    case getType(actions.FetchOneMaterial.success):
      return {
        ...state,
        loadingMaterial: false,
        materialLoaded: true,
        selectedMaterial: action.payload.material
      };
    case getType(actions.FetchOneMaterial.failure):
      return {
        ...state,
        loadingMaterial: false,
        materialLoaded: true,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchTrainingSessionMaterials.request):
      return {
        ...state,
        materialStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchTrainingSessionMaterials.success):
      return {
        ...state,
        materialStatus: ProgressStatus.Done,
        sessionMaterials: action.payload.sessionMaterials
      };
    case getType(actions.FetchTrainingSessionMaterials.failure):
      return {
        ...state,
        materialStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchCalendarListMaterials.request):
      return {
        ...state,
        calendarMaterialStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchCalendarListMaterials.success):
      return {
        ...state,
        calendarMaterialStatus: ProgressStatus.Done,
        calendarMaterialList: action.payload.calendarMaterialList
      };
    case getType(actions.FetchCalendarListMaterials.failure):
      return {
        ...state,
        calendarMaterialStatus: ProgressStatus.Error,
        errorMessage: action.payload.errorMessage
      };
    case getType(actions.FetchAllMaterials.request):
      return {
        ...state,
        fetchingMaterialsStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchAllMaterials.success):
      return {
        ...state,
        fetchingMaterialsStatus: ProgressStatus.Done,
        materials: action.payload
      };
    case getType(actions.FetchAllMaterials.failure):
      return {
        ...state,
        fetchingMaterialsStatus: ProgressStatus.Error
      };
    default:
      return { ...state };
  }
}
