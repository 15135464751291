import React, { useState } from 'react';
import PageHeader from '../../../../../shared/components/PageHeader';
import { RouteComponentProps } from 'react-router';
import { WithNamespaces } from 'react-i18next';
import Course from '../../../../../shared/interfaces/Course';
import { SessionRouteInfo } from '../Sessions/Shell';
import DetailForm from '../../../../../shared/components/DetailForm/DetailForm';
import { TrainingSessionMaterial, TransportType, WithMaterial } from '../../../../../shared/interfaces/TrainingSessionMaterial';
import sessionMaterialFormFields, { sessionMaterialsWithFilterCondition } from './helpers/sessionMaterialFormFields';
import { SingleEntitySelector } from '../../../../../shared/components/DetailForm/EntitySelector';
import DetailFormField, { DetailFormFieldTypes } from '../../../../../shared/components/DetailForm/DetailFormField';
import SearchMaterialForm from '../../../../../shared/components/DetailForm/EntitySelector/SelectForms/SearchMaterialForm';
import Entity from '../../../../../shared/interfaces/Entity';
import { Material } from '../../../../../shared/interfaces/Material';
import { FormikValues } from 'formik';
import { enumToOption } from '../../../../../shared/helpers/dropDownHelpers';
import { Button } from 'semantic-ui-react';
import { configConstants } from '../../../../../config/constants';

type Props = RouteComponentProps<SessionRouteInfo> &
  WithNamespaces & {
    course: Course;
    sessionMaterial: TrainingSessionMaterial;
    onSave: (sessionMaterial: TrainingSessionMaterial) => void;
    goBack: () => void;
  };

export default function DetailCreate(props: Props) {
  const { t, match, course, sessionMaterial } = props;
  const isNew = sessionMaterial.id === 0;

  const [sessionMateriaState, setSessionMaterialState] = useState(sessionMaterial);

  const getMaterial = (material: string) => {
    const data = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };

    fetch(configConstants.apiUrl + 'materials/' + material.replace('/materials/', ''), data).then(r => {
      if (r.status >= 200 && r.status < 300) {
        r.json().then(material => {
          setSessionMaterialState({ ...sessionMateriaState, rate: material.rate, material: material['@id'] });
        });
      }
    });
  };

  return (
    <>
      <PageHeader
        {...props}
        title={t('sessionMaterials')}
        breadCrumbParts={[
          t('trainings'),
          course.title,
          t('sessions'),
          match.params.sessionId,
          t('materials'),
          isNew ? t('new') : t('edit')
        ]}
      />
      <form className="ui form">
        <DetailFormField
          onChange={(data: FormikValues) => {
            setSessionMaterialState({ ...sessionMateriaState, rate: parseFloat(data.target.value) });
          }}
          value={sessionMateriaState.rate || ''}
          type={DetailFormFieldTypes.currency}
          propertyName="rate"
          label={'Tarief'}
          orderNr={0}
          onBlur={() => {}}
          selectedEntity={{ '@id': '0' }}
          required={true}
        />
        <DetailFormField
          onChange={(data: FormikValues) => {
            getMaterial(data.target.value);
          }}
          value={sessionMateriaState.material}
          type={DetailFormFieldTypes.entitySelector}
          searchEntityForm={SearchMaterialForm}
          renderEntity={(entity: Entity) => <span>{(entity as Material).name}</span>}
          propertyName="material"
          label={'Kies materiaal'}
          orderNr={0}
          filterCondition={sessionMaterial.trainingSession!.replace('/training_sessions/', '')}
          onBlur={() => {}}
          selectedEntity={{ '@id': '0' }}
          isNewVersion={true}
          dataTableFields={[
            { key: 'name', label: props.t('name') },
            { key: 'price', label: props.t('price') }
          ]}
          dataTableSortable={true}
          required={true}
        />
        <DetailFormField
          onChange={(data: FormikValues) => {
            setSessionMaterialState({ ...sessionMateriaState, transportType: data.target.value });
          }}
          value={sessionMateriaState.transportType}
          type={DetailFormFieldTypes.dropdownlist}
          propertyName="transportType"
          label={t('transportType')}
          orderNr={0}
          onBlur={() => {}}
          selectedEntity={{ '@id': '0' }}
          dropDownOptions={enumToOption(TransportType)}
        />
      </form>
      <div className="form-buttons">
        <Button onClick={() => props.goBack()}>{t('back')}</Button>
        <Button
          className="primary"
          onClick={() => {
            props.onSave(sessionMateriaState as TrainingSessionMaterial);
            props.goBack();
          }}>
          {t('save')}
        </Button>
      </div>
    </>
  );
}
