import React, { useState } from 'react';
import { FormikValues } from 'formik';

import { Modal, Button, Input } from 'semantic-ui-react';
import FileSelector from '../FileSelector';

import { DbFile } from '../../interfaces/DbFile';
import { Mail } from '../../interfaces/Mail';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import DetailFormField, { DetailFormFieldTypes } from '../DetailForm/DetailFormField';
import { arrayToOption } from '../../helpers/dropDownHelpers';
import { Select } from 'antd';
import 'antd/dist/antd.css';
const { Option, OptGroup } = Select;

interface MailerModalProps {
  entityReference: string;
  recipients?: string;
  receiverOptions?: { title: string; children: { text: string; value: string }[] }[];
  isOpen: boolean;
  files?: { key: string; file: DbFile }[];
  closeModal: () => void;
  submit: (mail: Mail) => void;
  templates?: { key: string; body: string }[];
}

export const newMail: Mail = {
  entity: '',
  recipients: '',
  subject: '',
  body: '',
  template: '',
  documents: []
};

const MailerModal = ({
  entityReference,
  recipients,
  receiverOptions,
  files,
  templates,
  isOpen,
  closeModal,
  submit,
  t
}: MailerModalProps & WithNamespaces) => {
  const [mail, setMail] = useState({ ...newMail, entity: entityReference });
  const [errors, setErrors] = useState({ subject: false, receiver: false, body: false });

  const validateAndSubmit = () => {
    if (mail.subject && mail.body && mail.body.replace(/<\/?[^>]+(>|$)/g, '')) {
      if (receiverOptions) {
        if (mail.recipients) {
          submit(mail);
          setMail({ ...newMail, entity: entityReference });
        } else {
          setErrors({ ...errors, receiver: true });
        }
      } else {
        submit(mail);
        setMail({ ...newMail, entity: entityReference });
      }
    }

    if (!mail.body || (mail.body && mail.body.replace(/<\/?[^>]+(>|$)/g, '') === null)) {
      setErrors({ ...errors, body: true });
    }

    if (!mail.subject) {
      setErrors({ ...errors, subject: true });
    }
  };

  return (
    <Modal open={isOpen} closeIcon onClose={closeModal} className="mailer">
      <Modal.Header>
        {t('mail.modal.header')} {recipients ? recipients : null}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div className="row">
            <div className="col-12 col-md-4">
              <label>{t('mail.subject.label')}</label>
              <Input
                onChange={(data: FormikValues) => setMail({ ...mail, subject: data.target.value })}
                value={mail.subject}
                className={'mailer-subject-input' + (errors.subject ? ' error' : '')}
              />
            </div>
            <div className="col-12 col-md-4">
              {templates ? (
                <DetailFormField
                  onChange={(data: FormikValues) => {
                    setMail({
                      ...mail,
                      template: data.target.value,
                      body: templates.filter(e => e.key === data.target.value).map(e => e.body)[0]
                    });
                  }}
                  value={mail.template}
                  type={DetailFormFieldTypes.dropdownlist}
                  propertyName="template"
                  label={t('mail.template.label')}
                  orderNr={0}
                  onBlur={() => {}}
                  selectedEntity={{ '@id': '0' }}
                  dropDownOptions={arrayToOption(
                    templates.map(f => {
                      return f.key;
                    })
                  )}
                />
              ) : null}
            </div>
            {receiverOptions ? (
              <div className="col-12 mt-2">
                <label>{t('mail.receiver.label')}</label>
                <Select
                  mode="multiple"
                  placeholder="Please select"
                  onChange={(value: any) => {
                    setMail({
                      ...mail,
                      recipients: value.join(',')
                    });
                  }}
                  className={errors.receiver ? ' error' : ''}
                  style={{ width: '100%' }}>
                  {receiverOptions.map((group, fkey) => {
                    return (
                      <OptGroup key={fkey} label={group.title}>
                        {group.children.map((option, key) => {
                          return (
                            <Option key={group.title + '-' + key} value={option.value}>
                              {option.text}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    );
                  })}
                </Select>
              </div>
            ) : null}
          </div>
          <form>
            <div className="m-t-lg">
              <DetailFormField
                onChange={(data: FormikValues) => setMail({ ...mail, body: data.target.value })}
                value={templates ? templates.filter(e => e.key === mail.template).map(e => e.body)[0] : ''}
                type={DetailFormFieldTypes.editor}
                propertyName="body"
                label={t('mail.body.label')}
                toolbar={['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }, 'clean']}
                orderNr={0}
                onBlur={() => {}}
                selectedEntity={{ '@id': '0' }}
              />
            </div>
            {errors.body ? <small className="error">Bericht veld is leeg</small> : null}
          </form>

          {files ? (
            <div className="m-t-lg">
              <label>{t('mail.attachments.label')}</label>
              <FileSelector
                files={files}
                selectedFiles={files => {
                  setMail({ ...mail, documents: files });
                }}
              />
            </div>
          ) : null}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('close')}</Button>
        <Button
          onClick={() => {
            validateAndSubmit();
          }}
          positive>
          {t('send')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default withNamespaces(['mailer', 'common', 'resources'], { nsMode: 'fallback' })(MailerModal);
