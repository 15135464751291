import React from 'react';
import { GlobalState } from '../../core/state';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DashboardAction, DashboardRequest } from '../state/state';
import * as actions from '../state/actions';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import { Training, WithCourse, WithTrainingSessions } from '../../shared/interfaces/Training';
import InitialLoader from '../../shared/components/InitialLoader';
import PageHeader from '../../shared/components/PageHeader';
import { RouteComponentProps } from 'react-router';
import { Table } from 'semantic-ui-react';
import Entity from '../../shared/interfaces/Entity';
import { translate, WithNamespaces } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { baseUrl as requestsBaseUrl } from '../../requests/helpers/constants';
import { baseUrl as trainingsBaseUrl } from '../../trainings/helpers/constants';
import { $enum } from 'ts-enum-util';
import { TrainingType } from '../../shared/interfaces/Training';
import { getRequestModuleStatus } from '../../core/state/selectors';

type Props = RouteComponentProps &
  WithNamespaces & {
    hasRequestModule: boolean;
    dashboardLoadingStatus: ProgressStatus;
    newRequests: DashboardRequest[] | undefined;
    wonRequests: DashboardRequest[] | undefined;
    lostRequests: DashboardRequest[] | undefined;
    upcomingTrainings: Training<WithCourse & WithTrainingSessions>[] | undefined;
    loadDashboardData: () => void;
  };

class Dashboard extends React.Component<Props> {
  componentDidMount() {
    this.props.loadDashboardData();
  }

  renderBlock<T extends Entity>(title: string, entities: T[], renderRow: (entity: T) => JSX.Element) {
    return (
      <>
        <div className="detail-group">
          <div className="header">
            <h4>{this.props.t(title).toUpperCase()}</h4>
          </div>

          <Table key={title}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{title === 'newRequests' ? this.props.t('company') : this.props.t('training')}</Table.HeaderCell>
                <Table.HeaderCell>{title === 'newRequests' ? this.props.t('course') : this.props.t('trainingType')}</Table.HeaderCell>
                <Table.HeaderCell>{title === 'newRequests' ? this.props.t('requestDate') : this.props.t('startDate')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{entities.slice(0, 5).map(renderRow)}</Table.Body>
          </Table>
        </div>
        <br />
      </>
    );
  }

  renderRequestsBlock(title: string, requests: DashboardRequest[]) {
    return this.renderBlock(title, requests, (request: DashboardRequest) => {
      const course = request.training && request.training.course ? request.training.course : request.course;

      return (
        <Table.Row key={request['@id']}>
          <Table.Cell>
            <Link to={`${requestsBaseUrl}/${request.id}`}>{request.companyName}</Link>
          </Table.Cell>
          <Table.Cell>{course ? course.title : '-'}</Table.Cell>
          <Table.Cell>{moment(request.createdAt && request.createdAt).format('DD/MM/YY')}</Table.Cell>
        </Table.Row>
      );
    });
  }
  renderRequestsBlockClosed(title: string, requests: DashboardRequest[]) {
    return this.renderBlockClosed(title, requests, (request: DashboardRequest) => {
      const course = request.training && request.training.course ? request.training.course : request.course;

      return (
        <Table.Row key={request['@id']}>
          <Table.Cell>
            <Link to={`${requestsBaseUrl}/${request.id}`}>{request.companyName}</Link>
          </Table.Cell>
          <Table.Cell>{course ? course.title : '-'}</Table.Cell>
          <Table.Cell>{request.training ? this.props.t($enum(TrainingType).getKeyOrDefault(request.training.type)!) : '-'}</Table.Cell>
        </Table.Row>
      );
    });
  }

  renderBlockClosed<T extends Entity>(title: string, entities: T[], renderRow: (entity: T) => JSX.Element) {
    return (
      <>
        <div className="detail-group">
          <div className="header">
            <h4>{this.props.t(title).toUpperCase()}</h4>
          </div>
          <Table key={title}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{this.props.t('company')}</Table.HeaderCell>
                <Table.HeaderCell>{this.props.t('course')}</Table.HeaderCell>
                <Table.HeaderCell>{this.props.t('trainingType')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{entities.slice(0, 5).map(renderRow)}</Table.Body>
          </Table>
        </div>
        <br />
      </>
    );
  }

  renderTrainingsBlock(trainings: Training<WithCourse & WithTrainingSessions>[]) {
    return this.renderBlock('upcomingTrainings', trainings, (training: Training<WithCourse & WithTrainingSessions>) => {
      const firstSession = training.trainingSessions.reduce(
        (result, next) => (moment(result.startDate).isBefore(next.startDate) ? result : next),
        training.trainingSessions[0]
      );
      return (
        <Table.Row key={training['@id']}>
          <Table.Cell>
            <Link to={`${trainingsBaseUrl}/${training.id}`}>{training.course ? training.course.title : null}</Link>
          </Table.Cell>
          <Table.Cell>{training.type ? this.props.t($enum(TrainingType).getKeyOrDefault(training.type)!) : '-'}</Table.Cell>
          <Table.Cell>{firstSession ? moment(firstSession.startDate).format('DD/MM/YY') : '-'}</Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    const { dashboardLoadingStatus, newRequests, lostRequests, wonRequests, upcomingTrainings } = this.props;

    if (dashboardLoadingStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    return (
      <>
        <PageHeader {...this.props} breadCrumbParts={[]} title="Dashboard" />

        <div className="row">
          <div className="col-lg-12 col-xl-6 order-lg-2">{this.renderTrainingsBlock(upcomingTrainings || [])}</div>

          {this.props.hasRequestModule && (
            <div className="col-lg-12 col-xl-6 order-lg-1">{this.renderRequestsBlock('newRequests', newRequests || [])}</div>
          )}
        </div>

        {this.props.hasRequestModule && (
          <div className="row">
            <div className="col-lg-12 col-xl-6">{this.renderRequestsBlockClosed('wonRequests', wonRequests || [])}</div>

            <div className="col-lg-12 col-xl-6">{this.renderRequestsBlockClosed('lostRequests', lostRequests || [])}</div>
          </div>
        )}
      </>
    );
  }
}

export default connect(
  (state: GlobalState) => ({
    hasRequestModule: getRequestModuleStatus(state.app),
    dashboardLoadingStatus: state.dashboard.dashboardLoadingStatus,
    newRequests: state.dashboard.latestNewRequests as DashboardRequest[] | undefined,
    wonRequests: state.dashboard.latestWonRequests as DashboardRequest[] | undefined,
    lostRequests: state.dashboard.latestLostRequests as DashboardRequest[] | undefined,
    upcomingTrainings: state.dashboard.upcomingTrainings as Training<WithCourse & WithTrainingSessions>[] | undefined
  }),
  (dispatch: Dispatch<DashboardAction>) => ({
    loadDashboardData: () => dispatch(actions.LoadDashboard.request())
  })
)(translate(['dashboard', 'menu', 'resources'], { nsMode: 'fallback' })(Dashboard));
