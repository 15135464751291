import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormFieldDefinition, FormFieldPosition, FormDefinition } from '../../shared/interfaces/FormFieldDefinition';
import { TrainingRequestStatus, TrainingRequestChannel } from '../../shared/interfaces/TrainingRequest';
import { enumToOption } from '../../shared/helpers/dropDownHelpers';
import { TrainingType } from '../../shared/interfaces/Training';
import * as Yup from 'yup';
import { emailValidation } from '../../shared/helpers/emailValidation';
import { phoneValidation } from '../../shared/helpers/phoneValidation';
import { vatValidation } from '../../shared/helpers/vatValidation';

export const searchColumnFields: Array<FormFieldDefinition> = [
  {
    propertyName: 'status',
    label: 'Status',
    type: DetailFormFieldTypes.dropdownlist,
    orderNr: 5,
    dropDownOptions: enumToOption(TrainingRequestStatus),
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'channel',
    label: 'Kanaal',
    type: DetailFormFieldTypes.dropdownlist,
    orderNr: 5,
    dropDownOptions: enumToOption(TrainingRequestChannel),
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'trainingType',
    label: 'trainingType',
    type: DetailFormFieldTypes.dropdownlist,
    orderNr: 5,
    dropDownOptions: enumToOption(TrainingType),
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'companyName',
    label: 'Klantnaam',
    type: DetailFormFieldTypes.text,
    orderNr: 5
  },
  {
    propertyName: 'vatNr',
    label: 'BTW nr',
    type: DetailFormFieldTypes.text,
    validationChecks: Yup.string().test('vat', 'Dit BTW-nummer is niet geldig', vatValidation),
    orderNr: 5
  },
  {
    propertyName: 'phoneNr',
    label: 'Telefoonnummer',
    type: DetailFormFieldTypes.text,
    validationChecks: Yup.string().test('phone', 'Dit telefoonnummer is niet geldig', phoneValidation),
    orderNr: 5
  },
  {
    propertyName: 'requestCourse',
    label: 'Pakket',
    type: DetailFormFieldTypes.dropdownsearchlist,
    orderNr: 5,
    dropDownOptions: enumToOption(TrainingRequestStatus),
    column: FormFieldPosition.Left
  }
];

export const formDefinition: FormDefinition = [
  {
    type: DetailFormFieldTypes.text,
    propertyName: 'vatNr',
    label: 'BTW nr.',
    validationChecks: Yup.string().test('vat', 'Dit BTW-nummer is niet geldig', vatValidation),
    orderNr: 6
  },
  {
    type: DetailFormFieldTypes.editor,
    propertyName: 'remarks',
    label: 'Opmerkingen',
    orderNr: 7,
    hasToolbar: false
  },
  {
    propertyName: 'status',
    label: 'Status',
    type: DetailFormFieldTypes.dropdownlist,
    orderNr: 5,
    dropDownOptions: enumToOption(TrainingRequestStatus),
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'channel',
    label: 'Kanaal',
    type: DetailFormFieldTypes.dropdownlist,
    orderNr: 5,
    dropDownOptions: enumToOption(TrainingRequestChannel),
    column: FormFieldPosition.Right
  },
  {
    propertyName: 'firstName',
    label: 'Voornaam',
    type: DetailFormFieldTypes.text,
    orderNr: 5,
    required: true,
    validationChecks: Yup.string().required()
  },
  {
    propertyName: 'lastName',
    label: 'Achternaam',
    type: DetailFormFieldTypes.text,
    orderNr: 5,
    required: true,
    validationChecks: Yup.string().required()
  },
  {
    propertyName: 'companyName',
    label: 'Klantnaam',
    type: DetailFormFieldTypes.text,
    orderNr: 5,
    required: true,
    validationChecks: Yup.string().required()
  },
  {
    propertyName: 'email',
    label: 'E-mailadres',
    type: DetailFormFieldTypes.text,
    orderNr: 5,
    required: true,
    validationChecks: Yup.string()
      .test('email', 'Dit email is niet geldig', emailValidation)
      .required()
  },
  {
    propertyName: 'phoneNr',
    label: 'Telefoonnummer',
    type: DetailFormFieldTypes.text,
    orderNr: 5,
    required: true,
    validationChecks: Yup.string()
      .test('phone', 'Dit telefoonnummer is niet geldig', phoneValidation)
      .required()
  },
  {
    type: DetailFormFieldTypes.datePicker,
    propertyName: 'followUpDate',
    label: 'Opvolgdatum',
    orderNr: 8,
    hasTimeField: false,
    required: false
  },
  {
    propertyName: 'hasKmoWallet',
    label: 'Kmo-portefeuille',
    type: DetailFormFieldTypes.checkbox,
    orderNr: 9,
    column: FormFieldPosition.Right
  }
];
