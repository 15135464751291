import SearchTextForm from '../SearchTextForm';
import { configConstants } from '../../../../../../config/constants';
import CourseCategory from '../../../../../interfaces/CourseCategory';
import { fetch } from '../../../../../../core/state/fetch';

export async function searchCategories(
  searchText: string,
  page?: number,
  sortField?: string,
  sortDirection?: 'asc' | 'desc',
  filterCondition?: string
) {
  const url = `${configConstants.apiUrl}course_categories?search_text=${searchText}&page=${page}`;
  const response = await fetch(url).then(r => r.json());
  const entities: CourseCategory[] = response['hydra:member'] || [];
  const amount: number = response['hydra:totalItems'] || 1;
  return { entities, amount };
}

export default class SearchCategoryForm extends SearchTextForm<CourseCategory> {
  placeholder = 'Zoek categorie';
  searchEntities = searchCategories;
}
