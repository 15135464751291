import React from 'react';
import { FormDefinition } from '../../../../../../shared/interfaces/FormFieldDefinition';
import { DetailFormFieldTypes } from '../../../../../../shared/components/DetailForm/DetailFormField';
import { TrainingSessionMaterial, TransportType } from '../../../../../../shared/interfaces/TrainingSessionMaterial';
import { Material } from '../../../../../../shared/interfaces/Material';
import Entity from '../../../../../../shared/interfaces/Entity';
import * as Yup from 'yup';
import SearchMaterialForm from '../../../../../../shared/components/DetailForm/EntitySelector/SelectForms/SearchMaterialForm';
import { enumToOption } from '../../../../../../shared/helpers/dropDownHelpers';

export const newEntity: TrainingSessionMaterial = {
  '@id': '',
  id: 0,
  material: undefined,
  rate: 0,
  trainingSession: undefined,
  transportType: TransportType.Customer
};

export const sessionMaterialsWithFilterCondition = (trainingSessionId: number) => {
  return [
    {
      type: DetailFormFieldTypes.currency,
      propertyName: 'rate',
      label: 'Tarief',
      orderNr: 1,
      required: true,
      validationChecks: Yup.number()
        .min(0, 'Nummer mag niet negatief zijn.')
        .required('Tarief moet ingevuld worden.')
    },
    {
      propertyName: 'material',
      label: 'Materiaal',
      type: DetailFormFieldTypes.entitySelector,
      orderNr: 5,
      validationChecks: Yup.string().required('Selecteer het materiaal.'),
      multiple: false,
      renderEntity: (material: Material) => (
        <span key={material['@id']}>
          {material.name}&nbsp;(€{material.rate})
        </span>
      ),
      filterCondition: trainingSessionId,
      isNewVersion: true,
      dataTableFields: [{ key: 'name' }],
      searchEntityForm: SearchMaterialForm,
      required: true
    },
    {
      propertyName: 'transportType',
      type: DetailFormFieldTypes.dropdownlist,
      dropDownOptions: enumToOption(TransportType),
      label: 'transportType',
      orderNr: 10,
      required: true,
      validationChecks: Yup.string().required('Vervoerstype moet ingevuld worden.')
    }
  ] as FormDefinition;
};

export default [
  {
    type: DetailFormFieldTypes.currency,
    propertyName: 'rate',
    label: 'Tarief',
    orderNr: 1,
    required: true,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required('Tarief is een verplicht veld.')
  },
  {
    propertyName: 'material',
    label: 'Materiaal',
    type: DetailFormFieldTypes.entitySelector,
    orderNr: 5,
    validationChecks: Yup.string().required('Materiaal moet gekozen worden.'),
    multiple: false,
    renderEntity: (material: Material) => (
      <span key={material['@id']}>
        {material.name}&nbsp;(€{material.rate})
      </span>
    ),
    isNewVersion: true,
    dataTableFields: [{ key: 'name' }],
    searchEntityForm: SearchMaterialForm,
    required: true
  },
  {
    propertyName: 'transportType',
    type: DetailFormFieldTypes.dropdownlist,
    dropDownOptions: enumToOption(TransportType),
    label: 'transportType',
    orderNr: 10,
    required: true,
    validationChecks: Yup.string().required()
  }
] as FormDefinition;
