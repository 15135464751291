import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';
import { CalendarEvent } from '../../interfaces/CalendarEvent';
import Toolbar from './Toolbar';
import { WithNamespaces, withNamespaces } from 'react-i18next';

const localizer = BigCalendar.momentLocalizer(moment);
const mobileBreakpoint = 768;

type CalendarProps = {
  events: CalendarEvent[];
  onViewChange: (view: View) => void;
  onDateRangeChange: (range: any) => void;
  onEventSelect: (event: CalendarEvent) => void;
  view: View;
  defaultDate: Date;
} & WithNamespaces;

type View = 'month' | 'week' | 'work_week' | 'day' | 'agenda';

function eventStyleGetter(event: CalendarEvent) {
  return {
    style: {
      backgroundColor: event.color
    }
  };
}

type calendarState = {
  width: number;
};

class Calendar extends Component<CalendarProps, calendarState> {
  constructor(props: CalendarProps) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });

    if (this.state.width <= mobileBreakpoint) {
      this.props.onViewChange('agenda');
    }
  };

  render() {
    const { events, onViewChange, onDateRangeChange, view, defaultDate, onEventSelect, t } = this.props;
    const { width } = this.state;
    const isMobile = width <= mobileBreakpoint;

    return (
      <BigCalendar
        onRangeChange={range => onDateRangeChange(range)}
        onView={view => onViewChange(view)}
        view={this.props.view}
        localizer={localizer}
        events={events}
        startAccessor="start"
        culture={'nl-be'}
        endAccessor="end"
        defaultDate={defaultDate}
        showMultiDayTimes={true}
        popup
        defaultView={isMobile ? 'agenda' : view}
        step={60}
        views={['month', 'week', 'day', 'agenda']}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={event => onEventSelect(event)}
        components={{
          toolbar: Toolbar
        }}
        messages={{ noEventsInRange: t('nothing-planned') }}
      />
    );
  }
}

export default withNamespaces(['calendar'])(Calendar);
