import { DetailFormFieldTypes } from '../../shared/components/DetailForm/DetailFormField';
import { FormDefinition } from '../../shared/interfaces/FormFieldDefinition';
import * as Yup from 'yup';

export const formDefinition: FormDefinition = [
  {
    propertyName: 'hourRate',
    label: 'hourRate',
    type: DetailFormFieldTypes.currency,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required('Prijs moet ingevuld worden.'),
    orderNr: 10,
    required: false
  },
  {
    propertyName: 'travelRate',
    label: 'travelRate',
    type: DetailFormFieldTypes.currency,
    validationChecks: Yup.number()
      .min(0, 'Nummer mag niet negatief zijn.')
      .required('Prijs moet ingevuld worden.'),
    orderNr: 20,
    required: false
  },
  {
    propertyName: 'travelDistance',
    label: 'travelDistance',
    type: DetailFormFieldTypes.distance,
    orderNr: 20,
    required: false
  },
  {
    propertyName: 'agreements',
    label: 'agreements',
    type: DetailFormFieldTypes.textbox,
    orderNr: 20,
    required: false
  }
];
