import SearchTextForm from '../SearchTextForm';
import { configConstants } from '../../../../../../config/constants';
import { fetch } from '../../../../../../core/state/fetch';
import TaskList from '../../../../../interfaces/TaskList';

export async function searchTaskLists(
  searchText: string,
  page?: number,
  sortField?: string,
  sortDirection?: 'asc' | 'desc',
  filterCondition?: string
) {
  const url = `${configConstants.apiUrl}training_predefined_task_lists?active=1&search_text=${searchText}&page=${page}`;
  const response = await fetch(url).then(r => r.json());
  const entities: TaskList[] = response['hydra:member'] || [];

  const amount: number = response['hydra:totalItems'] || 1;
  return { entities, amount };
}

export default class SearchTaskListForm extends SearchTextForm<TaskList> {
  placeholder = 'Zoek takenlijst';
  searchEntities = searchTaskLists;
}
