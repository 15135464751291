import React from 'react';
import './DetailView.scss';
import { Dispatch } from 'redux';
import { Tab, Label, Menu } from 'semantic-ui-react';
import { Trainer, WithCompetencies, WithLanguages } from '../../../shared/interfaces/Trainer';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Course from '../../../shared/interfaces/Course';
import { TrainerTraining } from '../../../shared/interfaces/TrainerTraining';
import { DetailCourseView } from '../Detail/DetailCourseView';
import { DetailTrainingView } from '../Detail/DetailTrainingView';
import { GlobalState } from '../../../core/state';
import { SetTrainerTabIndex } from '../../state/actions';
import { connect } from 'react-redux';
import { DisplayGroup } from '../../../shared/components/DisplayGroup';

interface DetailViewProps {
  selectedTrainer: Trainer<WithLanguages & WithCompetencies>;
  courses: Course[];
  trainings: TrainerTraining[];
  onEditBtnClick: () => void;
  setTabIndex: (index: number, trainer: Trainer) => void;
  activeTab: { index: number; trainer: Trainer } | undefined;
}

interface State {
  activeIndex: number;
  activeTabIndex: number;
}

class DetailView extends React.Component<DetailViewProps & WithNamespaces & RouteComponentProps, State> {
  state: State = { activeIndex: 0, activeTabIndex: 0 };

  componentDidMount() {
    this.checkTabContext();
  }

  componentDidUpdate(prevProps: DetailViewProps) {
    if (this.props.activeTab !== prevProps.activeTab) {
      if (this.props.activeTab) {
        this.setState({ activeTabIndex: this.props.activeTab.index });
      }
    }
  }

  checkTabContext = () => {
    if (this.props.activeTab && this.props.selectedTrainer.id === this.props.activeTab.trainer.id) {
      this.setState({ activeTabIndex: this.props.activeTab.index });
    }
  };

  handleClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { selectedTrainer, courses, trainings, t } = this.props;
    const { activeIndex } = this.state;

    const renderContact = (
      <div className="row contact">
        <div className="col-12 col-md-6">
          <DisplayGroup label={t('email')} value={selectedTrainer.email} />
          <DisplayGroup label={t('phoneNr')} value={selectedTrainer.phoneNr} />
          <DisplayGroup label={t('mobileNr')} value={selectedTrainer.mobileNr} />
          <DisplayGroup label={t('department')} value={selectedTrainer.department} />
          <DisplayGroup
            label={t('languages')}
            value={selectedTrainer.languages
              .map(language => {
                return t(language.iso);
              })
              .join(', ')}
          />
        </div>
        <div className="col-12 col-md-6">
          <div className="display-group">
            <label>{t('Address')}</label>
            <p>
              {selectedTrainer.street + ' ' + selectedTrainer.houseNr} {selectedTrainer.bus ? `bus ${selectedTrainer.bus}` : ''}
              <br />
              {selectedTrainer.city + ' ' + selectedTrainer.postalCode}
              <br />
              {t(selectedTrainer.country)}
            </p>
          </div>
          <DisplayGroup
            label={t('competencies')}
            value={
              selectedTrainer.competencies
                ? selectedTrainer.competencies
                    .map(competency => {
                      return t(competency.value);
                    })
                    .join('<br />')
                : '-'
            }
            isRaw
          />
        </div>
      </div>
    );

    const panes = [
      {
        menuItem: 'Contact',
        render: () => (
          <Tab.Pane attached={false} key="contact">
            {renderContact}
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item name="courses" key="courses">
            <span className="tab-title">{t('courses')}</span>
            {this.props.courses ? (
              <Label circular size={'tiny'} className="counter">
                {this.props.courses.length}
              </Label>
            ) : null}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false} key="cursussen">
            <DetailCourseView {...this.props} courses={courses} trainerId={selectedTrainer.id} />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          <Menu.Item name="trainingshistory" key="trainingshistory">
            <span className="tab-title">{t('trainingshistory')}</span>
            {this.props.trainings ? (
              <Label circular size={'tiny'} className="counter">
                {this.props.trainings.length}
              </Label>
            ) : null}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane attached={false} key="opleidingshistoriek">
            <DetailTrainingView {...this.props} sessions={trainings} />
          </Tab.Pane>
        )
      }
    ];

    return (
      <div className="trainer-detail">
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={this.state.activeTabIndex}
          onTabChange={(e, { activeIndex }) => {
            if (typeof activeIndex === 'number') {
              this.props.setTabIndex(activeIndex, (selectedTrainer as unknown) as Trainer);
            }
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    activeTab: state.trainers.activeTabIndex as { index: number; trainer: Trainer } | undefined
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTabIndex: (id: number, trainer: Trainer) => dispatch(SetTrainerTabIndex({ index: id, trainer: trainer }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withNamespaces(['trainers', 'resources', 'common'], {
    nsMode: 'fallback'
  })(DetailView)
);
