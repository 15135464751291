import { Trainer, TrainerType } from '../../shared/interfaces/Trainer';

export const baseUrl = 'trainers';

export const newEntity: Trainer = {
  '@id': '0',
  id: 0,
  languages: ['/languages/1'],
  competencies: [],
  type: TrainerType.Freelancer,
  firstName: '',
  lastName: '',
  street: '',
  houseNr: '',
  bus: '',
  postalCode: '',
  city: '',
  country: 'BE'
};
