import React from 'react';
import { configConstants } from '../../../../../../config/constants';
import { Trainer, WithCompetencies } from '../../../../../interfaces/Trainer';
import SearchTextForm from '../SearchTextForm';
import { fetch } from '../../../../../../core/state/fetch';

export async function searchTrainers(
  searchText: string,
  page?: number,
  sortField?: string,
  sortDirection?: 'asc' | 'desc',
  filterCondition?: string
) {
  let url = configConstants.apiUrl + 'trainers?active=1&embed[]=competency&search_text=' + searchText;
  if (filterCondition) {
    const modifiedCondition = filterCondition.substring(filterCondition.lastIndexOf('/') + 1);
    url = `${url}&courses.id=${modifiedCondition}`;
  }

  const response = await fetch(url).then(r => r.json());
  const trainers = response['hydra:member'].map((trainer: Trainer<WithCompetencies>) => ({
    ...trainer,
    competencies: (
      <ul>
        {trainer.competencies.map(competency => (
          <li key={competency.id}>{competency.value}</li>
        ))}
      </ul>
    )
  }));
  const entities = trainers || [];
  const amount: number = response['hydra:totalItems'] || 1;
  return { entities, amount };
}

export default class SearchTrainerForm extends SearchTextForm<Trainer<WithCompetencies>> {
  searchEntities = searchTrainers;
  placeholder = 'Zoek lesgever';
}
