import Client, { ClientType } from '../../shared/interfaces/Client';
import Contact from '../../shared/interfaces/Contact';

export enum ContactType {
  Participant = 'deelnemer',
  Responsible = 'verantwoordelijke',
  ContactPerson = 'contactpersoon'
}

export const baseUrl = 'clients';

export const newClientData: Client = {
  '@id': '',
  id: 0,
  type: ClientType.company,
  companyName: '',
  street: '',
  houseNr: '',
  bus: '',
  postalCode: '',
  city: '',
  country: 'BE',
  email: '',
  website: '',
  contacts: [],
  languages: ['/languages/1'],
  accountNr: '',
  vatNr: '',
  hasNewsletter: false,
  locations: []
};

export const newContactData: Contact = {
  id: 0,
  '@id': '0',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  cellphone: '',
  customer: undefined,
  hasNewsletter: false,
  isLeading: false
};
