import { configConstants } from '../../../config/constants';
import Contact from '../../../shared/interfaces/Contact';
import { fetch } from '../../../core/state/fetch';
import AlertService from '../../../core/alert/AlertService';

export default function saveContact(contact: Contact): Promise<Contact> {
  const isNew = contact.id === 0;
  const data = {
    method: isNew ? 'POST' : 'PUT',
    body: JSON.stringify({
      ...contact,
      id: isNew ? undefined : contact.id,
      '@id': isNew ? undefined : contact['@id']
    }),
    headers: { 'Content-Type': 'application/json' }
  };
  return fetch(configConstants.apiUrl + 'contacts' + (isNew ? '' : '/' + contact.id), data).then(r => {
    if (r.status >= 200 && r.status < 300) {
      AlertService.setMessage({
        title: 'Contact toegevoegd',
        messageText: 'Contact persoon succesvol toegevoegd.',
        type: 'success'
      });
      return r.json();
    }

    AlertService.setMessage({
      title: 'Contact niet toegevoegd',
      messageText: 'Oeps er liep iets mis tijdens het toevoegen van de contact persoon',
      type: 'error'
    });
    return r.json();
  });
}
