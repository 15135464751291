import { CourseLocation } from '../../shared/interfaces/CourseLocation';

export const baseUrl = 'locations';

export const newEntity: CourseLocation = {
  '@id': '/locations/0',
  id: 0,
  name: '',
  street: '',
  houseNr: '',
  bus: '',
  postalCode: '',
  city: '',
  country: 'BE',
  rate: '',
  travelDirections: '',
  remarks: '',
  contactPersonFirstName: '',
  contactPersonLastName: '',
  contactPersonEmail: '',
  contactPersonPhoneNr: '',
  isInternalLocation: false,
  hasInternet: false,
  hasBeamer: false,
  hasDrinks: false,
  customer: undefined,
  numberOfParticipants: undefined
};
