import { createSelector } from 'reselect';
import { TaskListsState } from './state';
import { GlobalState } from '../../core/state';

export const getTaskLists = createSelector(
  (state: TaskListsState) => state,
  state => state.taskLists
);

export const getSelectedTaskListId = createSelector(
  (state: TaskListsState) => state,
  state => state.selectedTaskListId
);

export const getSelectedTaskList = createSelector(
  (state: TaskListsState) => state,
  state => state.selectedTaskList
);

export const getLoadingLocations = createSelector(
  (state: TaskListsState) => state,
  state => state.loadingTaskListsStatus
);

export const getSavingLocations = createSelector(
  (state: TaskListsState) => state,
  state => state.savingTaskList
);

export const getLocationSaved = createSelector(
  (state: TaskListsState) => state,
  state => state.savingTaskList
);

export const getAmountPages = createSelector(
  (state: TaskListsState) => state,
  state => Math.ceil(state.totalTaskLists / state.pageSize)
);
