import { takeLatest, call, all, put } from 'redux-saga/effects';
import * as actions from './actions';
import { configConstants } from '../../config/constants';
import Language from '../../shared/interfaces/Language';
import * as constants from '../../shared/constants';
import ApiResponse from '../../shared/interfaces/ApiResponse';
import { fetch } from '../../core/state/fetch';
import { getType } from 'typesafe-actions';
import Modules from '../../shared/interfaces/Modules';
import Axios, { AxiosResponse } from 'axios';
import Competency from '../../shared/interfaces/Competency';

function* loadResources() {
  try {
    const response = yield all([call(fetch, `${configConstants.apiUrl}languages`), call(fetch, `${configConstants.apiUrl}competencies`)]);

    if (response[0].status >= 200 && response[0].status < 300) {
      const languageData = (yield response[0].json()) as ApiResponse<Language[]>;
      const competenciesData = (yield response[1].json()) as ApiResponse<Competency[]>;
      yield put(
        actions.LoadResources.success({
          languages: languageData['hydra:member'] as Language[],
          competencies: competenciesData['hydra:member'] as Competency[],
          sexes: constants.sexes,
          countryCodes: constants.countryCodes
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    yield put(actions.LoadResources.failure(error.statusText));
  }
}

function* fetchActiveModules() {
  let activeModules = { requestModule: false, materialModule: false } as Modules;

  yield Axios.get(`${configConstants.apiUrl}feature-toggle`)
    .then(function(response) {
      activeModules = {
        requestModule: response.data.feature_toggle_requests === 'true',
        materialModule: response.data.feature_toggle_materials === 'true'
      };
    })
    .catch(function(error) {
      put(actions.RequestActiveModules.failure());
    });

  yield put(actions.RequestActiveModules.success(activeModules));
}

function* doConfirmAction(action: ReturnType<typeof actions.ConfirmAction.success>) {
  action.payload();
  yield;
}

export const appSaga = [
  takeLatest(getType(actions.LoadResources.request), loadResources),
  takeLatest(getType(actions.RequestActiveModules.request), fetchActiveModules),
  takeLatest(getType(actions.ConfirmAction.success), doConfirmAction)
];
