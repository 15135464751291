// CORE
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

// CONFIG
import moment from 'moment-timezone';
import 'moment/locale/nl-be';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './config/i18';
import configureStore from './config/configStore';
import { history } from './config/configStore';

// STYLES
import './index.scss';
import './styles/styles.scss';
import './styles/animate.css';

// Noty Styles
import '../node_modules/noty/lib/noty.css';
import '../node_modules/noty/lib/themes/relax.css';
import MainRouter from './core/MainRouter';

const store = configureStore();

moment.locale('nl-be');
moment.tz.setDefault("UTC");

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ConnectedRouter history={history}>
        <MainRouter />
      </ConnectedRouter>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
