import React, { useState, Fragment } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { Mail, WithTrainingRequestDocuments } from '../../../shared/interfaces/Mail';
import moment from 'moment';
import AttachmentList from '../../../shared/components/AttachmentList/index';
import Drawer from '../../../shared/components/Drawer';

type DetailViewMails = {
  mails: Mail<WithTrainingRequestDocuments>[];
} & WithNamespaces;

export const DetailViewDocuments = ({ mails, t }: DetailViewMails) => {
  const [selectedMail, setSelectedMail] = useState<Mail<WithTrainingRequestDocuments> | undefined>(undefined);
  mails.sort((a: any, b: any) => {
    const x = a.createdAt;
    const y = b.createdAt;
    if (x > y) {
      return -1;
    }
    if (x < y) {
      return 1;
    }
    return 0;
  });
  return (
    <>
      <div className="mail-box">
        {undefined === mails || 0 === mails.length ? (
          <p>{t('noMails')}</p>
        ) : (
          mails.map((mail, index) => {
            return (
              <Fragment key={index}>
                <div className="mail" onClick={() => setSelectedMail(mail)}>
                  <h5>{mail.subject}</h5>
                  <span>{moment(mail.createdAt).format('DD/MM/YYYY')}</span>
                  <div className="teaser">{mail.body ? mail.body.replace(/(<([^>]+)>)/gi, '') : null}</div>
                  <div className="attachments">
                    <Icon name="paperclip" />
                    {mail.documents ? mail.documents.length : '0'}
                  </div>
                </div>
                {mails.length === index + 1 ? null : <hr />}
              </Fragment>
            );
          })
        )}

        <Drawer isOpen={selectedMail ? true : false} closeDrawer={() => setSelectedMail(undefined)}>
          {selectedMail ? (
            <div className="mail-detail">
              <h2>{selectedMail.subject}</h2>
              <span>{moment(selectedMail.createdAt).format('DD/MM/YYYY')}</span>
              {selectedMail.body ? <div className="mail-body raw-html" dangerouslySetInnerHTML={{ __html: selectedMail.body }} /> : null}
              {selectedMail.documents ? (
                <div className="m-t-lg">
                  <AttachmentList files={selectedMail.documents.map(d => d.trainingRequestDocument || [])} />
                </div>
              ) : null}
            </div>
          ) : null}
        </Drawer>
      </div>
    </>
  );
};

export default withNamespaces(['requests'], { nsMode: 'fallback' })(DetailViewDocuments);
