import { DeepReadonly } from 'utility-types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { configConstants } from '../../config/constants';
import { CourseLocation, WithClient } from '../../shared/interfaces/CourseLocation';
import ProgressStatus from '../../shared/interfaces/ProgressStatus';
import { GQLSessionLocations } from '../../shared/interfaces/GQLSessionLocations';
import CalendarListLocation from '../../shared/interfaces/CalendarLocation';
import TrainingSession, { WithTraining } from '../../shared/interfaces/TrainingSession';
export type LocationsAction = ActionType<typeof actions>;
const { pageSize } = configConstants;

// Interface
export type LocationsState = DeepReadonly<{
  loading: boolean;
  loadingLocations: boolean;
  locations: CourseLocation[];
  sessionLocations: GQLSessionLocations | null;
  calendarLocationList: CalendarListLocation[] | null;
  errorMessage: string | null;
  selectedLocationId: number;
  calendarListStatus: ProgressStatus;
  locationStatus: ProgressStatus;
  sessionLocationStatus: ProgressStatus;
  selectedLocation: CourseLocation<WithClient> | null;
  savingLocation: boolean;
  locationSaved: boolean;
  pageSize: number;
  totalLocations: number;
  sessions: TrainingSession<WithTraining>[];
}>;

// Initial State
const initialState: LocationsState = {
  loading: false,
  loadingLocations: false,
  errorMessage: null,
  locations: [],
  calendarLocationList: null,
  sessionLocations: null,
  calendarListStatus: ProgressStatus.Uninitialized,
  locationStatus: ProgressStatus.Uninitialized,
  sessionLocationStatus: ProgressStatus.Uninitialized,
  selectedLocationId: -1,
  selectedLocation: null,
  savingLocation: false,
  locationSaved: false,
  pageSize: pageSize,
  totalLocations: 0,
  sessions: []
};

// Reducer
export function locations(state: LocationsState = initialState, action: LocationsAction): LocationsState {
  switch (action.type) {
    case getType(actions.FetchLocations.success):
      return {
        ...state,
        locations: action.payload.locations,
        locationStatus: ProgressStatus.Done,
        totalLocations: action.payload.totalLocations
      };
    case getType(actions.FetchTrainingSessionLocations.success):
      return {
        ...state,
        sessionLocationStatus: ProgressStatus.Done,
        sessionLocations: action.payload.sessionLocations
      };
    case getType(actions.FetchOneLocation.request):
    case getType(actions.FetchLocations.request):
      return {
        ...state,
        locationStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchCalendarListLocations.request):
      return {
        ...state,
        calendarListStatus: ProgressStatus.InProgress
      };
    case getType(actions.FetchCalendarListLocations.success):
      return {
        ...state,
        calendarListStatus: ProgressStatus.Done,
        calendarLocationList: action.payload.calendarLocationList
      };
    case getType(actions.FetchTrainingSessionLocations.request):
      return {
        ...state,
        sessionLocationStatus: ProgressStatus.InProgress
      };

    case getType(actions.SaveLocation.request):
      return { ...state, savingLocation: true, locationSaved: false };
    case getType(actions.SaveLocation.success):
      return {
        ...state,
        savingLocation: false,
        locationSaved: true
      };
    case getType(actions.SaveLocation.failure):
      return {
        ...state,
        savingLocation: false,
        errorMessage: action.payload.errorMessage,
        locationSaved: false
      };
    case getType(actions.FetchOneLocation.success):
      return {
        ...state,
        selectedLocation: action.payload.location,
        locationStatus: ProgressStatus.Done
      };
    case getType(actions.ClearLocations):
      return {
        ...initialState
      };
    case getType(actions.FetchLocations.failure):
    case getType(actions.FetchOneLocation.failure):
    case getType(actions.FetchCalendarListLocations.failure):
    case getType(actions.FetchTrainingSessionLocations.failure):
      return { ...state, locationStatus: ProgressStatus.Error };
    case getType(actions.DeleteLocation.failure):
    case getType(actions.FetchSessionsForLocation.request):
      return {
        ...state
      };
    case getType(actions.FetchSessionsForLocation.success):
      return {
        ...state,
        sessions: action.payload.sessions
      };
    case getType(actions.FetchSessionsForLocation.failure):
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };
    default:
      return { ...state };
  }
}
