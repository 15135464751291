import CourseCategory from '../../shared/interfaces/CourseCategory';

export const baseUrl = 'courses';

export const newCategoryData: CourseCategory = {
  '@id': '',
  id: 0,
  title: '',
  parent: '',
  courses: [],
  children: []
};
