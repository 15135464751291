import React, { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';

interface CheckboxProps {
  checked: boolean;
}

export const CustomCheckbox = ({ checked }: CheckboxProps) => {
  return (
    <>
      {checked ? (
        <Icon className="custom-checkbox active" name="check circle" />
      ) : (
        <Icon className="custom-checkbox" name="circle outline" />
      )}
    </>
  );
};
