import React, { Dispatch } from 'react';
import { RouteComponentProps } from 'react-router';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { GlobalState } from '../../../core/state';
import * as actions from '../../state/actions';
import { CategoryAction } from '../../state/state';
import PageHeader from '../../../shared/components/PageHeader';
import QueryParams from '../../../shared/interfaces/QueryParams';
import { queryParams } from '../../../shared/helpers/queryStringHelpers';
import { Icon } from 'semantic-ui-react';
import CourseCategory from '../../../shared/interfaces/CourseCategory';
import { ConfirmAction } from '../../../core/state/actions';
import { baseUrl } from '../../helpers/constants';
import { AppAction } from '../../../core/state/app.state';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import InitialLoader from '../../../shared/components/InitialLoader';
import AlertService from '../../../core/alert/AlertService';

interface EditProps {
  location: any;
  loadCategories: (queryParams: QueryParams) => void;
  deleteCategory: (categoryId: number, queryParams: QueryParams) => void;
  loadingCategoriesStatus: ProgressStatus;
  categories: CourseCategory[];
}

class CategoryList extends React.Component<EditProps & RouteComponentProps & WithNamespaces> {
  queryParams(props: EditProps = this.props) {
    return queryParams<QueryParams>(props.location.search);
  }

  componentDidMount = () => {
    const { loadCategories } = this.props;
    loadCategories(this.queryParams());
  };

  canArchive(category: CourseCategory) {
    const { t } = this.props;

    if (category.courses.length > 0 && category.children.length > 0) {
      const errorMessage = this.props.t('coursesAndCategoriesAttached', {
        totalCategories: `${category.children.length}`,
        totalCourses: `${category.courses.length}`
      });
      AlertService.setMessage({ title: t('courses.category.archive.not-posible'), messageText: errorMessage, type: 'warning' });
      return;
    }

    if (category.courses.length > 0 && category.children.length === 0) {
      const errorMessage = this.props.t('coursesAttached', { totalCourses: `${category.courses.length}` });
      AlertService.setMessage({ title: t('courses.category.archive.not-posible'), messageText: errorMessage, type: 'warning' });
      return;
    }

    if (category.courses.length === 0 && category.children.length > 0) {
      const errorMessage = this.props.t('categoriesAttached', { totalCategories: `${category.children.length}` });
      AlertService.setMessage({ title: t('courses.category.archive.not-posible'), messageText: errorMessage, type: 'warning' });
      return;
    }

    this.props.deleteCategory(category.id, this.queryParams());
  }

  makeColumn(Categories: CourseCategory[]) {
    return Categories.map((category: CourseCategory) => (
      <div className="category" key={category.id}>
        <p>{category.title}</p>
        <span className="buttons">
          <button className="edit-button" onClick={() => this.props.history.push(`/${baseUrl}/categories/edit/${category.id}`)}>
            <Icon name="edit" />
          </button>
          <button className="archive-button" onClick={() => this.canArchive(category)}>
            <Icon name="archive" />
          </button>
        </span>
      </div>
    ));
  }

  render() {
    const { t, categories, loadingCategoriesStatus } = this.props;

    if (loadingCategoriesStatus === ProgressStatus.InProgress) {
      return <InitialLoader />;
    }

    const columnLengthXl = Math.ceil(categories.length / 3);
    const columnOneCategoriesXl = categories.slice(0, columnLengthXl);
    const columnTwoCategoriesXl = categories.slice(columnLengthXl, columnLengthXl * 2);
    const columnThreeCategoriesXl = categories.slice(columnLengthXl * 2);

    const columnLengthLg = Math.ceil(categories.length / 2);
    const columnOneCategoriesLg = categories.slice(0, columnLengthLg);
    const columnTwoCategoriesLg = categories.slice(columnLengthLg);

    return (
      <>
        <PageHeader title={t('edit category')} breadCrumbParts={[t('courses'), t('categories'), t('edit')]} {...this.props} />

        <button className="back-button" onClick={() => this.props.history.push(`/${baseUrl}`)}>
          <Icon name="arrow left" />
        </button>

        <div className="row">
          <div className="col-12 col-lg-6 col-xl-4">
            <div className="d-none d-xl-block">{this.makeColumn(columnOneCategoriesXl)}</div>
            <div className="d-none d-lg-block d-xl-none">{this.makeColumn(columnOneCategoriesLg)}</div>
            <div className="d-block d-lg-none">{this.makeColumn(categories)}</div>
          </div>

          <div className="col-lg-6 col-xl-4">
            <div className="d-none d-xl-block">{this.makeColumn(columnTwoCategoriesXl)}</div>
            <div className="d-none d-lg-block d-xl-none">{this.makeColumn(columnTwoCategoriesLg)}</div>
          </div>

          <div className="col-xl-4">
            <div className="d-none d-xl-block">{this.makeColumn(columnThreeCategoriesXl)}</div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    categories: state.categories.categories,
    loadingCategoriesStatus: state.categories.loadingCategoriesStatus
  };
};

const mapDispatchToProps = (dispatch: Dispatch<CategoryAction | AppAction>) => ({
  loadCategories: (queryParams: QueryParams) => dispatch(actions.FetchCategories.request(queryParams)),
  deleteCategory: (categoryId: number, queryParams: QueryParams) =>
    dispatch(
      ConfirmAction.request({
        title: 'deleteCategory',
        content: 'confirmDeleteCategory',
        action: () => dispatch(actions.DeleteCategory.request({ categoryId, queryParams }))
      })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['resources', 'menu', 'categories', 'courses'], { nsMode: 'fallback' })(CategoryList));
