import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from '../../../core/state';
import * as actions from '../../state/actions';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import InitialLoader from '../../../shared/components/InitialLoader';
import Client from '../../../shared/interfaces/Client';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Dispatch } from 'redux';
import DetailView from './DetailView';
import { formDefinition } from '../../helpers/DetailFormFields';
import Contact from '../../../shared/interfaces/Contact';
import Language from '../../../shared/interfaces/Language';
import DetailForm from '../../../shared/components/DetailForm/DetailForm';
import { baseUrl } from '../../helpers/constants';
import { CourseLocation } from '../../../shared/interfaces/CourseLocation';
import { GQLTraining, GQLRequest } from '../../state/state';
import ProgressStatus from '../../../shared/interfaces/ProgressStatus';
import PageHeader from '../../../shared/components/PageHeader';

export interface ClientResources {
  languages: Language[];
}

type DetailShellProps = {
  loadClient: (id: number) => void;
  loadClientTrainings: (id: number) => void;
  loadClientRequests: (id: number) => void;
  saveClient: (client: Client) => void;
  selectedClient: Client | undefined;
  clientContacts: Contact[] | undefined;
  locations: CourseLocation[] | undefined;
  trainings: GQLTraining[] | undefined;
  requests: GQLRequest[] | undefined;
  unlinkLocation: (id: number, clientId: number) => void;
  resources: ClientResources;
  loadingTrainingsStatus: ProgressStatus;
  loadingRequestsStatus: ProgressStatus;
};

interface RouteInfo {
  id: string;
}

class DetailShell extends Component<DetailShellProps & WithNamespaces & RouteComponentProps<RouteInfo>> {
  componentDidMount = ({ loadClientTrainings, loadClient, loadClientRequests, match, t } = this.props) => {
    loadClient(parseInt(match.params.id));
    loadClientTrainings(parseInt(match.params.id));
    loadClientRequests(parseInt(match.params.id));
  };

  render() {
    const { selectedClient, clientContacts, match, history, resources, saveClient, locations, trainings, t, unlinkLocation } = this.props;

    if (!selectedClient || !clientContacts || !locations || !trainings) {
      return <InitialLoader />;
    }

    return (
      <Switch>
        <Route
          exact
          path={`${match.path}/edit`}
          render={() => (
            <>
              <PageHeader
                {...this.props}
                breadCrumbParts={[t('clients'), selectedClient.companyName, t('edit')]}
                title={selectedClient.companyName}
              />
              <DetailForm
                onFormSubmit={data => {
                  delete data.contacts;
                  delete data.locations;
                  saveClient(data as Client);
                }}
                // savingForm={savingForm}
                selectedEntity={selectedClient as Client}
                onCancelBtnClick={() => history.push(`/${baseUrl}/${match.params.id}`)}
                formDefinition={formDefinition(resources.languages)}
              />
            </>
          )}
        />
        <Route
          exact
          path={`${match.path}`}
          render={() => (
            <DetailView
              key={selectedClient.id}
              {...this.props}
              client={selectedClient as Client}
              clientContacts={clientContacts}
              onEditBtnClick={() => this.props.history.push(`/clients/${match.params.id}/edit`)}
              unlinkLocation={(id: number) => unlinkLocation(id, parseInt(match.params.id, 10))}
              locations={locations}
              languages={resources.languages}
            />
          )}
        />
        <Route
          render={() => (
            <Redirect
              to={{
                pathname: `/${match.url.split('/')[1]}`
              }}
            />
          )}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state: GlobalState) => {
  return {
    loadingTrainingsStatus: state.clients.loadingTrainingsStatus,
    loadingRequestsStatus: state.clients.loadingRequestsStatus,
    selectedClient: state.clients.selectedClient as Client | undefined,
    clientContacts: state.clients.clientContacts as Contact[] | undefined,
    locations: state.clients.clientLocations as CourseLocation[] | undefined,
    trainings: state.clients.clientTrainings as GQLTraining[] | undefined,
    requests: state.clients.clientRequests as GQLRequest[] | undefined,
    resources: {
      languages: state.app.languages as Language[]
    }
  };
};

const mapDispatchToProps = (dispatch: Dispatch, getState: () => GlobalState) => ({
  loadClient: (id: number | string) => dispatch(actions.FetchOneClient({ clientId: id as number })),
  loadClientTrainings: (id: number) => dispatch(actions.FetchClientTrainings.request(id)),
  loadClientRequests: (id: number) => dispatch(actions.FetchClientRequests.request(id)),
  saveClient: (client: Client) => dispatch(actions.SaveClient({ client })),
  unlinkLocation: (id: number, clientId: number) => dispatch(actions.UnlinkLocationFromClient.request({ id, clientId }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces(['clients', 'menu', 'resources'], { nsMode: 'fallback' })(DetailShell));
