import React from 'react';
import { getEnvSettings } from '../core/environments/environments';

type subscriptionWebhookProperties = {
  topic: string;
  hook: string;
  token: string;
};

class Webhook extends React.Component<{}, subscriptionWebhookProperties> {
  constructor(props: any) {
    super(props);
    this.state = { topic: '', hook: '', token: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // @ts-ignore
    fetch(
      `${getEnvSettings(process.env.REACT_APP_ENVIRONMENT).REACT_APP_API_URL}webhook/webhook-access-token?code=${
        this.props.match.params.c
      }`,
      {
        method: 'GET'
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.access_token) {
          this.setState({ token: data.access_token });
        } else {
          // @ts-ignore
          window.location = `${getEnvSettings(process.env.REACT_APP_ENVIRONMENT).REACT_APP_API_URL}webhook/login`;
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  handleChange(event: any) {
    const obj: any = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  }

  handleSubmit(event: any) {
    fetch(
      `${getEnvSettings(process.env.REACT_APP_ENVIRONMENT).REACT_APP_API_URL}webhook/subscribe?hook=${this.state.hook}&topic=${
        this.state.topic
      }`,
      {
        method: 'GET'
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.access_token) {
          this.setState({ token: data.access_token });
        } else {
          // @ts-ignore
          window.location = `${getEnvSettings(process.env.REACT_APP_ENVIRONMENT).REACT_APP_API_URL}webhook/login`;
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });

    event.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>Topic:</label>
        <input type="text" name={'topic'} value={this.state.topic} onChange={this.handleChange} />
        <br />
        <label>Hook:</label>
        <input type="text" name={'hook'} value={this.state.hook} onChange={this.handleChange} />
        <br />
        <input type="submit" value="Subscribe hook" />
      </form>
    );
  }
}

export default Webhook;
