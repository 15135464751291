import { createAsyncAction } from 'typesafe-actions';
import { Material } from '../../shared/interfaces/Material';
import { GQLSessionMaterials } from '../../shared/interfaces/GQLSessionMaterials';
import CalendarListItem from '../../shared/interfaces/CalendarLocation';
import QueryParams from '../../shared/interfaces/QueryParams';

export enum MaterialActionTypes {
  SaveMaterial = '[Materials] Saving Material...',
  SaveMaterialSuccess = '[Materials] Saving Material Success',
  SaveMaterialFail = '[Materials] Saving Material Fail',
  FetchOneMaterial = '[Materials] Fetch One Material',
  FetchOneMaterialSuccess = '[Materials] Fetch One Material Success',
  FetchOneMaterialFail = '[Materials] Fetch One Material Fail',
  FetchTrainingSessionMaterials = '[Materials] Fetch Training Session Materials',
  FetchTrainingSessionMaterialsSuccess = '[Materials] Fetch Training Session Material Success',
  FetchTrainingSessionMaterialsFail = '[Materials] Fetch Training Session Material Fail',
  FetchCalendarListMaterials = '[Materials] Fetch Calendar Materials...',
  FetchCalendarListMaterialsSuccess = '[Materials] Fetch Calendar Materials Success',
  FetchCalendarListMaterialsFail = '[Materials] Fetch Calendar Materials Fail'
}

export const SaveMaterial = createAsyncAction(
  MaterialActionTypes.SaveMaterial,
  MaterialActionTypes.SaveMaterialSuccess,
  MaterialActionTypes.SaveMaterialFail
)<{ material: Material; noredirect?: boolean }, { material: Material }, { errorMessage: string }>();

export const FetchOneMaterial = createAsyncAction(
  MaterialActionTypes.FetchOneMaterial,
  MaterialActionTypes.FetchOneMaterialSuccess,
  MaterialActionTypes.FetchOneMaterialFail
)<{ materialId: number }, { material: Material }, { errorMessage: string }>();

export const FetchTrainingSessionMaterials = createAsyncAction(
  MaterialActionTypes.FetchTrainingSessionMaterials,
  MaterialActionTypes.FetchTrainingSessionMaterialsSuccess,
  MaterialActionTypes.FetchTrainingSessionMaterialsFail
)<QueryParams, { sessionMaterials: GQLSessionMaterials }, { errorMessage: string }>();

export const FetchCalendarListMaterials = createAsyncAction(
  MaterialActionTypes.FetchCalendarListMaterials,
  MaterialActionTypes.FetchCalendarListMaterialsSuccess,
  MaterialActionTypes.FetchCalendarListMaterialsFail
)<{}, { calendarMaterialList: CalendarListItem[] }, { errorMessage: string }>();

export const DeleteMaterial = createAsyncAction(
  '[Materials] Delete material...',
  '[Materials] Delete material success',
  '[Materials] Delete material failure'
)<{ materialId: number; queryParams?: QueryParams }, void, { errorMessage: string }>();

export const FetchAllMaterials = createAsyncAction(
  '[Materials] Fetching all materials...',
  '[Materials] Fetching all materials success',
  '[Materials] Fetching all materials failure'
)<void, Material[], { errorMessage: string }>();
