export const dataTableModel = ['title', 'courseType', 'active'];
export const DetailFields = [
  'courseType',
  'laptopCourse',
  'numberOfDays',
  'numberOfEvenings',
  'pricePerCourse',
  'pricePerDay',
  'pricePerSession',
  'standardPricing'
];
export const BooleanFields = ['laptopCourse', 'openSchedule'];
